import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { createPortal } from "react-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";

export const ColorPickerField: React.FC<{ color: string; onChange: (color: string) => void }> = ({ color, onChange }) => {
  const [isPickerOpen, setPickerOpen] = useState(false);
  const [anchorPosition, setAnchorPosition] = useState<{ top: number; left: number } | null>(null);

  const handleColorChange = (color: any) => {
    onChange(color.hex); 
  };

  const togglePicker = (event: React.MouseEvent) => {
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    setAnchorPosition({ top: rect.bottom + window.scrollY, left: rect.left + window.scrollX });
    setPickerOpen((prev) => !prev);
  };

  return (
    <>
      <div
        onClick={togglePicker}
        style={{
          width: "50px", 
          height: "50px",
          borderRadius: "50%", 
          border: "2px solid #ccc",
          backgroundColor: color || "#ffffff",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
        }}
      />
      {isPickerOpen &&
        anchorPosition &&
        createPortal(
          <ClickAwayListener onClickAway={() => setPickerOpen(false)}>
            <div
              style={{
                position: "absolute",
                top: anchorPosition.top,
                left: anchorPosition.left,
                zIndex: 1300,
              }}
            >
              <SketchPicker color={color} onChangeComplete={handleColorChange} />
            </div>
          </ClickAwayListener>,
          document.body
        )}
    </>
  );
};