import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  MenuItem,
  IconButton,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Formik, FieldArray, Form } from "formik";
import { NuvvenDivider } from "../../../ReservationManagement/Summary/Components/Divider";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { IAllocationRule, SelectionRuleCategory, updateAllocationRules } from "./utils";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { GET_ALLOCATION_RULES, SAVE_ALLOCATION_RULES } from "../../../../../graphql/allocationRules/allocationRules";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";

const renderMessage = (message: string) => (
  <Grid item xs={12}>
    <Typography
      variant="h3"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {message}
    </Typography>
  </Grid>
);

const AllocationRules: React.FC = () => {
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [allocationRuleCards, setAllocationRuleCards] = useState<IAllocationRule[]>([]);
  const [selectedRule, setSelectedRule] = useState<string>("");
  const isSupplierPortalEnabled = userState.currentOrganisation.supplierPortalEnabled;
  const driverAllocationRules: string[] = userState.currentOrganisation.enableDriverAllocationRules && userState.currentOrganisation.driverAllocationRules ? userState.currentOrganisation.driverAllocationRules : [];

  const [loadAllocationRules, { loading }] = useLazyQuery(GET_ALLOCATION_RULES, {
    onCompleted: ({ allocationRules }) => {
      setAllocationRuleCards(updateAllocationRules(allocationRules))
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
  })

  const [saveAllocationRulesMutation, { loading: saveAllocationRulesLoading }] = useMutation(SAVE_ALLOCATION_RULES, {
    onCompleted: (data) => {
      snackbar({
        message: 'Allocation Rules Saved Successfully!',
        variant: SnackBarVariant.SUCCESS
      });
      setAllocationRuleCards(updateAllocationRules(data?.saveAllocationRules || []))
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
  })

  const handleCardChange = (index: number, updatedCard: IAllocationRule) => {
    setAllocationRuleCards((prevCards) => {
      const updatedCards = [...prevCards];
      if (updatedCard.rules) {
        updatedCard.rules = updatedCard.rules.map((rule, idx) => ({
          ...rule,
          sequence: idx + 1
        }));
      }
      updatedCards[index] = updatedCard;
      return updatedCards;
    });
  };

  function saveAllocationRules() {
    saveAllocationRulesMutation({
      variables: {
        allocationRules: allocationRuleCards
      }
    })
  }

  useEffect(() => {
    isSupplierPortalEnabled && loadAllocationRules()
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12}>
        <Typography variant="h1">Allocation Rules</Typography>
      </Grid>
      <Grid item xs={12}>
        <NuvvenDivider noMargin />
      </Grid>
      {!isSupplierPortalEnabled ? (
        renderMessage("The Supplier Portal is disabled. Please contact the team for assistance.")
      ) : !userState.currentOrganisation.enableDriverAllocationRules ? (
        renderMessage("Allocation rule is disabled. Please enable it in the organization settings to proceed.")
      ) : (
        <>
          <Grid item xs={12} >
            <Typography variant="h4">Note: These rules are interdependent, 1st the 3PL allocation rule will take priority to allocate the 3PL and then the driver allocation</Typography>
          </Grid>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                mt: 2
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Grid item container spacing={2} xs={12}>
              {allocationRuleCards.map((card, cardIndex) => (
                <Grid item xs={12} sm={6} md={6} key={cardIndex}>
                  <Formik
                    initialValues={card}
                    onSubmit={(values) => {
                      console.log("Submitting Card Data:", values, cardIndex);
                    }}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      touched,
                      errors,
                      setFieldValue,
                    }) => {
                      const availableRules = driverAllocationRules.filter(
                        (allocationRule) => !values.rules.some((rule) => rule.ruleName === allocationRule)
                      );

                      useEffect(() => {
                        handleCardChange(cardIndex, values);
                      }, [values]);

                      return (
                        <Form>
                          <Card sx={{ width: "100%" }}>
                            <CardContent>
                              <Grid
                                container
                                item
                                mb={3}
                              >
                                <Typography variant="h3">
                                  {SelectionRuleCategory[values.ruleCategory as keyof typeof SelectionRuleCategory]}
                                </Typography>
                              </Grid>

                              {/* Rule List */}
                              <FieldArray name="rules">
                                {({ remove, push }) => (
                                  <Box>
                                    <div
                                      onDragOver={(e) => e.preventDefault()}
                                      onDrop={(e: React.DragEvent<HTMLDivElement>) => {
                                        e.preventDefault();
                                        const draggedIndex = parseInt(e.dataTransfer.getData("draggedIndex"), 10);
                                        const targetElement = (e.target as HTMLElement).closest("[data-index]") as HTMLElement;
                                        if (!targetElement) return;
                                        const droppedIndex = parseInt(targetElement.getAttribute("data-index") || "-1", 10);
                                        if (
                                          isNaN(draggedIndex) ||
                                          isNaN(droppedIndex) ||
                                          draggedIndex === droppedIndex
                                        ) {
                                          return;
                                        }
                                        const reorderedRules = [...values.rules];
                                        const [draggedItem] = reorderedRules.splice(draggedIndex, 1);
                                        reorderedRules.splice(droppedIndex, 0, draggedItem);
                                        reorderedRules.forEach((rule, index) => {
                                          rule.sequence = index + 1;
                                        });
                                        setFieldValue("rules", reorderedRules);
                                      }}
                                    >
                                      <Grid container spacing={2} sx={{ mb: 2 }}>
                                        <Grid item xs={2} />
                                        <Grid item xs={6}>
                                          <Typography variant="body2" fontWeight="bold">
                                            Rule
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Typography variant="body2" fontWeight="bold">
                                            SL
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={1} />
                                        <Grid item xs={12}>
                                          <NuvvenDivider noMargin />
                                        </Grid>
                                      </Grid>
                                      {values.rules.map((rule, index) => (
                                        <Grid
                                          container
                                          spacing={2}
                                          alignItems="center"
                                          sx={{ mb: 1 }}
                                          key={index}
                                          draggable
                                          data-index={index}
                                          onDragStart={(e) => e.dataTransfer.setData("draggedIndex", index.toString())}
                                          onDragOver={(e) => e.preventDefault()}
                                        >
                                          <Grid item xs={2}>
                                            <DragIndicatorIcon sx={{ transform: "rotate(90deg)", cursor: "grab" }} />
                                          </Grid>

                                          <Grid item xs={6}>
                                            {rule.ruleName}
                                          </Grid>

                                          <Grid item xs={2}>
                                            <Typography variant="body2">{index + 1}</Typography>
                                          </Grid>

                                          <Grid item xs={2}>
                                            <IconButton
                                              color="default"
                                              size="small"
                                              onClick={() => remove(index)}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </Grid>

                                          <Grid item xs={12}>
                                            <NuvvenDivider noMargin />
                                          </Grid>
                                        </Grid>
                                      ))}
                                    </div>

                                    {/* Add New Rule */}
                                    {availableRules.length > 0 && (
                                      <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                                        <Grid item xs={2}>
                                          <DragIndicatorIcon sx={{ transform: "rotate(90deg)" }} />
                                        </Grid>

                                        <Grid item xs={6}>
                                          <TextField
                                            select
                                            fullWidth
                                            label="Select Rule"
                                            variant="outlined"
                                            onChange={(e) => {
                                              push({
                                                ruleName: e.target.value,
                                                sequence: values.rules.length + 1,
                                              });
                                              setSelectedRule("");
                                            }}
                                            value={selectedRule || ""}
                                            sx={{ mt: 1 }}
                                          >
                                            {availableRules.map((rule) => (
                                              <MenuItem key={rule} value={rule}>
                                                {rule}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                        </Grid>

                                        <Grid item xs={2} />
                                        <Grid item xs={2} />
                                      </Grid>
                                    )}
                                  </Box>
                                )}
                              </FieldArray>
                            </CardContent>
                          </Card>
                        </Form>
                      );
                    }}
                  </Formik>
                </Grid>
              ))}
              <Grid container item sx={{ justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={saveAllocationRules}
                  disabled={saveAllocationRulesLoading || !allocationRuleCards?.length}
                >
                  {saveAllocationRulesLoading ? <CircularProgress size={14} style={{ color: "white" }} /> : 'Save'}
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}


    </Grid>
  );
};

export default AllocationRules;
