import { useLazyQuery, useMutation } from "@apollo/client";
import { ApolloError } from "@apollo/client";
import {
  Box,
  CircularProgress,
  Fab,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { DateTime as d } from "luxon";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { ADD_INVOICE_NOTE } from "../../../../graphql/bookings/addInvoiceNoteMutation";
import { SEND_INVOICE } from "../../../../graphql/bookings/sendInvoiceMutation";
import { CANCEL_PAYMENT_LINK } from "../../../../graphql/invoices/cancelPaymentLinkMutation";
import { CREATE_CREDIT_NOTE } from "../../../../graphql/invoices/createCreditNoteMutation";
import { CREATE_PAYMENT } from "../../../../graphql/invoices/createPaymentMutation";
import { GET_INVOICE } from "../../../../graphql/invoices/getInvoiceQuery";
import { MARK_INVOICE_VOID } from "../../../../graphql/invoices/markInvoiceVoidMutation";
import {
  RESENT_INVOICE_TO_SAGE,
  SEND_INVOICE_TO_XERO
} from "../../../../graphql/invoices/resendInvoiceToSageMutation";
import { UPDATE_INVOICE } from "../../../../graphql/invoices/updateInvoiceMutation";
import {
  BookingStatus,
  ICreateCreditNoteInput,
  ICreditNote
} from "../../../../reducers/bookings/types";
import {
  IInvoice,
  IPayment,
  IPaymentForm,
  IPaymentInput,
  PaymentMode,
  PaymentStatus,
  XeroStatus
} from "../../../../reducers/invoices/types";
import { IAppState } from "../../../../store";
import { getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { socket } from "../../../../utils/socket";
import { ConfirmationDialog } from "../../../common/ConfirmationDialog/ConfirmationDialog";
import MailSendBox from "../../../common/MailSendBox";
import ShowMessage from "../../../common/MailSendBox/ShowMessage";
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { SelectableRows } from "../../../common/NuvvenTable/types";
import DownloadPdf from "../../../common/Pdf/DownloadPdf";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  formatGraphQLErrorMessage,
  toCurrency
} from "../../../common/utils";
import {
  IConfirmationDialogData,
  defaultConfirmationDialogData
} from "../../ReservationManagement/Summary/const";
import { SOCKET_EVENTS } from "../../ReservationManagement/utils";
import AdjustedCreditNotes from "./InvoiceComponents/AdjustedCreditNotes";
import { ConsolidatedInvoiceDetails } from "./InvoiceComponents/ConsolidatedInvoiceDetails";
import CreditNotesComponent from "./InvoiceComponents/CreditNotesComponent";
import { InvoiceSummary } from "./InvoiceComponents/InvoiceSummary";
import NewCreditNote from "./InvoiceComponents/NewCreditNote";
import { PaymentDialog } from "./InvoiceComponents/PaymentDialog";
import { IInvoiceItem, InvoiceStatus } from "./InvoiceComponents/types";
import { CREATE_PAYMENT_MESSAGE } from "./messages";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "auto"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    hidden: {
      display: "none"
    },
    space: {
      height: "50px"
    },
    root: {
      padding: theme.spacing(3)
    }
  })
);

const UpdateConsolidatedInvoice: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const [loading, setLoading] = useState<boolean>(false);
  const [values, setValues] = useState<IInvoice>();
  const [creditNotes, setCreditNotes] = useState<ICreditNote[]>([]);
  const [newCreditNoteDialogOpen, setNewCreditNoteDialogOpen] = useState<
    boolean
  >(false);
  const [payments, setPayments] = useState<IPayment[]>([]);
  const [paymentDialogVisible, setPaymentDialogVisible] = useState<boolean>(
    false
  );
  const [notSageAccountingInvoiceId, setNotSageAccountingInvoiceId] = useState<
    boolean
  >(false);
  const [sageAccountingError, setSageAccountingError] = useState<string>();
  const [sendClick, setSendClick] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [showMails, setShowMails] = useState<string[]>([]);
  const prevBranchRef = useRef(userState.currentBranch);
  const [confirmationDialogData, setConfirmationDialogData] = useState<
    IConfirmationDialogData
  >(defaultConfirmationDialogData);
  const [invoiceNote, setInvoiceNote] = useState("");
  const [sendingToXero, setSendingToXero] = useState(false);

  const [getInvoice, { loading: invoiceLoading, data: invoice }] = useLazyQuery(
    GET_INVOICE,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (!data.invoice) {
          navigate("/consolidated-invoices");
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [updateInvoice, { loading: updateInvoiceLoading }] = useMutation(
    UPDATE_INVOICE,
    {
      onCompleted: (data) => {
        if (data && data.updateInvoice && data.updateInvoice.id) {
          snackbar({
            message: "Consolidated Invoice updated successfully",
            variant: SnackBarVariant.SUCCESS
          });
          setValues(data.updateInvoice);
          setIsEditable(false);
        }
      },
      onError: (error: ApolloError) => {
        setLoading(false);
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [resendInvoideToSageMutation] = useMutation(RESENT_INVOICE_TO_SAGE, {
    onCompleted: (data) => {
      if (
        data &&
        data.resendInvoiceToSage &&
        data.resendInvoiceToSage.message
      ) {
        snackbar({
          message: data.resendInvoiceToSage.message,
          variant: SnackBarVariant.SUCCESS
        });
        setSageAccountingError("");
        setNotSageAccountingInvoiceId(false);
      }
    },
    onError: (error: ApolloError) => {
      if (error && error.message) {
        setSageAccountingError(formatGraphQLErrorMessage(error.message));
      }
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [sendInvoiceToXeroMutation] = useMutation(SEND_INVOICE_TO_XERO, {
    onCompleted: ({ sendInvoiceToXero }) => {
      snackbar({
        message: sendInvoiceToXero.message,
        variant: SnackBarVariant.SUCCESS
      });
      setSendingToXero(false);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      setSendingToXero(false);
    }
  });

  const [addInvoiceNote] = useMutation(ADD_INVOICE_NOTE, {
    onCompleted: ({ addInvoiceNote }) => {
      setInvoiceNote(addInvoiceNote);
      snackbar({
        message: "Notes added successfully",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });
  const updatingSetValues = (value: string) => {
    if (values) {
      setValues({
        ...values,
        booking: {
          ...values.booking,
          poNumber: value
        }
      });
    }
  };

  useEffect(() => {
    socket.auth = {
      userId: userState.id
    };
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (invoice && invoice.invoice && invoice.invoice.id) {
      socket.emit(SOCKET_EVENTS.REQUEST_VIEW_INVOICE, {
        invoiceId: invoice.invoice.id
      });
    }
  }, [invoice]);

  useEffect(() => {
    socket.on(SOCKET_EVENTS.GET_UPDATED_INVOICE, (data) => {
      getInvoice({
        variables: {
          id: data.invoiceId
        }
      });
    });

    return () => {
      socket.off(SOCKET_EVENTS.GET_UPDATED_INVOICE);
    };
  }, []);

  useEffect(() => {
    if (
      userState &&
      userState.currentOrganisation &&
      userState.currentOrganisation.sageAccounting &&
      userState.currentOrganisation.sageAccounting.active &&
      invoice &&
      invoice.invoice &&
      !invoice.invoice.sageAccountingInvoiceId
    ) {
      setNotSageAccountingInvoiceId(true);
    }
    if (prevBranchRef.current !== userState.currentBranch) {
      navigate("/consolidated-invoices");
      prevBranchRef.current = userState.currentBranch;
    }
  }, [userState, invoice, userState.currentBranch]);

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const invoiceId = params.get("invoice");
      if (invoiceId) {
        getInvoice({
          variables: {
            id: invoiceId
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (invoice && invoice.invoice) {
      setInvoiceNote(invoice.invoice.notes);
      if (invoice.invoice.otherCharges?.length === 0) {
        const updatedInvoice = { ...invoice.invoice };
        updatedInvoice.otherCharges.push({ name: "", amount: 0 });
        setValues(updatedInvoice);
      } else {
        setValues(invoice.invoice);
      }
      if (
        invoice.invoice.sageAccountingError &&
        !invoice.invoice.sageAccountingInvoiceId
      ) {
        setSageAccountingError(invoice.invoice.sageAccountingError);
      }
      if (invoice.invoice.creditNotes.length) {
        const creditNotesArr: ICreditNote[] = [];
        invoice.invoice.creditNotes.forEach((creditNote: ICreditNote) => {
          if (creditNote.invoice.id === invoice.invoice.id) {
            creditNotesArr.push(creditNote);
          }
        });
        setCreditNotes(creditNotesArr);
      }
    }
  }, [invoice]);

  useEffect(() => {
    if (
      values?.adjustedDeposit?.amount &&
      values.booking.depositDetails?.payment
    ) {
      const payments: any[] = [...values.payments];
      if (
        !payments.some(
          (payment) => payment.id === values.booking.depositDetails?.payment.id
        )
      ) {
        payments.push({
          ...values.booking.depositDetails?.payment,
          amount: values.adjustedDeposit.amount,
          dateCreated: values.adjustedDeposit.adjustedAt,
          paymentMode: "Paid by Deposit",
          refundAmount: 0
        });
        setValues({
          ...values,
          payments
        });
      }
    }
    if (values?.payments) {
      let data: IPayment[] = values.payments
        .filter((payment) => payment.amount > 0)
        .map((payment) => {
          payment.paymentModeEdited = `${payment.paymentMode} (${payment.paymentGateway})`;
          return payment;
        });
      data = data.map((payment) => {
        payment.dateCreated = payment.capturedAt
          ? payment.capturedAt
          : payment.dateCreated;
        return payment;
      });
      setPayments(data);
    } else {
      setPayments([]);
    }
  }, [values]);

  const [createPayment] = useMutation(CREATE_PAYMENT, {
    onCompleted: () => {
      snackbar({
        message: CREATE_PAYMENT_MESSAGE,
        variant: SnackBarVariant.SUCCESS
      });
      if (values) {
        getInvoice({
          variables: {
            id: values.id
          }
        });
      }
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [sendInvoice] = useMutation(SEND_INVOICE, {
    onCompleted: () => {
      setLoading(false);
      const lastSent = d
        .now()
        .toUTC()
        .toISO();
      values && setValues({ ...values, lastSent });
      setShowMessage(true);
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [createCreditNote] = useMutation(CREATE_CREDIT_NOTE, {
    onCompleted: (data) => {
      if (values && data && data.createCreditNote) {
        setNewCreditNoteDialogOpen(false);
        snackbar({
          message: "Refund created Successfully",
          variant: SnackBarVariant.SUCCESS
        });
        getInvoice({
          variables: {
            id: values?.id
          }
        });
      }
    },
    onError: (error: ApolloError) => {
      setNewCreditNoteDialogOpen(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [
    cancelPaymentLink,
    { loading: cancelPaymentLinkLoading }
  ] = useMutation(CANCEL_PAYMENT_LINK, {
    onCompleted: (data) => {
      if (data?.cancelPaymentLink?.status === PaymentStatus.CANCELLED) {
        snackbar({
          message: "Payment cancelled successfully",
          variant: SnackBarVariant.SUCCESS
        });
        setConfirmationDialogData({
          ...confirmationDialogData,
          isOpen: false
        });
        getInvoice({
          variables: {
            id: values?.id
          }
        });
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const _updateInvoice = (items: IInvoiceItem[]) => {
    if (values) {
      updateInvoice({
        variables: {
          invoiceId: values.id,
          invoiceItems: items
        }
      });
    }
  };

  const savePayment = (data: IPaymentForm) => {
    if (values) {
      const payment: IPaymentInput = {
        paymentMode: data.paymentMode,
        paymentType: "INWARD",
        amount: data.amount,
        currency,
        description: data.transactionId ? data.transactionId : "",
        businessCustomer: values.businessCustomer?.id,
        emailNotify: true,
        expireBy: "",
        invoice: values.id,
        note: data.note,
        booking: values.booking ? values.booking.id : ""
      };
      if (data.capturedAt) {
        payment.capturedAt = data.capturedAt;
      }
      createPayment({
        variables: {
          payment,
          isConsolidated: true
        }
      });
      setPaymentDialogVisible(false);
    }
  };

  const resendInoviceToSage = (invoiceId: string) => {
    if (invoiceId) {
      resendInvoideToSageMutation({
        variables: {
          invoiceId
        }
      });
    }
  };

  const sendInvoiceEmail = (sentMails: string[]) => {
    const isBusinessCustomerBooking =
      values?.businessCustomer && values.businessCustomer.id !== ""
        ? true
        : false;
    const businessCustomer = values?.businessCustomer;
    const customer = values?.customer;
    const customerMail = isBusinessCustomerBooking
      ? businessCustomer
        ? businessCustomer.billing.email
        : ""
      : customer
      ? customer.email
      : "";
    setShowMails([customerMail, ...sentMails]);
    setLoading(true);
    setSendClick(false);
    sendInvoice({
      variables: {
        id: values?.id,
        additionalMails: sentMails
      }
    });
  };

  const updateCreditNote = (creditNote: ICreditNote) => {
    let creditNotesArr = [...creditNotes];
    const idx = creditNotesArr.findIndex((item) => item.id === creditNote.id);
    creditNotesArr.splice(idx, 1, creditNote);
    setCreditNotes(creditNotesArr);
  };

  const calculateAvailableCredit = () => {
    if (values) {
      const availableCredit =
        values.confirmedPaidAmount - values.confirmedRefundAmount;
      return availableCredit > 0 ? availableCredit : 0;
    }
    return 0;
  };

  const handleCreditNoteSubmit = (data: ICreateCreditNoteInput) => {
    createCreditNote({
      variables: {
        creditNote: data
      }
    });
  };

  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        filter: false,
        display: false,
        viewColumns: false
      }
    },
    {
      label: "Date",
      name: "dateCreated",
      options: {
        customBodyRender: (value: string) => {
          return getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED);
        }
      }
    },
    {
      label: "Amount",
      name: "amount",
      options: {
        customBodyRender: (value: any) => {
          return toCurrency(value, currency, locale);
        }
      }
    },
    {
      label: "Mode of Payments",
      name: "paymentModeEdited",
      options: {
        customBodyRender: (value: string) => {
          return value.replace(/_/g, " ");
        }
      }
    },
    {
      label: "Reference Number",
      name: "transactionId",
      options: {
        customBodyRender: (value: string) => {
          return value;
        }
      }
    },
    {
      label: "Status",
      name: "status"
    },
    {
      label: "Amount Refunded",
      name: "refundAmount",
      options: {
        customBodyRender: (value: any) => {
          return toCurrency(value || 0, currency, locale);
        }
      }
    },
    {
      label: "Actions",
      name: "paymentMode",
      options: {
        customBodyRender: (value: any, tableMeta: any) => {
          const { rowData } = tableMeta;
          if (
            value === PaymentMode.PAY_BY_LINK &&
            rowData[5] === PaymentStatus.NEW
          ) {
            return (
              <Typography
                style={{
                  cursor: "pointer",
                  color: cancelPaymentLinkLoading ? "gray" : "#1875d2"
                }}
                onClick={() => {
                  setConfirmationDialogData({
                    description: "Are you sure you want to cancel the payment?",
                    isOpen: true,
                    confirmText: "YES",
                    cancelText: "NO",
                    onCancel: () =>
                      setConfirmationDialogData((prevState) => {
                        return { ...prevState, isOpen: false };
                      }),
                    onConfirm: () => {
                      setConfirmationDialogData({
                        ...confirmationDialogData,
                        isOpen: true,
                        description:
                          "Are you sure you want to cancel the payment?",
                        title: "Cancel Payment",
                        isLoading: true,
                        confirmText: "YES",
                        cancelText: "NO"
                      });
                      cancelPaymentLink({
                        variables: {
                          paymentId: rowData[0]
                        }
                      });
                    },
                    title: "Cancel Payment"
                  });
                }}
              >
                Cancel
              </Typography>
            );
          }
        }
      }
    },
    {
      label: "Comments",
      name: "note"
    }
  ];

  if (invoiceLoading || !values) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={2}>
      <Grid container item xs={2}>
        <Typography variant="h1" color="primary">
          Invoice
        </Typography>
      </Grid>
      <Grid container item xs={10} justifyContent="flex-end">
        {notSageAccountingInvoiceId && (
          <Fab
            variant={"extended"}
            size="medium"
            aria-label="Update"
            style={{ marginRight: 10 }}
            onClick={() => resendInoviceToSage(values.id)}
          >
            Send Invoice to Sage
          </Fab>
        )}

        {userState.currentOrganisation.xeroEnabled &&
          values.status !== "VOID" &&
          values.status !== "DRAFT" &&
          !values.sentToXero && (
            <Fab
              variant={"extended"}
              size="medium"
              aria-label="Update"
              style={{ marginRight: 10 }}
              onClick={() => {
                if (values.xeroInvoiceStatus === XeroStatus.IN_PROGRESS) {
                  snackbar({
                    message:
                      "Invoice has been sent to Xero. Please reload for latest status",
                    variant: SnackBarVariant.ERROR
                  });
                } else {
                  setSendingToXero(true);
                  sendInvoiceToXeroMutation({
                    variables: {
                      invoiceId: values.id
                    }
                  });
                }
              }}
            >
              Send Invoice to Xero{" "}
              {sendingToXero && (
                <CircularProgress
                  size={14}
                  style={{ color: "black", marginLeft: "10px" }}
                />
              )}
            </Fab>
          )}
        <Fab
          variant={"extended"}
          size="medium"
          aria-label="Create Credit Note"
          disabled={
            calculateAvailableCredit() === 0 ||
            !(
              values.adjustedCreditNotes?.length ||
              values.payments.filter(
                (item) => item.paymentMode !== "Paid by Deposit (OFFLINE)"
              ).length
            )
          }
          onClick={() => {
            setNewCreditNoteDialogOpen(true);
          }}
        >
          Create Credit Note
        </Fab>
      </Grid>
      {sageAccountingError && (
        <p style={{ color: "red", marginLeft: 10 }}> {sageAccountingError} </p>
      )}
      <Grid container item xs={12}>
        <Paper className={classes.root}>
          <Grid container spacing={2}>
            <Grid container item xs={6}>
              <Typography variant={"h2"}>
                Consolidated Invoice Summary
              </Typography>
            </Grid>
            <Grid container xs={6} justifyContent="flex-end"></Grid>
            <Grid container item xs={12}>
              <InvoiceSummary
                invoice={values}
                updatePoNumber={(value: string) => updatingSetValues(value)}
              />
            </Grid>
            <Grid container item xs={12}>
              <ConsolidatedInvoiceDetails
                invoice={values}
                isEditable={isEditable}
                setIsEditable={setIsEditable}
                loading={updateInvoiceLoading}
                updateInvoice={_updateInvoice}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{ width: "100%" }}
                label="Add Notes"
                placeholder={"Additional TnCs, etc."}
                name={"invoiceNote"}
                multiline
                rows={1}
                disabled={payments.length > 0}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setInvoiceNote(event.target.value);
                }}
                variant="outlined"
                value={invoiceNote}
              />
            </Grid>
            <Grid item xs={4}>
              <Fab
                className="blackBackButton"
                variant="extended"
                size="medium"
                disabled={!invoiceNote || payments.length > 0}
                onClick={() => {
                  addInvoiceNote({
                    variables: {
                      bookingId: values.booking?.id || "",
                      note: invoiceNote,
                      invoiceId: values.id
                    }
                  });
                }}
              >
                Save
              </Fab>
            </Grid>
            <Grid container item xs={12}>
              <Grid container>
                <Grid container item xs={12}>
                  <Typography variant="h2">Payments</Typography>
                </Grid>
                <Grid container item xs={12}>
                  <Box pt={2}></Box>
                </Grid>
                <Grid item xs={12}>
                  <Paper variant="outlined">
                    <NuvvenTable
                      key={new Date().getMilliseconds()}
                      title={""}
                      rows={payments}
                      columns={columns}
                      options={{
                        download: false,
                        filter: false,
                        print: false,
                        search: false,
                        selectableRows: SelectableRows.NONE,
                        viewColumns: false,
                        elevation: 0
                      }}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid container spacing={2}>
                {values &&
                values.adjustedCreditNotes &&
                values.adjustedCreditNotes.length > 0 ? (
                  <Grid container item xs={12}>
                    {values && values.id && (
                      <AdjustedCreditNotes
                        creditNotes={values.adjustedCreditNotes}
                      />
                    )}
                  </Grid>
                ) : (
                  <></>
                )}
                {creditNotes.length > 0 ? (
                  <Grid container item xs={12}>
                    {values && values.id && (
                      <CreditNotesComponent
                        creditNotes={creditNotes}
                        updateCreditNote={updateCreditNote}
                      />
                    )}
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid container xs={12}>
        <Box sx={{ mt: 1 }}></Box>
      </Grid>
      <Grid container xs={12}>
        <Grid container xs={4} alignItems="center">
          {values.lastSent && (
            <Typography variant="body1">
              <span style={{ margin: "0 10px", fontWeight: 700 }}>
                Last invoice email sent on:
              </span>
              <span>
                {getLocalizedDateFormat(
                  country,
                  values.lastSent,
                  DATE_TYPE.EXPANDED
                )}
              </span>
            </Typography>
          )}
        </Grid>
        <Grid container item xs={8} justifyContent="flex-end">
          {[
            InvoiceStatus.DRAFT.toString(),
            InvoiceStatus.UNPAID.toString()
          ].includes(values.status) && (
            <React.Fragment>
              <Fab
                onClick={() => setIsEditable(true)}
                disabled={payments.length > 0}
                variant="extended"
                size="medium"
                type="button"
              >
                {"Edit"}
              </Fab>
              <Box color="white" sx={{ pr: 2 }}></Box>
            </React.Fragment>
          )}
          <Fab
            variant="extended"
            size="medium"
            aria-label="Update"
            disabled={
              values.status === InvoiceStatus.DRAFT ||
              values.booking?.status === BookingStatus.REQUIRES_APPROVAL
                ? true
                : values.dueAmount > 0
                ? false
                : true
            }
            onClick={() => {
              setPaymentDialogVisible(true);
            }}
          >
            ADD NEW PAYMENT
          </Fab>
          <Box color="white" sx={{ pr: 2 }}></Box>
          <DownloadPdf
            getSource={{ type: "INVOICE", id: values.id }}
            pdfSource={values.invoiceUrl}
            fileName={`Consolidated-Invoice-${values?.invoiceRef}.pdf`}
          />
          <Box color="white" sx={{ pr: 2 }}></Box>
          <Fab
            onClick={() => {
              setSendClick(true);
            }}
            variant="extended"
            size="medium"
            type="button"
            disabled={loading}
          >
            {loading && (
              <CircularProgress size={14} style={{ marginRight: "10px" }} />
            )}
            {"Send E-Mail"}
          </Fab>
          {sendClick ? (
            <MailSendBox
              documentType="Invoice"
              mails={(emails: string[]) => sendInvoiceEmail(emails)}
              open={sendClick}
              handleClose={() => {
                setSendClick(false);
              }}
            />
          ) : (
            ""
          )}
          {showMessage ? (
            <ShowMessage
              open={showMessage}
              sentMails={showMails}
              handleClose={() => setShowMessage(false)}
            />
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <ConfirmationDialog {...confirmationDialogData} />
      <Grid item container xs={12}>
        <div>
          <PaymentDialog
            amount={values.dueAmount}
            open={paymentDialogVisible}
            handleClose={() => {
              setPaymentDialogVisible(false);
            }}
            onSubmit={(payload: IPaymentForm) => savePayment(payload)}
            invoice={values.id}
            booking={values.booking?.id}
            businessCustomer={values.businessCustomer?.id}
            invoiceData={values}
            isConsolidated={true}
          />
        </div>
      </Grid>
      <Grid item container xs={12}>
        <NewCreditNote
          isOpen={newCreditNoteDialogOpen}
          invoice={values}
          availableCredit={calculateAvailableCredit()}
          onCancel={() => setNewCreditNoteDialogOpen(false)}
          onSubmit={handleCreditNoteSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default UpdateConsolidatedInvoice;
