import { gql } from "@apollo/client";

export const GET_RATE_CARD_BY_ID = gql`
  query getRateCardById($id: String!) {
    getRateCardById(id: $id) {
      id
      name
      isActive
      isDefault
      displayName
      dateUpdated
      lastUpdatedBy {
        firstName
        lastName
      }
      businessCustomers {
        businessCustomer {
          businessName
          id
        }
        autoIncrementRatesBy
        increaseRatesYearly
      }
      vehicleGroups {
        name
        unlimitedMileage
        mileageLimit
        pricePerExtraMile
        rateTypeName
        rateTypeDuration
        pricing {
          rangeStart
          rangeEnd
          unitPrice
        }
        vehicleGroup {
          id
          name
        }
      }
    }
  }
`;
