import { useLazyQuery } from "@apollo/client";
import {
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  Typography
} from "@mui/material";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addTableState } from "../../../actions/tableState/actions";
import { ITable, TableNames } from "../../../reducers/tableState/types";
import { IAppState } from "../../../store";
import { NuvvenTable } from "../../common/NuvvenTable/NuvvenTable";
import { formatGraphQLErrorMessage, returnSortedOrder, setTableSortOrder, toCurrency } from "../../common/utils";
import { GET_APP_SETTINGS } from '../../../graphql/consumerWebsite/websiteQueries'
import { IAppSetting } from './types';
import { SelectableRows } from "../../common/NuvvenTable/types";
import { ApolloError } from "@apollo/client";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { GET_APP_SETTINGS_COUNT } from "../../../graphql/consumerWebsite/getAppSettingsCount";

const ConsumerWebsites = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [rows, setRows] = useState<any>([]);
  const [limit, setLimit] = useState(10);
  const [totalAppSettingsCount, setTotalAppSettingsCount] = useState<number>(0);
  const [appSettinsTable, setAppSettinsTable] = useState<ITable>();
  const organisation = userState.currentOrganisation;
  const isSupplierPortalEnabled = organisation?.supplierPortalEnabled;


  const columns: any = [
    {
      label: "App Name",
      name: "name"
    },
    {
      label: "Web Address",
      name: "domain"
    },
    {
      label: "Organisation",
      name: "organisation"
    },
    {
      label: "Branches",
      name: "branches"
    },
    ...(isSupplierPortalEnabled
      ? [
          {
            label: "Partner Domain",
            name: "partnerDomain"
          }
        ]
      : [])
  ];

  const [getConsumerAppSettings, { loading: appLoading, data: consumerAppSettings }] = useLazyQuery(
    GET_APP_SETTINGS,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [getAppSettingsCount] = useLazyQuery(
    GET_APP_SETTINGS_COUNT,
    {
      fetchPolicy: "network-only",
      onCompleted: (appSettingsCountData) => {setTotalAppSettingsCount(appSettingsCountData.consumerAppSettingsCount)},
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  useEffect(() => {
    if (userState.tenancy) {
      getAppSettingsCount();
      getConsumerAppSettings({
        variables: {
          limit: limit,
          offset: 0,
        },
      });
    }
  }, [userState, limit]);


  useEffect(() => {
    if (consumerAppSettings && consumerAppSettings.getConsumerAppSettings) {
      // remove any
      const appSettings: IAppSetting[] = consumerAppSettings.getConsumerAppSettings;
      const tempRows = appSettings.map((appSetting) => {
        return {
          name: appSetting.appName,
          domain: appSetting.webAppSetting.websiteAddress,
          partnerDomain: appSetting.partnerAppSetting?.websiteAddress || "N/A",
          organisation: appSetting.organisation?.name,
          branches: appSetting.branchIds?.length + ' branches'
        }
      })
      setRows(tempRows);
    }
  }, [consumerAppSettings]);

  const tableStateReducer: any = useSelector(
    (state: IAppState) => state.tableStateReducer
  );
  let sortOrder: any = "";
  let insurancePoliciesTable: any;

  if (tableStateReducer && tableStateReducer.insurancePolicies) {
    insurancePoliciesTable = tableStateReducer.insurancePolicies;
    sortOrder = returnSortedOrder(insurancePoliciesTable);
  }

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    count: totalAppSettingsCount,
    pagination: !appLoading,
    rowsPerPageOptions: [10, 20, 100],
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          if (tableState.searchText) {
            return;
          }
          const { page, rowsPerPage } = tableState;
          if (page * rowsPerPage >= rows.length) {
            getConsumerAppSettings({
              variables: {
                limit: rowsPerPage,
                offset: page * rowsPerPage,
              },
            });
          }
          break;
        case "changeRowsPerPage":
          setLimit(tableState.rowsPerPage); 
          setRows([]);
          getConsumerAppSettings({
            variables: {
              limit: tableState.rowsPerPage || limit,
              offset: 0,
            },
          });
          break;
        default:
          break;
      }
    },
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: appLoading 
          ? "Loading..."
          : "Sorry, there is no matching data to display",
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTER",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    onRowClick: (
      rowData: any,
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row = consumerAppSettings.getConsumerAppSettings[rowMeta.dataIndex];
      // dispatch(updateInsurancePolicy(row));
      const appId = row.id
      if (appId) {
        navigate(`/update-app?appId=${appId}`);
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      dispatch(
        addTableState({
          tableName: TableNames.INSURANCE_POLICY,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
    appSettinsTable && appSettinsTable.rowsPerPage
        ? appSettinsTable.rowsPerPage
        : limit,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.INSURANCE_POLICY,
          columnName: changedColumn,
          direction
        })
      );
    },
    onTableInit: () => {
      if (insurancePoliciesTable && sortOrder) {
        setTableSortOrder(columns, insurancePoliciesTable, sortOrder);
      }
      if (insurancePoliciesTable && insurancePoliciesTable.rowsPerPage) {
        setLimit(insurancePoliciesTable.rowsPerPage);
      }
    }
  };

  const disabledRoles = ["FINANCE_MANAGER", "FLEET_MANAGER"];

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6} alignItems="center">
        <Typography variant="h1" color="primary">
          Manage Apps
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <Fab
          variant="extended"
          size="medium"
          aria-label="Create"
          onClick={() => {
            navigate(`/new-app`);
          }}
          disabled={disabledRoles.includes(userState.role)}
        >
          Add new website
        </Fab>
      </Grid>
      <Grid item container xs={12}>
        {appLoading ? (
          <CircularProgress />
        ) : (
          <NuvvenTable
            title={""}
            rows={rows}
            columns={columns}
            options={options}
            setSelection={(item: any) => item}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ConsumerWebsites;
