import { useLazyQuery } from "@apollo/client";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  alpha,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  InputBase,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GET_VEHICLE_GROUP_CRITERIA } from "../../../../../graphql/vehiclePriceGroup/getVehicleGroupCriteria";
import { GET_VEHICLE } from "../../../../../graphql/vehiclePriceGroup/getVehicleQuery";
import { GET_VEHICLES_BY_CRITERIA } from "../../../../../graphql/vehiclePriceGroup/getVehiclesByCriteria";
import { GET_VEHICLES } from "../../../../../graphql/vehiclePriceGroup/getVehiclesQuery";
import { IVehicle } from "../../../../../reducers/fleet/types";
import { vehiclePriceGroupInitialState } from "../../../../../reducers/vehiclePriceGroups/consts";
import {
  IVehicleGroupCriteria,
  IVehiclePriceGroup
} from "../../../../../reducers/vehiclePriceGroups/types";
import { IAppState } from "../../../../../store";
import { IFilter } from "../../../../common/FilterSection/FilterSection";
import { FiltersView } from "../../../../common/FiltersView/FiltersView";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ConfirmationDialog } from "../../../../common/ConfirmationDialog/ConfirmationDialog";
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from "@mui/icons-material/Search";
import theme from "../../../../common/Theme";
import sortAtoZIcon from "../../../../../assets/icons/sort-a-to-z-icon.svg"
import sortZtoAIcon from "../../../../../assets/icons/sort-z-to-a-icon.svg"
interface IProps {
  vehicleGroup: IVehiclePriceGroup;
  updateVehicleGroup(data: IVehiclePriceGroup): void;
  onSubmit(): void;
}

interface IVehicleFilter {
  id: string;
  licencePlate: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(50vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      width: '100%',
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    },
    paper1: {
      minHeight: 200,
      [theme.breakpoints.up('sm')]: {
        marginLeft: "30px",
        paddingBottom: 20,
        padding: 20,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '8px'
      },
    },
    paper2: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: "30px",
        paddingBottom: 20,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '8px'
      },
      minHeight: "86vh"
    },
    box: {
      [theme.breakpoints.up('sm')]: {
        overflow: "auto"
      },
      [theme.breakpoints.down('sm')]: {
        overflow: "visible"
      },
    },
  })
);

const vehicleFilterInitial: IVehicleFilter = {
  id: "",
  licencePlate: ""
}

export const GroupVehicleSelection: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const [filters, setFilters] = useState<IFilter[]>([]);
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [isStale, setIsStale] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");
  const [vehicleGroup, setVehicleGroup] = useState<IVehiclePriceGroup>(
    vehiclePriceGroupInitialState.vehiclePriceGroup
  );
  const [criteria, setCriteria] = useState<IVehicleGroupCriteria[]>(
    props.vehicleGroup.criteria
  );
  const [criteriaLoading, setCriteriaLoading] = useState<boolean>(true);
  const [vehicles, setVehicles] = useState<IVehicleFilter[]>([]);
  const [vehiclesByCriteria, setVehiclesByCriteria] = useState<IVehicle[]>([]);
  const [selectedVehicles, setSelectedVehicles] = useState<IVehicle[]>([]);
  const [selectedVehicleValue, setSelectedVehicleValue] = useState<IVehicleFilter>(vehicleFilterInitial);
  const [isConfirmDialogOpen, setIsconfirmDialogOpen] = useState<boolean>(false);
  const [isSortlistedVehiclesSorted, setIsSortlistedVehiclesSorted] = useState<boolean>(false);
  const [isSelectedVehiclesSorted, setIsSelectedVehiclesSorted] = useState<boolean>(false);
  const [selectedVehicleToShow, setSelectedVehiclesToShow] = useState<IVehicle[]>([]);
  const [vehiclesToFilter, setVehiclesToFilter] = useState<IVehicle[]>([]);
  const [isRemoveConfirmDialogOpen, setIsRemoveConfirmDialogOpen] = useState<boolean>(false);
  const [isVehicleRemovedBySearch, setIsVehicleRemovedBySearch] = useState<boolean>(false);
  const [searchedInputValue, setSearchedInputValue] = useState<string>("");
  const [searchFilters, setSearchFilters] = useState<{
    [key: string]: string[];
  }>({});

  const [getVehicles, { data: vehiclesData, loading: loadingVehiclesData }] = useLazyQuery(
    GET_VEHICLES,
    {
      fetchPolicy: "network-only"
    }
  );

  const [getVehicle] = useLazyQuery(
    GET_VEHICLE, {
    fetchPolicy: "network-only",
    onCompleted: ({ vehicle }) => {
      const modifiedSelectedVehicles = [...selectedVehicles];
      modifiedSelectedVehicles.unshift(vehicle);
      setSelectedVehicles([...modifiedSelectedVehicles]);
      setVehiclesToFilter([...modifiedSelectedVehicles]);
      setSelectedVehiclesToShow([...modifiedSelectedVehicles]);
      setSelectedVehicleValue(vehicleFilterInitial);
      setVehicleGroup({
        ...vehicleGroup,
        vehicles: [...vehicleGroup.vehicles, { ...vehicle }]
      });
    }
  }
  );

  const [getVehicleGroupCriteria, { data: criteriaData }] = useLazyQuery(
    GET_VEHICLE_GROUP_CRITERIA,
    {
      fetchPolicy: "network-only"
    }
  );

  const [getVehiclesByCriteria, { loading: loadingVehiclesByCriteria, data: vehiclesByCriteriaData }] = useLazyQuery(
    GET_VEHICLES_BY_CRITERIA,
    {
      fetchPolicy: "network-only",
      onError: () => {
        snackbar({
          message: "Error fetching vehicles",
          variant: SnackBarVariant.SUCCESS
        });
      }
    }
  );

  useEffect(() => {
    if (props.vehicleGroup) {
      setVehicleGroup(props.vehicleGroup);
    }
    if (props.vehicleGroup && props.vehicleGroup.vehicles && props.vehicleGroup.vehicles.length) {
      setSelectedVehicles([...props.vehicleGroup.vehicles]);
      setVehiclesToFilter([...props.vehicleGroup.vehicles]);
      setSelectedVehiclesToShow([...props.vehicleGroup.vehicles]);
    }
  }, [props.vehicleGroup]);

  useEffect(() => {
    if (!isStale && filters.length && props.vehicleGroup.criteria.length) {
      const filterObj: { [key: string]: string[] } = {};
      const criteriaArr: IVehicleGroupCriteria[] = [];
      props.vehicleGroup.criteria.forEach((item) => {
        criteriaArr.push(item);
        filterObj[item.type] = item.values.map((val: string) =>
          val.toUpperCase()
        );
      });
      setCriteria(criteriaArr);
      const filtersArr: IFilter[] = JSON.parse(JSON.stringify(filters));
      filtersArr.forEach((filter) => {
        return filter.options.map((option) => {
          if (
            filterObj[filter.id] &&
            filterObj[filter.id].includes(option.id.toUpperCase())
          ) {
            option.value = true;
          }
          return option;
        });
      });
      setFilters(filtersArr);
      setIsStale(true);
    }
  }, [filters, props.vehicleGroup.criteria]);

  useEffect(() => {
    if (vehiclesByCriteriaData && vehiclesByCriteriaData.vehiclesByCriteria) {
      const vehiclePriceGroupObj = {
        ...vehicleGroup,
        vehicles: vehiclesByCriteriaData.vehiclesByCriteria
      };
      setVehicleGroup(vehiclePriceGroupObj);
      const vehiclesNotExistsInGroup = vehiclesByCriteriaData.vehiclesByCriteria.filter((vehicleByCriteria: IVehicle) =>
        !selectedVehicles.some(selectedVehicle => selectedVehicle.id === vehicleByCriteria.id));
      vehiclesNotExistsInGroup.sort((a: IVehicle, b: IVehicle) => a.licencePlate.toLowerCase() > b.licencePlate.toLowerCase() ? 1 : -1);
      setIsSortlistedVehiclesSorted(true);
      setVehiclesByCriteria([...vehiclesNotExistsInGroup]);
    }
  }, [vehiclesByCriteriaData]);

  useEffect(() => {
    if (userState.tenancy) {
      getVehicleGroupCriteria();
      getVehicles();
    }
  }, [userState]);

  useEffect(() => {
    if (criteriaData) {
      setCriteriaLoading(false);
      const { vehicleGroupCriteria } = criteriaData;
      if (vehicleGroupCriteria && !filters.length) {
        setFilters(formatCriteriaForFilter(vehicleGroupCriteria));
      }
    }
  }, [criteriaData]);

  useEffect(() => {
    if (criteria.length) {
      fetchVehiclesByCriteria(criteria);
    }
  }, [criteria]);

  useEffect(() => {
    if (vehiclesData && vehiclesData.vehicles) {
      setVehicles(vehiclesData.vehicles);
    }
  }, [vehiclesData]);

  const handleFilterUpdate = (filters: { [key: string]: string[] }) => {
    const criteriaArr: IVehicleGroupCriteria[] = [...criteria];
    const _criteriaArr = _.cloneDeep(criteriaArr);
    setSearchFilters({
      ...searchFilters,
      ...filters
    });
    Object.keys(filters).forEach((filter) => {
      const existingCriteriaIndex = _criteriaArr.findIndex(
        (item: IVehicleGroupCriteria) => item.type === filter
      );
      if (existingCriteriaIndex >= 0) {
        _criteriaArr[existingCriteriaIndex].values = filters[
          filter
        ].map((val: string) => val.toUpperCase());
      } else {
        _criteriaArr.push({
          type: filter,
          values: filters[filter].map((val: string) => val.toUpperCase())
        });
      }
    });
    setCriteria(_criteriaArr);
  };

  const fetchVehiclesByCriteria = (criteria: IVehicleGroupCriteria[]) => {
    getVehiclesByCriteria({
      variables: {
        vehicleCriteria: criteria
      }
    });
  };

  const formatCriteriaForFilter: any = (data: any) => {
    const filter = {} as any;
    for (let i = 0; i < data.length; i++) {
      if (data[i].values) {
        let values = data[i].values.map((value: string) => value.toLowerCase())
        values = Array.from(new Set(values))
        filter[data[i].type] = {
          id: data[i].type,
          name: _.startCase(data[i].type),
          options: values.map((val: string) => ({
            id: val,
            name: val.split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')
          }))
        };
      }
    }
    return Object.values(filter);
  };

  const handleGroupActivation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const vehiclePriceGroupObj = {
      ...vehicleGroup,
      isActivated: event.target.checked
    };
    setVehicleGroup(vehiclePriceGroupObj);
  };

  const handleHideInB2b2cToggle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const vehiclePriceGroupObj = {
      ...vehicleGroup,
      hideInB2b2c: event.target.checked
    };
    setVehicleGroup(vehiclePriceGroupObj);
  };

  const handleAutoCompleteOnChange = _.debounce((newVal: any, reason: string) => {
    if (newVal && newVal.id) {
      setSelectedVehicleValue(newVal);
      const existingVehicleIndex = selectedVehicles.findIndex(selectedVehicle => selectedVehicle.id === newVal.id);
      if (existingVehicleIndex !== -1) {
        setSelectedVehicleValue(vehicleFilterInitial);
        return snackbar({
          message: "The searched registration number already selected",
          variant: SnackBarVariant.ERROR
        });
      }
      getVehicle({
        variables: {
          id: newVal.id
        }
      })
    }
    setVehiclesByCriteria(vehiclesByCriteria.filter(value => value.id !== newVal.id));
  }, 200);

  const addSelectedVehicle = (vehicle?: IVehicle) => {
    if (vehicle) {
      const modifiedSelectedVehicles = [...selectedVehicles];
      modifiedSelectedVehicles.unshift(vehicle);
      setSelectedVehicles([...modifiedSelectedVehicles]);
      setVehiclesToFilter([...modifiedSelectedVehicles]);
      setSelectedVehiclesToShow([...modifiedSelectedVehicles]);
      setVehiclesByCriteria(vehiclesByCriteria.filter(vehicleByCriteria => vehicleByCriteria.id !== vehicle.id));
    } else {
      const vehiclesNotExistsInGroup = vehiclesByCriteria.filter(vehicleByCriteria =>
        !selectedVehicles.some(selectedVehicle => selectedVehicle.id === vehicleByCriteria.id));
      if (vehiclesNotExistsInGroup && vehiclesNotExistsInGroup.length) {
        const modifiedSelectedVehicles = [...selectedVehicles];
        modifiedSelectedVehicles.unshift(...vehiclesNotExistsInGroup);
        setSelectedVehicles([...modifiedSelectedVehicles]);
        setVehiclesToFilter([...modifiedSelectedVehicles]);
        setSelectedVehiclesToShow([...modifiedSelectedVehicles]);
      }
      setVehiclesByCriteria([]);
      setIsSelectedVehiclesSorted(isSortlistedVehiclesSorted);
    }
  }

  const removeSelectedVehicle = (vehicle?: any) => {
    if (vehicle) {
      let modifiedVehicles: IVehicle[] = [];
      if (criteria && criteria.length) {
        for (let index = 0; index < criteria.length; index++) {
          const element = criteria[index];
          const criteriaValues = element.values.map((val) => val.toLocaleLowerCase());
          const _vehicleCriteria = vehicle[element.type];
          if (element.type === "features") {
            //if filter type is features and value is also selected then move vehicle for shortlisting
            if (element.values && element.values.length) {
              setVehiclesByCriteria([...vehiclesByCriteria, { ...vehicle }]);
              modifiedVehicles = selectedVehicles.filter(selectedVehicle => selectedVehicle.id !== vehicle.id);
            } else {
              //if filter type is features and value is deselected then move vehicle directly from selected pannel
              setSelectedVehicles(selectedVehicles.filter(selectedVehicle => selectedVehicle.id !== vehicle.id))
            }
          } else {
            if (criteriaValues.includes(typeof _vehicleCriteria === 'number' ?
              _vehicleCriteria.toString().toLocaleLowerCase() : _vehicleCriteria.toLocaleLowerCase())) {
              setVehiclesByCriteria([...vehiclesByCriteria, { ...vehicle }]);
              modifiedVehicles = selectedVehicles.filter(selectedVehicle => selectedVehicle.id !== vehicle.id);
            } else {
              modifiedVehicles = selectedVehicles.filter(selectedVehicle => selectedVehicle.id !== vehicle.id);
            }
          }
        }
      } else {
        modifiedVehicles = selectedVehicles.filter(selectedVehicle => selectedVehicle.id !== vehicle.id);
      }
      setSelectedVehicles([...modifiedVehicles]);
      setVehiclesToFilter([...modifiedVehicles]);
    } else {
      const vehicles: any[] = [...selectedVehicles];
      if (criteria && criteria.length > 0) {
        for (let index = 0; index < criteria.length; index++) {
          const element = criteria[index];
          const criteriaValues = element.values.map(value => value.toLocaleLowerCase());
          if (criteriaValues && criteriaValues.length) {
            const vehicleByCriteriaArr: IVehicle[] = vehicles.filter(vehicle => {
              if (element.type === "features") {
                return vehicle[element.type].some((feature: string) => criteriaValues.includes(feature))
              } else {
                const _vehicleCriteria = vehicle[element.type];
                return criteriaValues.includes(typeof _vehicleCriteria === 'number' ?
                  _vehicleCriteria.toString().toLocaleLowerCase() : _vehicleCriteria.toLocaleLowerCase())
              }
            });
            setVehiclesByCriteria([...vehiclesByCriteria, ...vehicleByCriteriaArr]);
            setSelectedVehicles([]);
          } else {
            setSelectedVehicles([]);
          }
        }
      } else {
        setSelectedVehicles([]);
      }
      setIsSortlistedVehiclesSorted(isSelectedVehiclesSorted);
      setIsRemoveConfirmDialogOpen(false);
    }
    const vehiclesToShowAfterSearchRemove = vehiclesToFilter.filter((vehicleTofilter: IVehicle) =>
      !selectedVehicles.some(selectedVehicle => selectedVehicle.id === vehicleTofilter.id));
    if (vehiclesToShowAfterSearchRemove && vehiclesToShowAfterSearchRemove.length) {
      setSelectedVehicles([...vehiclesToShowAfterSearchRemove]);
      setVehiclesToFilter([...vehiclesToShowAfterSearchRemove]);
      setIsVehicleRemovedBySearch(true);
    }
    setSearchedInputValue("")
  }

  const sortShortlistedVehicles = () => {
    if (!isSortlistedVehiclesSorted) {
      vehiclesByCriteria.sort((a: IVehicle, b: IVehicle) => a.licencePlate.toLowerCase() > b.licencePlate.toLowerCase() ? 1 : -1);
      setVehiclesByCriteria([...vehiclesByCriteria]);
      setIsSortlistedVehiclesSorted(true);
    } else {
      vehiclesByCriteria.sort((a: IVehicle, b: IVehicle) => a.licencePlate.toLowerCase() < b.licencePlate.toLowerCase() ? 1 : -1);
      setVehiclesByCriteria([...vehiclesByCriteria]);
      setIsSortlistedVehiclesSorted(false);
    }
  }

  const sortSelectedVehicles = () => {
    if (!isSelectedVehiclesSorted) {
      selectedVehicles.sort((a: IVehicle, b: IVehicle) => a.licencePlate.toLowerCase() > b.licencePlate.toLowerCase() ? 1 : -1);
      setSelectedVehicles([...selectedVehicles]);
      setIsSelectedVehiclesSorted(true);
    } else {
      selectedVehicles.sort((a: IVehicle, b: IVehicle) => a.licencePlate.toLowerCase() < b.licencePlate.toLowerCase() ? 1 : -1);
      setSelectedVehicles([...selectedVehicles]);
      setIsSelectedVehiclesSorted(false);
    }
  }

  const handleAutoCompleteInputChange = _.debounce((value: string, reason: any) => {
    if (reason === "input" || reason === "reset") {
      setIsVehicleRemovedBySearch(false);
      const resourcesToFilter = [...selectedVehicleToShow];
      if (!value) {
        setSelectedVehicles(resourcesToFilter);
      } else {
        const filtered = resourcesToFilter.filter((resource) => {
          if (Object.values(resource).some((val) => typeof val === "string" &&
            val.toLowerCase().includes(value.toLowerCase()))
          ) {
            return resource;
          }
        });
        if (filtered && filtered.length > 0) {
          setSelectedVehicles([...filtered]);
        }
      }
    }
    if (reason === 'clear') {
      if (isVehicleRemovedBySearch) {
        setSelectedVehicles([...selectedVehicles]);
        setVehiclesToFilter([...selectedVehicles]);
        setSelectedVehiclesToShow([...selectedVehicles]);
      } else {
        setSelectedVehicles([...vehiclesToFilter]);
      }
    }
  }, 200);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return <>
    <Grid container spacing={2} style={{ marginBottom: 30 }}>
      <Grid item xs={12} >
        Enter a Group Name, select options in the filters to set the group criteria and vehicles
        from the inventory will be shortlisted if they meet the criteria.
      </Grid>
      <Grid item xs={12} >
        <Grid container alignItems="center" justifyContent="space-between">
          {vehicleGroup.id ? (
            <>
              <Grid item xs={8} sm={6}>
                <TextField
                  required
                  id="outlined-required"
                  label="Group Name"
                  variant="outlined"
                  style={{
                    background: "white"
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setError(true)
                      setErrorText("Group Name is required")
                    }
                    else {
                      setError(false)
                      setErrorText("")
                    }
                  }}
                  error={error}
                  helperText={errorText}
                  value={vehicleGroup.name || ""}
                  onChange={(e) => {
                    const vehiclePriceGroupObj = {
                      ...vehicleGroup,
                      name: e.target.value
                    };
                    setVehicleGroup(vehiclePriceGroupObj);
                  }}
                  inputProps={{ maxLength: 100 }}

                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          name="checkedB"
                          color="primary"
                          onChange={handleHideInB2b2cToggle}
                          checked={vehicleGroup.hideInB2b2c ?? false}
                          />
                      }
                      label={"Hide on customer portal & website"}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          name="checkedB"
                          color="primary"
                          onChange={handleGroupActivation}
                          checked={vehicleGroup.isActivated}
                        />
                      }
                      label={vehicleGroup.isActivated ? "Active" : "Activate Group"}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sm={3}>
              <TextField
                required
                id="outlined-required"
                label="Group Name"
                variant="outlined"
                style={{
                  width: "100%",
                  background: "white"
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    setError(true)
                    setErrorText("Group Name is required")
                  }
                  else {
                    setError(false)
                    setErrorText("")
                  }
                }}
                error={error}
                helperText={errorText}
                value={vehicleGroup.name || ""}
                onChange={(e) => {
                  const vehiclePriceGroupObj = {
                    ...vehicleGroup,
                    name: e.target.value
                  };
                  setVehicleGroup(vehiclePriceGroupObj);
                }}
                inputProps={{ maxLength: 100 }}

              />
            </Grid>
          )
          }
        </Grid>
      </Grid>
      <Hidden smDown>
        <Grid item xs={12}>
          <Typography variant={"h3"} style={{ marginBottom: "10px" }}>
            FILTERS
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {criteriaLoading && loadingVehiclesData ? (
            <CircularProgress />
          ) : (
            <React.Fragment>
              <Grid item xs={12}>
                <Autocomplete
                  id="free-solo-demo"
                  options={vehicles}
                  getOptionLabel={(option: any) =>
                    option.licencePlate
                  }
                  onChange={(_: any, newVal: any, reason) => {
                    if (reason === 'selectOption') {
                      handleAutoCompleteOnChange(newVal, reason);
                    } else {
                      setSelectedVehicleValue(vehicleFilterInitial);
                      setVehiclesByCriteria(vehiclesByCriteria.filter(value => value.id !== newVal.id));
                    }
                  }}
                  value={selectedVehicleValue}
                  renderInput={(params) => (
                    <TextField {...params}
                      label="Search"
                      placeholder="Registration Number"
                      margin="normal"
                      variant="outlined"
                      style={{ backgroundColor: "white" }}
                    />
                  )}
                />
              </Grid>
              <FiltersView filters={filters} onChange={handleFilterUpdate} />
              <Fab
                variant="extended"
                size="medium"
                aria-label="Create"
                className="createButton"
                style={{ marginTop: 20, marginBottom: 20 }}
                onClick={() => {
                  if (criteriaData && criteriaData.vehicleGroupCriteria) {
                    setFilters(formatCriteriaForFilter(criteriaData.vehicleGroupCriteria));
                  }
                  setVehiclesByCriteria([]);
                  setCriteria([]);
                  setSelectedVehicleValue(vehicleFilterInitial);
                }}
              >
                CLEAR FILTERS
              </Fab>
            </React.Fragment>
          )}
        </Grid>

      </Hidden>
      <Grid item xs={12} sm={10}>
        <Grid container>
          {vehiclesByCriteria && !vehiclesByCriteria.length ? (
            <>
              <Grid item xs={12} sm={6}>
                <Box
                  className={classes.box}
                  style={{
                    maxHeight: "100vh",
                  }}
                >
                  <Paper
                    variant="outlined"
                    square
                    className={classes.paper1}
                  >
                    <Grid container style={{ minHeight: "80vh", border: "2px dashed grey" }}>
                      {loadingVehiclesByCriteria ? (<CircularProgress style={{ marginTop: 8, marginLeft: 8 }} />) : (
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Grid container style={{ paddingLeft: 30, paddingRight: 30 }}>
                            <Typography variant="subtitle1">
                              Search for Vehicle(s) using the filters and the vehicles matching the criteria will be shortlisted here
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Box>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sm={6}>
              <Box
                className={classes.box}
                style={{
                  maxHeight: "86vh",
                }}>
                <Paper
                  variant="outlined"
                  square
                  className={classes.paper2}
                >
                  {loadingVehiclesByCriteria ? (
                    <Box p={3}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                      >
                        <Grid container>
                          {vehiclesByCriteria && vehiclesByCriteria.length && (
                            <React.Fragment>
                              <Grid container>
                                <Grid item xs={8} sm={10} style={{
                                  marginLeft: 16,
                                  marginTop: 16,
                                  fontSize: 13
                                }}>
                                  {vehiclesByCriteria.length} vehicles shortlisted
                                </Grid>
                                <Grid item xs={1} style={{
                                  marginLeft: 16,
                                  marginTop: 16,
                                  fontSize: 13
                                }}>
                                  <span onClick={() => sortShortlistedVehicles()}>
                                    <img src={isSortlistedVehiclesSorted ? sortAtoZIcon : sortZtoAIcon}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={2} sm={1}>
                                  <span onClick={() => addSelectedVehicle()} style={{ color: "white" }}>
                                    <AddIcon style={{
                                      marginLeft: 20,
                                      fontSize: 18,
                                      backgroundColor: "var(--theme-primary)",
                                      cursor: "pointer",
                                      borderRadius: 2
                                    }}
                                    />
                                  </span>
                                </Grid>
                                <Grid item xs={8} sm={10} style={{
                                  marginLeft: 16,
                                  fontSize: 13
                                }}>
                                  Click the '+' button to add these vehicles to the group.
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          )}
                        </Grid>
                      </Grid>
                      {vehiclesByCriteria.map((vehicle: IVehicle, idx: number) => {
                        const matchedVehicleIds: string[] = []
                        selectedVehicles.filter(selectedVehicle => {
                          vehiclesByCriteria.forEach(vehicleByCriteria => {
                            if (vehicleByCriteria.id === selectedVehicle.id) {
                              if (selectedVehicle && selectedVehicle.id) {
                                matchedVehicleIds.push(selectedVehicle.id);
                              }
                            }
                          })
                        })
                        const vehicleId = vehicle && vehicle.id ? vehicle.id : "";
                        return (
                          <Grid item xs={12} key={idx}>
                            <Paper
                              elevation={3}
                              style={{
                                margin: "5px",
                                marginLeft: "16px",
                                marginRight: "16px"
                              }}
                            >
                              <Grid container style={{ padding: "5px 0" }}>
                                <Grid item xs={2} sm={1} alignContent='center' alignItems='center' justifyContent='center'>
                                  <Grid item
                                    onClick={() => addSelectedVehicle(vehicle)}
                                    style={{ color: "white" }}
                                  >
                                    <AddIcon
                                      style={{
                                        marginTop: 5,
                                        marginLeft: 10,
                                        backgroundColor: "var(--theme-primary)",
                                        cursor: "pointer",
                                        fontSize: 16,
                                        borderRadius: 2
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                                <Hidden smUp>
                                  <Grid container item xs={9}>
                                    <Grid item xs={12}>
                                      <Typography variant="h3">
                                        {vehicle.licencePlate?.toUpperCase()}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Grid container>
                                        <Grid
                                          item
                                          xs={12}
                                          style={{ marginTop: 5 }}
                                        >
                                          {vehicle.make} {vehicle.model} |{" "}
                                          {vehicle.year} | {vehicle.bodyType}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Hidden>
                                <Hidden smDown>
                                  <Grid item xs={3}>
                                    <Typography variant="h3">
                                      {vehicle.licencePlate?.toUpperCase()}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ marginTop: 5 }}
                                      >
                                        {vehicle.make} {vehicle.model} |{" "}
                                        {vehicle.year} | {vehicle.bodyType}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Grid container justifyContent="flex-end">
                                      <IconButton
                                        style={{
                                          color: "black",
                                          padding: 0,
                                          paddingRight: 5
                                        }}
                                        size="large">
                                        <Tooltip
                                          title={
                                            <div>
                                              <p> {`${vehicle.numberOfDoors} Doors`} </p>
                                              <p> {`${vehicle.numberOfSeats} Seats`} </p>
                                              <p> {`${vehicle.fuelType}`} </p>
                                              <p>{`${vehicle.transmission}`}</p>
                                            </div>
                                          }
                                          arrow
                                          placement={"right"}
                                        >
                                          <InfoIcon />
                                        </Tooltip>
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Hidden>
                              </Grid>
                            </Paper>
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                </Paper>
              </Box>
            </Grid>
          )}

          {selectedVehicles && !selectedVehicles.length ? (
            <Grid item xs={12} sm={6}>
              <Box
                className={classes.box}
                style={{
                  maxHeight: "100vh",
                }}
              >
                <Paper
                  variant="outlined"
                  square
                  className={classes.paper1}
                >
                  <Grid container style={{ minHeight: "80vh" }}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "2px dashed grey"
                      }}
                    >
                      <Grid container style={{ paddingLeft: 30, paddingRight: 30 }}>
                        <Typography variant="subtitle1">
                          Click the '+' button on top of the left window or individual vehicle cards to create a group of vehicles from those shortlisted in the left window.
                          Search by 'Registration no.' will directly add the vehicle to this window.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12} sm={6}>
              <Box
                className={classes.box}
                style={{ maxHeight: "86vh", }}
              >
                <Paper
                  variant="outlined"
                  square
                  className={classes.paper2}
                >
                  <Grid container>
                    <Grid container>
                      {selectedVehicles && selectedVehicles.length > 0 && (
                        <React.Fragment>
                          <Grid container>
                            <Grid item xs={8} sm={10} style={{
                              marginLeft: 16,
                              marginTop: 16,
                              fontSize: 13
                            }}>
                              Number of vehicles in this group: {selectedVehicles.length}
                            </Grid>
                            <Grid item xs={2} sm={1} style={{
                              marginLeft: 16,
                              marginTop: 16,
                              fontSize: 13
                            }}>
                              <span onClick={() => sortSelectedVehicles()}>
                                <img src={isSelectedVehiclesSorted ? sortAtoZIcon : sortZtoAIcon}
                                  style={{ cursor: "pointer" }}
                                />
                              </span>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={2} sm={1} style={{ marginTop: 10 }}>
                              <span
                                onClick={() => setIsRemoveConfirmDialogOpen(true)}
                                style={{ color: "white" }}
                              >
                                <RemoveIcon style={{
                                  marginLeft: 20,
                                  fontSize: 18,
                                  backgroundColor: "var(--theme-primary)",
                                  cursor: "pointer",
                                  borderRadius: 2
                                }} />
                              </span>
                            </Grid>
                            <Grid item xs={8} sm={10} style={{
                              marginBottom: 10,
                              marginLeft: 24,
                              fontSize: 13
                            }}>
                              <Grid container justifyContent="flex-end">
                                <Grid item xs={12} sm={6}>
                                  <Autocomplete
                                    id="free-solo-demo"

                                    options={vehiclesToFilter}
                                    getOptionLabel={(option: any) =>
                                      option.licencePlate
                                    }
                                    onInputChange={(event: any, value: string, reason: string) => {
                                      handleAutoCompleteInputChange(value, reason);
                                      setSearchedInputValue(value);
                                    }}
                                    inputValue={searchedInputValue}
                                    renderInput={(params) => (
                                      <TextField {...params}
                                        label="Search"
                                        placeholder="Registration Number"
                                        margin="normal"
                                        variant="outlined"
                                        style={{ backgroundColor: "white" }}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                    {selectedVehicles && selectedVehicles.length > 0 && selectedVehicles.map((vehicle: IVehicle, idx: number) => {
                      return (
                        <Grid item xs={12} key={idx}>
                          <Paper
                            elevation={3}
                            style={{
                              margin: "5px",
                              marginLeft: "16px",
                              marginRight: "16px"
                            }}
                          >
                            <Grid container style={{ padding: "5px 0" }}>
                              <Grid item xs={2} sm={1} alignContent='center' alignItems='center' justifyContent='center'>
                                <Grid item
                                  onClick={() => removeSelectedVehicle(vehicle)}
                                  style={{ color: "white" }}
                                >
                                  <RemoveIcon
                                    style={{
                                      marginTop: 5,
                                      marginLeft: 10,
                                      backgroundColor: "var(--theme-primary)",
                                      cursor: "pointer",
                                      fontSize: 16,
                                      borderRadius: 2
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Hidden smUp>
                                <Grid container item xs={9}>
                                  <Grid item xs={12}>
                                    <Typography variant="h3">
                                      {vehicle.licencePlate?.toUpperCase()}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ marginTop: 5 }}
                                      >
                                        {vehicle.make} {vehicle.model} |{" "}
                                        {vehicle.year} | {vehicle.bodyType}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Hidden>
                              <Hidden smDown>
                                <Grid item xs={3}>
                                  <Typography variant="h3">
                                    {vehicle.licencePlate?.toUpperCase()}
                                  </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ marginTop: 5 }}
                                    >
                                      {vehicle.make} {vehicle.model} |{" "}
                                      {vehicle.year} | {vehicle.bodyType}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                  <Grid container justifyContent="flex-end">
                                    <IconButton
                                      style={{
                                        color: "black",
                                        padding: 0,
                                        paddingRight: 5
                                      }}
                                      size="large">
                                      <Tooltip
                                        title={
                                          <div>
                                            <p> {`${vehicle.numberOfDoors} Doors`} </p>
                                            <p> {`${vehicle.numberOfSeats} Seats`} </p>
                                            <p> {`${vehicle.fuelType}`} </p>
                                            <p>{`${vehicle.transmission}`}</p>
                                          </div>
                                        }
                                        arrow
                                        placement={"right"}
                                      >
                                        <InfoIcon />
                                      </Tooltip>
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Hidden>
                            </Grid>
                          </Paper>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Paper>
              </Box>
            </Grid>
          )}
          <Hidden smDown>
            <Grid
              container
              item
              xs={12}
              style={{ marginTop: 20 }}
            >
              <Grid container justifyContent="flex-end" xs={12}>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="Create"
                  className="createButton"
                  disabled={!selectedVehicles.length || !vehicleGroup.name}
                  onClick={() => {
                    const criteriaValue = criteria.some(criteriaData => criteriaData.values.length);
                    if (criteriaValue) {
                      setIsconfirmDialogOpen(true);
                    } else {
                      props.updateVehicleGroup({
                        ...vehicleGroup,
                        criteria,
                        vehicles: [...selectedVehicles]
                      });
                      props.onSubmit();
                    }
                  }
                  }
                >
                  PROCEED
                </Fab>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <Hidden smUp><Grid item xs={12}><Box height={'50px'} /></Grid></Hidden>
      {isConfirmDialogOpen && (
        <ConfirmationDialog
          isOpen={isConfirmDialogOpen}
          title={""}
          description={"Do you want this group to be automatically updated each time a new vehicle with the same filter criteria is added to the inventory?"}
          confirmText={"YES"}
          cancelText={"NO"}
          disabledBackDropClick={true}
          onBackDropClick={() => { setIsconfirmDialogOpen(false) }}
          onCancel={() => {
            props.updateVehicleGroup({
              ...vehicleGroup,
              criteria,
              automaticAddVehicle: false,
              vehicles: [...selectedVehicles]
            });
            props.onSubmit();
          }}
          onConfirm={() => {
            props.updateVehicleGroup({
              ...vehicleGroup,
              criteria,
              automaticAddVehicle: true,
              vehicles: [...selectedVehicles]
            });
            props.onSubmit();
          }}
        />
      )}
      {isRemoveConfirmDialogOpen && (
        <ConfirmationDialog
          isOpen={isRemoveConfirmDialogOpen}
          title={""}
          description={"Are you sure you want to remove the vehicles from this group?"}
          onCancel={() => setIsRemoveConfirmDialogOpen(false)}
          onConfirm={() => removeSelectedVehicle()}
          confirmText={"Yes"}
          cancelText={"No"}
        />
      )}
    </Grid >
    <Hidden smUp>
      <AppBar position="fixed" className={classes.appBar}>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={12}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <Autocomplete
                id="free-solo-demo"
                options={vehicles}
                getOptionLabel={(option: any) =>
                  option.licencePlate
                }
                onChange={(_: any, newVal: any, reason) => {
                  if (reason === 'selectOption') {
                    handleAutoCompleteOnChange(newVal, reason);
                  } else {
                    setSelectedVehicleValue(vehicleFilterInitial);
                    setVehiclesByCriteria(vehiclesByCriteria.filter(value => value.id !== newVal.id));
                  }
                }}
                value={selectedVehicleValue}
                renderInput={(params) => {
                  return <TextField {...params}
                    placeholder="Registration Number Search"
                    classes={{
                      root: classes.inputRoot,
                      // input: classes.inputInput,
                    }}
                  />
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classes.bottomButton}
              variant="contained"
              aria-label="Filters"
              onClick={handleClickOpen}
            >
              Filters
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classes.bottomButton}
              variant="contained"
              aria-label="Continue"
              disabled={!selectedVehicles.length || !vehicleGroup.name}
              onClick={() => {
                const criteriaValue = criteria.some(criteriaData => criteriaData.values.length);
                if (criteriaValue) {
                  setIsconfirmDialogOpen(true);
                } else {
                  props.updateVehicleGroup({
                    ...vehicleGroup,
                    criteria,
                    vehicles: [...selectedVehicles]
                  });
                  props.onSubmit();
                }
              }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </AppBar>
    </Hidden>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Filters</DialogTitle>
      <DialogContent>
        <FiltersView
          searchFilters={searchFilters}
          filters={filters}
          onChange={handleFilterUpdate}
        />
        <Button
          className={classes.bottomButton}
          variant="contained"
          aria-label="Create"
          style={{ marginTop: '8px' }}
          onClick={() => {
            if (criteriaData && criteriaData.vehicleGroupCriteria) {
              setFilters(formatCriteriaForFilter(criteriaData.vehicleGroupCriteria));
              setSearchFilters(formatCriteriaForFilter(criteriaData.vehicleGroupCriteria));
            }
            setVehiclesByCriteria([]);
            setCriteria([]);
            setSelectedVehicleValue(vehicleFilterInitial);
          }}
        >
          CLEAR FILTERS
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Exit</Button>
      </DialogActions>
    </Dialog>
  </>;
};
