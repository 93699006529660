import { gql } from "@apollo/client";

export const GET_VEHICLE_GROUP = gql`
  query vehicleGroup($id: String!) {
    vehicleGroup(id: $id) {
      id
      name
      criteria {
        type
        values
      }
      images{
        idx
        url
        default
      }
      vehicles {
        id
        bodyType
        make
        model
        year
        licencePlate
        transmission
        colour
        steering
        fuelType
        features
        wheelPlan
        cylinderCapacity
        numberOfSeats
        numberOfDoors
        imageUrl
        isGhostVehicle
      }
      deposit
      basePrices {
        rateTypeName
        rateTypeDuration
        rate
        longTerm
        mileageLimit
        pricePerExtraMile
        unlimitedMileage
      }
      isActivated
      hideInB2b2c
      imageUrl
      createdAt
      updatedAt
      yieldPricingEnabled
      yieldPriceSlabs {
        yieldThreshold
        rules {
          rateType
          value
          valueType
        }
        startDate
        endDate
      }
    }
  }
`;
