import { useLazyQuery, useMutation, ApolloError } from "@apollo/client";
import { CircularProgress, Dialog, DialogContent, DialogTitle, Fab, Grid, Paper, Divider, TextField, Typography, Theme, FormControlLabel, Checkbox } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { GET_BOOKING_BY_REFERENCE } from "../../../graphql/bookings/getBookingByReferenceQuery";
import { GET_BOOKING_BY_PCN } from "../../../graphql/pcn/fetchBookingByPCNQuery";
import { GET_PCN_BY_ID } from "../../../graphql/pcn/getPCNByIdQuery";
import { TRANSFER_LIABILITY } from "../../../graphql/pcn/transferLiabilityMutation";
import { MARK_PCN_AS_CLOSED } from "../../../graphql/pcn/updatePCNAsClosedMutation";
import { MARK_PCN_AS_PAID } from "../../../graphql/pcn/updatePCNAsPaidMutation";
import { IBooking } from "../../../reducers/bookings/types";
import { IBusinessCustomer, ICustomer } from "../../../reducers/customer/types";
import { IAppState } from "../../../store";
import { getSignedUrl } from "../../../utils/getSignedUrl";
import { getLocalizedDateFormat } from "../../../utils/localized.syntex";
import { ConfirmationDialog } from "../../common/ConfirmationDialog/ConfirmationDialog";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { DATE_TYPE, formatGraphQLErrorMessage } from "../../common/utils";
import { SummaryField } from "./UpdateInvoice/InvoiceComponents/SummaryField";
import { captureErrorException } from "../../../utils/sentry";
import { SEND_PCN_MAIL } from "../../../graphql/pcn/sendPcnMailMutation";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    width: "100%",
  },
  tableContainer: {
    border: "solid 1px rgba(224, 224, 224, 1)",
    marginTop: "1rem"
  }
}))

const NoticeSummary: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [noticeData, setNoticeData] = useState<any>({})
  const [transferModalOpen, setTransferModalOpen] = useState<Boolean>(false)
  const [confirmPayment, setConfirmPayment] = useState<boolean>(false)
  const [confirmClosed, setConfirmClosed] = useState<boolean>(false)
  const [noticeId, setNoticeId] = useState<string | null>("")
  const [liabilityTransferred, setLiabilityTransferred] = useState<boolean>(false);
  const [selectedBookingRef, setSelectedBookingRef] = useState<string>("")
  const [bookingData, setBookingData] = useState<IBooking>()
  const [agreementUrl, setAgreementUrl] = useState<any>("")
  const [customer, setCustomer] = useState<ICustomer>()
  const [businessCustomer, setBusinessCustomer] = useState<IBusinessCustomer>()
  const [shareAgreementUrl, setShareAgreementUrl] = useState<boolean>(false)

  const PCNTicketStatus = {
    OPEN: "OPEN",
    CLOSED: "CLOSED"
  }
  const [getNoticeByID, { data }] = useLazyQuery(
    GET_PCN_BY_ID,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data.getPCNById) {
          setNoticeData(data.getPCNById)
          setLiabilityTransferred(data.getPCNById.liabilityTransferred || false)
        } else {
          navigate("/penalty-charge-notice");
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [markAsPaid] = useMutation(MARK_PCN_AS_PAID, {
    onCompleted: (data: any) => {
      snackbar({
        message: "Notice has been marked as Paid",
        variant: SnackBarVariant.SUCCESS
      });
      setConfirmPayment(false)
      getNoticeByID({
        variables: {
          id: noticeId
        }
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [markAsClosed] = useMutation(MARK_PCN_AS_CLOSED, {
    onCompleted: (data: any) => {
      snackbar({
        message: "Notice Ticket has been closed",
        variant: SnackBarVariant.SUCCESS
      });
      setConfirmClosed(false);
      getNoticeByID({
        variables: {
          id: noticeId
        }
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [transferLiability] = useMutation(TRANSFER_LIABILITY, {
    onCompleted: (data: any) => {
      snackbar({
        message: "Liability has been successfully transferred to the customer",
        variant: SnackBarVariant.SUCCESS
      });
      sendNoticeMail({
        variables: {
          customerId: customer ? customer.id : businessCustomer?.id,
          vehicle: noticeData?.vehicle?.licencePlate,
          noticeUrl: noticeData?.noticeToKeeper?.url
        }
      })
      setLiabilityTransferred(true);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: "Liability has been successfully transferred to the customer",
        variant: SnackBarVariant.SUCCESS
      });
      sendNoticeMail({
        variables: {
          customerId: customer ? customer.id : businessCustomer?.id,
          vehicle: noticeData?.vehicle?.licencePlate,
          noticeUrl: noticeData?.noticeToKeeper?.url
        }
      })
      setConfirmClosed(false);
    }
  });

  const [sendNoticeMail] = useMutation(SEND_PCN_MAIL, {
    onCompleted: () => {

    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [getBookingByRef, { loading: bookingLoading }] = useLazyQuery(GET_BOOKING_BY_REFERENCE,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data.getBookingByRef) {
          setBookingData(data.getBookingByRef)

          if (data.getBookingByRef.customer) {
            setCustomer(data.getBookingByRef.customer)
          }
          if (data.getBookingByRef.businessCustomer) {
            setBusinessCustomer(data.getBookingByRef.businessCustomer)
          }
        } else {
          snackbar({
            message: "Booking with this reference doesn't exists",
            variant: SnackBarVariant.ERROR
          });
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [getBookingByNoticeDate, { loading: loading }] = useLazyQuery(GET_BOOKING_BY_PCN,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data.fetchBookingByPCN) {
          setBookingData(data.fetchBookingByPCN)

          if (data.fetchBookingByPCN.customer) {
            setCustomer(data.fetchBookingByPCN.customer)
          }
          if (data.fetchBookingByPCN.businessCustomer) {
            setBusinessCustomer(data.fetchBookingByPCN.businessCustomer)
          }
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const noticeId = params.get("noticeId");
      setNoticeId(noticeId)
      if (noticeId) {
        getNoticeByID({
          variables: {
            id: noticeId
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (bookingData?.rentalAgreementUrl) {
      createSignedUrl(bookingData?.rentalAgreementUrl)
    }
  }, [bookingData])

  const createSignedUrl = async (url: string) => {
    if (url) {
      try {
        const file = await getSignedUrl(url)
        setAgreementUrl(file);
      } catch (error) {
        captureErrorException(error)
        snackbar({
          message: 'error in creating signed url',
          variant: SnackBarVariant.ERROR
        });
      }
    }
  };

  return (
    <Grid container spacing={2} xs={12}>
      <Grid container item xs={4}>
        <Typography variant="h1" color="primary">
          Penalty  Charge Notice
        </Typography>
      </Grid>
      <Grid container item xs={8} justifyContent="flex-end">
        {noticeData.status === PCNTicketStatus.OPEN &&
          <>
            {!liabilityTransferred &&
              <>
                <Fab
                  variant={"extended"}
                  size="medium"
                  aria-label="Pay Fine"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    window.open(`${noticeData.paymentUrl}`, "_blank");
                  }}
                >
                  Pay Fine
                </Fab>
                <Fab
                  variant={"extended"}
                  size="medium"
                  aria-label="Mark as Paid"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setConfirmPayment(true)
                  }}
                >
                  Mark as Paid
                </Fab>
                <Fab
                  variant={"extended"}
                  size="medium"
                  aria-label="Change Liability"
                  // style={{ marginRight: 10 }}
                  onClick={() => {
                    getBookingByNoticeDate({
                      variables: {
                        vehicleId: noticeData.vehicle.id,
                        pcnDate: noticeData.contraventionDate
                      }
                    })
                    setTransferModalOpen(true)
                  }}
                >
                  Transfer Liability
                </Fab>
              </>
            }
            {liabilityTransferred &&
              <Fab
                variant={"extended"}
                size="medium"
                aria-label="Mark as Paid"
                style={{ marginRight: 10 }}
                onClick={() => {
                  setConfirmPayment(true)
                }}
              >
                Mark as Paid
              </Fab>
            }
            {liabilityTransferred &&
              <Fab
                variant={"extended"}
                size="medium"
                aria-label="Mark as Paid"
                // style={{ marginRight: 10 }}
                onClick={() => {
                  setConfirmClosed(true)
                }}
              >
                Mark as Closed
              </Fab>
            }
          </>
        }
      </Grid>
      <Grid container item xs={12}>
        <Paper className={classes.root}>
          <Grid container spacing={2}>
            <Grid container item xs={6}>
              <Typography variant={"h2"}>
                {"PENALTY CHARGE SUMMARY"}
              </Typography>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              {/* <Grid container xs={4} spacing={1}> */}
              <Grid item xs={4} spacing={1}>
                <SummaryField
                  label={`VRN`}
                  value={noticeData.vehicle?.licencePlate}
                />
              </Grid>
              <Grid item xs={4} spacing={1}>
                <SummaryField
                  label={`Notice Number`}
                  value={noticeData.noticeRef}
                />
              </Grid>
              <Grid item xs={4} spacing={1}>
                <SummaryField
                  label={`Location`}
                  value={noticeData.location}
                />
              </Grid>
              <Grid item xs={4} spacing={1}>
                <SummaryField
                  label={`Issue Date`}
                  value={getLocalizedDateFormat(country, noticeData.dateCreated, DATE_TYPE.CONDENSED)}
                />
              </Grid>
              <Grid item xs={4} spacing={1}>
                <SummaryField
                  label={`Offence Date`}
                  value={getLocalizedDateFormat(country, noticeData.contraventionDate, DATE_TYPE.CONDENSED)}
                />
              </Grid>
              <Grid item xs={4} spacing={1}>
                <SummaryField
                  label={`Penalty Type`}
                  value={noticeData.noticeType}
                />
              </Grid>
              <Grid item xs={4} spacing={1}>
                <SummaryField
                  label={`Amount to be paid`}
                  value={noticeData.totalDue}
                />
              </Grid>
              {/* </Grid> */}
            </Grid>
            {transferModalOpen &&
              <Dialog
                fullWidth={true}
                open={true}
                onClose={() => {
                  setTransferModalOpen(false);
                }}
              >
                <DialogTitle id="template-title" style={{ padding: "20px 24px 10px" }}><Typography variant="h3">{'Transfer Liability to Customer'}</Typography></DialogTitle>
                <DialogContent style={{ maxWidth: "100%", maxHeight: '100%' }}>
                  <Grid container xs={12}>
                    {(customer || businessCustomer) &&
                      <>
                        <Grid container xs={12} spacing={2} style={{ marginTop: "20px" }}>
                          <Grid item xs={12}>
                            <Typography variant="h4"> Customer Details: </Typography>
                          </Grid>

                          <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12} spacing={2}>
                              <SummaryField
                                label={`Customer Name`}
                                value={customer ? `${customer.firstName} ${customer.lastName}` : (businessCustomer ? businessCustomer.businessName : "")}
                              />
                            </Grid>
                            <Grid item xs={12} spacing={2}>
                              <SummaryField
                                label={`Email`}
                                value={customer ? customer.email : (businessCustomer ? businessCustomer?.contact.email : "")}
                              />
                            </Grid>
                            <Grid item xs={12} spacing={2}>
                              <SummaryField
                                label={`CustomerType`}
                                value={customer ? "Individual" : "Business"}
                              />
                            </Grid>
                            <Grid item xs={12} spacing={2}>
                              <SummaryField
                                label={`Phone Number`}
                                value={customer ? customer.phoneNumber.phone : (businessCustomer ? businessCustomer.contact.phoneNumber.phone : "")}
                              />
                            </Grid>
                            <Grid item xs={12} spacing={2}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={shareAgreementUrl}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                      setShareAgreementUrl(event.target.checked)
                                    }}
                                    name="shareAgreementUrl"
                                    color="primary"
                                  />
                                }
                                label={<>
                                  <b>Share Agreement Url</b>
                                </>}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item spacing={2} style={{ padding: "10px", marginLeft: '40%', marginTop: "20px" }}>
                          <Fab
                            variant={"extended"}
                            size="medium"
                            aria-label="Proceed"
                            onClick={() => {
                              transferLiability({
                                variables: {
                                  customerId: customer ? customer.id : businessCustomer?.id,
                                  noticeId: noticeId,
                                  shareUrl: shareAgreementUrl,
                                  url: shareAgreementUrl ? agreementUrl : ""
                                }
                              })
                            }}
                          >
                            Proceed
                          </Fab>
                        </Grid>
                        <Grid item xs={12} style={{ margin: '40px' }}>
                          <Divider variant="middle" />
                        </Grid>
                      </>
                    }
                    {(loading || bookingLoading) && <CircularProgress size={14} style={{ color: "white", margin: "20px" }} />}
                    <Grid item xs={12}>
                      <Typography>
                        Search Customer By Booking Reference
                      </Typography>
                    </Grid>
                    <Grid container xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                      <Grid item xs={8}>
                        <TextField
                          fullWidth
                          placeholder="Add Booking Reference"
                          label="Add Booking Reference"
                          name={"bookingRef"}
                          InputProps={{
                            value: selectedBookingRef,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setSelectedBookingRef(e.target.value)
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Fab
                          variant={"extended"}
                          size="medium"
                          aria-label="Search"
                          disabled={!selectedBookingRef}
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            getBookingByRef({
                              variables: {
                                bookingRef: selectedBookingRef
                              }
                            })
                          }}
                        >
                          {bookingLoading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                          Search
                        </Fab>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
              </Dialog>
            }
          </Grid>
        </Paper>
      </Grid>
      <Grid container item xs={12} justifyContent="flex-end">
        <Fab
          variant={"extended"}
          size="medium"
          aria-label="Download Notice"
        >
          <a href={noticeData?.noticeToKeeper?.url}>Download Notice</a>
        </Fab>
      </Grid>
      {confirmPayment &&
        <ConfirmationDialog
          isOpen={confirmPayment}
          onCancel={() => setConfirmPayment(false)}
          onConfirm={() => {
            markAsPaid({
              variables: {
                id: noticeId,
              }
            })
          }}
          title=""
          description={"Are you sure you want to mark this notice as Paid? This will close this penalty charge ticket."}
        />
      }
      {
        confirmClosed &&
        <ConfirmationDialog
          isOpen={confirmClosed}
          onCancel={() => setConfirmClosed(false)}
          onConfirm={() => {
            markAsClosed({
              variables: {
                id: noticeId,
              }
            })
          }}
          title=""
          description={"Are you sure you want to close this notice?"}
        />
      }
    </Grid >
  )
}

export default NoticeSummary;