import {
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  Paper,
  Theme,
  Typography,
  TextField as InputField,
  FormControlLabel,
  Switch,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Fab,
  Autocomplete,
  Button,
  IconButton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { IRateCard, IRateCardBusinessCustomer, IRateCardVehicleGroup } from "./RateCards";
import { GET_RATE_CARD_BY_ID } from "../../../../../graphql/rateCards/getRateCardQuery";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { IBusinessCustomer } from "../../../../../reducers/customer/types";
import { GET_BUSINESS_CUSTOMER_PRICE } from "../../../../../graphql/priceRules/getBusinessCustomersQuery";
import { Field, Form, Formik } from "formik";
import { DEACTIVATE_RATE_CARD } from "../../../../../graphql/rateCards/deactivateRateCardMutation";
import { UPDATE_RATE_CARD } from "../../../../../graphql/rateCards/updateRateCardMutation";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { TextField } from "formik-mui";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { E164Number } from "libphonenumber-js/types.cjs";

const useStyles = makeStyles((theme: Theme) =>
({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1
  },
  ruleCardMain: {
    position: "relative"
  },
  addButton: {
    position: "fixed",
    [theme.breakpoints.up('md')]: {
      right: "6rem",
      bottom: '30%',
    },
    [theme.breakpoints.down('sm')]: {
      right: "2rem",
      bottom: '10%',
    },
  },
  iconStyle: {
    padding: 2
  },
  tableContainer: {
    maxHeight: 300,
    border: "solid 1px rgba(224, 224, 224, 1)"
  },
  avatarStyle: {
    background: "var(--theme-accent)",
    height: 25,
    width: 25
  },
  ruleAvatar: {
    background: "var(--theme-accent)",
    height: 25,
    width: 25
  },
  expansionPanel: {
    marginTop: 8,
    width: `calc(100vw - 105px)`,
  },
})
);

const newBusinessCustomer: IRateCardBusinessCustomer = {
  businessCustomer: {
    id: "",
    businessName: "",
    accountManager: "",
    registrationNumber: "",
    VAT: "",
    registeredAddress: {
      street: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      fullAddress: ""
    },
    tradingAddressSame: false,
    tradingAddress: {
      street: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      fullAddress: ""
    },
    approvedDrivers: [],
    authorizedSignatories: [],
    billing: {
      phoneNumber: {
        phone: "" as E164Number,
        country: ""
      },
      email: ""
    },
    contact: {
      firstName: "",
      lastName: "",
      phoneNumber: {
        phone: "" as E164Number,
        country: ""
      },
      email: ""
    },
    documents: [],
    status: "",
    declinedReason: "",
  },
  autoIncrementRatesBy: 0,
  increaseRatesYearly: false
};

const RateCard: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const [values, setValues] = useState<IRateCard>();
  const [businessCustomers, setBusinessCustomers] = useState<IBusinessCustomer[]>([]);
  const [selectedBusinessCustomers, setSelectedBusinessCustomers] = useState<IBusinessCustomer[]>([])
  const [hourlyRates, setHourlyRates] = useState<IRateCardVehicleGroup[]>([])
  const [dailyRates, setDailyRates] = useState<IRateCardVehicleGroup[]>([])
  const [weeklyRates, setWeeklyRates] = useState<IRateCardVehicleGroup[]>([])
  const [monthlyRates, setMonthlyRates] = useState<IRateCardVehicleGroup[]>([])
  const userState = useSelector((state: IAppState) => state.userReducer);
  const prevBranchRef = useRef(userState.currentBranch);

  const [
    loadRateCard,
    { loading: rateCardLoading, data: rateCardData }
  ] = useLazyQuery(GET_RATE_CARD_BY_ID, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.getRateCardById) {
        navigate("/rate-cards");
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [loadBusinessCustomers, { loading: businessCustomersLoading, data: businessCustomersData }
  ] = useLazyQuery(
    GET_BUSINESS_CUSTOMER_PRICE, {
    fetchPolicy: "network-only"
  })

  const [deactivateRateCard, { loading: deactivateLoading, data: deactivateData }] = useMutation(DEACTIVATE_RATE_CARD, {
    fetchPolicy: "no-cache",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: (data) => {
      snackbar({
        message: `Rate Card ${data.deactivateRateCard.isActive ? "activated" : "deactivated"} successfully!`,
        variant: SnackBarVariant.SUCCESS
      })
    }
  })

  const [updateRateCard, { loading: updateRateCardLoading, data: updateRateCardData }] = useMutation(UPDATE_RATE_CARD, {
    fetchPolicy: "no-cache",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: (data) => {
      snackbar({
        message: `Rate Card updated successfully!`,
        variant: SnackBarVariant.SUCCESS
      })
    }
  })

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const rateCardId = params.get("id");
      if (rateCardId) {
        loadRateCard({
          variables: {
            id: rateCardId
          }
        })
        loadBusinessCustomers();
      }
    }
  }, [location]);

  useEffect(() => {
    if (rateCardData && rateCardData.getRateCardById) {
      setValues(rateCardData.getRateCardById);
      setSelectedBusinessCustomers(rateCardData.getRateCardById.businessCustomers)
      // reshapeRateCardsData(rateCardData.getRateCardById)
    }
  }, [rateCardData]);

  useEffect(() => {
    if (updateRateCardData && updateRateCardData.updateRateCard) {
      setValues(updateRateCardData.updateRateCard);
      setSelectedBusinessCustomers(updateRateCardData.updateRateCard.businessCustomers)
      // reshapeRateCardsData(updateRateCardData.updateRateCard)
    }
  }, [updateRateCardData]);

  useEffect(() => {
    if (deactivateData) {
      setValues(deactivateData.deactivateRateCard)
    }
  }, [deactivateData])

  useEffect(() => {
    if (businessCustomersData && businessCustomersData.businessCustomers) {
      setBusinessCustomers(businessCustomersData.businessCustomers)
    }
  }, [businessCustomersData]);

  const reshapeRateCardsData = (rateCard: IRateCard) => {
    const hourly: IRateCardVehicleGroup[] = [];
    const daily: IRateCardVehicleGroup[] = [];
    const weekly: IRateCardVehicleGroup[] = [];
    const monthly: IRateCardVehicleGroup[] = [];
    rateCard.vehicleGroups.forEach((vg) => {
      switch (vg.rateTypeName) {
        case "hourly":
          hourly.push(vg)
          break;
        case "daily":
          daily.push(vg)
          break;
        case "weekly":
          weekly.push(vg)
          break;
        case "monthly":
          monthly.push(vg)
          break;
        default:
          break;
      }
    })
    setHourlyRates(hourly)
    setDailyRates(daily)
    setWeeklyRates(weekly)
    setMonthlyRates(monthly)

  }

  const changeRateCardStatus = (checked: boolean) => {
    deactivateRateCard({
      variables: {
        id: values?.id,
        activate: checked
      }
    })
  }

  const addNewBusinessCustomer = (values: IRateCard) => {
    if (values) {
      setValues({
        ...values,
        businessCustomers: [
          ...values.businessCustomers,
          newBusinessCustomer
        ]
      })
    }
  }

  const deleteBusinessCustomer = (index: number) => {
    if (values) {
      const tempValues = {...values};
      tempValues.businessCustomers.splice(index, 1);
      setValues(tempValues);
    }
  }

  const updateRateCardSchema = Yup.object().shape({})

  if (rateCardLoading || !values) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid item container xs={12}>
        <Grid container item xs={6} alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="h1" color="primary">
              Rate Cards
            </Typography>
          </Grid>
          <Grid item> 
            <DoubleArrowIcon />
          </Grid>
          <Grid item>
            <Typography variant="h1" color="primary">
              Update Rate Card
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
      <Paper className={classes.root}>
        <Formik
          enableReinitialize
          initialValues={values}
          validationSchema={updateRateCardSchema}
          onSubmit={(values, { setSubmitting }) => {
            if (values.businessCustomers.some(rateCardbusinessCustomer => rateCardbusinessCustomer.businessCustomer.id === "")) {
              snackbar({
                message: `Please select Business customers for all fields`,
                variant: SnackBarVariant.ERROR
              });
              return;
            }
            setSubmitting(false);
            updateRateCard({
              variables: {
                id: values.id,
                businessCustomers: values.businessCustomers.map((item) => {
                  return {
                    businessCustomer: item.businessCustomer.id,
                    autoIncrementRatesBy: item.autoIncrementRatesBy || 0,
                    increaseRatesYearly: item.increaseRatesYearly || false
                  }
                })
              }
            })
          }}
        >
          {(props) => (
            <Form>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={6} md={3}>
                  <Field
                    component={TextField}
                    name="name"
                    label="Rule Name"
                    fullWidth
                    required
                    inputProps={{
                      value: values.displayName,
                    }}
                    disabled
                  ></Field>
                </Grid>
                <Grid container xs={6} md={9} justifyContent="flex-end">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.isActive ? true : false}
                        onChange={(event: any) => {
                          // deactivate/activate
                          changeRateCardStatus(event.target.checked)
                        }}
                        name="deactivate"
                        color="primary"
                        disabled={deactivateLoading}
                      />
                    }
                    labelPlacement='bottom'
                    label={<>
                      {deactivateLoading && <CircularProgress size={10} />}
                      <Typography style={{ display: "inline", marginLeft: "0.5rem" }}>{!values.isActive ? "Activate" : "Active"}</Typography>
                    </>}
                  />
                </Grid>
                <Grid item container xs={12} justifyContent={"space-between"} alignItems={"center"}>
                  <Grid item xs={6}>
                    <Typography variant="h3" color="primary">
                      Business Customers
                    </Typography>
                  </Grid>
                  <Grid item container xs={6} justifyContent={"flex-end"}>
                    <Button variant="outlined" startIcon={<AddIcon />} onClick={() => addNewBusinessCustomer(props.values)}>
                      Add Business Customer
                    </Button>
                  </Grid>
                </Grid>
                {props.values.businessCustomers.map((rateCardbusinessCustomer, index) =>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={4}>
                      <Autocomplete
                        freeSolo
                        id={`select-business-customer-${index}`}
                        disableClearable
                        options={businessCustomers}
                        getOptionLabel={(option: any) =>
                          option.businessName
                        }
                        componentName={`businessCustomers[${index}]`}
                        filterSelectedOptions={true}
                        onChange={(_: any, newValues: any, reason) => {
                          if (reason === "selectOption") {
                            props.setFieldValue(`businessCustomers[${index}].businessCustomer`, newValues);
                            props.setFieldValue(`businessCustomers[${index}].increaseRatesYearly`, false);
                            props.setFieldValue(`businessCustomers[${index}].autoIncrementRatesBy`, 0);
                          }
                          setSelectedBusinessCustomers(newValues);
                        }}
                        value={rateCardbusinessCustomer.businessCustomer}
                        renderInput={(params) => (
                          <InputField
                            {...params}
                            label="Select Business Customer"
                            variant="outlined"
                            placeholder={"Select Business Customer"}
                            InputProps={{ ...params.InputProps, type: "search" }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item container xs={7} spacing={2}>
                      <Grid item xs={5}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.values.businessCustomers[index].increaseRatesYearly || false}
                              name={`businessCustomers[${index}].increaseRatesYearly`}
                              color="primary"
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                props.setFieldValue(
                                  `businessCustomers[${index}].increaseRatesYearly`,
                                   event.target.checked
                                );
                              }}
                            />
                          }
                        label={"Enable Yearly Auto Increment"}
                        />
                      </Grid>
                    {rateCardbusinessCustomer.increaseRatesYearly && 
                      <Grid item xs={4}>
                        <Field
                          component={TextField}
                          placeholder="e.g 10"
                          label="Auto Increment Rates By (%)"
                          name={`businessCustomers[${index}].autoIncrementRatesBy`}
                          required
                          type="number"
                          InputProps={{
                            value: rateCardbusinessCustomer.autoIncrementRatesBy || 0,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (parseFloat(e.target.value) > 99) {
                                snackbar({
                                  message: `Amount can't be more than 99%`,
                                  variant: SnackBarVariant.ERROR
                                });
                              } else if (parseFloat(e.target.value) < 0) {
                                snackbar({
                                  message: `Amount can't be less than 0%`,
                                  variant: SnackBarVariant.ERROR
                                });
                              } else {
                                props.setFieldValue(
                                  `businessCustomers[${index}].autoIncrementRatesBy`,
                                  parseFloat(e.target.value)
                                );
                              }
                            },
                            min: "1"
                          }}
                          fullWidth
                        />
                      </Grid>
                    }
                    </Grid>
                    <Grid item container xs={1} justifyContent={"flex-end"}>
                      <IconButton aria-label="delete-business-customer" onClick={() => deleteBusinessCustomer(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Fab
                    variant="extended"
                    size="medium"
                    aria-label="submit"
                    type="submit"
                    disabled={updateRateCardLoading}
                  >
                    {updateRateCardLoading && (
                      <CircularProgress
                        size={14}
                        style={{ color: "white", marginRight: "10px" }}
                      />
                    )}
                    Save
                  </Fab>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        {/* <Grid container xs={12}>
          {hourlyRates.length > 0 && <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
            </Table>}
        </Grid> */}

      </Paper>
      </Grid>
    </Grid>
  )
}

export default RateCard;