import {
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  Paper,
  Theme,
  Typography,
  TextField as InputField,
  FormControlLabel,
  Switch,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Fab,
  Autocomplete,
  Button,
  IconButton
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { IBusinessCustomer } from "../../../../../reducers/customer/types";
import { GET_BUSINESS_CUSTOMER_PRICE } from "../../../../../graphql/priceRules/getBusinessCustomersQuery";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { IAddonRateCard, IRateCardAddon } from "./AddonRateCards";
import { UPDATE_ADDON_RATE_CARD } from "../../../../../graphql/addonRateCards/updateAddonRateCard";
import { GET_ADDON_RATE_CARD_BY_ID } from "../../../../../graphql/addonRateCards/getAddonRateCardQuery";
import { DEACTIVATE_ADDON_RATE_CARD } from "../../../../../graphql/addonRateCards/deactivateAddonRateCard";
import { createStyles, makeStyles } from "@mui/styles";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { TextField } from "formik-mui";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      flexGrow: 1
    },
    ruleCardMain: {
      position: "relative"
    },
    addButton: {
      position: "fixed",
      [theme.breakpoints.up('md')]: {
        right: "6rem",
        bottom: '30%',
      },
      [theme.breakpoints.down('sm')]: {
        right: "2rem",
        bottom: '10%',
      },
    },
    iconStyle: {
      padding: 2
    },
    tableContainer: {
      maxHeight: 300,
      border: "solid 1px rgba(224, 224, 224, 1)"
    },
    avatarStyle: {
      background: "var(--theme-accent)",
      height: 25,
      width: 25
    },
    ruleAvatar: {
      background: "var(--theme-accent)",
      height: 25,
      width: 25
    },
    expansionPanel: {
      marginTop: 8,
      width: `calc(100vw - 105px)`,
    },
  })
);



const AddonRateCard: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const [values, setValues] = useState<IAddonRateCard>();
  const [businessCustomers, setBusinessCustomers] = useState<IBusinessCustomer[]>([]);
  const [selectedBusinessCustomers, setSelectedBusinessCustomers] = useState<IBusinessCustomer[]>([])
  const [fixedRates, setFixedRates] = useState<IRateCardAddon[]>([])
  const [hourlyRates, setHourlyRates] = useState<IRateCardAddon[]>([])
  const [dailyRates, setDailyRates] = useState<IRateCardAddon[]>([])
  const [weeklyRates, setWeeklyRates] = useState<IRateCardAddon[]>([])
  const [monthlyRates, setMonthlyRates] = useState<IRateCardAddon[]>([])
  const userState = useSelector((state: IAppState) => state.userReducer);
  const prevBranchRef = useRef(userState.currentBranch);

  const [
    loadAddonRateCard,
    { loading: addonRateCardLoading, data: addonRateCardData }
  ] = useLazyQuery(GET_ADDON_RATE_CARD_BY_ID, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.getAddonRateCardById) {
        navigate("/addon-rate-cards");
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [loadBusinessCustomers, { loading: businessCustomersLoading, data: businessCustomersData }
  ] = useLazyQuery(
    GET_BUSINESS_CUSTOMER_PRICE, {
    fetchPolicy: "network-only"
  })

  const [deactivateAddonRateCard, { loading: deactivateLoading, data: deactivateData }] = useMutation(DEACTIVATE_ADDON_RATE_CARD, {
    fetchPolicy: "no-cache",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: (data) => {
      snackbar({
        message: `Rate Card ${data.deactivateAddonRateCard.isActive ? "activated" : "deactivated"} successfully!`,
        variant: SnackBarVariant.SUCCESS
      })
    }
  })

  const [updateAddonRateCard, { loading: updateAddonRateCardLoading, data: updateAddonRateCardData }] = useMutation(UPDATE_ADDON_RATE_CARD, {
    fetchPolicy: "no-cache",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: (data) => {
      snackbar({
        message: `Rate Card updated successfully!`,
        variant: SnackBarVariant.SUCCESS
      })
    }
  })

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const rateCardId = params.get("id");
      if (rateCardId) {
        loadAddonRateCard({
          variables: {
            id: rateCardId
          }
        })
        loadBusinessCustomers();
      }
    }
  }, [location]);

  useEffect(() => {
    if (addonRateCardData && addonRateCardData.getAddonRateCardById) {
      setValues(addonRateCardData.getAddonRateCardById);
      setSelectedBusinessCustomers(addonRateCardData.getAddonRateCardById.businessCustomers)
      // reshapeRateCardsData(rateCardData.getRateCardById)
    }
  }, [addonRateCardData]);

  useEffect(() => {
    if (updateAddonRateCardData && updateAddonRateCardData.updateAddonRateCard) {
      setValues(updateAddonRateCardData.updateAddonRateCard);
      setSelectedBusinessCustomers(updateAddonRateCardData.updateAddonRateCard.businessCustomers)
      // reshapeRateCardsData(updateRateCardData.updateRateCard)
    }
  }, [updateAddonRateCardData]);

  useEffect(() => {
    if (deactivateData) {
      setValues(deactivateData.deactivateAddonRateCard)
    }
  }, [deactivateData])

  useEffect(() => {
    if (businessCustomersData && businessCustomersData.businessCustomers) {
      setBusinessCustomers(businessCustomersData.businessCustomers)
    }
  }, [businessCustomersData]);

  const reshapeRateCardsData = (rateCard: IAddonRateCard) => {
    const fixed: IRateCardAddon[] = [];
    const hourly: IRateCardAddon[] = [];
    const daily: IRateCardAddon[] = [];
    const weekly: IRateCardAddon[] = [];
    const monthly: IRateCardAddon[] = [];
    rateCard.addons.forEach((addon) => {
      switch (addon.rateTypeName) {
        case "hourly":
          hourly.push(addon)
          break;
        case "daily":
          daily.push(addon)
          break;
        case "weekly":
          weekly.push(addon)
          break;
        case "monthly":
          monthly.push(addon)
          break;
        case "fixed":
          fixed.push(addon)
        default:
          break;
      }
    })
    setFixedRates(fixed)
    setHourlyRates(hourly)
    setDailyRates(daily)
    setWeeklyRates(weekly)
    setMonthlyRates(monthly)

  }

  const changeRateCardStatus = (checked: boolean) => {
    deactivateAddonRateCard({
      variables: {
        id: values?.id,
        activate: checked
      }
    })
  }

  const updateRateCardSchema = Yup.object().shape({})

  if (addonRateCardLoading || !values) {
    return <CircularProgress />
  }

  return (
    <Grid item container xs={12} spacing={1}>
      <CssBaseline />
      <Grid item container xs={12}>
        <Grid container item xs={6} alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="h1" color="primary">
              Rate Cards
            </Typography>
          </Grid>
          <Grid item> 
            <DoubleArrowIcon />
          </Grid>
          <Grid item>
            <Typography variant="h1" color="primary">
              Update Rate Card
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
      <Paper className={classes.root}>
        <Formik
          enableReinitialize
          initialValues={values}
          validationSchema={updateRateCardSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            updateAddonRateCard({
              variables: {
                id: values.id,
                businessCustomers: values.businessCustomers.map((item: any) => {
                  if (item && typeof item !== "string") {
                    return item.id
                  }
                  return item
                })
              }
            })
          }}
        >
          {(props) => (
            <Form>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={6} md={3}>
                  <Field
                    component={TextField}
                    name="name"
                    label="Rule Name"
                    fullWidth
                    required
                    inputProps={{
                      value: values.displayName,
                    }}
                    disabled
                  ></Field>
                </Grid>
                <Grid container xs={6} md={9} justifyContent="flex-end">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.isActive ? true : false}
                        onChange={(event: any) => {
                          // deactivate/activate
                          changeRateCardStatus(event.target.checked)
                        }}
                        name="deactivate"
                        color="primary"
                        disabled={deactivateLoading}
                      />
                    }
                    labelPlacement='bottom'
                    label={<>
                      {deactivateLoading && <CircularProgress size={10} />}
                      <Typography style={{ display: "inline", marginLeft: "0.5rem" }}>{!values.isActive ? "Activate" : "Active"}</Typography>
                    </>}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    freeSolo
                    id="free-solo-customer-search"
                    disableClearable
                    options={businessCustomers}
                    getOptionLabel={(option: any) =>
                      option.businessName
                    }
                    filterSelectedOptions={true}
                    onChange={(_: any, newValues: any, reason) => {
                      if (reason === "selectOption") {
                        setValues({
                          ...props.values,
                          businessCustomers: [
                            ...props.values.businessCustomers,
                            newValues[newValues.length - 1].id
                          ]
                        })
                      }
                      if (reason === "removeOption") {
                        const updatedCustomers = newValues.map((value: IBusinessCustomer) => value.id)
                        setValues({
                          ...props.values,
                          businessCustomers: updatedCustomers
                        })
                      }
                      setSelectedBusinessCustomers(newValues)
                    }}
                    value={selectedBusinessCustomers}
                    defaultValue={[]}
                    renderInput={(params) => (
                      <InputField
                        {...params}
                        label="Select Business Customer(s)"
                        variant="outlined"
                        placeholder={"Select Business Customer(s)"}
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Fab
                    variant="extended"
                    size="medium"
                    aria-label="submit"
                    type="submit"
                    disabled={updateAddonRateCardLoading}
                  >
                    {updateAddonRateCardLoading && (
                      <CircularProgress
                        size={14}
                        style={{ color: "white", marginRight: "10px" }}
                      />
                    )}
                    Save
                  </Fab>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        {/* <Grid container xs={12}>
          {hourlyRates.length > 0 && <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
            </Table>}
        </Grid> */}

      </Paper>
      </Grid>
    </Grid>
  )
}

export default AddonRateCard;