import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CssBaseline,
  Fab,
  Grid,
  Paper,
  Theme,
  Typography,
  CircularProgress,
  Tooltip,
  FormControlLabel,
  Switch
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import _ from "lodash";
import React, { ChangeEvent, useEffect, useState, FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { IAppState } from "../../../../../store";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { GET_USER_PERMISSION_DOCUMENT } from "../../../../../graphql/userPermissions/getUserPermissionDocumentQuery";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CREATE_USER_ACCESS_POLICY, UPDATE_USER_ACCESS_POLICY } from "../../../../../graphql/userPermissions/userAccessMutations";
import { CHECK_TENANCY_SUBSCRIPTION_FOR_VEHICLESUBSCRIPTION, GET_USER_ACCESS_POLICY } from "../../../../../graphql/userPermissions/userAccessQuery";
import { UserAccessType } from "./Policies";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      width: "100%"
    }
  })
);

export interface IUserAccessPolicy {
  id?: string;
  name: string;
  policyType?: UserAccessType,
  isActive: boolean;
  permissions: string[];
  selectedKeys: string[];
}

const bfsSearch = (graph: any, targetId: string) => {
  const queue = [...graph];

  while (queue.length > 0) {
    const currNode = queue.shift();
    if (currNode.key === targetId) {
      return currNode;
    }
    if (currNode.actions) {
      queue.push(...currNode.actions);
    }
  }
  return []; // Target node not found
};

export interface IPermissionListData {
  key: string;
  label: string;
  actions: IPermissionAction[]
}

export interface IPermissionAction {
  key: string;
  label: string;
  permissions: string[];
  extends: string[];
  actions: IPermissionAction[]
}

const vehicleSubscriptionKeys = ["subscriptions", "listSubscriptionPlans", "viewSubscriptionPlans", "createSubscriptionPlan", "updateSubscriptionPlan"]

export const NewPolicy = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate()
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const [userPermissionDocument, setUserPermissionDocument] = useState<any>();
  const [selectedNodes, setSelectedNodes] = useState<any[]>([]);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [values, setValues] = useState<IUserAccessPolicy>({
    name: "",
    isActive: true,
    permissions: [],
    selectedKeys: []
  });

  const [getUserPermissionDocument, { loading, data }] = useLazyQuery(GET_USER_PERMISSION_DOCUMENT, {
    fetchPolicy: "network-only",
    onCompleted: () => {

    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [getUserAccessPolicy, { loading: loadingUserPolicy, data: userAccessPolicyData }] =
    useLazyQuery(GET_USER_ACCESS_POLICY, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (!data.getUserAccessPolicy) {
          navigate("/policies");
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    });

  const [checkTenancyVehicleSubscription, { }] =
    useLazyQuery(CHECK_TENANCY_SUBSCRIPTION_FOR_VEHICLESUBSCRIPTION, {
      fetchPolicy: "network-only",
      onCompleted: ({ checkTenancySubscribedForVehicleSubscription }) => {
        if (!checkTenancySubscribedForVehicleSubscription.success) {
          setSelectedNodes((prevSelectedNode) => prevSelectedNode.filter(node => !vehicleSubscriptionKeys.includes(node)));
          return snackbar({
            message: checkTenancySubscribedForVehicleSubscription.message,
            variant: SnackBarVariant.ERROR
          });
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    });

  const [createUserAccessPolicyMutation] = useMutation(CREATE_USER_ACCESS_POLICY, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data && data.createUserAccessPolicy) {
        snackbar({
          message: "User policy has been created",
          variant: SnackBarVariant.SUCCESS
        });
      }
      navigate("/policies");
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [updateUserAccessPolicyMutation] = useMutation(UPDATE_USER_ACCESS_POLICY, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data && data.updateUserAccessPolicy) {
        snackbar({
          message: "User policy has been updated",
          variant: SnackBarVariant.SUCCESS
        });
      }
      navigate("/policies");
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    getUserPermissionDocument();
  }, [userReducer.tenancy])

  useEffect(() => {
    if (data && data.getUserPermissionDocument) {
      setUserPermissionDocument(data.getUserPermissionDocument)
    }
  }, [data]);

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const policyId = params.get("policy");
      if (policyId) {
        getUserAccessPolicy({
          variables: {
            userAccessPolicyId: policyId,
            policyType: state.policyType
          }
        })
      }
    }
  }, [location]);

  useEffect(() => {
    if (userAccessPolicyData && userAccessPolicyData.getUserAccessPolicy) {
      setValues({ ...values, ...userAccessPolicyData.getUserAccessPolicy });
      setPermissions(userAccessPolicyData.getUserAccessPolicy.permissions);
      setSelectedNodes(userAccessPolicyData.getUserAccessPolicy.selectedKeys);
    }
  }, [userAccessPolicyData]);

  useEffect(() => {
  }, [selectedNodes]);

  // Retrieve all ids from node to his actions's
  function getAllIds(node: any, idList: string[] = []) {
    idList.push(node.key);
    if (node.actions) {
      node.actions.forEach((child: any) => getAllIds(child, idList));
    }
    return idList;
  }
  // Get IDs of all actions from specific node
  const getAllChild = (key: string) => {
    return getAllIds(bfsSearch(userPermissionDocument.permissionList, key));
  };

  // Get all father IDs from specific node
  const getAllFathers: any = (key: string, list: any[] = []) => {
    const node: any = bfsSearch(userPermissionDocument.permissionList, key);
    if (node.parent) {
      list.push(node.parent);

      return getAllFathers(node.parent, list);
    }

    return list;
  };

  function isAllChildrenChecked(node: any, list: any) {
    const allChild = getAllChild(node.key);
    const nodeIdIndex = allChild.indexOf(node.key);
    allChild.splice(nodeIdIndex, 1);

    return allChild.every((nodeId: any) =>
      selectedNodes.concat(list).includes(nodeId)
    );
  }

  const handleNodeSelect = (event: any, nodeKey: any) => {
    event.stopPropagation();
    const allChild = getAllChild(nodeKey);
    const fathers = getAllFathers(nodeKey);
    if (vehicleSubscriptionKeys.includes(nodeKey)) {
      checkTenancyVehicleSubscription();
    }
    if (selectedNodes.includes(nodeKey)) {
      for (let index = 0; index < userPermissionDocument.permissionList.length; index++) {
        const parentNode = userPermissionDocument.permissionList[index];
        if (parentNode.key === nodeKey) {
          for (let index = 0; index < parentNode.actions.length; index++) {
            const childNode = parentNode.actions[index];
            setPermissions((prevPermissions) =>
              prevPermissions.filter((permission) => !childNode.permissions.includes(permission))
            );
            setSelectedNodes((prevSelectedNodes) =>
              prevSelectedNodes.filter((node) => !childNode.extends.includes(node))
            );
            if (childNode.actions) {
              for (let index = 0; index < childNode.actions.length; index++) {
                const subChildNode = childNode.actions[index];
                setPermissions((prevPermissions) =>
                  prevPermissions.filter((permission) => !subChildNode.permissions.includes(permission))
                )
                setSelectedNodes((prevSelectedNodes) =>
                  prevSelectedNodes.filter((node) => !subChildNode.extends.includes(node))
                );
              }
            }
          }
        } else {
          for (let index = 0; index < parentNode.actions.length; index++) {
            const childNode = parentNode.actions[index];
            if (childNode.key === nodeKey) {
              setPermissions((prevPermissions) =>
                prevPermissions.filter((permission) => !childNode.permissions.includes(permission))
              )
              setSelectedNodes((prevSelectedNodes) =>
                prevSelectedNodes.filter((node) => !childNode.extends.includes(node))
              );
              if (childNode.actions) {
                for (let index = 0; index < childNode.actions.length; index++) {
                  const subChildNode = childNode.actions[index];
                  setPermissions((prevPermissions) =>
                    prevPermissions.filter((permission) => !subChildNode.permissions.includes(permission))
                  )
                  setSelectedNodes((prevSelectedNodes) =>
                    prevSelectedNodes.filter((node) => !subChildNode.extends.includes(node))
                  );
                }
              }
            } else {
              if (childNode.actions) {
                for (let index = 0; index < childNode.actions.length; index++) {
                  const subChildNode = childNode.actions[index];
                  if (subChildNode.key === nodeKey) {
                    setPermissions((prevPermissions) =>
                      prevPermissions.filter((permission) => !subChildNode.permissions.includes(permission))
                    )
                    setSelectedNodes((prevSelectedNodes) =>
                      prevSelectedNodes.filter((node) => !subChildNode.extends.includes(node))
                    );
                  }
                }
              }
            }
          }
        }
      }
      // Need to de-check
      setSelectedNodes((prevSelectedNodes) =>
        prevSelectedNodes.filter((key) => !allChild.concat(fathers).includes(key))
      );
    } else {
      // Need to check
      for (let index = 0; index < userPermissionDocument.permissionList.length; index++) {
        const parentNode = userPermissionDocument.permissionList[index];
        if (parentNode.key === nodeKey) {
          for (let index = 0; index < parentNode.actions.length; index++) {
            const childNode = parentNode.actions[index];
            if (childNode) {
              setPermissions((prevPermission) =>
                [...prevPermission].concat(childNode.permissions.filter((item: string) => prevPermission.indexOf(item) < 0))
              );
              setSelectedNodes((prevSelectedNodes) =>
                [...prevSelectedNodes].concat(childNode.extends.filter((item: string) => prevSelectedNodes.indexOf(item) < 0))
              );
            }
            if (childNode.actions) {
              for (let index = 0; index < childNode.actions.length; index++) {
                const subChildNode = childNode.actions[index];
                setPermissions((prevPermission) =>
                  [...prevPermission].concat(subChildNode.permissions.filter((item: string) => prevPermission.indexOf(item) < 0))
                );
                setSelectedNodes((prevSelectedNodes) =>
                  [...prevSelectedNodes].concat(subChildNode.extends.filter((item: string) => prevSelectedNodes.indexOf(item) < 0))
                );
              }
            }
          }
        } else {
          for (let index = 0; index < parentNode.actions.length; index++) {
            const childNode = parentNode.actions[index];
            if (childNode.key === nodeKey) {
              setPermissions((prevPermission) =>
                [...prevPermission].concat(childNode.permissions.filter((item: string) => prevPermission.indexOf(item) < 0))
              );
              setSelectedNodes((prevSelectedNodes) =>
                [...prevSelectedNodes].concat(childNode.extends.filter((item: string) => prevSelectedNodes.indexOf(item) < 0))
              );
              if (childNode.actions) {
                for (let index = 0; index < childNode.actions.length; index++) {
                  const subChildNode = childNode.actions[index];
                  setPermissions((prevPermission) =>
                    [...prevPermission].concat(subChildNode.permissions.filter((item: string) => prevPermission.indexOf(item) < 0))
                  );
                  setSelectedNodes((prevSelectedNodes) =>
                    [...prevSelectedNodes].concat(subChildNode.extends.filter((item: string) => prevSelectedNodes.indexOf(item) < 0))
                  );
                }
              }
            } else {
              if (childNode.actions) {
                for (let index = 0; index < childNode.actions.length; index++) {
                  const subChildNode = childNode.actions[index];
                  if (subChildNode.key === nodeKey) {
                    setPermissions((prevPermission) =>
                      [...prevPermission].concat(subChildNode.permissions.filter((item: string) => prevPermission.indexOf(item) < 0))
                    );
                    setSelectedNodes((prevSelectedNodes) =>
                      [...prevSelectedNodes].concat(subChildNode.extends.filter((item: string) => prevSelectedNodes.indexOf(item) < 0))
                    );
                  }
                }
              }
            }
          }
        }
      }
      const ToBeChecked = allChild;
      for (let i = 0; i < fathers.length; ++i) {
        if (isAllChildrenChecked(bfsSearch(userPermissionDocument.permissionList, fathers[i]), ToBeChecked)) {
          ToBeChecked.push(fathers[i]);
        }
      }
      setSelectedNodes((prevSelectedNodes) =>
        [...prevSelectedNodes].concat(ToBeChecked)
      );
    }
  };

  const handleExpandClick = (event: any) => {
    // prevent the click event from propagating to the checkbox
    event.stopPropagation();
  };

  const renderTree = (nodes: any) => {
    return (
      <TreeItem
        key={nodes.key}
        nodeId={nodes.key}
        onClick={handleExpandClick}
        label={
          <>
            <Checkbox
              checked={selectedNodes.indexOf(nodes.key) !== -1}
              tabIndex={-1}
              disableRipple
              disabled={values.policyType === UserAccessType.SYSTEM_DEFINED}
              onClick={(event) => handleNodeSelect(event, nodes.key)}
            />
            {nodes.label}
          </>
        }
      >
        {Array.isArray(nodes.actions)
          ? nodes.actions.map((node: any) => renderTree(node))
          : null}
      </TreeItem>
    )
  };

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12} alignItems="center">
        {values && values.id ? (
          <Typography variant="h1" color="primary">
            Update
          </Typography>
        ) : (
          <Typography variant="h1" color="primary">
            Create
          </Typography>
        )}
        <Typography variant="h1" color="primary">
          &nbsp;Policy
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Paper className={classes.root}>
          {loadingUserPolicy && loading ? <CircularProgress /> : (
            <Formik
              enableReinitialize
              initialValues={values}
              onSubmit={(values) => {
                values.permissions = [...permissions];
                values.selectedKeys = _.uniq([...selectedNodes]);
                const { id, policyType, ...rest } = values;
                if (values.id) {
                  updateUserAccessPolicyMutation({
                    variables: {
                      userAccessPolicyId: values.id,
                      userAccessPolicy: { ...rest }
                    }
                  })
                } else {
                  createUserAccessPolicyMutation({
                    variables: {
                      userAccessPolicy: {
                        ...rest
                      }
                    }
                  })
                }
              }}
            >
              {(props) => (
                <Form noValidate>
                  <Grid container spacing={2}>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Field
                          component={TextField}
                          name="name"
                          placeholder="Policy Name"
                          label="Policy Name"
                          value={props.values.name}
                          disabled={values.policyType === UserAccessType.SYSTEM_DEFINED}
                          fullWidth
                          required
                        />
                      </Grid>
                      {values.id && (
                        <Grid container item xs={12} sm={12} md={8} lg={8} style={{ paddingLeft: 100 }} justifyContent="flex-end">
                          <Tooltip title={props.values.isActive ? "Deactivate" : "Activate"}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={props.values.isActive}
                                  onChange={(event: any) => {
                                    setValues({
                                      ...props.values,
                                      isActive: event.target.checked
                                    })
                                  }
                                  }
                                  name="isActive"
                                  color="primary"
                                  disabled={values.policyType === UserAccessType.SYSTEM_DEFINED}
                                />
                              }
                              label={"Active"}
                              disabled={values.policyType === UserAccessType.SYSTEM_DEFINED}
                            />
                          </Tooltip>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="subtitle1">
                          Choose Permissions
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        {userPermissionDocument && userPermissionDocument.permissionList ? (
                          <TreeView
                            multiSelect
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            selected={selectedNodes}
                          >
                            {_.sortBy(userPermissionDocument.permissionList, permission =>
                              permission.key.toLowerCase()).map((node: any) => renderTree(node))}
                          </TreeView>
                        ) : <CircularProgress />}
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} justifyContent="flex-start">
                      <Fab
                        variant="extended"
                        size="medium"
                        type="submit"
                        aria-label="add"
                        disabled={values.policyType === UserAccessType.SYSTEM_DEFINED || !props.values.name || !selectedNodes.length}
                      >
                        SAVE
                      </Fab>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}