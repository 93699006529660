import { gql } from "@apollo/client";

export const UPDATE_VEHICLE_ON_HOLD_MUTATION = gql`
  mutation updateVehicleHold($vehicleId: ID!, $holdId: ID!, $holdInfo: HoldInfoInput!) {
    updateVehicleHold(vehicleId: $vehicleId, holdId: $holdId, holdInfo: $holdInfo) {
      id
      startDate
      endDate
      notes {
        description
        dateCreated
        createdBy {
          firstName
          lastName
        }
      }
      dateCreated
      createdBy {
        firstName
        lastName
      }
      eventsColor {
        reason
        color
      }
    }
  }
`;