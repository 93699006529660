import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { DATE_TYPE, formatGraphQLErrorMessage } from "../../common/utils";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { CircularProgress, Paper } from "@mui/material";
import { MUIDataTableOptions } from "mui-datatables";
import { SelectableRows } from "../../common/NuvvenTable/types";
import { NuvvenTable } from "../../common/NuvvenTable/NuvvenTable";
import { getLocalizedDateFormat } from "../../../utils/localized.syntex";
import { useLocation } from "react-router-dom";
import { GET_ALL_JOBS_FOR_PARTNER } from "../../../graphql/jobSchedular/getJobsQuery";
import { addTableState } from "../../../actions/tableState/actions";
import { TableNames } from "../../../reducers/tableState/types";

export const PartnerJobHistory = () => {
    const snackbar = useSnackBar();
    const userState = useSelector((state: IAppState) => state.userReducer);
    const { country } = userState.currentOrganisation.address;
    const [rows, setRows] = useState<any[]>([]);
    const location = useLocation();
    const [limit, setLimit] = useState(10);
    const partnerJobTableState = useSelector(
      (state: IAppState) => state.tableStateReducer.partnerjob
    );
    const dispatch = useDispatch();
    const [partnerJob, setPartnerJob] = useState<string>("");

    const [
        getPartnerDriversJob,
        { loading: partnerDriversJobLoading, data: partnerDriversJobData }
      ] = useLazyQuery(GET_ALL_JOBS_FOR_PARTNER, {
        fetchPolicy: "network-only",
        
        onCompleted: (partnerDriversJobData) => {
          const partnerdriverdata = reshapeDriverData(partnerDriversJobData.getAllJobsForPartner)          
          if(partnerdriverdata) {
          setRows(partnerdriverdata);
          }
        },
        onError: (error: ApolloError) => {
          snackbar({
            message: formatGraphQLErrorMessage(error.message),
            variant: SnackBarVariant.ERROR
          });
        }
      });

      useEffect(() => {
        if (location && location.search) {
          const params = new URLSearchParams(location.search);
          const partnerId = params.get("partner");
          if (partnerId) {
            setPartnerJob(partnerId);
            getPartnerDriversJob({
              variables: {
                partnerId: partnerId,
                limit: partnerJobTableState?.rowsPerPage ? partnerJobTableState.rowsPerPage : limit,
                offset: 0,
              }
            });
          }
        }
      }, [location]);

      const reshapeDriverData = (data: any[]) => {
        const filteredData = data.filter(driver => driver?.booking);
        return filteredData.map((driver: any) => {
          const booking = driver.booking;
          return {
            referenceNumber: booking?.referenceNumber ?? "N/A",
            pickupDateTime: getLocalizedDateFormat(country, booking?.pickupDateTime, DATE_TYPE.EXPANDED) ?? "N/A",
            assignedTo: `${driver?.assignedTo?.firstName || "N/A"} ${driver?.assignedTo?.lastName || ""}`.trim(),
            bookingType: booking?.bookingType ?? "N/A",
            customerName: booking?.businessCustomer
              ? booking.businessCustomer?.businessName || ""
              : `${booking?.customer?.firstName || "N/A"} ${booking?.customer?.lastName || ""}`.trim(),
            status: booking?.status ?? "N/A",
          };
        });
      };
      
      
    
  const columns = [
    {
      label: "ID",
      name: "referenceNumber"
    },
    {
      label: "Booking Date",
      name: "pickupDateTime"
    },
    {
      label: "Driver Name",
      name: "assignedTo"
    },
    {
      label: "Booking Type",
      name: "bookingType"
    },
    {
      label: "Customer Name",
      name: "customerName"
    },
    {
      label: "Status",
      name: "status"
    },
    // {
    //     label: "Customer rating",
    //     name: "rating",
    //   },
  ]

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    rowsPerPageOptions: [10, 20, 100],
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: partnerDriversJobLoading ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)
      dispatch(
        addTableState({
          tableName: TableNames.PARTNER_JOB,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
    partnerJobTableState && partnerJobTableState.rowsPerPage
        ? partnerJobTableState.rowsPerPage
        : limit,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.PARTNER_JOB,
          columnName: changedColumn,
          direction
        })
      );
    },
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          if (tableState.searchText) {
            return;
          }
          const { page, rowsPerPage } = tableState;
          if (page * rowsPerPage >= rows.length) {
            getPartnerDriversJob({
              variables: {
                partnerId: partnerJob,
                offset: page * rowsPerPage,
                limit: rowsPerPage
              }
            });
          }
          break;
        case "changeRowsPerPage":
          setRows([])
          getPartnerDriversJob({
            variables: {
              partnerId: partnerJob,
              offset: 0,
              limit: tableState.rowsPerPage || limit
            }
          });
          break;
        default:
          break;
      }
    },
  
  };

  return (
    <div>
      {!(partnerDriversJobLoading && !rows.length) ? (
        <Paper variant="outlined">
          <NuvvenTable
            title={""}
            rows={rows}
            columns={columns}
            setSelection={() => { }}
            options={options}
          />
        </Paper>
      ) : (
        <CircularProgress />
      )}
    </div>
  )

};