export enum SelectionRuleCategory {
	'3PL_SELECTION_RULE' = '3PL Selection Rule',
	DRIVER_SELECTION_RULE = 'Driver Selection Rule'
}

export interface IGetSingleEntityParams {
	id?: string;
	tenantId?: string;
	organisationId?: string;
	branchId?: string;
	createdAt?: string;
	updatedAt?: string;
}

export interface IRuleItem {
	ruleName: string;
	sequence: number;
}

export interface IAllocationRule extends IGetSingleEntityParams {
	ruleCategory: string;
	rules: IRuleItem[];
	priority: number;
}

export const allocationRules: IAllocationRule[] = [
	{
		ruleCategory: "3PL_SELECTION_RULE",
		rules: [],
		priority: 1,
	},
	{
		ruleCategory: "DRIVER_SELECTION_RULE",
		rules: [],
		priority: 2,
	}
];

// Rule Items
export const ruleItems = [
	{ value: "typeOfBooking", label: "Type of booking" },
	{ value: "distanceFromLocation", label: "Distance from location" },
	{ value: "supplierRating", label: "Supplier rating" },
	{ value: "skills", label: "Skills" },
	{ value: "csiRating", label: "CSI rating" },
];

export function updateAllocationRules(response: IAllocationRule[]): IAllocationRule[] {
	if (!response?.length) return allocationRules;
	const updatedRules = [...allocationRules];
	for (let i = 0; i < response.length; i++) {
		const res = response[i];
		const { createdAt, updatedAt, ...rest } = res;
		const index = updatedRules.findIndex((rule) => rule.ruleCategory === res.ruleCategory);
		if (index !== -1) {
			updatedRules[index] = {
				...rest,
				rules: res.rules,
			};
		}
	}
	return updatedRules;
}