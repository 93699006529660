import { useLazyQuery, useMutation } from "@apollo/client";
import { ApolloError } from "@apollo/client";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Field, Form, Formik } from "formik";
import { Select, TextField } from "formik-mui";
import { cloneDeep, reverse } from "lodash";
import { DateTime as d } from "luxon";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { GET_BOOKING } from "../../../graphql/bookings/getBookingQuery";
import { GET_VEHICLE_SERVICE } from "../../../graphql/fleet/vehicleServices/getVehicleService";
import {
  GET_ACTION_BY_ID,
  GET_AVAILABLE_PARTNERS_FOR_JOB,
  GET_JOB,
  GET_PARTNER_USER_IN_PARTNERSHIP,
  GET_USERS_IN_BRANCH,
  GET_VEHICLE_FOR_REPLACEMENT
} from "../../../graphql/jobSchedular/getJobsQuery";
import {
  ADD_USER_JOB_NOTE,
  CANCEL_JOB,
  CHANGE_JOB_STATUS,
  CREATE_JOB,
  UPDATE_JOB
} from "../../../graphql/jobSchedular/jobMutation";
import { IBooking } from "../../../reducers/bookings/types";
import { IVehicleService } from "../../../reducers/fleet/types";
import { jobInitialState } from "../../../reducers/jobSchedular/const";
import { IDateTime, IUserJobWrite } from "../../../reducers/jobSchedular/types";
import { IUser } from "../../../reducers/user/types";
import { IAppState } from "../../../store";
import {
  getLocalizedBookingSyntex,
  getLocalizedDateFormat
} from "../../../utils/localized.syntex";
import { captureErrorException } from "../../../utils/sentry";
import { SimpleDateTimePicker } from "../../common/SimpleDateTimePicker";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { DATE_TYPE, formatGraphQLErrorMessage } from "../../common/utils";
import { IPendingAction } from "../DashboardNew/UpcomingEvents";
import { UserJobTypes, userJobStatus } from "./JobUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    form: {
      flexGrow: 1
    },
    createButton: {
      background: "#000000",
      color: "#fff",
      fontSize: "12px"
    },
    title: {
      marginBottom: theme.spacing(2)
    }
  })
);

export const viewColumns = [
  {
    label: "Ownership Number",
    name: "ownershipNumber"
  },
  {
    label: "Name",
    name: "fullName"
  },
  {
    label: "Phone Number",
    name: "phoneNumber"
  },
  {
    label: "Email",
    name: "email"
  }
];

export enum JobStatus {
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
  IN_PROGRESS = "IN_PROGRESS",
  CANCELLED = "CANCELLED"
}

interface IVehicle {
  make?: string;
  model?: string;
  colour?: string;
  transmission?: string;
  vrn?: string;
  licencePlate?: string;
}

export const UserJob: React.FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [values, setValues] = useState<IUserJobWrite>(jobInitialState);
  const [users, setUsers] = useState<IUser[]>([]);
  const [pendingAction, setPendingAction] = useState<IPendingAction>();
  const [initialNoteValue, setInitialNoteValue] = useState({ note: "" });
  const [notes, setNotes] = useState([]);
  const { country } = userState.currentOrganisation.address;
  const organisation = userState.currentOrganisation;
  const [booking, setBooking] = useState<IBooking>();
  const [service, setService] = useState<IVehicleService>();
  const [bookingRef, setBookingRef] = useState("");
  const [vehicleRef, setVehicleRef] = useState("");
  const [statusDisabled, setStatusDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDriver, setOpenDriver] = useState(false);
  const [makes, setMakes] = useState<string[]>([]);
  const [models, setModels] = useState<string[]>([]);
  const [colours, setColours] = useState<string[]>([]);
  const [vehicle, setVehicle] = useState<IVehicle>({
    make: "",
    model: "",
    colour: "",
    transmission: "",
    licencePlate: ""
  });
  const [transmissions, setTransmissions] = useState<string[]>([]);
  const [vrns, setVrns] = useState<string[]>([]);
  const [partnerState, setPartnerState] = useState<any[]>([]);
  const [vehicleData, setVehicleData] = useState<any[]>([]);
  const [vehicles, setVehicles] = useState<any[]>([]); // State to store saved vehicle details
  const [formatedDrivers, setFormatedDrivers] = useState<any>();
  const [isPendingAction, setIsPendingAction] = useState<boolean>(false);
  const isSupplierPortalEnabled = organisation?.supplierPortalEnabled;

  const [
    getUsersInBranch,
    { loading: branchUsersLoading, data: branchUsersData }
  ] = useLazyQuery(GET_USERS_IN_BRANCH, {
    fetchPolicy: "network-only"
  });

  const [fetchJob, { loading: jobDataLoading, data: jobData }] = useLazyQuery(
    GET_JOB,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (!data.userJobById) {
          navigate("/user-jobs");
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        navigate("/user-jobs");
      }
    }
  );

  const [
    fetchPendingActionById,
    { loading: pendingActionLoading, data: pendingActionData }
  ] = useLazyQuery(GET_ACTION_BY_ID, {
    fetchPolicy: "network-only",
    onCompleted: (pendingActionData) => {
      if (
        pendingActionData?.getActionById.vehicle &&
        pendingActionData?.getActionById?.vehicle.make
      ) {
        getVehicles({
          variables: {
            make: pendingActionData?.getActionById?.vehicle.make
          }
        });
      } else {
        getVehicles();
      }
      if (pendingActionData?.getActionById.partner) {
        setFormatedDrivers(pendingActionData?.getActionById.assignedTo);
      }
      setVehicle({
        make: pendingActionData?.getActionById?.vehicle.make,
        model: pendingActionData?.getActionById?.vehicle.model,
        colour: pendingActionData?.getActionById?.vehicle.colour,
        transmission: pendingActionData?.getActionById?.vehicle.transmission,
        licencePlate: pendingActionData?.getActionById?.vehicle.licencePlate
      });
      setVehicles([
        {
          make: pendingActionData?.getActionById?.vehicle.make,
          model: pendingActionData?.getActionById?.vehicle.model,
          colour: pendingActionData?.getActionById?.vehicle.colour,
          transmission: pendingActionData?.getActionById?.vehicle.transmission,
          licencePlate: pendingActionData?.getActionById?.vehicle.licencePlate
        }
      ]);
    }
  });

  const [getVehicles, { loading, data }] = useLazyQuery(
    GET_VEHICLE_FOR_REPLACEMENT,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [
    getPartnerUsers,
    { loading: partnerUsers, data: partnerUsersData }
  ] = useLazyQuery(GET_PARTNER_USER_IN_PARTNERSHIP, {
    fetchPolicy: "network-only",
    onCompleted: (partnerUsersData) => {
      setPartnerState(partnerUsersData.getPartnerUsersInPartnership?.filter((user: any)=> user.partnerProfile?.branchId === userState.currentBranch.id));
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [
    getavailablePartners,
    { loading: availablePartners, data: availablePartnersData }
  ] = useLazyQuery(GET_AVAILABLE_PARTNERS_FOR_JOB, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [createJob, { loading: saveJobLoading }] = useMutation(CREATE_JOB, {
    onCompleted: () => {
      snackbar({
        message: "Job Created successfully",
        variant: SnackBarVariant.SUCCESS
      });
      navigate(`/user-jobs`);
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [updateJob] = useMutation(UPDATE_JOB, {
    onCompleted: (data) => {
      if (data) {
        snackbar({
          message: "Job Updated successfully",
          variant: SnackBarVariant.SUCCESS
        });
        navigate(`/user-jobs`);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [changeUserJobStatus] = useMutation(CHANGE_JOB_STATUS, {
    onCompleted: (data) => {
      if (data) {
        snackbar({
          message: "Job Updated successfully",
          variant: SnackBarVariant.SUCCESS
        });
        navigate(`/user-jobs`);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [addNote, { data: jobNote }] = useMutation(ADD_USER_JOB_NOTE, {
    onCompleted: (data: any) => {
      if (data) {
        snackbar({
          message: "Note added successfully",
          variant: SnackBarVariant.SUCCESS
        });
      }
    }
  });

  const [
    fetchBooking,
    { data: bookingData, loading: bookingLoading }
  ] = useLazyQuery(GET_BOOKING, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [
    loadVehicleService,
    { loading: serviceLoading, data: vehicleServiceData }
  ] = useLazyQuery(GET_VEHICLE_SERVICE, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [cancelUserJob] = useMutation(CANCEL_JOB, {
    onCompleted: (data) => {
      if (data) {
        snackbar({
          message: "Job cancelled successfully",
          variant: SnackBarVariant.SUCCESS
        });
        navigate(`/user-jobs`);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (userState) {
      getUsersInBranch();
      getavailablePartners({
        variables: {
          partnerType: "THIRD_PARTY_DRIVER_AGENCY"
        }
      });
    }
  }, [userState]);

  useEffect(() => {
    if (data?.getVehiclesForReplacement) {
      let vehicleData = data.getVehiclesForReplacement;
      if (vehicle.make) {
        vehicleData = vehicleData.filter((v: any) => v.make === vehicle.make);
      }
      const uniqueMakes = Array.from(
        new Set(vehicleData.map((vehicle: { make: string }) => vehicle.make))
      ) as string[];
      const uniqueModels = Array.from(
        new Set(vehicleData.map((vehicle: { model: string }) => vehicle.model))
      ) as string[];
      const uniqueColors = Array.from(
        new Set(
          vehicleData.map((vehicle: { colour: string }) => vehicle.colour)
        )
      ) as string[];
      const uniqueTransmissions = Array.from(
        new Set(
          vehicleData.map(
            (vehicle: { transmission: string }) => vehicle.transmission
          )
        )
      ) as string[];
      const uniqueVrns = Array.from(
        new Set(
          vehicleData.map(
            (vehicle: { licencePlate: string }) => vehicle.licencePlate
          )
        )
      ) as string[];
      setMakes(uniqueMakes);
      setModels(uniqueModels);
      setColours(uniqueColors);
      setTransmissions(uniqueTransmissions);
      setVrns(uniqueVrns);
      setVehicleData(vehicleData);
    }
  }, [data]);

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const pendingActionId = params.get("pendingAction");
      const jobId = params.get("jobId");
      const bookingId = params.get("booking");
      const vehicleServiceId = params.get("vehicleservice");
      if (pendingActionId) {
        setIsPendingAction(true);
        fetchPendingActionById({
          variables: {
            id: pendingActionId
          }
        });
      }
      if (jobId) {
        fetchJob({
          variables: {
            id: jobId
          }
        });
      }
      if (bookingId) {
        fetchBooking({
          variables: {
            id: bookingId
          }
        });
      }
      if (vehicleServiceId) {
        loadVehicleService({
          variables: {
            id: vehicleServiceId
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (pendingActionData && pendingActionData.getActionById) {
      const data: IPendingAction = pendingActionData.getActionById;
      setPendingAction(data);
      setValues({
        ...values,
        actionsLinked: [data.id],
        subCategory: data.subCategory,
        description: data.description,
        jobDate: data.actionDate,
        partnerId: data.partner?.partnerName,
        assignedTo: data.assignedTo?.firstName || "",
      });
    }
  }, [pendingActionData]);

  useEffect(() => {
    if (bookingData && bookingData.booking) {
      const booking: IBooking = bookingData.booking;
      setBooking(booking);
      setValues({
        ...values,
        booking: booking.id,
        jobDate: booking.pickupDateTime
      });
    }
  }, [bookingData]);

  useEffect(() => {
    if (vehicleServiceData && vehicleServiceData.vehicleService) {
      const vehicleService: IVehicleService = vehicleServiceData.vehicleService;
      setService(vehicleService);
      setValues({
        ...values,
        vehicle: vehicleService.vehicleId,
        jobDate: vehicleService.appointmentDate
      });
    }
  }, [vehicleServiceData]);

  useEffect(() => {
    if (branchUsersData && branchUsersData.getUsersInBranch?.length) {
      setUsers(branchUsersData.getUsersInBranch);
    }
  }, [branchUsersData]);

  useEffect(() => {
    if (jobData && jobData.userJobById) {
      if (jobData.userJobById?.actionsLinked.length){
        setIsPendingAction(true);
      }
      if (
        jobData.userJobById.status &&
        (jobData.userJobById.status === JobStatus.CANCELLED ||
          jobData.userJobById.status === JobStatus.COMPLETE)
      ) {
        setStatusDisabled(true);
      }
      setValues({
        ...jobData.userJobById,
        assignedTo: jobData.userJobById.assignedTo.id,
        booking: jobData.userJobById?.booking?.id,
        vehicle: jobData.userJobById?.vehicle?.id
      });
      const updatedPendingAction: Partial<IPendingAction> = {
        ...pendingAction,
        booking: jobData.userJobById?.booking,
        description: jobData.userJobById?.description,
        dateCreated: jobData.userJobById?.jobDate
      };
      setPendingAction(updatedPendingAction as IPendingAction);
      setVehicles([jobData.userJobById?.vehicle]);
      setFormatedDrivers(reshapeDriver(jobData.userJobById?.assignedTo?.partnerProfile));
      if (jobData.userJobById?.booking && jobData.userJobById?.booking?.id) {
        setBookingRef(jobData.userJobById?.booking?.referenceNumber);
      } else if (
        jobData.userJobById?.vehicle &&
        jobData.userJobById?.vehicle.id
      ) {
        setVehicleRef(jobData.userJobById?.vehicle.licencePlate);
      }
      const jobNotes = cloneDeep(jobData.userJobById?.notes);
      setNotes(reverse(jobNotes));
    }
  }, [jobData]);

  function reshapeDriver(driver: any) {
    return {
      id: driver?.id,
      firstName: driver?.firstName,
      lastName: driver?.lastName,
      email: driver?.email,
      phoneNumber: driver?.phoneNumber,
      license: driver?.license
    };
  }

  const handleFormSubmit = (values: IUserJobWrite) => {
    const currentDate = d
      .now()
      .toUTC()
      .toISO();
    if (!values.id) {
      delete values.status;
      if (values.jobDate < currentDate) {
        snackbar({
          message: "Time cannot be in the past. Please select a valid Time.!",
          variant: SnackBarVariant.ERROR
        });
      } else {
        values.partnerId = values.partnerId
        values.assignedTo = values.assignedTo
        createJob({
          variables: {
            userJob: values
          }
        });
      }
    } else {
      const actionId = values.actionsLinked.map((key: any) => {
        return key.id;
      });
      values.actionsLinked = actionId;
      const { notes, ...rest } = values;
      if (!values.jobDate) {
        snackbar({
          message: "Please select date and time !",
          variant: SnackBarVariant.ERROR
        });
      } else {
        updateJob({
          variables: {
            id: values.id,
            userJob: rest
          }
        });
      }
    }
  };

  const jobValidationSchema = Yup.object().shape({
    subCategory: Yup.string().required("Task Type is required."),
    description: Yup.string().required("Please add the task description")
  });

  if (
    jobDataLoading ||
    branchUsersLoading ||
    pendingActionLoading ||
    bookingLoading ||
    serviceLoading ||
    serviceLoading
  ) {
    return <CircularProgress />;
  }

  function removeSentenceAfterWord(input: string, word: string): string {
    const index = input.indexOf(word);
    if (index !== -1) {
      return input.slice(0, index).trim();
    }
    return input;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenDriver = () => {
    setOpenDriver(true);
  };

  const handleCloseDriver = () => {
    setOpenDriver(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const changeJobStatus = (status: string) => {
    changeUserJobStatus({
      variables: {
        id: values.id,
        status: status
      }
    });
  };

  const cancelJob = () => {
    cancelUserJob({
      variables: {
        id: values.id
      }
    });
  };
  const onNoteSubmit = async () => {
    try {
      const result = await addNote({
        variables: {
          id: values.id || "",
          note: initialNoteValue
        }
      });
      let newNotes = reverse(result.data.addJobNote.notes);
      setNotes(newNotes);
      setInitialNoteValue({ note: "" });
    } catch (error) {
      captureErrorException(error);
      console.error("Error adding note:", error);
    }
  };

  const getDate = (dateInput: string) => {
    const date = d.fromISO(dateInput);
    return d.now() > date
      ? date.toUTC().toISO()
      : d
          .now()
          .toUTC()
          .toISO();
  };

  const handleSave = () => {
    handleClose();
    setVehicles([{ ...vehicle }]);
  };

  const handleSaveDriver = (driverId : any) => {
    handleCloseDriver();
  };

  const search = (data: any, query: any) => {
    return data.filter((vehicle: any) =>
      Object.entries(query).every(([k, v]) => vehicle[k] === v)
    );
  };

  const handleChangeDriver = (
    e: React.ChangeEvent<HTMLInputElement >,
    field: string
  ) => {
    if (field === "assignedTo") {
      setValues({...values, assignedTo : e.target.value})
      const filterDriver = partnerState?.find(
        (driver: any) => driver.id === e.target.value
      );
  
      if (filterDriver?.partnerProfile?.license) {
        const updatedDriver = {
          ...filterDriver,
          license: { ...filterDriver.partnerProfile.license },
        };
        setFormatedDrivers(reshapeDriver(updatedDriver));
      }
    }
    else {
      setValues({...values, partnerId : e.target.value})
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | { value: unknown }>,
    field: keyof IVehicle
  ) => {
    let uniqueModels = [];
    let uniqueColors = [];
    let uniqueTransmissions = [];
    let uniqueVrns = [];
    let query = {};
    let filteredVehicles = [];
    if (field === "make") {
      query = { make: e.target.value };
      filteredVehicles = search(vehicleData, query);
      uniqueModels = Array.from(
        new Set(
          filteredVehicles.map((vehicle: { model: string }) => vehicle.model)
        )
      ) as string[];
      uniqueColors = Array.from(
        new Set(
          filteredVehicles.map((vehicle: { colour: string }) => vehicle.colour)
        )
      ) as string[];
      uniqueTransmissions = Array.from(
        new Set(
          filteredVehicles.map(
            (vehicle: { transmission: string }) => vehicle.transmission
          )
        )
      ) as string[];
      uniqueVrns = Array.from(
        new Set(
          filteredVehicles.map(
            (vehicle: { licencePlate: string }) => vehicle.licencePlate
          )
        )
      ) as string[];

      setModels(uniqueModels);
      setColours(uniqueColors);
      setTransmissions(uniqueTransmissions);
      setVrns(uniqueVrns);
    } else if (field === "model") {
      query = { make: vehicle.make, model: e.target.value };
      filteredVehicles = search(vehicleData, query);
      uniqueColors = Array.from(
        new Set(
          filteredVehicles.map((vehicle: { colour: string }) => vehicle.colour)
        )
      ) as string[];
      uniqueTransmissions = Array.from(
        new Set(
          filteredVehicles.map(
            (vehicle: { transmission: string }) => vehicle.transmission
          )
        )
      ) as string[];
      uniqueVrns = Array.from(
        new Set(
          filteredVehicles.map(
            (vehicle: { licencePlate: string }) => vehicle.licencePlate
          )
        )
      ) as string[];

      setColours(uniqueColors);
      setTransmissions(uniqueTransmissions);
      setVrns(uniqueVrns);
    } else if (field === "colour") {
      query = {
        make: vehicle.make,
        model: vehicle.model,
        colour: e.target.value
      };
      filteredVehicles = search(vehicleData, query);
      uniqueTransmissions = Array.from(
        new Set(
          filteredVehicles.map(
            (vehicle: { transmission: string }) => vehicle.transmission
          )
        )
      ) as string[];
      uniqueVrns = Array.from(
        new Set(
          filteredVehicles.map(
            (vehicle: { licencePlate: string }) => vehicle.licencePlate
          )
        )
      ) as string[];

      setTransmissions(uniqueTransmissions);
      setVrns(uniqueVrns);
    } else if (field === "transmission") {
      query = {
        make: vehicle.make,
        model: vehicle.model,
        colour: vehicle.colour,
        transmission: e.target.value
      };
      filteredVehicles = search(vehicleData, query);
      uniqueVrns = Array.from(
        new Set(
          filteredVehicles.map(
            (vehicle: { licencePlate: string }) => vehicle.licencePlate
          )
        )
      ) as string[];

      setVrns(uniqueVrns);
    }
    setVehicle((prevVehicle) => ({
      ...prevVehicle,
      [field]: e.target.value
    }));
  };

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6} alignItems="center">
        <Typography variant="h1" color="primary">
          Task
        </Typography>
        <Box color="white" sx={{ pr: 1 }}></Box>
        <DoubleArrowIcon />
        <Box color="white" sx={{ pl: 1 }}></Box>
        {!values.id ? (
          <Typography variant="h1" color="primary">
            New
          </Typography>
        ) : (
          <Typography variant="h1" color="primary">
            Update
          </Typography>
        )}
        <Typography variant="h1" color="primary">
          &nbsp;Task
        </Typography>
      </Grid>
      {values.id ? (
        <Grid container item xs={6} justifyContent="flex-end">
          <Typography>
            {values?.status === JobStatus.PENDING ? (
              <Fab
                variant="extended"
                size="medium"
                aria-label="add"
                className="addButton"
                onClick={() => {
                  changeJobStatus(JobStatus.IN_PROGRESS);
                }}
              >
                Mark as In-Progress
              </Fab>
            ) : values?.status === JobStatus.IN_PROGRESS ? (
              <Fab
                variant="extended"
                size="medium"
                aria-label="add"
                className="addButton"
                onClick={() => {
                  changeJobStatus(JobStatus.COMPLETE);
                }}
              >
                Mark as Complete
              </Fab>
            ) : (
              ""
            )}
            {values.status && values.status === JobStatus.PENDING && (
              <Fab
                variant="extended"
                size="medium"
                aria-label="add"
                className="addButton"
                onClick={() => {
                  cancelJob();
                }}
                style={{ marginLeft: 15 }}
              >
                Cancel
              </Fab>
            )}
          </Typography>
        </Grid>
      ) : (
        ""
      )}
      <Grid container item xs={12}>
        <Paper className={classes.root}>
          { isPendingAction && organisation.supplierPortalEnabled && (            
          <Grid container spacing={3} style={{ marginBottom: "1rem" }}>
            {pendingAction?.booking && (
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={4}>
                <Typography variant="h6">
                  Task :{" "}
                  <strong>
                    {pendingAction?.description
                      ? removeSentenceAfterWord(
                          pendingAction.description,
                          "with"
                        )
                      : "No task available"}
                  </strong>
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="h6">
                  Customer Name:{" "}
                  <strong>
                    {pendingAction?.booking?.businessCustomer
                      ? pendingAction?.booking?.businessCustomer
                          ?.businessName || ""
                      : `${pendingAction?.booking?.customer?.firstName} ${pendingAction?.booking?.customer?.lastName}`}
                  </strong>
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="h6">
                  Phone Number:{" "}
                  <strong>
                    {pendingAction?.booking?.businessCustomer
                      ? pendingAction?.booking?.businessCustomer?.contact
                          ?.phoneNumber?.phone || ""
                      : pendingAction?.booking?.customer?.phoneNumber?.phone}
                  </strong>
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="h6">
                  Email:{" "}
                  <strong>
                    {pendingAction?.booking?.businessCustomer
                      ? pendingAction?.booking?.businessCustomer?.contact
                          ?.email || ""
                      : pendingAction?.booking?.customer?.email}
                  </strong>
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="h6">
                  Booking ref:{" "}
                  <strong>{pendingAction?.booking?.referenceNumber}</strong>
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="h6">
                  Delivery Date & Time:{" "}
                  <strong>
                    {pendingAction?.dateCreated
                      ? getLocalizedDateFormat(
                          country,
                          pendingAction.dateCreated,
                          DATE_TYPE.CONDENSED
                        )
                      : "No delivery date available"}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
            )}
            <Grid item container xs={12}>
            <Paper variant="outlined" className={classes.root}>
            <Typography>Vehicle Details</Typography>
              {vehicles && vehicles.length === 0 && (
                <Grid
                  container
                  item
                  xs={12}
                  style={{ marginTop: "1rem", paddingBottom: "10px" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpen}
                  >
                    Add Vehicle Details
                  </Button>
                </Grid>
              )}

              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Select Vehicle</DialogTitle>
                <DialogContent>
                  <Box mb={2}></Box>
                  <Formik
                    initialValues={values}
                    validationSchema={Yup.object().shape({
                      make: Yup.string().required("Make is required"),
                      model: Yup.string().required("Model is required")
                    })}
                    onSubmit={handleFormSubmit}
                  >
                    {({ handleSubmit, values }) => (
                      <Form
                        onSubmit={handleSubmit}
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "16px",
                          minWidth: "400px"
                        }}
                      >
                        <Field
                          component={Select}
                          name="make"
                          label="Select Make"
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={vehicle.make}
                          onChange={(e: any) => handleChange(e, "make")}
                          InputLabelProps={{ shrink: true }}
                        >
                          {makes?.map((makeOption, index) => (
                            <MenuItem key={index} value={makeOption}>
                              {makeOption}
                            </MenuItem>
                          ))}
                        </Field>
                        <Field
                          component={Select}
                          name="model"
                          label="Select Model"
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={vehicle.model}
                          onChange={(e: any) => handleChange(e, "model")}
                          InputLabelProps={{ shrink: true }}
                        >
                          {models?.map((modelOption, index) => (
                            <MenuItem key={index} value={modelOption}>
                              {modelOption}
                            </MenuItem>
                          ))}
                        </Field>
                        <Field
                          component={Select}
                          name="colour"
                          label="Select Color"
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={vehicle.colour}
                          onChange={(e: any) => handleChange(e, "colour")}
                        >
                          {colours?.map((colourOption, index) => (
                            <MenuItem key={index} value={colourOption}>
                              {colourOption}
                            </MenuItem>
                          ))}
                        </Field>
                        <Field
                          component={Select}
                          name="transmission"
                          label="Transmission"
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={vehicle.transmission}
                          onChange={(e: any) => handleChange(e, "transmission")}
                        >
                          {transmissions?.map((transmissionOption, index) => (
                            <MenuItem key={index} value={transmissionOption}>
                              {transmissionOption}
                            </MenuItem>
                          ))}
                        </Field>
                        <Field
                          component={Select}
                          name="vrn"
                          label="Select VRN"
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={vehicle.licencePlate}
                          onChange={(e: any) => handleChange(e, "licencePlate")}
                        >
                          {vrns?.map((vrnOption, index) => (
                            <MenuItem key={index} value={vrnOption}>
                              {vrnOption}
                            </MenuItem>
                          ))}
                        </Field>
                      </Form>
                    )}
                  </Formik>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleSave} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
              <Paper variant="outlined" className={classes.root}>
                <Grid className={classes.title}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Make</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>VRN</TableCell>
                        <TableCell>Color</TableCell>
                        <TableCell>Transmission</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {vehicles && vehicles.length ? (
                        vehicles.map((vehicle: IVehicle) => (
                          <TableRow>
                            <TableCell>{vehicle?.make}</TableCell>
                            <TableCell>{vehicle?.model}</TableCell>
                            <TableCell>{vehicle?.licencePlate}</TableCell>
                            <TableCell>{vehicle?.colour}</TableCell>
                            <TableCell>{vehicle?.transmission}</TableCell>
                            <TableCell>
                              <Typography
                                color="primary"
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline"
                                }}
                                onClick={handleClickOpen}
                              >
                                Replace Vehicle
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            No vehicle details available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Grid>
              </Paper>
            </Paper>
            </Grid>

            <Grid item container xs={12}>
            <Paper variant="outlined" className={classes.root}>
              <Grid className={classes.title}>
                <Typography>Driver Details</Typography>
                <Grid
                  container
                  item
                  xs={12}
                  style={{ marginTop: "1rem", paddingBottom: "10px" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpenDriver}
                  >
                    Select Driver
                  </Button>
                </Grid>

                <Dialog open={openDriver} onClose={handleCloseDriver}>
                  <DialogTitle>Select Vehicle</DialogTitle>
                  <DialogContent>
                    <Box mb={2}></Box>
                    <Formik initialValues={values} onSubmit={handleFormSubmit}>
                      {({ handleSubmit, values, setValues }) => (
                        <Form
                          onSubmit={handleSubmit}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gap: "16px",
                            minWidth: "400px"
                          }}
                        >
                          <Field
                            component={Select}
                            name="partnerId"
                            label="Select Agency"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            value={values.partnerId}
                            onChange={(e: any) => {
                              handleChangeDriver(e, "partnerId");
                              getPartnerUsers({
                                context: {
                                  headers: {
                                    partnerId: e.target.value
                                  }
                               },
                              });
                            }}
                            InputLabelProps={{ shrink: true }}
                          >
                            {availablePartnersData?.getAvailablePartnersForJob?.map(
                              (partner: any) => (
                                <MenuItem key={partner.id} value={partner.id}>
                                  {partner.partnerName}
                                </MenuItem>
                              )
                            )}
                          </Field>
                          <Field
                            component={Select}
                            name="assignedTo"
                            label="Select Driver"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            value={values.assignedTo}
                            onChange={(e: any) =>
                              handleChangeDriver(e, "assignedTo")
                            }
                            InputLabelProps={{ shrink: true }}
                          >
                            {partnerState?.map(
                              (driver: any) => (
                                <MenuItem key={driver.id} value={driver.id}>
                                  {`${driver.firstName} ${driver.lastName}`}
                                </MenuItem>
                              )
                            )}
                          </Field>
                        </Form>
                      )}
                    </Formik>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDriver}>Cancel</Button>
                    <Button onClick={handleSaveDriver} color="primary">
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>

                <Paper variant="outlined" className={classes.root}>
                  <Grid className={classes.title}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>First Name</TableCell>
                          <TableCell>Last Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Phone</TableCell>
                          <TableCell>Licence Number</TableCell>
                          <TableCell>Licence Expiry Date</TableCell>
                          <TableCell>Licence Image</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {formatedDrivers && (
                          <TableRow key={formatedDrivers.id}>
                            <TableCell>
                              {formatedDrivers.firstName ?? "N/A"}
                            </TableCell>
                            <TableCell>
                              {formatedDrivers.lastName ?? "N/A"}
                            </TableCell>
                            <TableCell>
                              {formatedDrivers.email ?? "N/A"}
                            </TableCell>
                            <TableCell>
                              {formatedDrivers.phoneNumber?.phone ?? "N/A"}
                            </TableCell>
                            <TableCell>
                              {formatedDrivers.license?.licenseNumber ?? "N/A"}
                            </TableCell>
                            <TableCell>
                              {formatedDrivers.license?.dateOfExpiry ?? "N/A"}
                            </TableCell>
                            <TableCell>
                              {formatedDrivers.license?.images?.[0] ? (
                                <img
                                  src={formatedDrivers.partnerProfile?.license?.images[0]}
                                  alt="License Image"
                                  style={{
                                    width: "50px",
                                    height: "50px"
                                  }}
                                />
                              ) : (
                                "N/A"
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Grid>
                </Paper>
              </Grid>
            </Paper>
            </Grid>
          </Grid>
          )}

          <Grid container xs={12}>
            <Typography variant={"h3"} style={{ marginBottom: "1rem" }}>
              {booking && (
                <span>
                  {getLocalizedBookingSyntex(country)} Reference :{" "}
                  {booking.referenceNumber || "NA"}
                </span>
              )}
              {bookingRef && (
                <span>
                  {getLocalizedBookingSyntex(country)} Reference :{" "}
                  {bookingRef || "NA"}
                </span>
              )}
            </Typography>
          </Grid>
          <Grid container xs={12}>
            <Typography variant={"h3"} style={{ marginBottom: "1rem" }}>
              {service && (
                <span>
                  Service Reference : {service.referenceNumber || "NA"}
                </span>
              )}
              {vehicleRef && (
                <span>Vehicle Reference : {vehicleRef || "NA"}</span>
              )}
            </Typography>
          </Grid>
          <Formik
            enableReinitialize
            validationSchema={jobValidationSchema}
            initialValues={values}
            onSubmit={(data, { setSubmitting }) => {
              handleFormSubmit(data);
              setSubmitting(false);
            }}
          >
            {(props) => (
              <Form className={classes.form}>
                <Grid container spacing={2}>
                  <Grid container item xs={12} sm={6} md={3}>
                    <Field
                      component={TextField}
                      placeholder="Task Type"
                      label="Task Type"
                      name={"subCategory"}
                      onChange={(e: any) => {
                        props.setValues({
                          ...props.values,
                          subCategory: e.target.value
                        });
                      }}
                      fullWidth
                      required
                      select
                      value={props.values.subCategory}
                    >
                      {UserJobTypes.map((key: any, index: number) => {
                        return (
                          <MenuItem key={index} value={key.value}>
                            {key.label}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Grid>
                  {booking && (
                    <Grid container item xs={12} sm={6} md={3}> 
                      <Field
                        component={TextField}
                        placeholder="Vehicle"
                        label="Select Vehicle"
                        name={"vehicle"}
                        value={props.values.vehicle}
                        onChange={(e: any) => {
                          props.setValues({
                            ...props.values,
                            vehicle: e.target.value
                          });
                        }}
                        fullWidth
                        required
                        select
                      >
                        {booking.currentBookingSchedules.map(
                          (key: any, index: number) => {
                            return (
                              <MenuItem key={index} value={key.vehicle.id}>
                                {key.vehicle.licencePlate}
                              </MenuItem>
                            );
                          }
                        )}
                      </Field>
                    </Grid>
                  )}
                  {!isSupplierPortalEnabled && (
                  <Grid container item xs={12} sm={6} md={3}>
                    <Field
                      component={TextField}
                      placeholder="Assign To"
                      label="Assign To"
                      name={"assignedTo"}
                      value={props.values.assignedTo}
                      onChange={(event: any) => {
                        props.setValues({
                          ...props.values,
                          assignedTo: event.target.value
                        });
                      }}
                      fullWidth
                      required
                      select
                    >
                      {users.map((option: any, index: number) => {
                        return (
                          <MenuItem key={option.id} value={option.id}>
                            {option.firstName} {option.lastName}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Grid>
                  )}
                  <Grid container item xs={12} sm={6} md={3}>
                    <SimpleDateTimePicker
                      date={props.values?.jobDate}
                      handleChange={(date: IDateTime) => {
                        if (date && date.date) {
                          props.setValues({
                            ...props.values,
                            jobDate: date.date
                          });
                        }
                      }}
                      required={true}
                      name={"jobDate"}
                      dateTitle={"Task Date"}
                      timeTitle={"Task Time"}
                      timeToShow={"before"}
                      {...(!props.values.id
                        ? {
                            minDate: d
                              .now()
                              .toUTC()
                              .toISO()
                          }
                        : {
                            minDate: getDate(values.jobDate)
                          })}
                    />
                  </Grid>
                  {values.id && (
                    <Grid item xs={12} sm={6} md={3}>
                      <Field
                        component={TextField}
                        placeholder="Status"
                        label="Status"
                        name={"status"}
                        onChange={(event: any) => {
                          props.setValues({
                            ...props.values,
                            status: event.target.value
                          });
                        }}
                        fullWidth
                        required
                        select
                        disabled={statusDisabled}
                        value={props.values.status}
                      >
                        {userJobStatus.map((key: any, index: number) => {
                          return (
                            <MenuItem key={index} value={key.value}>
                              {key.label}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Field
                      component={TextField}
                      label={"Description"}
                      name={"description"}
                      inputProps={{ maxLength: 500 }}
                      fullWidth
                      multiline
                      rows={"3"}
                      value={props.values.description}
                      onChange={(e: any) => {
                        props.setValues({
                          ...props.values,
                          description: e.target.value
                        });
                      }}
                    />
                  </Grid>
                  <Grid item container xs={12}>
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="add"
                      type="submit"
                      disabled={statusDisabled || saveJobLoading}
                    >
                      {saveJobLoading && (
                        <CircularProgress
                          size={14}
                          style={{ color: "white", marginRight: "10px" }}
                        />
                      )}
                      Save
                    </Fab>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>

      {values.id ? (
        <Grid container item xs={12}>
          <Paper className={classes.root}>
            <Typography variant="h3" style={{ marginBottom: 15 }}>
              {" "}
              Notes{" "}
            </Typography>
            <Formik
              enableReinitialize
              initialValues={initialNoteValue}
              onSubmit={(data, { setSubmitting }) => {
                onNoteSubmit();
                setSubmitting(false);
              }}
            >
              {() => (
                <Form className={classes.form}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Field
                      component={TextField}
                      label={"Note"}
                      name={"note"}
                      inputProps={{ maxLength: 500 }}
                      fullWidth
                      multiline
                      rows={"3"}
                      onChange={(e: any) => {
                        setInitialNoteValue({
                          ...initialNoteValue,
                          note: e.target.value
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="add"
                      type="submit"
                      disabled={!initialNoteValue.note}
                    >
                      Add
                    </Fab>
                  </Grid>
                </Form>
              )}
            </Formik>

            {notes && notes.length ? (
              <TableContainer>
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{ maxWidth: 300, minWidth: 200 }}
                      >
                        <Typography>Notes</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>Added By</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography> Date & Time</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {notes.map((key: any) => {
                      return (
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{ maxWidth: 300, minWidth: 200 }}
                          >
                            {" "}
                            {key?.note || "NA"}{" "}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {key?.createdBy?.firstName}{" "}
                            {key?.createdBy?.lastName}{" "}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {getLocalizedDateFormat(
                              country,
                              `${key?.createdAt}`,
                              DATE_TYPE.EXPANDED
                            )}{" "}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              ""
            )}
          </Paper>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
};