import { gql } from "@apollo/client";

export const GET_USER = gql`
  query User($id: String!) {
    user(id: $id) {
      id
      email
      phoneNumber {
        phone
        country
      }
      firstName
      lastName
      role
      userRole {
        id
        name
        key
        policies {
          id
          name
          permissions
        }
      }
      active
      tenancy {
        id
        name
        logoUri
        chargebeeSubscriptionId
        chargebeePlanId
        chargebeeCustomerId
        chargebeePlanType
        chargebeeSubscriptionExpiry
        appLogo
        themeColor
        carSubscriptionEnabled
        taxEnabledCreditNotes
        loginEnabled
        enableEpyxIntegration
      }
      currentTenancy {
        id
        name
        idvEnabled
        taxEnabledCreditNotes
        creditCheckEnabled
        logoUri
        appLogo
        themeColor
        carSubscriptionEnabled
        loginEnabled
        enableEpyxIntegration
        transfer360Enabled
        transfer360Integrated
        rateCardsEnabled
        trailerCSVUploadEnabled
      }
      organisation {
        id
        allowPreSigningAgreement
        supplierPortalEnabled
        requiresBookingApproval
        allowCarSharing
        disableMapForCarShareVehicles
        inspectionCheckEnabled
        maxImagesPerDamage
        owner {
          id
        }
        enableCostCenter
        closeGroupSharingEnabled
        costCenters {
          name
          projectIds
        }
        locale
        emailId
        name
        phoneNumber {
          phone
          country
        }
        currency
        stripeAccountId
        sepaEnabled
        bacsEnabled
        cashEnabled
        cardEnabled
        offlineBankTransferEnabled
        payByLinkEnabled
        payOnCollectionEnabled
        autoChargeEnabled
        requiresCustomerVerification
        enableIdentityVerification
        crossLocationBookingEnabled
        logoUrl
        address {
          city
          state
          street
          country
          zipcode
          fullAddress
        }
        sageAccounting {
          active
        }
        orgVatNumber
        orgRegNumber
        branches {
          id
          name
        }
        convergeEnabled
        convergeAutoChargeAvailable
        bookingCategories {
          isActive
          type
          default
        }
        sendServiceBillsToXero
        enableDriverAllocationRules
        driverAllocationRules
      }
      branch {
        id
        name
        groupName
        autoColorEnabled
        eventsColor {
          reason
          color
        }
        corporateRateCardEnabled
        overbookingAllowed
        depositCollection
        timeZone
        useThresholds
        pointThreshold
        offenceThreshold
        distanceUnit
        dailyBillingCycle
        weeklyBillingCycle
        monthlyBillingCycle
        vehiclePreparationTime
        carShareGracePeriodMins
        durationBasedPricingEnabled
        downloadHistorySettings {
          invoiceHistory
        }
        locations {
          id
          name
          isDisabled
        }
        holidays {
          id
          name
          startDate
          endDate
          isActive
        }
        operatingHours {
          day
          dayIndex
          pickupFromTime
          pickupToTime
          returnFromTime
          returnToTime
          closed
        }
        preBookingQuestions {
          question
          answerType
          required
          expectedAnswer
          options
          errorMessage
          B2B
          B2B2C
        }
        isAdditionalDriverQuestionsEnabled
        additionalDriverQuestions {
          question
          answerType
          required
          expectedAnswer
          options
          errorMessage
        }
        deliveryQuestions {
          question
          answerType
          required
          expectedAnswer
          options
          errorMessage
          B2B
          B2B2C
        }
        dashboardReminder
        autoPayEnabled
        autoPayDays
      }
      currentOrganisation {
        id
        allowPreSigningAgreement
        supplierPortalEnabled
        requiresBookingApproval
        allowCarSharing
        disableMapForCarShareVehicles
        inspectionCheckEnabled
        maxImagesPerDamage
        owner {
          id
        }
        enableCostCenter
        closeGroupSharingEnabled
        costCenters {
          name
          projectIds
        }
        locale
        emailId
        name
        phoneNumber {
          phone
          country
        }
        currency
        stripeAccountId
        sepaEnabled
        bacsEnabled
        cashEnabled
        cardEnabled
        offlineBankTransferEnabled
        payByLinkEnabled
        payOnCollectionEnabled
        autoChargeEnabled
        crossLocationBookingEnabled
        requiresCustomerVerification
        enableIdentityVerification
        enableAutoCreditCheck
        xeroEnabled
        logoUrl
        address {
          city
          state
          street
          country
          zipcode
          fullAddress
        }
        sageAccounting {
          active
        }
        orgVatNumber
        orgRegNumber
        branches {
          id
          name
        }
        businessCustomerTags
        convergeEnabled
        convergeAutoChargeAvailable
        bookingCategories {
          isActive
          type
          default
        }
        sendServiceBillsToXero
        enableDriverAllocationRules
        driverAllocationRules
      }
      currentBranch {
        id
        name
        groupName
        depositCollection
        autoColorEnabled
        eventsColor {
          reason
          color
        }
        corporateRateCardEnabled
        overbookingAllowed
        timeZone
        useThresholds
        pointThreshold
        offenceThreshold
        distanceUnit
        dailyBillingCycle
        weeklyBillingCycle
        monthlyBillingCycle
        vehiclePreparationTime
        carShareGracePeriodMins
        durationBasedPricingEnabled
        downloadHistorySettings {
          invoiceHistory
        }
        locations {
          id
          name
          address {
            street
            city
            fullAddress
            state
            country
            zipcode
            geometry {
              type
              latitude
              longitude
            }
          }
          isDisabled
        }
        holidays {
          id
          name
          startDate
          endDate
          isActive
        }
        operatingHours {
          day
          dayIndex
          pickupFromTime
          pickupToTime
          returnFromTime
          returnToTime
          closed
        }
        preBookingQuestions {
          question
          answerType
          required
          expectedAnswer
          options
          errorMessage
          B2B
          B2B2C
        }
        isAdditionalDriverQuestionsEnabled
        additionalDriverQuestions {
          question
          answerType
          required
          expectedAnswer
          options
          errorMessage
        }
        deliveryQuestions {
          question
          answerType
          required
          expectedAnswer
          options
          errorMessage
          B2B
          B2B2C
        }
        dashboardReminder
        autoPayEnabled
        autoPayDays
      }
    }
  }
`;