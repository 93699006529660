import { initialBasePrices } from "../../components/views/Fleet/VehiclePriceGroups/utils";
import { FleetTypes } from "../../components/views/ReservationManagement/utils";
import { IVehiclePriceGroupState } from "./types";

export const vehiclePriceGroupInitialState: IVehiclePriceGroupState = {
  vehiclePriceGroup: {
    id: "",
    name: "",
    fleetType: FleetTypes.OWN,
    criteria: [],
    basePrices: initialBasePrices,
    deposit: 0,
    isActivated: true,
    hideInB2b2c: false,
    vehicles: [],
    automaticAddVehicle: false,
    imageUrl: "",
    yieldPricingEnabled: false,
    yieldPriceSlabs: [],
  }
};