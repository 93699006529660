import "./index.scss";
import {
  Grid,
  Theme,
  Typography,
  Hidden,
  Box,
  Paper
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IVehicle, IVehicleService, VehicleServiceStatus, VehicleServiceStatusValue, VehicleServiceType, VehicleServiceTypeValue } from "../../../../../../reducers/fleet/types";
import { IAppState } from "../../../../../../store";
import { DATE_TYPE, toCurrency } from '../../../../../common/utils';
import { MobileServiceCard } from "./MobileServiceCard";
import { NuvvenTable } from "../../../../../common/NuvvenTable/NuvvenTable";
import { MUIDataTableOptions } from "mui-datatables";
import { SelectableRows } from "../../../../../common/NuvvenTable/types";
import { getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";

interface IProps {
  vehicle: IVehicle;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    green: {
      color: "#00ff00"
    },
    header: {
      color: "rgb(45, 157, 184)",
      fontSize: 20
    },
    label: {
      color: "grey",
      fontSize: 12,
      fontWeight: 100,
      marginLeft: 20
    },
    paper: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      textAlign: "center"
    },
    red: {
      color: "#ff0000"
    },
    root: {
      padding: theme.spacing(3)
    }
  })
);


export const ServiceHistory = (props: IProps) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const classes = useStyles();
  const [vehicleService, setVehicleService] = useState<IVehicleService[]>([]);

  useEffect(() => {
    if (props.vehicle && props.vehicle.services && props.vehicle.services.length) {
      setVehicleService(props.vehicle.services);
    }
  }, [props.vehicle]);

  const columns = [
    {
      label: "Reference Number",
      name: "referenceNumber"
    },
    {
      label: "Service Start Date",
      name: "startDate",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
      }
    },
    {
      label: "Service End Date",
      name: "completionDate",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
      }
    },
    {
      label: "Service Type",
      name: "serviceType",
      options: {
        customBodyRender: (value: VehicleServiceType) => VehicleServiceTypeValue[value]
      }
    },
    {
      label: "Total Expenses",
      name: "totalExpenses",
      options: {
        customBodyRender: (value: string) => toCurrency(parseInt(value), currency, locale)
      }
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value: VehicleServiceStatus) => VehicleServiceStatusValue[value]
      }
    }
  ];

  function Spacer() {
    return (
      <Grid item xs={12}>
        <Box mt={1} />
      </Grid>
    )
  }

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    elevation: 0,
    onRowClick: (rowData: string[], rowMeta: { dataIndex: number }) => {
      if (rowData && rowData.length && props?.vehicle?.services) {
        const serviceClicked = props.vehicle.services[rowMeta.dataIndex];
        if (serviceClicked) {
          window.open(`/new-services-and-repair?id=${serviceClicked.id}`, "_blank");
        }
      }
    },
  }

  return (
    <Grid container className={classes.root}>
      <Grid container item xs={12}>
        <Grid container item xs={12}>
          <Typography variant="subtitle1">SERVICE HISTORY</Typography>
        </Grid>
        <Spacer />
        <Grid item xs={12}>
          <Hidden smDown>
            {vehicleService && vehicleService.length > 0 ? (
              <Paper variant="outlined">
                <NuvvenTable
                  title=""
                  rows={vehicleService}
                  columns={columns}
                  options={options}
                />
              </Paper>
            ) : (
              <Grid xs={12} item>
                <Typography variant="h4">
                  No Service Data
                </Typography>
              </Grid>
            )}
          </Hidden>
          <Hidden smUp>
            {(vehicleService.length === 0) && (
              <Grid xs={12} item>
                <Typography variant="h4">
                  No Service Data
                </Typography>
              </Grid>
            )}
            {vehicleService && vehicleService.map((service: IVehicleService, index: number) => {

              return (
                <Grid container item xs={12} justifyContent='center'>
                  <Grid item>
                    <MobileServiceCard row={service} index={index} />
                  </Grid>
                </Grid>
              )
            })}
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
};
