import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  CssBaseline,
  Fab,
  FormControl,
  Grid,
  Typography,
  Box,
  Paper,
  MenuItem,
  Theme,
  Tooltip,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
  TextField as InputField
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Field, Form, Formik, FieldProps } from "formik";
import { TextField } from "formik-mui";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ApolloError } from "@apollo/client";
import PhoneInput, { Value, isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";

import { IPartner } from "../../../reducers/partners/types";
import { partnerInitialState } from "../../../reducers/partners/const";
import { GET_PARTNER } from "../../../graphql/partners/getPartnerQuery";
import { CREATE_PARTNER } from "../../../graphql/partners/createPartnerMutation";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import {
  formatGraphQLErrorMessage
} from "../../common/utils";
import { UPDATE_PARTNER } from "../../../graphql/partners/updatePartnerMutatio";
import { partNerTypes } from "./utils";
import AddressPicker from "../CustomerManagement/NewCustomer/AddressPicker/AddressPicker";
import { IAddress } from "../../../reducers/user/types";
import { validateEmail } from "../../common/ValidationRules";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import { UserRoles } from "../../hoc/Authorization";
import { getDefaultCountryCode } from "../../../utils/localized.syntex";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    form: {
      flexGrow: 1
    },
    checkboxLabel: {
      fontSize: "16px !important"
    },
  })
);

export const Partner: React.FC = () => {

  const classes = useStyles();
  const snackbar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [values, setValues] = useState<IPartner>(partnerInitialState)
  const [isNewPartner, setIsNewPartner] = useState<boolean>(true);
  const organisation = userState.currentOrganisation;
  const isSupplierPortalEnabled = organisation?.supplierPortalEnabled;
  const [phoneError, setPhoneError] = useState<string>("");
  const [secPhoneError, setSecPhoneError] = useState<string>("");
  const [billingContactError, setBillingContactError] = useState<string>("");
  const [countryCode, setCountry] = useState<any>({
    primary: getDefaultCountryCode(country),
    secondary: getDefaultCountryCode(country),
    binningContactPhone: getDefaultCountryCode(country)
  });

  function handleCheckbox(
    event: React.ChangeEvent<HTMLInputElement>,
    data: any
  ) {
    if (event.target.checked) {
      setValues({
        ...data,
        tradingAddress: data.registeredAddress,
        tradingAddressSame: true
      });
    } else {
      setValues({
        ...data,
        tradingAddressSame: false
      });
    }
  }

  const [
    loadPartner,
    { loading: loadingPartner, data: partnerData }
  ] = useLazyQuery(GET_PARTNER, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.partner) {
        navigate("/partners");
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
      navigate("/partners");
    }
  });

  const [createPartner, { loading: savePartnerLoading }] = useMutation(CREATE_PARTNER, {
    onCompleted: () => {
      snackbar({
        message: "Partner Created successfully",
        variant: SnackBarVariant.SUCCESS
      })
      navigate(`/partners`)
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [updatePartner] = useMutation(UPDATE_PARTNER, {
    onCompleted: (data) => {
      if (data && data.updatePartner) {
        snackbar({
          message: "Partner Updated successfully",
          variant: SnackBarVariant.SUCCESS
        });
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const partnerId = params.get("partner");
      if (partnerId) {
        setIsNewPartner(false);
        loadPartner({
          variables: {
            id: partnerId
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (partnerData && partnerData.partner) {
      setValues(partnerData.partner)
    }
  }, [partnerData])

  const handleFormSubmit = (values: IPartner) => {
    const { id, ...rest } = values
    if (values.id) {
      updatePartner({
        variables: {
          id: values.id,
          partner: values
        }
      })
    } else {
      createPartner({
        variables: {
          partner: rest
        }
      })
    }
  }

  const partnerValidationSchema = Yup.object().shape({
    phoneNumber: Yup.object().shape({
      phone: Yup.string()
        .test("test-is-valid-phone-number", "", function (value) {
          if (value && !isValidPhoneNumber(value)) {
            return this.createError({ message: "Invalid phone number" })
          }
          return true;
        })
        .required("Phone Number is required.")
    }),
    email: Yup.string()
      .required("Email is required.")
      .email("Please enter a valid email Address."),
    partnerName: Yup.string().required("Partner Name is required."),
    VAT: Yup.string().required("VAT No is required."),
    companyRegistrationNo: Yup.string().required("Company Reg. No. is required."),
    address: Yup.object().shape({
      fullAddress: Yup.string().required("Address is required"),
      country: Yup.string().required("Country is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      street: Yup.string().required("Street is required"),
    }).required("Address is required"),
    partnerContact: Yup.object().shape({
      firstName: Yup.string().required("First Name is required."),
      lastName: Yup.string().required("Last Name is required."),
      phoneNumber: Yup.object().shape({
        phone: Yup.string()
          .test("test-is-valid-phone-number", "", function (value) {
            if (value && !isValidPhoneNumber(value)) {
              return this.createError({ message: "Invalid phone number" })
            }
            return true;
          })
          .required("Phone Number is required.")
      }),
      email: Yup.string()
        .required("Email is required.")
        .email("Please enter a valid email address.")
    })
  });

  if (loadingPartner) {
    return <CircularProgress />
  }

  const isEditDisabled = (userState.role === UserRoles.BOOKING_AGENT || userState.role === UserRoles.FINANCE_MANAGER);
  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12} alignItems="center">
        <Typography variant="h1" color="primary">
          Partner{"  "}
        </Typography>
        <Box color="white" sx={{ pr: 1 }}></Box>
        <DoubleArrowIcon />
        <Box color="white" sx={{ pl: 1 }}></Box>
        {isNewPartner ? (
          <Typography variant="h1" color="primary">
            New
          </Typography>
        ) : (
          <Typography variant="h1" color="primary">
            Update
          </Typography>
        )}
        <Typography variant="h1" color="primary">
          &nbsp;Partner
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Paper className={classes.root}>
          <Formik
            enableReinitialize
            validationSchema={partnerValidationSchema}
            initialValues={values}
            onSubmit={(values, { setSubmitting }) => {
              const updateValues = {
                ...values,
                phoneNumber: {
                  ...values.phoneNumber,
                  country: countryCode.primary
                },
                partnerContact: {
                  ...values.partnerContact,
                  phoneNumber: {
                    ...values.partnerContact.phoneNumber,
                    country: countryCode.secondary
                  }
                },
                billingContact: {
                  ...values.billingContact,
                  phoneNumber: {
                    ...values.billingContact?.phoneNumber,
                    country: countryCode.binningContactPhone
                  }
                }
              };
              handleFormSubmit(updateValues);
              setSubmitting(false);
            }}
          >
            {(props) => (
              <Form className={classes.form}>
                <Grid container style={{ marginBottom: 10 }}>
                  <Grid item xs={10}>
                    <Typography variant="h2">Company Information</Typography>{" "}
                  </Grid>
                  {values.id && (
                    <Grid item xs={2} style={{ paddingLeft: 100 }}>
                      <Tooltip title={props.values.isActive ? "Deactivate" : "Activate"}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.values.isActive}
                              onChange={(event: any) => {
                                setValues({
                                  ...props.values,
                                  isActive: event.target.checked
                                })
                              }}
                              disabled={isEditDisabled}
                              name="isActive"
                              color="primary"
                            />
                          }
                          label={"Active"}
                        />
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <FormControl variant="outlined" fullWidth>
                      <Field
                        component={TextField}
                        name={"partnerType"}
                        fullWidth
                        type="text"
                        select
                        required
                        disabled={isSupplierPortalEnabled ? false : true}
                        label="Partner Type"
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          onChange: (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setValues({
                              ...props.values,
                              partnerType: event.target.value
                            });
                          },
                          value: props.values.partnerType
                        }}
                      >
                        {partNerTypes.map((item: any, index: number) => {
                          return (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={TextField}
                      placeholder="Partner Name"
                      label="Partner Name"
                      name={"partnerName"}
                      value={props.values.partnerName}
                      onChange={props.handleChange}
                      fullWidth
                      required
                      disabled={isEditDisabled}
                    ></Field>
                  </Grid>
                  <Grid item container xs={4}>
                    <AddressPicker
                      fieldName="address"
                      required
                      disabled={isEditDisabled}
                      onChange={(address: IAddress) => {
                        if (address) {
                          setValues({ ...props.values, address: address })
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={TextField}
                      placeholder="Email"
                      label="Email Address"
                      name={"email"}
                      value={props.values.email}
                      onChange={props.handleChange}
                      fullWidth
                      required
                      disabled={isEditDisabled}
                    ></Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Field
                      component={TextField}
                      name={"phoneNumber.phone"}
                      required
                    >
                      {({ field, form: { setFieldValue, setTouched, touched, errors }, meta }: FieldProps) => {
                        return (
                          <div className={`phone-input-container`}>
                            <label className={`${phoneError ? "input-error-label" : ""} phone-input-label`}>
                              Phone Number <sup className={"phone-input-required"}>*</sup>
                            </label>
                            <PhoneInput
                              international={false}
                              defaultCountry={countryCode.primary}
                              placeholder="Phone Number"
                              className={phoneError ? "phone-input-error" : ""}
                              value={props.values.phoneNumber.phone}
                              name={"phoneNumber.phone"}
                              required
                              disabled={isEditDisabled}
                              onChange={(val: Value) => {
                                if (val && isValidPhoneNumber(val)) {
                                  setPhoneError("");
                                }
                                setValues({
                                  ...props.values,
                                  phoneNumber: {
                                    ...props.values.phoneNumber,
                                    phone: val
                                  }
                                });
                              }}
                              onBlur={(val) => {
                                if (!props.values.phoneNumber.phone) {
                                  setPhoneError("Phone Number is required.");
                                } else if (!isValidPhoneNumber(props.values.phoneNumber.phone)) {
                                  setPhoneError("Enter a valid phone number.");
                                } else {
                                  setPhoneError("");
                                }
                              }}
                              onCountryChange={(val) => {
                                setCountry({
                                  ...countryCode,
                                  primary: val
                                });
                              }}
                            />
                            {
                              phoneError &&
                              <span className={"phone-error-message"}>{phoneError}</span>
                            }
                          </div>
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={TextField}
                      placeholder="Company Reg. No."
                      label="Company Reg. No."
                      name={"companyRegistrationNo"}
                      value={props.values.companyRegistrationNo}
                      onChange={props.handleChange}
                      fullWidth
                      required
                      disabled={isEditDisabled}
                    ></Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={TextField}
                      placeholder="VAT No"
                      label="VAT No"
                      name={"VAT"}
                      value={props.values.VAT}
                      onChange={props.handleChange}
                      fullWidth
                      required
                      disabled={isEditDisabled}
                    ></Field>
                  </Grid>
                  {isSupplierPortalEnabled && ( <>
                  <Grid item xs={12}>
                    <Typography variant="h2">
                      Billing Contact Details
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Field
                      component={TextField}
                      name="billingContact.email"
                      placeholder="Email Address"
                      label="Email Address"
                      value={props.values.billingContact?.email}
                      onChange={props.handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Field
                      component={TextField}
                      name={"billing.phoneNumber.phone"}
                      required
                    >
                      {({
                        field,
                        form: { setFieldValue, setTouched, touched, errors },
                        meta
                      }: FieldProps) => {
                        return (
                          <div className={`phone-input-container`}>
                            <label
                              className={`${
                                billingContactError ? "input-error-label" : ""
                              } phone-input-label`}
                            >
                              Phone Number{" "}
                              <sup className={"phone-input-required"}>*</sup>
                            </label>
                            <PhoneInput
                              international={false}
                              defaultCountry={countryCode.binningContactPhone}
                              placeholder="Phone Number"
                              className={
                                billingContactError ? "phone-input-error" : ""
                              }
                              value={
                                props.values.billingContact?.phoneNumber?.phone
                              }
                              name={"billing.phoneNumber.phone"}
                              required
                              disabled={isEditDisabled}
                              onChange={(val: Value) => {
                                if (val && isValidPhoneNumber(val)) {
                                  setBillingContactError("");
                                }
                                setValues({
                                  ...props.values,
                                  billingContact: {
                                    ...props.values.billingContact,
                                    phoneNumber: {
                                      ...props.values.billingContact
                                        ?.phoneNumber,
                                      phone: val
                                    }
                                  }
                                });
                              }}
                              onBlur={(val) => {
                                if (
                                  !props.values.billingContact.phoneNumber
                                    ?.phone
                                ) {
                                  setBillingContactError(
                                    "Phone Number is required."
                                  );
                                } else if (
                                  !isValidPhoneNumber(
                                    props.values.billingContact?.phoneNumber
                                      ?.phone
                                  )
                                ) {
                                  setBillingContactError(
                                    "Enter a valid phone number."
                                  );
                                } else {
                                  setBillingContactError("");
                                }
                              }}
                              onCountryChange={(val) => {
                                setCountry({
                                  ...countryCode,
                                  binningContactPhone: val
                                });
                              }}
                            />
                            {billingContactError && (
                              <span className={"phone-error-message"}>
                                {billingContactError}
                              </span>
                            )}
                          </div>
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography variant="h3">REGISTERED ADDRESS</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography variant="h3">TRADING ADDRESS</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <AddressPicker
                        fieldName={"registeredAddress"}
                        required={true}
                        onChange={(address: IAddress) => {
                          if (address) {
                            setValues({
                              ...props.values,
                              registeredAddress: address
                            });
                          }
                        }}
                      />
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      {!props.values.tradingAddressSame ? (
                        <AddressPicker
                          fieldName={"tradingAddress"}
                          required={true}
                          onChange={(address: IAddress) => {
                            if (address) {
                              setValues({
                                ...props.values,
                                tradingAddress: address
                              });
                            }
                          }}
                        />
                      ) : (
                        <InputField
                          placeholder="Trading Address"
                          label="Trading Address"
                          disabled
                          fullWidth
                          value={props.values.registeredAddress.fullAddress}
                        ></InputField>
                      )}
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormGroup row className={classes.checkboxLabel}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={props.values.tradingAddressSame}
                              onChange={(e) => handleCheckbox(e, props.values)}
                              color="secondary"
                              name={"premium"}
                            />
                          }
                          label={
                            <Typography variant="body1">
                              {"Use Registered Address"}
                            </Typography>
                          }
                          classes={{ label: classes.checkboxLabel }}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  </>
                  )}
                  <Grid item xs={12}>
                    <Typography variant="h2">
                      {isSupplierPortalEnabled ? "Partner Admin Contact" : "Company Contact Person" }
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={TextField}
                      placeholder="First Name"
                      label="First Name"
                      name={"partnerContact.firstName"}
                      value={props.values.partnerContact.firstName}
                      onChange={props.handleChange}
                      fullWidth
                      required
                      disabled={isEditDisabled}
                    ></Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={TextField}
                      placeholder="Last Name"
                      label="Last Name"
                      name={"partnerContact.lastName"}
                      value={props.values.partnerContact.lastName}
                      onChange={props.handleChange}
                      fullWidth
                      required
                      disabled={isEditDisabled}
                    ></Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={TextField}
                      placeholder="example@xyz.com"
                      label="Email Address"
                      name={"partnerContact.email"}
                      value={props.values.partnerContact.email}
                      onChange={props.handleChange}
                      fullWidth
                      required
                      disabled={isEditDisabled}
                    ></Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Field
                      component={TextField}
                      name={"partnerContact.phoneNumber.phone"}
                      required
                    >
                      {({ field, form: { setFieldValue, setTouched, touched, errors }, meta }: FieldProps) => {
                        return (
                          <div className={`phone-input-container`}>
                            <label className={`${secPhoneError ? "input-error-label" : ""} phone-input-label`}>
                              Phone Number <sup className={"phone-input-required"}>*</sup>
                            </label>
                            <PhoneInput
                              international={false}
                              defaultCountry={countryCode.secondary}
                              placeholder="Phone Number"
                              className={secPhoneError ? "phone-input-error" : ""}
                              value={props.values.partnerContact.phoneNumber.phone}
                              name={"partnerContact.phoneNumber.phone"}
                              required
                              disabled={isEditDisabled}
                              onChange={(val: Value) => {
                                if (val && isValidPhoneNumber(val)) {
                                  setSecPhoneError("");
                                }
                                setValues({
                                  ...props.values,
                                  partnerContact: {
                                    ...props.values.partnerContact,
                                    phoneNumber: {
                                      ...props.values.partnerContact.phoneNumber,
                                      phone: val
                                    }
                                  }
                                });
                              }}
                              onBlur={(val) => {
                                if (!props.values.partnerContact.phoneNumber.phone) {
                                  setSecPhoneError("Phone Number is required.");
                                } else if (!isValidPhoneNumber(props.values.partnerContact.phoneNumber.phone)) {
                                  setSecPhoneError("Enter a valid phone number.");
                                } else {
                                  setSecPhoneError("");
                                }
                              }}
                              onCountryChange={(val) => {
                                setCountry({
                                  ...countryCode,
                                  secondary: val
                                });
                              }}
                            />
                            {
                              secPhoneError &&
                              <span className={"phone-error-message"}>{secPhoneError}</span>
                            }
                          </div>
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item container xs={12}>
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="add"
                      type="submit"
                      disabled={isEditDisabled || savePartnerLoading}
                    >
                      {savePartnerLoading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                      Save
                    </Fab>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  )
}
