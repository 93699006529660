import { useMutation } from "@apollo/client";
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Fade,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Switch,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import * as _ from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { CREATE_VEHICLE_GROUP } from "../../../../../graphql/vehiclePriceGroup/createVehicleGroupMutation";
import { UPDATE_VEHICLE_GROUP } from "../../../../../graphql/vehiclePriceGroup/updateVehicleGroupMutation";
import {
  IRate,
  IVehiclePriceGroup,
  IYieldPriceRule,
  IYieldPriceSlabs
} from "../../../../../reducers/vehiclePriceGroups/types";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  capitalize,
  DATE_TYPE,
  formatGraphQLErrorMessage
} from "../../../../common/utils";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { getSignedUrl } from '../../../../../utils/getSignedUrl';
import DragDropImages from "../../../../common/DragAndDropFiles/DragAndDropImage/DragDropImages";
import { captureErrorException } from "../../../../../utils/sentry";
import FavoriteIcon from '@mui/icons-material/Favorite';
import ClearIcon from '@mui/icons-material/Clear';
import { RATE_TYPES } from "../../../ReservationManagement/utils";
import { ValueType } from "../../../../../reducers/priceRule/types";
import Close from "@mui/icons-material/Close";
import Add from "@mui/icons-material/Add";
import FlatPickerBar from "../../../../common/FlatPicker";
import { DateTime as d } from "luxon"
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "200px"
    },
    imgContainer: {
      alignContent: "center",
      display: "flex",
      width: "100%"
    },
    saveButtonDisabled: {
      backgroundColor: "#d3d3d3",
      border: 0,
      borderRadius: 26,
      fontSize: "14px",
      color: "white",
      minHeight: 43,
      minWidth: "auto",
      width: 150
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(50vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(0, 2, 2),
    },
    formControlLabel: {
      fontSize: 14,
      fontWeight: "bold"
    }
  })
);

interface IProps {
  vehicleGroup: IVehiclePriceGroup;
  onPrevious: (values: IVehiclePriceGroup) => void;
}

interface IVehicleGroupImage {
  idx: number;
  url: string;
  default?: boolean;
}

export const GroupVehicleSetPrice: React.FC<IProps> = (props) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [values, setValues] = useState<IVehiclePriceGroup>({ ...props.vehicleGroup });
  const [vehicleGroupImage, setVehicleGroupImage] = useState<string | object>("");
  const [isImageUploadOpen, setImageUploadOpen] = useState(false);
  const [imageUploadInProgress, setImageUploadInProgress] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [images, setImages] = useState<IVehicleGroupImage[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedHeartIndex, setSelectedHeartIndex] = useState<number>(0);
  const [uploadedImages, setUploadedImages] = useState<IVehicleGroupImage[]>([]);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [uploadedImageCount, setUploadedImageCount] = useState(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [imageToDelete, setImageToDelete] = useState<number>(0);
  const [yieldPricingSlabs, setYieldPricingSlabs] = useState<IYieldPriceSlabs[]>([]);
  const { country } = userState.currentOrganisation.address

  const [createVehicleGroup] = useMutation(CREATE_VEHICLE_GROUP, {
    onCompleted: () => {
      snackbar({
        message: "Vehicle Price Group created successfully!",
        variant: SnackBarVariant.SUCCESS
      });
      navigate("/vehicle-price-groups");
      setDisabledSave(false);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [updateVehicleGroup] = useMutation(UPDATE_VEHICLE_GROUP, {
    onCompleted: () => {
      snackbar({
        message: "Vehicle Price Group updated successfully!",
        variant: SnackBarVariant.SUCCESS
      });
      navigate("/vehicle-price-groups");
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (props.vehicleGroup.yieldPriceSlabs) {
      setYieldPricingSlabs([...props.vehicleGroup.yieldPriceSlabs]);
    }
  }, [props.vehicleGroup.yieldPriceSlabs])

  useEffect(() => {
    const fetchData = async () => {
      if (props.vehicleGroup) {
        setValues(props.vehicleGroup);
        if (props.vehicleGroup.images) {
          const renderedImages = await Promise.all(props.vehicleGroup.images.map(async (image: any, index: any) => {
            return {
              idx: image.idx,
              default: image.default,
              url: image.url,
            };
          }));
          const renderedUploadedImages = await Promise.all(props.vehicleGroup.images.map(async (image: any, index: any) => {
            const url = image?.url ? await getSignedUrl(image.url) : "";
            return {
              idx: image.idx,
              default: image.default,
              url: url,
            };
          }));
          const sortedImages = renderedUploadedImages.sort((a: any, b: any) => b.default - a.default)
          setUploadedImages(sortedImages);
          setImages(renderedImages)
        }
      }
    }
    fetchData();
  }, [props.vehicleGroup]);

  useEffect(() => {
    if (images.length) {
      const selectedDefaultImage = images.filter((image) => image.default == true)[0]
      if (selectedDefaultImage && selectedDefaultImage.idx) {
        setSelectedHeartIndex(selectedDefaultImage.idx)
      }
    }
  }, [images])
  useEffect(() => {
    if (uploadedImages.length > 0 && selectedImageIndex === 0) {
      setSelectedImage(uploadedImages[0]?.url || "");
    }
  }, [uploadedImages, selectedImageIndex]);
  const handleHeartClick = (index: number) => {
    setSelectedHeartIndex(index);
    const updateDefaultImage = images.map((image) => {
      if (image.idx === index) {
        return {
          idx: image.idx,
          default: true,
          url: image.url,
        };
      } else {
        return {
          idx: image.idx,
          default: false,
          url: image.url,
        };
      }
    })
    setImages(updateDefaultImage)
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleDeleteImage = (imageKeyToDelete: number) => {
    setImageToDelete(imageKeyToDelete);
    setShowDeleteConfirmation(true);
  };
  const handleDeleteConfirmation = (confirmed: boolean) => {
    setShowDeleteConfirmation(false);
    if (confirmed) {
      const updatedImages = images.filter((image) => image.idx !== imageToDelete);
      const updatedUploadedImages = uploadedImages.filter((image) => image.idx !== imageToDelete);
      const defaultImage = updatedImages.find((image) => image.default);
      if (!defaultImage && updatedImages.length > 0) {
        updatedImages[0].default = true;
        setSelectedHeartIndex(updatedImages[0].idx);
      }
      setImages(updatedImages);
      setUploadedImages(updatedUploadedImages);
      setUploadedImageCount(updatedImages.length);
    }
  };
  const renderUploadedImages = () => {
    const frames = [];
    const itemsPerPage = 4;
    for (let i = 0; i < Math.ceil(uploadedImages.length / itemsPerPage); i++) {
      const frameImages = uploadedImages.slice(i * itemsPerPage, (i + 1) * itemsPerPage);
      frames.push(
        <div key={i} style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          {frameImages.map((image, index) => {
            return (
              <div key={image.idx} style={{ marginRight: index < itemsPerPage - 1 ? '10px' : '0' }}>
                {image.url && (
                  <div
                    style={{
                      position: 'relative',
                      width: '130px',
                      height: '76px',
                      overflow: 'hidden',
                      margin: '0 5px',
                      border: '1px solid #ddd'
                    }}
                  >
                    <img
                      src={image.url}
                      alt={`Vehicle Image ${image.idx}`}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleImageClick(image.idx, index)}
                    />
                    <IconButton
                      style={{
                        position: 'absolute',
                        top: '-10px',
                        left: '-10px',
                        color: image.idx === selectedHeartIndex ? 'red' : 'gray'
                      }}
                      onClick={() => handleHeartClick(image.idx)}
                    >
                      <FavoriteIcon />
                    </IconButton>
                    <IconButton
                      style={{
                        position: 'absolute',
                        top: '-10px',
                        right: '-10px',
                        color: 'black',
                      }}
                      onClick={() => handleDeleteImage(image.idx)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      );
    }
    return frames;
  };
  const handleImageClick = (idx: number, index: number) => {
    const imageSelected = images.find((image) => image.idx == idx)
    if (imageSelected) {
      setSelectedImage(imageSelected.url);
    }
    setSelectedImageIndex(index);
    setOpenModal(true);
  };

  const renderRates = (rateType: string) => {
    if (rateType === "deposit") {
      return values.deposit;
    } else {
      const obj = values.basePrices.find(
        (item) => item.rateTypeName === rateType
      );
      if (obj) {
        return obj.rate;
      }
    }
  };

  const updateRates = (rateType: string, rate: number | string) => {
    const updatedRate: number = typeof rate === "string" ? parseInt(rate) : rate;
    const basePricesArr = values.basePrices.map((item) => {
      if (item.rateTypeName === rateType) {
        return {
          ...item,
          rate: updatedRate
        }
      }
      return item;
    });
    const valueObj = {
      ...values,
      basePrices: basePricesArr
    };
    setValues(valueObj);
  };

  const handleFormSubmit = (values: IVehiclePriceGroup) => {
    const vehicles: string[] = [];
    if (values.vehicles) {
      values.vehicles.forEach((vehicle: any) => {
        vehicles.push(vehicle.id);
      });
    }
    for (let index = 0; index < values.basePrices.length; index++) {
      const bp = values.basePrices[index];
      if (!bp.unlimitedMileage && bp.rate && !bp.mileageLimit) {
        return snackbar({
          message: `Mileage Limit can not be empty for ${bp.rateTypeName} base price.`,
          variant: SnackBarVariant.ERROR
        });
      }
      if (!bp.unlimitedMileage && bp.rate && bp.mileageLimit && !bp.pricePerExtraMile) {
        return snackbar({
          message: `Price per extra mileage can not be empty for ${bp.rateTypeName} base price.`,
          variant: SnackBarVariant.ERROR
        });
      }
    }
    const updatedBp = values.basePrices.map((bp: IRate) => {
      return {
        longTerm: false,
        mileageLimit: bp.mileageLimit,
        pricePerExtraMile: bp.pricePerExtraMile,
        rate: bp.rate,
        rateTypeDuration: bp.rateTypeDuration,
        rateTypeName: bp.rateTypeName,
        unlimitedMileage: bp.unlimitedMileage ? bp.unlimitedMileage : false
      };
    });
    const slabCombinations = new Set();
    for (let index = 0; index < yieldPricingSlabs.length; index++) {
      const { startDate, endDate, yieldThreshold } = yieldPricingSlabs[index];
      const slabNumber = index + 1;
      if (!startDate) {
        return snackbar({
          message: `Start Date is required ${yieldPricingSlabs.length > 1 ? `in slab ${slabNumber}` : ''}`,
          variant: SnackBarVariant.ERROR
        });
      }
      if (!endDate) {
        return snackbar({
          message: `End Date is required ${yieldPricingSlabs.length > 1 ? `in slab ${slabNumber}` : ''}`,
          variant: SnackBarVariant.ERROR
        });
      }
      const combinationKey = `${startDate}-${endDate}-${yieldThreshold}`;
      if (slabCombinations.has(combinationKey)) {
        return snackbar({
          message: `Duplicate slabs can't be set`,
          variant: SnackBarVariant.ERROR
        });
      }
      slabCombinations.add(combinationKey);
    }
    const vehiclePriceGroupObj = {
      name: values.name.trim(),
      deposit: values.deposit,
      isActivated: values.isActivated,
      hideInB2b2c: values.hideInB2b2c ? values.hideInB2b2c : false,
      criteria: values.criteria,
      basePrices: updatedBp,
      automaticAddVehicle: values.automaticAddVehicle ? values.automaticAddVehicle : false,
      imageUrl: values.imageUrl,
      images: images,
      vehicles,
      yieldPricingEnabled: values.yieldPricingEnabled,
      yieldPriceSlabs: yieldPricingSlabs
    };
    if (props.vehicleGroup.id) {
      updateVehicleGroup({
        variables: {
          vehicleGroup: {
            ...vehiclePriceGroupObj,
            id: props.vehicleGroup.id
          }
        }
      });
    } else {
      setDisabledSave(true);
      createVehicleGroup({
        variables: {
          vehicleGroup: vehiclePriceGroupObj
        }
      });
    }
  };

  const handlePrevious = (values: IVehiclePriceGroup) => {
    props.onPrevious(values);
  };

  const handleUnlimitedMileage = (rateTypeIndex: number, checked: boolean) => {
    const selectedBasePrice = values.basePrices[rateTypeIndex];
    const updateBasePrices = values.basePrices.map((_basePrice) => {
      let basePrice = { ..._basePrice };
      if (basePrice.rateTypeName === selectedBasePrice.rateTypeName) {
        basePrice.mileageLimit = 0;
        basePrice.pricePerExtraMile = 0;
        basePrice.unlimitedMileage = checked;
        return basePrice;
      } else {
        return basePrice;
      }
    });
    setValues({ ...values, basePrices: updateBasePrices });
  };

  async function uploadVehicleImage(files: File[]) {
    try {
      if (imageUploadInProgress) {
        return snackbar({
          message: "Please wait, image upload is in progress",
          variant: SnackBarVariant.ERROR
        });
      }
      const totalImages = uploadedImages.length + files.length;
      if (totalImages > 8) {
        return snackbar({
          message: "Cannot upload more than 8 images",
          variant: SnackBarVariant.ERROR
        });
      }
      const imageKeys: IVehicleGroupImage[] = [];
      const uploadedImageKeys: IVehicleGroupImage[] = []
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const img = new Image();
        img.src = URL.createObjectURL(file);
        await new Promise<void>(resolve => {
          img.onload = () => resolve();
        });
        const aspectRatio = img.width / img.height;
        if (aspectRatio !== 5 / 3) {
          throw new Error("Aspect ratio must be 5:3");
        }
        // 5MB limit exceeded
        if (file.size > 5242880) {
          throw new Error("File size exceeded limit of 50MB");
        }
        setImageUploadInProgress(true);
        let key: string = "";
        const uniqueId = uuidv4();
        if (file.type === "image/jpeg") {
          key = `${userState.tenancy.id}/${uniqueId}-vehicle-group.jpeg`;
        } else if (file.type === "image/png") {
          key = `${userState.tenancy.id}/${uniqueId}-vehicle-group.png`;
        }

        if (_e_) {
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                setValues({
                  ...values,
                  imageUrl: key
                });
                const maxImage = images.length ? images.reduce((max, image) => image.idx > max ? image.idx : max, images[0].idx) : 0;
                imageKeys.push({
                  default: images.length + index == 0 ? true : false,
                  idx: (maxImage + 1) + index,
                  url: key,
                });
                uploadedImageKeys.push({
                  default: images.length + index == 0 ? true : false,
                  idx: (maxImage + 1) + index,
                  url: await getSignedUrl(key)
                })
                snackbar({
                  message: "Image Uploaded Successfully",
                  variant: SnackBarVariant.SUCCESS
                });
                setImageUploadOpen(false);
                setImageUploadInProgress(false)
                const combinedImages = [...images, ...imageKeys];
                const combinedUploadedImages = [...uploadedImages, ...uploadedImageKeys]
                setUploadedImages(combinedUploadedImages);
                setImages(combinedImages);
              }
            });
        }
      }
    } catch (err: any) {
      captureErrorException(err)
      setImageUploadInProgress(false);
      snackbar({
        message: formatGraphQLErrorMessage(err?.message),
        variant: SnackBarVariant.ERROR
      });
    }
  }

  const getVehicleImage = async (url: string) => {
    if (url) {
      try {
        const file = await getSignedUrl(url);
        setVehicleGroupImage(file);
      } catch (error: any) {
        captureErrorException(error)
        snackbar({
          message: error,
          variant: SnackBarVariant.ERROR
        });
      }
    }
  };

  const MobileDividerLine = () => {
    return (
      <Hidden smUp>
        <Grid item xs={12}>
          <hr
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              color: "rgba(0, 0, 0, 0.1)",
              height: 1
            }}
          />
        </Grid>
      </Hidden>
    )
  }

  const addNewSlab = () => {
    const newSlab = {
      yieldThreshold: 0,
      startDate: '',
      endDate: '',
      rules: [
        {
          rateType: RATE_TYPES.HOURLY,
          value: 0,
          valueType: ValueType.FIXED
        },
        {
          rateType: RATE_TYPES.DAILY,
          value: 0,
          valueType: ValueType.FIXED
        },
        {
          rateType: RATE_TYPES.WEEKLY,
          value: 0,
          valueType: ValueType.FIXED
        },
        {
          rateType: RATE_TYPES.MONTHLY,
          value: 0,
          valueType: ValueType.FIXED
        }
      ]
    };
    setYieldPricingSlabs((slabs) => [...slabs, newSlab]);
  }

  const deleteYieldSlab = (index: number) => {
    const slabsArr = [...yieldPricingSlabs];
    slabsArr.splice(index, 1);
    setYieldPricingSlabs(slabsArr);
  }

  const RenderSlab = (row: IYieldPriceSlabs, index: number) => {
    return <React.Fragment>
      <Paper elevation={3}>
        <Grid container spacing={2} style={{ padding: "20px" }}>
          <Grid container item justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h3">Slab Details</Typography>
            </Grid>
            <Grid item>
              <FlatPickerBar
                enableTime={false}
                handleDateChange={(value: Date) => {

                  setYieldPricingSlabs((prevSlabs) => [
                    ...prevSlabs.slice(0, index),
                    {
                      ...prevSlabs[index],
                      startDate: d.fromJSDate(value).startOf("day").toUTC().toISO(),
                      yieldThreshold: prevSlabs[index]?.yieldThreshold ?? 0,
                      rules: prevSlabs[index]?.rules ?? [],
                      endDate: prevSlabs[index]?.endDate ?? ""
                    },
                    ...prevSlabs.slice(index + 1)
                  ]);
                }}
                label={"Start Date"}
                identifier={`${yieldPricingSlabs?.[index]}.startDate`}
                placeholderValue={"Select date..."}
                value={yieldPricingSlabs?.[index]?.startDate
                  ? getLocalizedDateFormat(country, yieldPricingSlabs[index].startDate, DATE_TYPE.CONDENSED)
                  : ""
                }
                minDate={
                  yieldPricingSlabs?.[index]?.startDate &&
                    yieldPricingSlabs[index].startDate < d.now().toISO()
                    ? getLocalizedDateFormat(country, yieldPricingSlabs[index].startDate, DATE_TYPE.CONDENSED)
                    : "today"
                }
                country={country}
              />
            </Grid>
            <Grid item>
              <FlatPickerBar
                enableTime={false}
                handleDateChange={(value: Date) => {
                  setYieldPricingSlabs((prevSlabs) => [
                    ...prevSlabs.slice(0, index),
                    {
                      ...prevSlabs[index],
                      endDate: d.fromJSDate(value).startOf("day").toUTC().toISO(),
                      yieldThreshold: prevSlabs[index]?.yieldThreshold ?? 0,
                      rules: prevSlabs[index]?.rules ?? [],
                      startDate: prevSlabs[index]?.startDate ?? ""
                    },
                    ...prevSlabs.slice(index + 1)
                  ]);
                }}
                disabled={!yieldPricingSlabs?.[index]?.startDate}
                label={"End Date"}
                identifier={`${yieldPricingSlabs?.[index]}.endDate`}
                placeholderValue={"Select date..."}
                value={yieldPricingSlabs?.[index]?.endDate
                  ? getLocalizedDateFormat(country, yieldPricingSlabs[index].endDate, DATE_TYPE.CONDENSED)
                  : ""
                }
                minDate={getLocalizedDateFormat(country, d.fromISO(yieldPricingSlabs?.[index]?.startDate || "").plus({ days: 1 }).toUTC().toISO(), DATE_TYPE.CONDENSED)}
                country={country}
              />
            </Grid>
            <Grid item >
              <IconButton aria-label="delete" onClick={() => deleteYieldSlab(index)}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} md={12}>
            <Field
              component={TextField}
              placeholder="e.g 10"
              label="Vehicle Threshold (%)"
              name={"vehicleThreshold"}
              required
              type="number"
              InputProps={{
                value: row.yieldThreshold / 100,
                onChange: (
                  e: React.ChangeEvent<HTMLInputElement>
                ) => {
                  if (parseFloat(e.target.value) > 99) {
                    snackbar({
                      message: `Amount can't be more than 99%`,
                      variant: SnackBarVariant.ERROR
                    });
                  } else if (parseFloat(e.target.value) < 0) {
                    snackbar({
                      message: `Amount can't be less than 0%`,
                      variant: SnackBarVariant.ERROR
                    });
                  } else {
                    setYieldPricingSlabs(yieldPricingSlabs.map((oldSlab: IYieldPriceSlabs, i: number) => {
                      const slabCopy = structuredClone(oldSlab);
                      if (i === index) {
                        slabCopy.yieldThreshold = parseFloat(e.target.value) * 100;
                      }
                      return slabCopy;
                    }));
                  }
                },
                min: "1"
              }}
              fullWidth
            />
          </Grid>
          <Grid container item xs={12}>
            <Typography variant="h3">Rates</Typography>
          </Grid>
          {row.rules.map((rule: IYieldPriceRule, ruleIndex: number) => {
            return <Grid container item justifyContent={"space-between"} alignItems={"center"} spacing={2} key={ruleIndex}>
              <Grid container item xs={2}>
                <Typography variant="h4">{capitalize(rule.rateType)}</Typography>
              </Grid>
              <Grid container item xs={5}>
                <Field
                  component={TextField}
                  label="Type"
                  select
                  name={"valueType"}
                  fullWidth
                  required
                  value={rule.valueType}
                  InputProps={{
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      setYieldPricingSlabs(yieldPricingSlabs.map((oldSlab: IYieldPriceSlabs, i: number) => {
                        const slabCopy = structuredClone(oldSlab);
                        if (i === index) {
                          slabCopy.rules[ruleIndex].valueType = e.target.value;
                          slabCopy.rules[ruleIndex].value = 0;
                        }
                        return slabCopy;
                      }));
                    }
                  }}
                >
                  {Object.entries(ValueType).map(
                    ([key, value]) => {
                      return <MenuItem value={key}>
                        {value}
                      </MenuItem>
                    }
                  )}
                </Field>
              </Grid>
              <Grid container item xs={5}>
                {rule.valueType ===
                  ValueType.VARIABLE ? (
                  <Field
                    component={TextField}
                    placeholder="e.g 10"
                    label="Value (%)"
                    name={"value"}
                    required
                    type="number"
                    InputProps={{
                      value: rule.value / 100,
                      onChange: (
                        e: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (parseFloat(e.target.value) < 0) {
                          snackbar({
                            message: `Amount can't be less than 0%`,
                            variant: SnackBarVariant.ERROR
                          });
                        } else {
                          setYieldPricingSlabs(yieldPricingSlabs.map((oldSlab: IYieldPriceSlabs, i: number) => {
                            const slabCopy = structuredClone(oldSlab);
                            if (i === index) {
                              slabCopy.rules[ruleIndex].value = parseFloat(e.target.value) * 100;
                            }
                            return slabCopy;
                          }));
                        }
                      },
                      min: "1"
                    }}
                    fullWidth
                  />
                ) : (
                  <Field
                    component={TextField}
                    label={`Amount`}
                    name={"value"}
                    fullWidth
                    required
                    value={rule.value}
                    InputProps={{
                      inputComponent: FloatInput as any
                    }}
                    inputProps={{
                      hasCurrencyPrefix: true,
                      allowNegative: false,
                      onChange: (
                        e: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        let slab: IYieldPriceSlabs = structuredClone(yieldPricingSlabs[index]);
                        slab.rules[ruleIndex].value = parseFloat(e.target.value);
                        const slabsArr = [...yieldPricingSlabs];
                        slabsArr.splice(index, 1, slab);
                        setYieldPricingSlabs(slabsArr);
                      }
                    }}
                  />
                )}
              </Grid>
            </Grid>
          })}
        </Grid>
      </Paper>
    </React.Fragment>
  }

  return <>
    <Formik
      enableReinitialize
      initialValues={values}
      onSubmit={(values, { setSubmitting }) => {
        handleFormSubmit(values);
        setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <Form>
          <Paper variant="outlined" square style={{ padding: "30px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h2">Vehicle Pricing</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Field
                  component={TextField}
                  placeholder="Deposit"
                  label="Deposit"
                  name={"deposit"}
                  fullWidth
                  required
                  InputProps={{
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      setValues({
                        ...formikProps.values,
                        deposit: parseInt(e.target.value)
                      })
                    },
                    value: renderRates("deposit"),
                    inputComponent: FloatInput as any
                  }}
                  inputProps={{
                    hasCurrencyPrefix: true,
                    allowNegative: false
                  }}
                />
              </Grid>
              {values && values.basePrices && values.basePrices.length > 0 ? (
                values.basePrices.map((basePrice: IRate, index: number) => {
                  return (
                    <Grid container item xs={12} spacing={2} key={index}>
                      <MobileDividerLine />
                      <Grid item xs={6} sm={3}>
                        <Field
                          component={TextField}
                          placeholder=""
                          label={`${capitalize(
                            basePrice.rateTypeName
                          )} Base Price`}
                          name={`${basePrice.rateTypeName}Rate`}
                          fullWidth
                          required
                          InputProps={{
                            inputComponent: FloatInput as any,
                            onChange: (
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              updateRates(basePrice.rateTypeName, event.target.value);
                            },
                            value: basePrice.rate
                          }}
                          inputProps={{
                            hasCurrencyPrefix: true,
                            allowNegative: false
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>

                      <Hidden xsDown>
                        <Grid item xs={3}>
                          <Field
                            component={TextField}
                            placeholder="Enter Mileage Limit"
                            label={`Mileage Limit`}
                            name={`${basePrice.rateTypeName}MileageLimit`}
                            disabled={basePrice.unlimitedMileage}
                            fullWidth
                            required
                            inputProps={{
                              onChange: (
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const elementsIndex = values.basePrices.findIndex(
                                  (el: IRate) =>
                                    el.rateTypeName === basePrice.rateTypeName
                                );
                                const newArray = [...values.basePrices];
                                let tempVal = event.target.value;
                                if (event.target.value && event.target.value.length && event.target.value[0] === '0') {
                                  tempVal = event.target.value.substr(1);
                                }
                                newArray[elementsIndex] = {
                                  ...newArray[elementsIndex],
                                  mileageLimit: Number(tempVal)
                                };
                                setValues({
                                  ...values,
                                  basePrices: newArray
                                });
                              },
                              value: basePrice.mileageLimit.toString()
                            }}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <Field
                            component={TextField}
                            name={`${basePrice.rateTypeName}pricePerExtraMile`}
                            fullWidth
                            label={"Price Per Extra Mile Thereafter"}
                            disabled={basePrice.unlimitedMileage}
                            required
                            InputProps={{
                              inputComponent: FloatInput as any,
                              onChange: (
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const elementsIndex = values.basePrices.findIndex(
                                  (el: IRate) =>
                                    el.rateTypeName === basePrice.rateTypeName
                                );
                                const newArray = [...values.basePrices];
                                newArray[elementsIndex] = {
                                  ...newArray[elementsIndex],
                                  pricePerExtraMile: Number(event.target.value)
                                };
                                setValues({
                                  ...values,
                                  basePrices: newArray
                                });
                              },
                              value: basePrice.pricePerExtraMile
                            }}
                            inputProps={{
                              hasCurrencyPrefix: true,
                              allowNegative: false
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </Grid>
                      </Hidden>

                      <Grid item xs={6} sm={3}>
                        <FormControlLabel
                          value={basePrice.rateTypeName}
                          name={basePrice.rateTypeName}
                          control={
                            <Checkbox
                              checked={basePrice.unlimitedMileage}
                              onClick={(event: any) => {
                                handleUnlimitedMileage(
                                  index,
                                  event.target.checked
                                );
                              }}
                              color="secondary"
                            />
                          }
                          label={`Unlimited Mileage`}
                        />
                      </Grid>

                      <Hidden smUp>
                        {!basePrice.unlimitedMileage && (
                          <>
                            <Grid item xs={12}>
                              <Field
                                component={TextField}
                                placeholder="Enter Mileage Limit"
                                label={`Mileage Limit`}
                                name={`${basePrice.rateTypeName}MileageLimit`}
                                disabled={basePrice.unlimitedMileage}
                                fullWidth
                                required
                                inputProps={{
                                  onChange: (
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const elementsIndex = values.basePrices.findIndex(
                                      (el: IRate) =>
                                        el.rateTypeName === basePrice.rateTypeName
                                    );
                                    const newArray = [...values.basePrices];
                                    let tempVal = event.target.value;
                                    if (event.target.value && event.target.value.length && event.target.value[0] === '0') {
                                      tempVal = event.target.value.substr(1);
                                    }
                                    newArray[elementsIndex] = {
                                      ...newArray[elementsIndex],
                                      mileageLimit: Number(tempVal)
                                    };
                                    setValues({
                                      ...values,
                                      basePrices: newArray
                                    });
                                  },
                                  value: basePrice.mileageLimit.toString()
                                }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <Field
                                component={TextField}
                                name={`${basePrice.rateTypeName}pricePerExtraMile`}
                                fullWidth
                                label={"Price Per Extra Mile Thereafter"}
                                disabled={basePrice.unlimitedMileage}
                                required
                                InputProps={{
                                  inputComponent: FloatInput as any,
                                  onChange: (
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const elementsIndex = values.basePrices.findIndex(
                                      (el: IRate) =>
                                        el.rateTypeName === basePrice.rateTypeName
                                    );
                                    const newArray = [...values.basePrices];
                                    newArray[elementsIndex] = {
                                      ...newArray[elementsIndex],
                                      pricePerExtraMile: Number(event.target.value)
                                    };
                                    setValues({
                                      ...values,
                                      basePrices: newArray
                                    });
                                  },
                                  value: basePrice.pricePerExtraMile
                                }}
                                inputProps={{
                                  hasCurrencyPrefix: true,
                                  allowNegative: false
                                }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </Grid>
                          </>
                        )}
                      </Hidden>

                    </Grid>
                  );
                })
              ) : (
                <CircularProgress />
              )}

              <Grid container item xs={12} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Grid item xs={6}>
                  <Typography variant="h2">Yield Based Pricing</Typography>
                </Grid>
                <Grid container item xs={6} direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formikProps.values.yieldPricingEnabled}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setValues({
                              ...values,
                              yieldPricingEnabled: event.target.checked
                            })
                            formikProps.handleChange(event);
                          }}
                          name="yieldPricingEnabled"
                          value="yieldPricingEnabled"
                        />
                      }
                      label={`Enable`}
                    />
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" startIcon={<Add />} onClick={addNewSlab} disabled={!formikProps.values.yieldPricingEnabled}>Add New Slab</Button>
                  </Grid>
                </Grid>
              </Grid>
              {formikProps.values.yieldPricingEnabled ? (
                <Grid item container spacing={2} columns={{ xs: 1, sm: 2, md: 2 }}>
                  {yieldPricingSlabs && yieldPricingSlabs.length > 0 && yieldPricingSlabs.map((row: IYieldPriceSlabs, index: number) => (
                    <Grid container item xs={12} sm={6} md={6}>
                      {RenderSlab(row, index)}
                    </Grid>
                  ))}
                </Grid>
              ) : (<></>)}
              <Grid item xs={12}>
                <Typography variant="h2">Upload Vehicle Group Images</Typography>
                <Typography
                  variant="subtitle2"
                  style={{ color: "#5a5a5a" }}
                >
                  (Minimum Dimensions = 750x450, Aspect Ratio = 5:3). See how to{" "}
                  <a href="https://imageresizer.com" target="_blank"> resize an image </a>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={1}>
                  {renderUploadedImages()}
                </Grid>
              </Grid>
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                className={styles.modal}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={openModal}>
                  <div
                    className={styles.paper}
                    style={{
                      width: 750,
                      height: 450,
                      backgroundColor: 'white',
                      margin: 'auto',
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <IconButton
                        style={{
                        }}
                        onClick={handleCloseModal}
                      >
                        <ClearIcon />
                      </IconButton>
                    </div>
                    {images.length > 0 && selectedImageIndex !== null && images[selectedImageIndex]?.url && (
                      <img
                        src={uploadedImages[selectedImageIndex].url}
                        alt={`Vehicle Image ${selectedImageIndex + 1}`}
                        style={{ width: '100%', height: 'calc(100% - 50px)', objectFit: 'contain' }}
                      />
                    )}
                  </div>
                </Fade>
              </Modal>
              <Grid item container xs={12} spacing={1}>
                <Fab
                  className={styles.saveButtonDisabled}
                  variant="extended"
                  size="medium"
                  aria-label="Upload Vehicle Image"
                  type="button"
                  onClick={() => setImageUploadOpen(true)}
                >
                  Upload Image
                </Fab>
              </Grid>
              {isImageUploadOpen && (
                <DragDropImages
                  open={isImageUploadOpen}
                  handleCloseDragDrop={() => setImageUploadOpen(!isImageUploadOpen)}
                  handleSave={uploadVehicleImage}
                  filesLimit={8 - uploadedImages.length}
                />
              )}
            </Grid>
            <Dialog
              open={showDeleteConfirmation}
              onClose={() => handleDeleteConfirmation(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this image?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleDeleteConfirmation(false)} color="primary">
                  No
                </Button>
                <Button onClick={() => handleDeleteConfirmation(true)} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
          <Grid item xs={12}>
            <Hidden xsDown>
              <Grid container style={{ marginTop: "30px" }}>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="Back"
                  className="blackBackButton"
                  style={{ marginRight: "30px" }}
                  onClick={() => handlePrevious(values)}
                >
                  Back
                </Fab>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="Save"
                  className="createButton"
                  type="submit"
                  disabled={values.id ? false : disabledSave}
                >
                  {values.id ? "Update" : "Save"}
                </Fab>
              </Grid>
            </Hidden>
            <Hidden smUp><Box height='50px' /></Hidden>
          </Grid>
          <Hidden smUp>
            <AppBar position="fixed" className={styles.appBar}>
              <Grid container xs={12} spacing={1}>
                <Grid item xs={6}>
                  <Button
                    className={styles.bottomButton}
                    variant="contained"
                    aria-label="Back"
                    onClick={() => handlePrevious(values)}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={styles.bottomButton}
                    variant="contained"
                    aria-label="Save"
                    type="submit"
                    disabled={values.id ? false : disabledSave}
                  >
                    {values.id ? "Update" : "Save"}
                  </Button>
                </Grid>
              </Grid>
            </AppBar>
          </Hidden>
        </Form>
      )}
    </Formik>

  </>;
};
