import { useLazyQuery, useMutation, ApolloError } from "@apollo/client";
import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import BuildIcon from "@mui/icons-material/Build";
import CancelIcon from "@mui/icons-material/CancelOutlined"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RoundIcon from "@mui/icons-material/FiberManualRecord";
import { DateTime as d } from "luxon";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { GET_ASSIGNED_USER_JOBS } from "../../../../graphql/jobSchedular/getJobsQuery";
import {
  CANCEL_PENDING_ACTION,
  GET_PENDING_ACTIONS
} from "../../../../graphql/PendingActions/getPendingActions";
import { IAppState } from "../../../../store";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import styles from "./index.module.css";
import { getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { DATE_TYPE } from "../../../common/utils";
import { IBooking } from "../../../../reducers/bookings/types";
import { IDriverRow, IUser, IUserState } from "../../../../reducers/user/types";
import { IPartner } from "../../../../reducers/partners/types";

const pageStyle = {
  actionCard: {
    display: "flex",
    justifyContent: "flex-start"
  },
  iconWrapper: {
    color: "rgb(0, 87, 206)"
  },
  actionIcon: {
    fontSize: "16",
    marginRight: "10"
  },
  roundIcon: {
    fontSize: 12,
    marginLeft: -3,
    color: "green !important"
  },
  assignAction: {
    color: "green",
    fontSize: 14,
    boxShadow: "0 1px 6px rgba(32, 33, 36, 0.15)",
    cursor: "pointer",
    borderRadius: 10,
    padding: 5,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: 5
  },
  cancelAction: {
    color: "red",
    fontSize: 14,
    boxShadow: "0 1px 6px rgba(32, 33, 36, 0.15)",
    cursor: "pointer",
    borderRadius: 10,
    padding: 5,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: 5
  },
  addNoteWrapper: {
    color: "yellow",
    width: 100,
    borderRadius: 5,
    padding: 5,
    marginTop: 10,
    textAlign: "center"
  }
};

export interface IReminder {
  name: string;
  numbers: IReminderNumbers;
}

export interface IReminderNumbers {
  soon: number;
  overdue: number;
  soonIds: string[];
  overdueIds: string[];
}

export interface IUpcomingEvents {
  pickups: IVehicleEvent[];
  deliveries: IVehicleEvent[];
  returns: IVehicleEvent[];
  collections: IVehicleEvent[];
  services: IVehicleEvent[];
}
interface IVehicleEvent {
  id: string;
  licencePlate: string;
  eventTime: string;
}
enum IEventType {
  PICKUP = "PICKUP",
  DELIVERY = "DELIVERY",
  RETURN = "RETURN",
  COLLECTION = "COLLECTION",
  SERVICE = "SERVICE"
}
interface ITimelinedEventData {
  type: IEventType;
  timeNumber: number;
  data: IVehicleEvent;
}

export enum PendingActionSubCategories {
  APPROVE_REJECT_BOOKING = "APPROVE_REJECT_BOOKING",
  VEHICLE_SWAP = "VEHICLE_SWAP",
  BOOKING_EXTENSION = "BOOKING_EXTENSION",
  APPROVE_REJECT_CUSTOMER = "APPROVE_REJECT_CUSTOMER",
  VEHICLE_PICK_UP = "VEHICLE_PICK_UP",
  VEHICLE_DROP_OFF = "VEHICLE_DROP_OFF",
  VEHICLE_SERVICE_PICK_UP = "VEHICLE_SERVICE_PICK_UP",
  VEHICLE_SERVICE_DROP_OFF = "VEHICLE_SERVICE_DROP_OFF",
  COURTSEY_BOOKING = "COURTSEY_BOOKING"
}
export interface IPendingAction {
  booking: IBooking;
  category: string;
  subCategory: string;
  id: string;
  status: string;
  dateCreated: string;
  actionDate: string;
  dateCompleted: string;
  contentId: string;
  description: string;
  partner?: IPartner;
  assignedTo?: IUserState;
}

const UpcomingEvents = ({
  events,
  reminders,
  action
}: {
  events?: IUpcomingEvents;
  reminders: IReminder[];
  action: "pendingAction";
}) => {
  const navigate = useNavigate();
  const [tab, setTab] = React.useState(0);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [timelinedData, setTimelinedData] = useState<ITimelinedEventData[]>([]);
  const [_reminders, setReminders] = useState([...reminders]);
  const [pendingActions, setPendingActions] = useState<IPendingAction[]>([]);
  const [userJobs, setUserJobs] = useState([]);
  const snackbar = useSnackBar();

  const [loadPendingActions, { loading: loadingPendingActions, data: pendingActionsData }] = useLazyQuery(GET_PENDING_ACTIONS, {
    fetchPolicy: "network-only"
  });

  const [loadJobs, { data: userJobData }] = useLazyQuery(
    GET_ASSIGNED_USER_JOBS,
    {
      fetchPolicy: "network-only"
    }
  );

  const [cancelAction, { data: loadCancel }] = useMutation(
    CANCEL_PENDING_ACTION,
    {
      onCompleted: (data: any) => {
        if (data.cancelPendingAction.success) {
          loadPendingActions();
          snackbar({
            message: data.cancelPendingAction.message,
            variant: SnackBarVariant.SUCCESS
          });
        }
      }
    }
  );

  useEffect(() => {
    loadPendingActions();
    loadJobs();
  }, []);

  useEffect(() => {
    if (pendingActionsData && pendingActionsData.pendingActions) {
      let obj: any = [];
      let currentDateTime = d.now();
      let timeDifference;
      pendingActionsData.pendingActions.forEach((element: any) => {
        if (
          element.subCategory ===
          PendingActionSubCategories.VEHICLE_SERVICE_DROP_OFF
        ) {
          if (!obj.hasOwnProperty("vehicle_service_drop_off")) {
            obj.vehicle_service_drop_off = [];
          }
          timeDifference = d
            .fromISO(element.actionDate)
            .diff(currentDateTime, "hour").hours;
          obj.vehicle_service_drop_off.push({
            ...element,
            timeDuration: timeDifference
          });

        }
        else if (
          element.subCategory ===
          PendingActionSubCategories.APPROVE_REJECT_BOOKING
        ) {
          if (!obj.hasOwnProperty("booking_approval")) {
            obj.booking_approval = [];
          }
          timeDifference = d
            .fromISO(element.actionDate)
            .diff(currentDateTime, "hour").hours;
          obj.booking_approval.push({
            ...element,
            timeDuration: timeDifference
          });

        } else if (
          element.subCategory === PendingActionSubCategories.VEHICLE_DROP_OFF
        ) {
          if (!obj.hasOwnProperty("vehicle_drop_off")) {
            obj.vehicle_drop_off = [];
          }
          timeDifference = d
            .fromISO(element.actionDate)
            .diff(currentDateTime, "hour").hours;
          obj.vehicle_drop_off.push({
            ...element,
            timeDuration: timeDifference
          });

        } else if (
          element.subCategory === PendingActionSubCategories.COURTSEY_BOOKING
        ) {
          if (!obj.hasOwnProperty("courtesy_booking")) {
            obj.courtesy_booking = [];
          }
          timeDifference = d
            .fromISO(element.actionDate)
            .diff(currentDateTime, "hour").hours;
          obj.courtesy_booking.push({
            ...element,
            timeDuration: timeDifference
          });

        } else if (
          element.subCategory === PendingActionSubCategories.VEHICLE_SWAP
        ) {
          if (!obj.hasOwnProperty("vehicle_swap")) {
            obj.vehicle_swap = [];
          }
          timeDifference = d
            .fromISO(element.actionDate)
            .diff(currentDateTime, "hour").hours;
          obj.vehicle_swap.push({
            ...element,
            timeDuration: timeDifference
          });
        } else {
          obj.unknown_action = [];
          obj.unknown_action.push(element);
        }
      });
      obj = Object.entries(obj);
      obj.sort((a: any, b: any) => b[1].length - a[1].length);
      obj.map((key: any) => {
        key[1].sort((a: any, b: any) => a.timeDuration - b.timeDuration);
      });
      setPendingActions(obj);
    }
  }, [pendingActionsData])
  interface Entry {
    id: string;
    subCategory: string;
  }

  const subCategoryObjects: Record<string, Entry[]> = {};

  const organisation = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;

  useEffect(() => {
    if (userJobData && userJobData.userJobDashboardData) {
      userJobData.userJobDashboardData.forEach((entry: Entry) => {
        const { subCategory } = entry;
        if (!subCategoryObjects[subCategory]) {
          subCategoryObjects[subCategory] = [];
        }
        subCategoryObjects[subCategory].push(entry);
      });
      const obj: any = Object.entries(subCategoryObjects);
      obj.sort((a: any, b: any) => b[1].length - a[1].length);
      setUserJobs(obj);
    }
  }, [userJobData]);

  useEffect(() => {
    loadPendingActions();
    if (events && events.pickups) {
      const formattedData = [
        ...events.pickups.map((pickup) => ({
          type: IEventType.PICKUP,
          timeNumber:
            d.fromISO(pickup.eventTime).diff(d.now().startOf("day"), "minute")
              .minutes / 60,
          data: pickup
        })),
        ...events.deliveries.map((event) => ({
          type: IEventType.DELIVERY,
          timeNumber:
            d.fromISO(event.eventTime).diff(d.now().startOf("day"), "minute")
              .minutes / 60,
          data: event
        })),
        ...events.returns.map((rtrn) => ({
          type: IEventType.RETURN,
          timeNumber:
            d.fromISO(rtrn.eventTime).diff(d.now().startOf("day"), "minute")
              .minutes / 60,
          data: rtrn
        })),
        ...events.collections.map((event) => ({
          type: IEventType.COLLECTION,
          timeNumber:
            d.fromISO(event.eventTime).diff(d.now().startOf("day"), "minute")
              .minutes / 60,
          data: event
        })),
        ...events.services.map((service) => ({
          type: IEventType.SERVICE,
          timeNumber:
            d.fromISO(service.eventTime).diff(d.now().startOf("day"), "minute")
              .minutes / 60,
          data: service
        }))
      ];
      formattedData.sort(function (a, b) {
        return a.timeNumber - b.timeNumber;
      });
      setTimelinedData(formattedData);
    }
  }, [events]);

  useEffect(() => {
    if (userState.currentOrganisation.address.country === "United States") {
      const updatedReminders = _reminders.filter(
        (reminder) => reminder.name !== "MOT Test" && reminder.name !== "Vehicle Tax"
      );
      setReminders(updatedReminders);
    }
  }, [userState]);

  const redirectToActionPage = (key: any) => {
    if (
      key.subCategory === PendingActionSubCategories.VEHICLE_SERVICE_DROP_OFF
    ) {
      navigate(`/new-services-and-repair?id=${key.contentId}`);
    } else if (
      key.subCategory === PendingActionSubCategories.APPROVE_REJECT_BOOKING
    ) {
      if (userState.role !== "FINANCE_MANAGER") {
        navigate(`/view-booking?booking=${key.contentId}`);
      }
    } else if (key.subCategory === PendingActionSubCategories.VEHICLE_SWAP) {
      navigate(`view-booking?booking=${key.contentId}`);
    } else if (
      key.subCategory === PendingActionSubCategories.VEHICLE_DROP_OFF
    ) {
      if (userState.role !== "FINANCE_MANAGER") {
        // history.push(`view-booking?booking=${key.contentId}`)
      }
    }
  };

  const redirectToJobPage = (actionId: any) => {
    navigate(`/user-job?pendingAction=${actionId}`);
  };

  const redirectToJob = (jobId: any) => {
    navigate(`/user-job?jobId=${jobId}`);
  };

  const trimDescription = (description: string) => {
    const MAX_LENGTH = 70;
    let trimmedDescription = description;
    if (description.length > MAX_LENGTH) {
      trimmedDescription = description.slice(0, MAX_LENGTH - 3) + "...";
    }
    return trimmedDescription;
  };

  const cancelPendingAction = (id: string) => {
    cancelAction({
      variables: {
        id: id ? id : ""
      }
    });
  };
  return (
    <div className={styles.rightColumn}>
      <div className="flex cross-center">
        <div className="flex fill margin-left">
          <div
            onClick={() => setTab(0)}
            className={`${styles.tab} ${tab === 0 ? styles.active : ""}`}
          >
            <Typography variant="body1" className={`${styles.label} bold`}>
              Pending
            </Typography>
            <div className={styles.indicator}></div>
          </div>
          <div
            onClick={() => setTab(1)}
            className={`${styles.tab} ${tab === 1 ? styles.active : ""}`}
          >
            <Typography variant="body1" className={`${styles.label} bold`}>
              Reminders
            </Typography>
            <div className={styles.indicator}></div>
          </div>
          <div
            onClick={() => setTab(2)}
            className={`${styles.tab} ${tab === 2 ? styles.active : ""}`}
          >
            <Typography variant="body1" className={`${styles.label} bold`}>
              My Tasks
            </Typography>
            <div className={styles.indicator}></div>
          </div>
        </div>
      </div>
      {tab === 0 && (
        <div className={styles.list}>
          {pendingActions && pendingActions.length ? (
            pendingActions.map((key: any) => {
              return (
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <Accordion style={{ marginBottom: 20, marginTop: 20 }}>
                    <AccordionSummary
                      style={{ margin: "0 !important" }}
                      expandIcon={
                        <ExpandMoreIcon style={{ padding: "0 !important" }} />
                      }
                    >
                      <Grid
                        container
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Grid item xs={12}>
                          <Typography variant={"h4"}>
                            {key[0] === "vehicle_service_drop_off" ? (
                              <span style={pageStyle.actionCard}>
                                {" "}
                                <div style={pageStyle.iconWrapper}>
                                  {" "}
                                  <BuildIcon
                                    style={pageStyle.actionIcon}
                                  />{" "}
                                </div>{" "}
                                Service & Repair{" "}
                              </span>
                            ) : key[0] === "booking_approval" ? (
                              <span style={pageStyle.actionCard}>
                                {" "}
                                <div style={pageStyle.iconWrapper}>
                                  {" "}
                                  <CheckCircleOutlineIcon
                                    style={pageStyle.actionIcon}
                                  />{" "}
                                </div>{" "}
                                Booking Approvals{" "}
                              </span>
                            ) : key[0] === "vehicle_drop_off" ? (
                              <span style={pageStyle.actionCard}>
                                {" "}
                                <div style={pageStyle.iconWrapper}>
                                  {" "}
                                  <DirectionsCarIcon
                                    style={pageStyle.actionIcon}
                                  />{" "}
                                </div>{" "}
                                Pick-ups{" "}
                              </span>
                            ) : key[0] === "vehicle_swap" ? (
                              <span style={pageStyle.actionCard}>
                                {" "}
                                <div style={pageStyle.iconWrapper}>
                                  {" "}
                                  <DirectionsCarIcon
                                    style={pageStyle.actionIcon}
                                  />{" "}
                                </div>{" "}
                                Swap Requests{" "}
                              </span>
                            ) : key[0] === "courtesy_booking" ? (
                              <span style={pageStyle.actionCard}>
                                {" "}
                                <div style={pageStyle.iconWrapper}>
                                  {" "}
                                  <DirectionsCarIcon
                                    style={pageStyle.actionIcon}
                                  />{" "}
                                </div>{" "}
                                Courtesy Booking{" "}
                              </span>
                            ) : (
                              "Unknown Action"
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <p> {key[1].length} </p>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ display: "block", marginTop: 0 }}
                    >
                      {key[1] && key[1].length > 0
                        ? key[1].map((k: any) => {
                          return (
                            <Grid item xs={12} className={styles.item}>
                              <Grid
                                item
                                xs={12}
                                onClick={() => redirectToActionPage(k)}
                              >
                                <Typography
                                  style={{ marginBottom: 5 }}
                                  variant={"h4"}
                                >
                                  <span style={{ color: "red" }}>
                                    <RoundIcon
                                      color="inherit"
                                      style={pageStyle.roundIcon}
                                    />
                                  </span>
                                  <span> {k.description} </span>
                                </Typography>
                                <Typography>
                                  {k.booking && (
                                    <span>
                                      {" "}
                                      Booking Ref: {
                                        k.booking.referenceNumber
                                      }{" "}
                                    </span>
                                  )}
                                  {k.vehicle && (
                                    <span>
                                      {" "}
                                      VRN: {k.vehicle.licencePlate}{" "}
                                    </span>
                                  )}
                                </Typography>
                                <Typography style={{ marginTop: 10 }}>
                                  <span>
                                    Action date:{" "}
                                    {
                                      getLocalizedDateFormat(country, k.actionDate, DATE_TYPE.EXPANDED)
                                    }
                                  </span>
                                </Typography>
                                <Typography
                                  className={styles.tag}
                                  style={{ marginTop: 10 }}
                                >
                                  {k.timeDuration <= 0 ? (
                                    <span>
                                      {" "}
                                      Overdue by : {Math.abs(
                                        Math.floor(k.timeDuration)
                                      )}{" "}
                                      Hours
                                    </span>
                                  ) : (
                                    <span>
                                      {" "}
                                      Time Remaining : {
                                        Math.floor(k.timeDuration)
                                      } Hours{" "}
                                    </span>
                                  )}
                                </Typography>
                              </Grid>
                              <Typography
                                className={styles.css}
                                style={{
                                  marginTop: 10,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center"
                                }}
                              >
                                <p
                                  style={pageStyle.assignAction}
                                  onClick={() => redirectToJobPage(k.id)}
                                >
                                  <CheckCircleOutlineIcon
                                    color="inherit"
                                    style={{ fontSize: 16, marginRight: 5 }}
                                  />{" "}
                                  Assign
                                </p>
                                <p
                                  style={pageStyle.cancelAction}
                                  onClick={() => cancelPendingAction(k.id)}
                                >
                                  <CancelIcon
                                    color="inherit"
                                    style={{ fontSize: 16, marginRight: 5 }}
                                  />{" "}
                                  Cancel
                                </p>
                              </Typography>
                            </Grid>
                          );
                        })
                        : ""}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })
          ) : (
            <Typography
              variant="h3"
              style={{ textAlign: "center", margin: 30 }}
            >
              No Action found
            </Typography>
          )}
        </div>
      )}
      {tab === 1 && (
        <div className={styles.list}>
          {_reminders.length ? (
            _reminders.map((reminder, index) => {
              const redirectLink =
                reminder.name === "Driving Licence"
                  ? "/individual-customer?drivingLicense"
                  : `/vehicle-inventory?filter=${reminder.name}&documentstatus`;
              return (
                <div onClick={() => { }} className={styles.item} key={index}>
                  <div className="flex cross-center">
                    <Typography variant="body1">{reminder.name}</Typography>
                  </div>
                  <div className="flex cross-center">
                    <div
                      onClick={() => {
                        if (reminder.numbers.overdue) {
                          navigate(`${redirectLink}=overdue`, { state: reminder.numbers.overdueIds })
                        }
                      }}
                      className={styles.tag}
                      style={{ cursor: reminder.numbers.overdue ? "pointer" : "not-allowed" }}
                    >
                      Overdue: {reminder.numbers.overdue}
                    </div>
                    <div
                      onClick={() => {
                        if (reminder.numbers.soon) {
                          navigate(`${redirectLink}=soon`, { state: reminder.numbers.soonIds })
                        }
                      }}
                      className={`${styles.tag} ${styles.soon}`}
                      style={{ cursor: reminder.numbers.soon ? "pointer" : "not-allowed" }}
                    >
                      Soon: {reminder.numbers.soon}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <Typography variant="body1" className="flex main-center margin-top">
              No Reminders for today
            </Typography>
          )}
        </div>
      )}

      {tab === 2 && (
        <div className={styles.list}>
          {userJobs && userJobs.length ? (
            userJobs.map((key: any) => {
              return (
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <Accordion style={{ marginBottom: 20, marginTop: 20 }}>
                    <AccordionSummary
                      style={{ margin: 0 }}
                      expandIcon={
                        <ExpandMoreIcon style={{ padding: "0 !important" }} />
                      }
                    >
                      <Grid
                        container
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Grid item xs={12}>
                          <Typography variant={"h4"}>
                            {key[0] === "VEHICLE_CLEANING" ? (
                              <span style={pageStyle.actionCard}>
                                {" "}
                                <div style={pageStyle.iconWrapper}>
                                  <DirectionsCarIcon
                                    style={pageStyle.actionIcon}
                                  />
                                </div>{" "}
                                Vehicle Cleaning
                              </span>
                            ) : key[0] === "VEHICLE_FUELING" ? (
                              <span style={pageStyle.actionCard}>
                                {" "}
                                <div style={pageStyle.iconWrapper}>
                                  <DirectionsCarIcon
                                    style={pageStyle.actionIcon}
                                  />
                                </div>{" "}
                                Vehicle Fueling
                              </span>
                            ) : key[0] === "VEHICLE_SERVICE_PICK_UP" ? (
                              <span style={pageStyle.actionCard}>
                                {" "}
                                <div style={pageStyle.iconWrapper}>
                                  <DirectionsCarIcon
                                    style={pageStyle.actionIcon}
                                  />
                                </div>{" "}
                                Vehicle Service Pickup
                              </span>
                            ) : key[0] === "VEHICLE_SERVICE_DROP_OFF" ? (
                              <span style={pageStyle.actionCard}>
                                {" "}
                                <div style={pageStyle.iconWrapper}>
                                  <DirectionsCarIcon
                                    style={pageStyle.actionIcon}
                                  />
                                </div>{" "}
                                Vehicle Service Drop Off
                              </span>
                            ) : key[0] === "VEHICLE_DROP_OFF" ? (
                              <span style={pageStyle.actionCard}>
                                {" "}
                                <div style={pageStyle.iconWrapper}>
                                  <DirectionsCarIcon
                                    style={pageStyle.actionIcon}
                                  />
                                </div>{" "}
                                Vehicle Drop Off
                              </span>
                            ) : key[0] === "VEHICLE_PICK_UP" ? (
                              <span style={pageStyle.actionCard}>
                                {" "}
                                <div style={pageStyle.iconWrapper}>
                                  <DirectionsCarIcon
                                    style={pageStyle.actionIcon}
                                  />
                                </div>{" "}
                                Vehicle Pickup
                              </span>
                            ) : key[0] === "BOOKING_EXTENSION" ? (
                              <span style={pageStyle.actionCard}>
                                {" "}
                                <div style={pageStyle.iconWrapper}>
                                  <CheckCircleOutlineIcon
                                    style={pageStyle.actionIcon}
                                  />
                                </div>{" "}
                                Booking Extension
                              </span>
                            ) : key[0] === "EARLY_TERMINATION" ? (
                              <span style={pageStyle.actionCard}>
                                {" "}
                                <div style={pageStyle.iconWrapper}>
                                  <DirectionsCarIcon
                                    style={pageStyle.actionIcon}
                                  />
                                </div>{" "}
                                Early Termination
                              </span>
                            ) : key[0] === "VEHICLE_SWAP" ? (
                              <span style={pageStyle.actionCard}>
                                {" "}
                                <div style={pageStyle.iconWrapper}>
                                  <DirectionsCarIcon
                                    style={pageStyle.actionIcon}
                                  />
                                </div>{" "}
                                Vehicle Swap
                              </span>
                            ) : key[0] === "VEHICLE_CHECK" ? (
                              <span style={pageStyle.actionCard}>
                                {" "}
                                <div style={pageStyle.iconWrapper}>
                                  <DirectionsCarIcon
                                    style={pageStyle.actionIcon}
                                  />
                                </div>{" "}
                                Vehicle Check
                              </span>
                            ) : key[0] === "APPROVE_REJECT_BOOKING" ? (
                              <span style={pageStyle.actionCard}>
                                {" "}
                                <div style={pageStyle.iconWrapper}>
                                  <CheckCircleOutlineIcon
                                    style={pageStyle.actionIcon}
                                  />
                                </div>{" "}
                                Booking
                              </span>
                            ) : key[0] === "APPROVE_REJECT_CUSTOMER" ? (
                              <span style={pageStyle.actionCard}>
                                {" "}
                                <div style={pageStyle.iconWrapper}>
                                  <CheckCircleOutlineIcon
                                    style={pageStyle.actionIcon}
                                  />
                                </div>{" "}
                                Customer
                              </span>
                            ) : (
                              <span style={pageStyle.actionCard}>
                                {" "}
                                <div style={pageStyle.iconWrapper}>
                                  <DirectionsCarIcon
                                    style={pageStyle.actionIcon}
                                  />
                                </div>{" "}
                                Others
                              </span>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <p> {key[1].length} </p>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        display: "block",
                        marginTop: 0,
                        paddingTop: 0,
                        paddingBottom: 10
                      }}
                    >
                      {key[1] && key[1].length > 0
                        ? key[1].map((k: any) => {
                          return (
                            <Grid item xs={12} className={styles.item}>
                              <Grid
                                item
                                xs={12}
                                onClick={() => redirectToJob(k.id)}
                              >
                                <Typography variant={"h4"}>
                                  <span style={{ color: "red" }}>
                                    <RoundIcon
                                      color="inherit"
                                      style={pageStyle.roundIcon}
                                    />
                                  </span>
                                  {k?.vehicle?.licencePlate ? (
                                    <span>
                                      License Plate:{" "}
                                      <span className={styles.tag}>
                                        {" "}
                                        {k.vehicle.licencePlate}{" "}
                                      </span>
                                    </span>
                                  ) : (
                                    <span> License Plate: NA </span>
                                  )}
                                </Typography>
                                {k.description ? (
                                  <Typography
                                    style={{ marginBottom: 5, marginTop: 10 }}
                                    variant={"h4"}
                                  >
                                    <span style={{ color: "red" }}></span>
                                    <span>
                                      {" "}
                                      {trimDescription(k.description)}{" "}
                                    </span>
                                  </Typography>
                                ) : (
                                  ""
                                )}
                                <Typography style={{ marginTop: 10 }}>
                                  <span>
                                    Job date:{" "}
                                    {
                                      getLocalizedDateFormat(country, k.jobDate, DATE_TYPE.EXPANDED)
                                    }
                                  </span>
                                </Typography>
                                <Typography
                                  className={styles.tag}
                                  style={{ marginTop: 10 }}
                                >
                                  <span>
                                    {" "}
                                    Assigned To : {
                                      k.assignedTo?.firstName
                                    }{" "}
                                    {k.assignedTo?.lastName}{" "}
                                  </span>
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        })
                        : ""}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })
          ) : (
            <Typography
              variant="h3"
              style={{ textAlign: "center", margin: 30 }}
            >
              No Task found
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default UpcomingEvents;
