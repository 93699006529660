import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  CssBaseline,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Theme,
  Tooltip,
  Typography,
  Hidden
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { CREATE_ADDON_TYPE } from "../../../../../graphql/addOns/createAddonMutation";
import { GET_ADDON_TYPE } from "../../../../../graphql/addOns/getAddonQuery";
import { UPDATE_ADDON_TYPE } from "../../../../../graphql/addOns/updateAddonMutation";
import {
  AddonPayloadType,
  AddonTypes,
  IAddonType,
  IRange
} from "../../../../../reducers/addonType/types";
import { IAppState } from "../../../../../store";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { capitalizeFirstLetter } from "../../../ReservationManagement/utils";
import { category, getRateType, subCategories } from "./utils";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { captureErrorException } from "../../../../../utils/sentry";
import { UPDATE_ADDON_TYPE_STATUS } from "../../../../../graphql/addOns/updateAddonTypeStatusMutation";
import { addonInitialState } from "../../../../../reducers/addonType/consts";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(3),
      flexGrow: 1
    },
    iconStyle: {
      padding: 2
    },
    avatarStyle: {
      background: "var(--theme-accent)",
      height: 25,
      width: 25
    },
    ruleCardMain: {
      position: "relative"
    },
    section: {
      marginBottom: "25px"
    }
  })
);

interface IProps {
  addonType: IAddonType;
  clearAddonType(): void;
  updateAddonType(payload: AddonPayloadType): void;
}

enum AddonCategory {
  Service = "SERVICE",
  Product = "PRODUCT"
}

const Addons: React.FC = () => {

  const classes = useStyles();
  const location = useLocation();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);

  const [isNewAddon, setIsNewAddon] = useState<boolean>(true);
  const [values, setValues] = useState<IAddonType>(_.cloneDeep(addonInitialState.addonType));

  const [loadAddonType, { loading: loadingAddonType, data }] = useLazyQuery(
    GET_ADDON_TYPE,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (!data.addonType) {
          navigate("/addons");
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [
    createAddonTypeMutation,
    { loading: createAddonTypeLoading }
  ] = useMutation(CREATE_ADDON_TYPE, {
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      navigate("/addons");
    },
    onCompleted: () => {
      snackbar({
        message: formatGraphQLErrorMessage("Add on created"),
        variant: SnackBarVariant.SUCCESS
      });
    }
  });

  const [updateAddonTypeMutation, { loading: addonUpdateLoading }] = useMutation(
    UPDATE_ADDON_TYPE,
    {
      onCompleted: (data) => {
        if (data && data.updateAddonType) {
          setValues({ ...values, ...data.updateAddonType })
        }
        snackbar({
          message: "Add on successfully updated",
          variant: SnackBarVariant.SUCCESS
        })
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [updateAddonTypeStatusMutation, { loading: addonUpdateStatusLoading }] = useMutation(
    UPDATE_ADDON_TYPE_STATUS,
    {
      onCompleted: (data) => {
        if (data && data.updateAddonTypeStatus) {
          setValues({ ...values, ...data.updateAddonTypeStatus })
        }
        snackbar({
          message: "Add-on status successfully updated",
          variant: SnackBarVariant.SUCCESS
        })
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const addonId = params.get("addon");
      if (addonId) {
        setIsNewAddon(false);
        loadAddonType({
          variables: {
            id: addonId
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (data && data.addonType) {
      setValues(data.addonType);
    }
  }, [data]);

  const createAddonType = (addonTypeInput: IAddonType) => {
    const addonType = addonTypeInput;
    delete addonType.availableQuantity;
    delete addonType.id;
    createAddonTypeMutation({
      variables: {
        addonType
      }
    });
  }

  const _updateAddonType = (addonTypeInput: IAddonType) => {
    const addonType = addonTypeInput;
    delete addonType.availableQuantity;
    updateAddonTypeMutation({
      variables: {
        addonType
      }
    });
  }

  const updateAddonTypeStatus = (id: string, isActivated: boolean) => {
    updateAddonTypeStatusMutation({
      variables: {
        id,
        isActivated
      }
    })
  }

  const handleFormSubmit = () => {
    if (values.tax && !values.tax.type) {
      values.tax.type = "PERCENTAGE";
    }
    if (values.hasFixedRate) {
      if (!values.fixedRate) {
        values.fixedRate = 0;
      }
    } else {
      delete values.fixedRate;
    }
    if (values.id === "") {
      delete values.id;
      createAddonType(values);
    } else {
      _updateAddonType(values);
    }
  }


  const addRuleToTrigger = () => {
    if (values && values?.rules?.length) {
      const lastRule = values.rules[values.rules.length - 1];
      setValues({
        ...values,
        rules: [
          ...(values.rules || []),
          {
            from: lastRule.to + 1,
            to: lastRule.to + 1,
            amount: 0,
          }
        ]
      });
    } else {
      setValues({
        ...values,
        rules: [
          ...(values.rules || []),
          {
            from: 0,
            to: 1,
            amount: 0,
          }
        ]
      });
    }
  };

  const onChangeValueTypefrom = (event: React.ChangeEvent<HTMLInputElement>, ruleIndex: number) => {
    setValues((prevValues) => {
      const inputValue = event.target.value.trim() !== '' ? parseInt(event.target.value) : 0;
      const updatedRules = prevValues.rules?.map((rule, index) => {
        if (index === ruleIndex) {
          return {
            ...rule,
            from: inputValue,
          };
        }
        return rule;
      });
      return {
        ...prevValues,
        rules: updatedRules || [],
      };
    });
  };

  const onChangeValueTypeto = (event: React.ChangeEvent<HTMLInputElement>, ruleIndex: number) => {
    const inputValue = parseFloat(event?.target?.value);
    setValues((prevValues) => {
      const updatedRules = prevValues.rules?.map((rule, index) => {
        if (index === ruleIndex) {
          return {
            ...rule,
            to: inputValue ? inputValue : 0,
          };
        }
        return rule;
      });
      return {
        ...prevValues,
        rules: updatedRules || [],
      };
    });
  };

  useEffect(() => {
    if (values && values.rules && values.rules.length > 0) {
      let x = values.rules[0].to;
      let updatedRules = [...values.rules];
      let isFalse = false;
      for (let i = 1; i < values.rules.length; i++) {
        if (updatedRules[i].from != updatedRules[i - 1].to + 1) {
          updatedRules[i] = {
            ...updatedRules[i],
            from: updatedRules[i - 1].to + 1,
            to: updatedRules[i - 1].to + 1
          }
          isFalse = true;
        }
      }
      if (isFalse) {
        setValues({
          ...values,
          rules: updatedRules,
        });
      }
    }
  }, [values])

  const onChangeValueTypeAmount = (event: React.ChangeEvent<HTMLInputElement>, ruleIndex: number) => {
    setValues((prevValues) => {
      const inputValue = event.target.value !== '' ? parseInt(event.target.value) : 0;
      const updatedRules = prevValues.rules?.map((rule, index) => {
        if (index === ruleIndex) {
          return {
            ...rule,
            amount: inputValue,
          };
        }
        return rule;
      });
      return {
        ...prevValues,
        rules: updatedRules || [],
      };
    });
  };


  const removeRuleFromTrigger = (ruleIndex: number) => {
    setValues({
      ...values,
      rules: [
        ...(values.rules?.slice(0, ruleIndex) || []),
        ...(values.rules?.slice(ruleIndex + 1) || [])
      ]
    })
  }

  const totalQuantityValidation = () =>
    values.category === AddonTypes.PRODUCT &&
      values.name !== "ADDITIONAL_DRIVER"
      ? {
        totalQuantity: Yup.string()
          .min(1)
          .required("Quantity is required.")
          .nullable()
      }
      : {};

  const addonSchema = Yup.object().shape({
    ...totalQuantityValidation,
    displayName: Yup.string()
      .min(3)
      .required("Name is required."),
    name: Yup.string().required("Please select Sub-Category."),
    description: Yup.string()
      .min(5)
      .required("Description is required."),
    taxable: Yup.boolean().nullable(),
    tax: Yup.object()
      .shape({
        title: Yup.string().when("taxable", {
          is: true,
          then: schema => schema.required("Title is required."),
          otherwise: schema => schema.nullable()
        }),
        value: Yup.number().when("taxable", {
          is: true,
          then: schema => schema
            .min(1, "Tax value can not be zero.")
            .required("Tax value is required."),
          otherwise: schema => schema.nullable()
        })
      })
      .nullable()
  });

  if (loadingAddonType) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6} alignItems="center">
        <Typography variant="h1" color="primary">
          Add-ons
        </Typography>
        <Box color="white" sx={{ pr: 1 }}></Box>
        <DoubleArrowIcon />
        <Box color="white" sx={{ pl: 1 }}></Box>
        {!isNewAddon ? (
          <Typography variant="h1" color="primary">
            Update
          </Typography>
        ) : (
          <Typography variant="h1" color="primary">
            New
          </Typography>
        )
        }
        <Box color="white" sx={{ pl: 1 }}></Box>
        < Typography variant="h1" color="primary" >
          Add-on
        </Typography >
      </Grid >
      {
        values.name !== "ADDITIONAL_DRIVER" && (
          <Grid container item xs={6} justifyContent={"flex-end"}>
            {
              !addonUpdateStatusLoading ? (
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isActivated}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setValues({
                          ...values,
                          isActivated: event.target.checked
                        });
                        if (values.id) {
                          updateAddonTypeStatus(values.id, event.target.checked)
                        }
                      }}
                      name="isActivated"
                      color="primary"
                    />
                  }
                  label={!values.isActivated ? "Activate" : "Active"}
                />
              ) : (
                <CircularProgress />
              )
            }
          </Grid>
        )
      }
      < Grid container item xs={12} >
        <Paper className={classes.root}>
          <Formik
            enableReinitialize
            validationSchema={addonSchema}
            initialValues={values}
            onSubmit={(values, { setSubmitting }) => {
              if (values && values.rules && values.rules.length > 0) {
                for (let index = 0; index < values.rules.length; index++) {
                  const rule = values.rules[index];
                  if (rule && rule.to == 0) {
                    return snackbar({
                      message: formatGraphQLErrorMessage("Enter value of to price rule greater than 0"),
                      variant: SnackBarVariant.ERROR
                    })
                  }
                  if (rule && rule.to < rule.from) {
                    return snackbar({
                      message: formatGraphQLErrorMessage("Price Rule to value should be greater than Price Rule from value"),
                      variant: SnackBarVariant.ERROR
                    })
                  }
                }
                handleFormSubmit();
              }
              else {
                handleFormSubmit();
              }
              setSubmitting(false);
            }}
          >
            {() => (
              <Form>
                <Grid container className={classes.section} spacing={2}>
                  <Grid item container xs={12}>
                    <Typography variant="h2">
                      {isNewAddon ? "CREATE NEW" : "UPDATE"} ADD-ON
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={6} sm={3}>
                      <Field
                        component={TextField}
                        placeholder="Name"
                        label="Name"
                        name={"displayName"}
                        InputProps={{
                          onChange: (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setValues({
                              ...values,
                              displayName: event.target.value
                            });
                          },
                          value: values.displayName
                        }}
                        inputProps={{ maxLength: 35 }}
                        fullWidth
                        required
                      ></Field>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={TextField}
                          name={"category"}
                          fullWidth
                          type="text"
                          select
                          required
                          label="Category"
                          value={values.category}
                          InputLabelProps={{
                            shrink: true
                          }}
                          disabled={values.id}
                          InputProps={{
                            onChange: (
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const isServiceType = event.target.value.includes(
                                AddonCategory.Service
                              );
                              setValues({
                                ...values,
                                [event.target.name]: event.target.value,
                                name: "",
                                hasQuantity: isServiceType,
                                totalQuantity: isServiceType
                                  ? 0
                                  : values.totalQuantity,
                                availableQuantity: isServiceType
                                  ? 0
                                  : values.availableQuantity
                              });
                            },
                            value: values.category
                          }}
                        >
                          {category.map((item: any, index: number) => {
                            return (
                              <MenuItem key={index} value={item.value}>
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={TextField}
                          name={"name"}
                          fullWidth
                          type="text"
                          select
                          required
                          label="Sub Category"
                          InputLabelProps={{
                            shrink: true
                          }}
                          disabled={values.id}
                          InputProps={{
                            onChange: (
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setValues({
                                ...values,
                                [event.target.name]: event.target.value
                              });
                            },
                            value: values.name
                          }}
                        >
                          {subCategories.map((item: any, index: number) => {
                            if (item.type.includes(values.category)) {
                              return (
                                <MenuItem
                                  key={index}
                                  value={item.value}
                                  disabled={
                                    item.value === "ADDITIONAL_DRIVER"
                                  }
                                >
                                  {item.label}
                                </MenuItem>
                              );
                            }
                          })}
                        </Field>
                      </FormControl>
                    </Grid>
                    {values.category === AddonCategory.Product &&
                      values.name !== "ADDITIONAL_DRIVER" && (
                        <Grid item xs={6} sm={3}>
                          <Field
                            component={TextField}
                            fullWidth
                            placeholder="Quantity"
                            label="Quantity"
                            name={"totalQuantity"}
                            required={
                              values.category === AddonCategory.Product
                            }
                            InputProps={{
                              onChange: (
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const val = event.target.value;
                                setValues({
                                  ...values,
                                  totalQuantity: parseInt(val),
                                  hasQuantity: val !== ""
                                });
                              },
                              value: values.totalQuantity
                                ? values.totalQuantity
                                : ""
                            }}
                          ></Field>
                        </Grid>
                      )}
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item container xs={12}>
                    <Field
                      component={TextField}
                      placeholder="Description"
                      label="Description"
                      name={"description"}
                      multiline
                      required
                      rows={3}
                      fullWidth
                      InputProps={{
                        onChange: (
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setValues({
                            ...values,
                            description: event.target.value
                          });
                        },
                        value: values.description ? values.description : ""
                      }}
                    ></Field>
                  </Grid>
                  {values.name === "VALET" && (
                    <Grid item xs={12} container className={classes.ruleCardMain}>
                      <Grid item xs={11}>
                        <Typography variant={"h3"} color={"secondary"}>SET RANGE RULES</Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            addRuleToTrigger()
                          }}
                          className={classes.iconStyle}
                          aria-label="add-icon"
                        >
                          <Tooltip title="Add Rule" aria-label="add">
                            <Avatar className={classes.avatarStyle} ><AddIcon /></Avatar>
                          </Tooltip>
                        </IconButton>
                      </Grid>

                      <Grid container spacing={1}>
                        {values.rules && values.rules.length > 0 &&
                          values.rules.map((rule: IRange, index: number) => {
                            return (
                              <Grid item xs={12} md={4} key={index}>
                                <Paper elevation={3} style={{ flexGrow: 1, padding: 20 }}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                      <Field
                                        component={TextField}
                                        fullWidth
                                        placeholder={userState.currentBranch.distanceUnit === "mile" ? "Mile" : "Km"}
                                        label={"From " + (userState.currentBranch.distanceUnit === "mile" ? "(In Miles)" : "(In Km)")}
                                        required
                                        name={`${values.rules?.[index]?.from ?? 0}`}
                                        InputProps={{
                                          value: rule.from,
                                          readOnly: true,
                                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                            if (e.target.value === '') {
                                              e.target.value = "0"
                                            }
                                            else
                                              onChangeValueTypefrom(e, index);
                                          },
                                        }}
                                        inputProps={{
                                          hasCurrencyPrefix: true,
                                          allowNegative: false,
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Field
                                        component={TextField}
                                        fullWidth
                                        placeholder={userState.currentBranch.distanceUnit === "mile" ? "Mile" : "Km"}
                                        label={"To " + (userState.currentBranch.distanceUnit === "mile" ? "(In Miles)" : "(In Km)")}
                                        required
                                        name={`${values.rules?.[index]?.to ?? 0}`}
                                        InputProps={{
                                          value: rule.to,
                                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                            onChangeValueTypeto(e, index);
                                          },
                                        }}
                                        inputProps={{
                                          hasCurrencyPrefix: true,
                                          allowNegative: false,
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Field
                                        component={TextField}
                                        fullWidth
                                        placeholder="Amount"
                                        label="Amount"
                                        required
                                        name={`${values.rules?.[index]?.amount ?? 0}`}
                                        InputProps={{
                                          value: rule.amount,
                                          inputComponent: FloatInput as any,
                                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                            onChangeValueTypeAmount(e, index);
                                          },
                                        }}
                                        inputProps={{
                                          hasCurrencyPrefix: true,
                                          allowNegative: false,
                                        }}
                                      />
                                    </Grid>
                                    <Hidden smDown>
                                      {
                                        (
                                          <Grid item xs={12} md={3}>
                                            <IconButton
                                              style={{ padding: 6, color: "var(--theme-accent)" }}
                                              onClick={() => {
                                                removeRuleFromTrigger(index)
                                              }}
                                              aria-label="delete-icon"
                                            >
                                              <Tooltip title="Delete This Rule" aria-label="add">
                                                <DeleteIcon />
                                              </Tooltip>
                                            </IconButton>
                                          </Grid>
                                        )
                                      }
                                    </Hidden>
                                  </Grid>
                                </Paper>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Grid>
                  )
                  }
                  {values.category === AddonCategory.Product && (
                    <Grid item container xs={12} spacing={2}>
                      <Grid item xs={4}>
                        <FormControl>
                          <RadioGroup
                            row
                            name="hasFixedRate"
                            value={
                              values.hasFixedRate
                                ? "fixedRate"
                                : "recurringRate"
                            }
                            onChange={(event) => {
                              if (event.target.value === "fixedRate") {
                                setValues({
                                  ...values,
                                  hasFixedRate: true
                                });
                              } else {
                                setValues({
                                  ...values,
                                  hasFixedRate: false
                                });
                              }
                            }}
                          >
                            <FormControlLabel
                              value="fixedRate"
                              control={<Radio />}
                              label={
                                <Typography variant="body1">
                                  Fixed Rate
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="recurringRate"
                              control={<Radio />}
                              label={
                                <Typography variant="body1">
                                  Rental Rate
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid >
                  )
                  }
                  {
                    values.category === AddonCategory.Product &&
                      values.hasFixedRate ? (
                      <Grid item xs={3}>
                        <Field
                          component={TextField}
                          placeholder={"Fixed Rate"}
                          label={
                            <Typography variant="body1">
                              {" "}
                              Fixed Rate{" "}
                            </Typography>
                          }
                          name={"fixedRate"}
                          InputProps={{
                            onChange: (event: any) => {
                              setValues({
                                ...values,
                                fixedRate: event.target.value
                              });
                            },
                            value: values.fixedRate ? values.fixedRate : 0,
                            inputComponent: FloatInput as any
                          }}
                          inputProps={{
                            hasCurrencyPrefix: true,
                            allowNegative: false
                          }}
                          fullWidth
                        />
                      </Grid>
                    ) : values.category === AddonCategory.Product ? (
                      values.recurringRate &&
                      values.recurringRate.map((rate, index) => {
                        return (
                          <Grid container item xs={6} sm={3} key={index}>
                            <Field
                              component={TextField}
                              placeholder={
                                capitalizeFirstLetter(rate.rateTypeName) +
                                " Rate"
                              }
                              label={
                                capitalizeFirstLetter(rate.rateTypeName) +
                                " Rate"
                              }
                              name={rate.rateTypeName}
                              InputProps={{
                                onChange: (event: any) => {
                                  setValues({
                                    ...values,
                                    recurringRate: values.recurringRate?.map(
                                      (rateType) => {
                                        if (
                                          rateType.rateTypeName ===
                                          rate.rateTypeName
                                        ) {
                                          rateType = {
                                            ...rateType,
                                            rate: event.target.value
                                          }
                                        }
                                        return rateType;
                                      }
                                    )
                                  });
                                },
                                value: getRateType(rate.rateTypeName, values),
                                inputComponent: FloatInput as any
                              }}
                              inputProps={{
                                hasCurrencyPrefix: true,
                                allowNegative: false
                              }}
                              fullWidth
                            />
                          </Grid>
                        );
                      })
                    ) : (
                      ""
                    )
                  }
                  {
                    values.category === AddonCategory.Product && (
                      <>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.taxable}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  const isChecked = e.target.checked;
                                  setValues(prevValues => ({
                                    ...prevValues,
                                    taxable: isChecked,
                                    tax: {
                                      ...prevValues.tax,
                                      value: isChecked ? prevValues.tax?.value ?? 0 : 0,
                                      title: isChecked ? prevValues.tax?.title ?? '' : ''
                                    }
                                  }));
                                }}
                                name={"taxable"}
                                color="primary"
                              />
                            }
                            label={userState.currentOrganisation.address.country === "United States" ? "Add Taxes & Fees" : "Add Tax"}
                          />
                        </Grid>
                        {values.taxable && (
                          <>
                            <Grid item xs={3}>
                              <Field
                                component={TextField}
                                label="Tax Title"
                                name={"title"}
                                fullWidth
                                InputProps={{
                                  value:
                                    values.tax && values.tax.title
                                      ? values.tax.title
                                      : "",
                                  onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setValues({
                                      ...values,
                                      tax: {
                                        ...values.tax,
                                        title: e.target.value
                                      }
                                    });
                                  }
                                }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                required
                              ></Field>
                            </Grid>
                            <Grid item xs={3}>
                              <Field
                                component={TextField}
                                label="Tax Value(%)"
                                fullWidth
                                name={"value"}
                                type="number"
                                InputProps={{
                                  value:
                                    values.tax && values.tax.value
                                      ? values.tax.value
                                      : 0,
                                  onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const value = parseFloat(e.target.value) ? parseFloat(e.target.value) : 0;
                                    if (value > 100) {
                                      snackbar({
                                        message:
                                          "Tax Value can't be more than 100%",
                                        variant: SnackBarVariant.ERROR
                                      });
                                    }
                                    else if (value < 0) {
                                      snackbar({
                                        message:
                                          "Tax value can't be less than 0%",
                                        variant: SnackBarVariant.ERROR
                                      });
                                    }
                                    else {
                                      setValues({
                                        ...values,
                                        tax: {
                                          ...values.tax,
                                          value: value
                                        }
                                      });
                                    }
                                  }
                                }}
                              ></Field>
                            </Grid>
                          </>
                        )}
                      </>
                    )
                  }
                  <Grid className={classes.section} container item xs={12}>
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="add"
                      type="submit"
                      disabled={createAddonTypeLoading || addonUpdateLoading}
                    >
                      {(createAddonTypeLoading || addonUpdateLoading) && (
                        <CircularProgress
                          size={14}
                          style={{ color: "white", marginRight: "10px" }}
                        />
                      )}
                      Save
                    </Fab>
                  </Grid>
                </Grid >
              </Form >
            )}
          </Formik >
        </Paper >
      </Grid >
    </Grid >
  );
}

export default Addons;
