import { useLazyQuery, useMutation, ApolloError } from "@apollo/client";
import {
  Box,
  Checkbox,
  CircularProgress,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Theme,
  Typography
} from "@mui/material";
import { DateTime as d } from "luxon";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CHECK_BOOKING_CONFIRMATION_ELIGIBILITY } from "../../../../../graphql/bookings/checkBookingConfirmationEligibility";
import { CHECK_FOR_TBA_WARNINGS } from "../../../../../graphql/bookings/CheckForTbaWarnings";
import { CONFIRM_BOOKING } from "../../../../../graphql/bookings/confirmBookingMutation";
import { GET_BOOKING } from "../../../../../graphql/bookings/getBookingQuery";
import { CREATE_PAYMENT } from "../../../../../graphql/invoices/createPaymentMutation";
import { GET_ADJUSTABLE_CREDIT_NOTES_FOR_BOOKING } from "../../../../../graphql/invoices/getAdjustableCreditNotesForBooking";
import { BOOKING_TYPES } from "../../../../../reducers/bookings/consts";
import {
  BookingStatus,
  IBooking,
  IBookingCreateInput,
  ICreditNote
} from "../../../../../reducers/bookings/types";
import {
  IBusinessCustomer,
  ICustomer
} from "../../../../../reducers/customer/types";
import {
  IPaymentInput,
  PaymentMode
} from "../../../../../reducers/invoices/types";
import { DepositCollection } from "../../../../../reducers/organisation/const";
import { IAppState } from "../../../../../store";
import { getLocalizedBookingSyntex } from "../../../../../utils/localized.syntex";
import { ConfirmationDialog } from "../../../../common/ConfirmationDialog/ConfirmationDialog";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { TabPanel } from "../../../../common/TabPannel/TabPannel";
import {
  formatGraphQLErrorMessage,
  isLongTermBooking,
  toCurrency
} from "../../../../common/utils";
import BacsTransaction from "./BacsTransaction";
import { CardTransaction } from "./CardTransaction";
import { CashTransaction } from "./CashTransaction";
import PayByLink from "./PayByLink";
import { PayOnCollection } from "./PayOnCollection";
import SepaTransaction from "./SepaTransaction";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

interface IProps {
  bookingData: IBookingCreateInput;
}

export interface ICreditNotesToAdjust {
  creditNoteId: string;
  amount: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  rootPaper: {
    width: "60%",
    margin: "0 auto",
    padding: 30
  }
}));

export const PaymentView: React.FC<IProps> = (props) => {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const [booking, setBooking] = useState<IBooking>();
  const [isBusinessCustomerBooking, setIsBusinessCustomerBooking] = useState<
    boolean
  >();
  const [businessCustomer, setBusinessCustomer] = useState<IBusinessCustomer>();
  const [customer, setCustomer] = useState<ICustomer>();
  const [bookingType, setBookingType] = useState<string>(BOOKING_TYPES.PREPAID);
  const [paymentInput, setPaymentInput] = useState<IPaymentInput>();
  const [adjustableCreditNotes, setAdjustableCreditNotes] = useState<
    ICreditNote[]
  >([]);
  const [adjustableAmount, setAdjustableAmount] = useState<number>(0);
  const [creditNotesApplied, setCreditNotesApplied] = useState<boolean>(false);
  const [creditNotesToAdjust, setCreditNotesToAdjust] = useState<
    ICreditNotesToAdjust[]
  >([]);
  const [collectDeposit, setCollectDeposit] = useState<boolean>(false);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { currency, locale } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const [loading, setLoading] = useState<boolean>(false);
  const [tbaWarning, setTbaWarning] = useState<boolean>(false);
  const [longTermBooking, setLongTermBooking] = useState<boolean>(false);
  const [confirmBookingLoading, setConfirmBookingLoading] = useState<boolean>(
    false
  );
  const [paymentType, setPaymentType] = useState<string>("");
  const [
    loadBooking,
    { data: bookingData, loading: loadBookingLoading }
  ] = useLazyQuery(GET_BOOKING, {
    fetchPolicy: "network-only"
  });

  const [
    getAdjustableCreditNotesForBooking,
    { data: adjustableCreditNotesData }
  ] = useLazyQuery(GET_ADJUSTABLE_CREDIT_NOTES_FOR_BOOKING, {
    fetchPolicy: "network-only"
  });

  const [checkForTbaWarnings] = useLazyQuery(CHECK_FOR_TBA_WARNINGS, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      navigate(`/update-booking?booking=${props.bookingData.id}&step=3`);
    },
    onCompleted: (data) => {
      if (data.checkForTbaWarnings) {
        setTbaWarning(true);
      } else {
        if (paymentInput) {
          createPayment({
            variables: {
              payment: {
                ...paymentInput,
                booking: props.bookingData.id
              },
              collectDeposit,
              creditNotesToAdjust
            }
          });
        } else if (
          booking &&
          creditNotesToAdjust.length &&
          !getCollectableAmount()
        ) {
          const data: IPaymentInput = {
            amount: 0,
            currency,
            description: PaymentMode.CASH,
            expireBy: "",
            invoice: "",
            booking: booking.id,
            paymentMode: PaymentMode.CASH,
            paymentType: "INWARD",
            capturedAt: d
              .now()
              .toUTC()
              .toISO()
          };
          createPayment({
            variables: {
              payment: data,
              collectDeposit: false, // because credit notes can't be adjusted against deposit
              creditNotesToAdjust
            }
          });
        } else {
          confirmBookingMutation({
            variables: {
              id: props.bookingData.id,
              bookingType
            }
          });
        }
      }
    }
  });

  const [checkBookingConfirmationEligibility] = useLazyQuery(
    CHECK_BOOKING_CONFIRMATION_ELIGIBILITY,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        setLoading(false);
        navigate(`/update-booking?booking=${props.bookingData.id}&step=3`);
      },
      onCompleted: () => {
        if (booking?.tba) {
          checkForTbaWarnings({
            variables: {
              bookingId: props.bookingData.id
            }
          });
        } else {
          if (bookingType === BOOKING_TYPES.POSTPAID) {
            confirmBookingMutation({
              variables: {
                id: props.bookingData.id,
                bookingType: BOOKING_TYPES.POSTPAID
              }
            });
          } else {
            if (paymentType === "payOnCollection") {
              if (booking && creditNotesToAdjust.length) {
                // signifies the case of pay on collection with some credit notes to adjust
                const data: IPaymentInput = {
                  amount: 0,
                  currency,
                  description: PaymentMode.CASH,
                  expireBy: "",
                  invoice: "",
                  booking: booking.id,
                  paymentMode: PaymentMode.CASH,
                  paymentType: "INWARD",
                  capturedAt: d
                    .now()
                    .toUTC()
                    .toISO()
                };
                createPayment({
                  variables: {
                    payment: data,
                    collectDeposit: false, // because credit notes can't be adjusted against deposit
                    creditNotesToAdjust
                  }
                });
              } else {
                confirmBookingMutation({
                  variables: {
                    id: booking?.id,
                    bookingType: BOOKING_TYPES.PREPAID
                  }
                });
              }
            } else {
              if (paymentInput) {
                createPayment({
                  variables: {
                    payment: {
                      ...paymentInput,
                      booking: props.bookingData.id
                    },
                    collectDeposit,
                    creditNotesToAdjust
                  }
                });
              } else if (
                booking &&
                creditNotesToAdjust.length &&
                !getCollectableAmount()
              ) {
                const data: IPaymentInput = {
                  amount: 0,
                  currency,
                  description: PaymentMode.CASH,
                  expireBy: "",
                  invoice: "",
                  booking: booking.id,
                  paymentMode: PaymentMode.CASH,
                  paymentType: "INWARD",
                  capturedAt: d
                    .now()
                    .toUTC()
                    .toISO()
                };
                createPayment({
                  variables: {
                    payment: data,
                    collectDeposit: false, // because credit notes can't be adjusted against deposit
                    creditNotesToAdjust
                  }
                });
              }
            }
          }
        }
      }
    }
  );

  const [createPayment, { data: createPaymentData }] = useMutation(
    CREATE_PAYMENT,
    {
      onCompleted: () => {
        setConfirmBookingLoading(false);
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    }
  );

  const [confirmBookingMutation] = useMutation(CONFIRM_BOOKING, {
    onCompleted: ({ confirmBooking }) => {
      navigate(`/view-booking?booking=${confirmBooking.id}`);
      setLoading(false);
      setConfirmBookingLoading(false);
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const submitPayment = (data: IPaymentInput) => {
    setLoading(true);
    setPaymentInput(data);
    checkBookingConfirmationEligibility({
      variables: {
        bookingId: props.bookingData.id
      }
    });
  };

  const payOnSubmit = (data: string) => {
    setPaymentType(data);
    setLoading(true);
    checkBookingConfirmationEligibility({
      variables: {
        bookingId: props.bookingData.id
      }
    });
  };

  useEffect(() => {
    if (userState && userState.currentBranch) {
      if (
        userState.currentBranch.depositCollection ===
        DepositCollection.AT_BOOKING_CONFIRMATION
      ) {
        setCollectDeposit(true);
      }
    }
  }, [userState]);

  useEffect(() => {
    if (props.bookingData.id) {
      loadBooking({
        variables: {
          id: props.bookingData.id
        }
      });
      getAdjustableCreditNotesForBooking({
        variables: {
          bookingId: props.bookingData.id
        }
      });
    }
  }, [props.bookingData]);

  useEffect(() => {
    if (bookingData && bookingData.booking) {
      setBooking(bookingData.booking);
      if (isLongTermBooking(bookingData.booking)) {
        setLongTermBooking(true);
      }
      const isBusinessCustomerBooking =
        bookingData.booking.customerType === "business" &&
          bookingData.booking.businessCustomer &&
          bookingData.booking.businessCustomer.id !== ""
          ? true
          : false;
      setIsBusinessCustomerBooking(isBusinessCustomerBooking);
      setBusinessCustomer(bookingData.booking.businessCustomer);
      setCustomer(bookingData.booking.customer);
    }
  }, [bookingData]);

  useEffect(() => {
    if (createPaymentData && createPaymentData.createPayment?.success) {
      setLoading(false);
      navigate(`/view-booking?booking=${booking?.id}`);
    }
  }, [createPaymentData]);

  useEffect(() => {
    if (
      adjustableCreditNotesData &&
      adjustableCreditNotesData.adjustableCreditNotesForBooking?.length
    ) {
      setAdjustableCreditNotes(
        adjustableCreditNotesData.adjustableCreditNotesForBooking
      );
      const amount = calcAdjustableAmount(
        adjustableCreditNotesData.adjustableCreditNotesForBooking
      );
      setAdjustableAmount(amount);
    }
  }, [adjustableCreditNotesData]);

  useEffect(() => {
    if (creditNotesApplied && adjustableCreditNotes.length > 0 && booking) {
      const creditNotes = adjustableCreditNotes
      let adjustableCreditNotesArr = creditNotes.slice().sort((a, b) =>
        b.availableAmount > a.availableAmount ? 1 : -1
      );
      const arr: ICreditNotesToAdjust[] = [];
      let amountToPay = booking.paymentDetails.totalPayableAmount;
      let amountPaid = 0;
      let amountRemaining = amountToPay;
      for (let i = 0; i < adjustableCreditNotesArr.length; i++) {
        const creditNote: ICreditNote = adjustableCreditNotesArr[i];
        if (amountPaid >= amountToPay) {
          break;
        }
        if (creditNote.availableAmount >= amountRemaining) {
          amountPaid += amountRemaining;
          arr.push({
            creditNoteId: creditNote.id,
            amount: amountRemaining
          });
          amountRemaining -= amountRemaining;
        } else {
          amountPaid += creditNote.availableAmount;
          arr.push({
            creditNoteId: creditNote.id,
            amount: creditNote.availableAmount
          });
          amountRemaining -= creditNote.availableAmount;
        }
      }
      setCreditNotesToAdjust(arr);
    }
  }, [creditNotesApplied]);

  const calcAdjustableAmount = (creditNotes: ICreditNote[]) => {
    let adjustableAmount: number = 0;
    if (creditNotes.length) {
      creditNotes.forEach((note: ICreditNote) => {
        adjustableAmount += note.availableAmount;
      });
    }
    return adjustableAmount;
  };

  const getCollectableAmount = () => {
    if (booking) {
      let amount = booking.paymentDetails.totalPayableAmount;
      if (collectDeposit && booking.depositDetails?.amount) {
        amount += booking.depositDetails.amount;
      }
      if (creditNotesApplied && creditNotesToAdjust.length) {
        creditNotesToAdjust.forEach((item) => {
          amount -= item.amount;
        });
      }
      if (amount > 0) {
        return amount;
      }
      return 0;
    }
    return 0;
  };

  const getAmountTobeUtilised = () => {
    let amount = 0;
    creditNotesToAdjust.forEach((item) => {
      amount += item.amount;
    });
    return amount;
  };

  const handleCheckboxChange = () => {
    if (creditNotesApplied) {
      // signifies unchecking of checkbox as previous state was true.
      setCreditNotesToAdjust([]); // if checkbox unchecked then creditNotesToAdjust should be empty
    }
    setCreditNotesApplied(!creditNotesApplied);
  };

  if (!booking || loadBookingLoading) {
    return (
      <Grid item xs={12} container justifyContent={"center"} alignItems={"center"} style={{ margin: "0 auto", marginTop: "20px", height: "50vh" }}>
        <CircularProgress />
      </Grid>
    )
  }

  return (
    <>
      <Hidden xsDown>
        <Box sx={{ marginTop: 2 }}>
          <Paper className={classes.rootPaper} elevation={1}>
            <Grid container className={classes.root} >
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    row
                    name="bookingType"
                    value={bookingType}
                    onChange={(event) => {
                      setBookingType(event.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={BOOKING_TYPES.PREPAID}
                      control={<Radio />}
                      disabled={loading}
                      label={<Typography variant="body1">{"Pay & Use"}</Typography>}
                    />
                    <FormControlLabel
                      value={BOOKING_TYPES.POSTPAID}
                      control={<Radio />}
                      disabled={loading || booking.isSubscription}
                      label={<Typography variant="body1">{"Use & Pay"}</Typography>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {bookingType === BOOKING_TYPES.PREPAID && adjustableAmount > 0 && (
                <Grid container xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={creditNotesApplied}
                          onChange={handleCheckboxChange}
                          value={creditNotesApplied}
                          color="secondary"
                          disabled={loading}
                        />
                      }
                      label={
                        <Typography variant="body1">
                          {`Use Credit Note Balance: ${toCurrency(
                            adjustableAmount,
                            currency,
                            locale
                          )}`}
                          {`  (Note: Credit notes cannot be used to pay deposit amount)`}
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Grid>
              )}
              {bookingType === BOOKING_TYPES.PREPAID && getCollectableAmount() > 0 && (
                <div className="flex">
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Payment tabs"
                    className={classes.tabs}
                  >
                    <Tab label={PaymentMode.CARD} disabled={loading} />
                    <Tab label={PaymentMode.CASH} disabled={loading} />
                    <Tab label={PaymentMode.PAY_BY_LINK} disabled={loading} />
                    {currency === "EUR" && (
                      <Tab label={PaymentMode.SEPA_BANK_DEBIT} disabled={loading} />
                    )}
                    {currency === "GBP" && (
                      <Tab label={PaymentMode.BACS_BANK_DEBIT} disabled={loading} />
                    )}
                    {booking.status === BookingStatus.QUOTE && (
                      <Tab
                        label={PaymentMode.PAY_BY_COLLECTION}
                        disabled={loading}
                      />
                    )}
                  </Tabs>
                  <div className="flex fill">
                    <TabPanel value={value} index={0}>
                      <CardTransaction
                        bookingId={booking.id}
                        invoice={""}
                        longTermBooking={longTermBooking}
                        amount={getCollectableAmount()}
                        creditAmount={getAmountTobeUtilised()}
                        creditNotesToAdjust={creditNotesToAdjust}
                        depositAmount={booking.depositDetails?.amount || 0}
                        tba={booking.tba}
                        collectDeposit={collectDeposit}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <CashTransaction
                        loading={loading}
                        onSubmit={submitPayment}
                        booking={booking}
                        amount={getCollectableAmount()}
                        creditAmount={getAmountTobeUtilised()}
                        depositAmount={booking.depositDetails?.amount || 0}
                        collectDeposit={collectDeposit}
                        tba={booking.tba}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <PayByLink
                        bookingId={booking.id}
                        invoice={""}
                        longTermBooking={longTermBooking}
                        creditNotesToAdjust={creditNotesToAdjust}
                        amount={getCollectableAmount()}
                        creditAmount={getAmountTobeUtilised()}
                        depositAmount={booking.depositDetails?.amount || 0}
                        collectDeposit={collectDeposit}
                        tba={booking.tba}
                      />
                    </TabPanel>
                    {currency === "EUR" && (
                      <TabPanel value={value} index={3}>
                        <SepaTransaction
                          bookingId={booking.id}
                          invoice={""}
                          longTermBooking={longTermBooking}
                          creditNotesToAdjust={creditNotesToAdjust}
                          amount={getCollectableAmount()}
                          creditAmount={getAmountTobeUtilised()}
                          depositAmount={booking.depositDetails?.amount || 0}
                          collectDeposit={collectDeposit}
                          tba={booking.tba}
                        />
                      </TabPanel>
                    )}
                    {currency === "GBP" && (
                      <TabPanel value={value} index={3}>
                        <BacsTransaction
                          bookingId={booking.id}
                          invoice={""}
                          longTermBooking={longTermBooking}
                          creditNotesToAdjust={creditNotesToAdjust}
                          amount={getCollectableAmount()}
                          creditAmount={getAmountTobeUtilised()}
                          depositAmount={booking.depositDetails?.amount || 0}
                          collectDeposit={collectDeposit}
                          tba={booking.tba}
                        />
                      </TabPanel>
                    )}
                    {booking.status === BookingStatus.QUOTE && (
                      <TabPanel
                        value={value}
                        index={["GBP", "EUR"].includes(currency) ? 4 : 3}
                      >
                        <PayOnCollection
                          amount={getCollectableAmount()}
                          bookingId={booking.id}
                          invoice={""}
                          loading={loading}
                          tba={booking.tba}
                          creditNotesToAdjust={creditNotesToAdjust}
                          creditAmount={getAmountTobeUtilised()}
                          depositAmount={booking.depositDetails?.amount || 0}
                          collectDeposit={collectDeposit}
                          onSubmit={() => {
                            payOnSubmit("payOnCollection");
                          }}
                        />
                      </TabPanel>
                    )}
                  </div>
                </div>
              )}
              {bookingType === BOOKING_TYPES.PREPAID &&
                getCollectableAmount() <= 0 && (
                  <Grid container xs={12}>
                    <Grid item xs={12}>
                      <Typography variant="h4">
                        Amount to be utilised:{" "}
                        {toCurrency(getAmountTobeUtilised(), currency, locale)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "1rem" }}>
                      <Fab
                        variant="extended"
                        size="medium"
                        aria-label="add"
                        type="button"
                        disabled={loading}
                        onClick={() => {
                          setLoading(true);
                          checkBookingConfirmationEligibility({
                            variables: {
                              bookingId: props.bookingData.id
                            }
                          });
                        }}
                      >
                        {loading && (
                          <CircularProgress
                            size={14}
                            style={{ color: "white", marginRight: "10px" }}
                          />
                        )}
                        {`Confirm ${getLocalizedBookingSyntex(country)}`}
                      </Fab>
                    </Grid>
                  </Grid>
                )}
              {bookingType === BOOKING_TYPES.POSTPAID && (
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="add"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    setLoading(true);
                    checkBookingConfirmationEligibility({
                      variables: {
                        bookingId: props.bookingData.id,
                        bookingType
                      }
                    });
                  }}
                >
                  {loading && (
                    <CircularProgress
                      size={14}
                      style={{ color: "white", marginRight: "10px" }}
                    />
                  )}
                  {`Confirm ${getLocalizedBookingSyntex(country)}`}
                </Fab>
              )}
            </Grid>
          </Paper>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Grid container xs={12} className={classes.root}>
          <Grid item xs={12}>
            <FormControl>
              <RadioGroup
                row
                name="bookingType"
                value={bookingType}
                onChange={(event) => {
                  setBookingType(event.target.value);
                }}
              >
                <FormControlLabel
                  value={BOOKING_TYPES.PREPAID}
                  control={<Radio />}
                  disabled={loading}
                  label={<Typography variant="body1">{"Pay & Use"}</Typography>}
                />
                <FormControlLabel
                  value={BOOKING_TYPES.POSTPAID}
                  control={<Radio />}
                  disabled={loading || booking.isSubscription}
                  label={<Typography variant="body1">{"Use & Pay"}</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {bookingType === BOOKING_TYPES.PREPAID && (
            <>
              <Grid item xs={12}>
                <Tabs
                  orientation="horizontal"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Payment tabs"
                  className={classes.tabs}
                >
                  <Tab label={PaymentMode.CARD} disabled={loading} />
                  <Tab label={PaymentMode.CASH} disabled={loading} />
                  {currency === "EUR" && (
                    <Tab
                      label={PaymentMode.SEPA_BANK_DEBIT}
                      disabled={loading}
                    />
                  )}
                  {currency === "GBP" && (
                    <Tab
                      label={PaymentMode.BACS_BANK_DEBIT}
                      disabled={loading}
                    />
                  )}
                  {booking.status === BookingStatus.QUOTE && (
                    <Tab
                      label={PaymentMode.PAY_BY_COLLECTION}
                      disabled={loading}
                    />
                  )}
                </Tabs>
              </Grid>
              <Grid item xs={12}>
                <TabPanel value={value} index={0}>
                  <CardTransaction
                    bookingId={booking.id}
                    invoice={""}
                    longTermBooking={longTermBooking}
                    creditNotesToAdjust={creditNotesToAdjust}
                    amount={getCollectableAmount()}
                    creditAmount={getAmountTobeUtilised()}
                    depositAmount={booking.depositDetails?.amount || 0}
                    collectDeposit={collectDeposit}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <CashTransaction
                    loading={loading}
                    onSubmit={submitPayment}
                    booking={booking}
                    amount={getCollectableAmount()}
                    creditAmount={getAmountTobeUtilised()}
                    depositAmount={booking.depositDetails?.amount || 0}
                    collectDeposit={collectDeposit}
                  />
                </TabPanel>
                {currency === "EUR" && (
                  <TabPanel value={value} index={2}>
                    <SepaTransaction
                      bookingId={booking.id}
                      invoice={""}
                      longTermBooking={longTermBooking}
                      creditNotesToAdjust={creditNotesToAdjust}
                      amount={getCollectableAmount()}
                      creditAmount={getAmountTobeUtilised()}
                      depositAmount={booking.depositDetails?.amount || 0}
                      collectDeposit={collectDeposit}
                    />
                  </TabPanel>
                )}
                {currency === "GBP" && (
                  <TabPanel value={value} index={2}>
                    <BacsTransaction
                      bookingId={booking.id}
                      invoice={""}
                      longTermBooking={longTermBooking}
                      creditNotesToAdjust={creditNotesToAdjust}
                      amount={getCollectableAmount()}
                      creditAmount={getAmountTobeUtilised()}
                      depositAmount={booking.depositDetails?.amount || 0}
                      collectDeposit={collectDeposit}
                    />
                  </TabPanel>
                )}
                {booking.status === BookingStatus.QUOTE && (
                  <TabPanel
                    value={value}
                    index={["GBP", "EUR"].includes(currency) ? 3 : 2}
                  >
                    <PayOnCollection
                      amount={getCollectableAmount()}
                      bookingId={booking.id}
                      invoice={""}
                      creditAmount={getAmountTobeUtilised()}
                      creditNotesToAdjust={creditNotesToAdjust}
                      loading={loading}
                      onSubmit={() => {
                        payOnSubmit("payOnCollection");
                      }}
                    />
                  </TabPanel>
                )}
              </Grid>
            </>
          )}
          {bookingType === BOOKING_TYPES.POSTPAID && (
            <Fab
              variant="extended"
              size="medium"
              aria-label="add"
              type="button"
              disabled={loading}
              onClick={() => {
                setLoading(true);
                checkBookingConfirmationEligibility({
                  variables: {
                    bookingId: props.bookingData.id,
                    bookingType
                  }
                });
              }}
            >
              {loading && (
                <CircularProgress
                  size={14}
                  style={{ color: "white", marginRight: "10px" }}
                />
              )}
              {`Confirm ${getLocalizedBookingSyntex(country)}`}
            </Fab>
          )}
        </Grid>
      </Hidden>
      {tbaWarning && (
        <ConfirmationDialog
          isOpen={tbaWarning}
          onCancel={() => {
            setTbaWarning(false);
            navigate(
              `/update-booking?booking=${props.bookingData.id}&step=3`
            );
          }}
          isLoading={confirmBookingLoading}
          onConfirm={() => {
            if (!confirmBookingLoading) {
              setConfirmBookingLoading(true);
              if (paymentInput) {
                setTbaWarning(false);
                createPayment({
                  variables: {
                    payment: {
                      ...paymentInput,
                      booking: props.bookingData.id
                    },
                    collectDeposit,
                    creditNotesToAdjust
                  }
                });
              }
              if (paymentType === "payOnCollection") {
                confirmBookingMutation({
                  variables: {
                    id: booking?.id,
                    bookingType: BOOKING_TYPES.PREPAID
                  }
                });
              }
              if (bookingType === BOOKING_TYPES.POSTPAID) {
                confirmBookingMutation({
                  variables: {
                    id: props.bookingData.id,
                    bookingType: BOOKING_TYPES.POSTPAID
                  }
                });
              }
            }
          }}
          title=""
          description={
            "The vehicles in this booking have some future bookings. Do you wish to continue to with this Flex booking?"
          }
        />
      )}
    </>
  );
};
