import { gql } from "@apollo/client";

export const UPDATE_VEHICLE = gql`
  mutation UpdateVehicle($id: String!, $vehicle: UpdateVehicleInput!) {
    updateVehicle(id: $id, vehicle: $vehicle) {
      isGhostVehicle
      insurerCode
      airConditioner
      assetTag
      brakeHorsePower
      bluetooth
      bodyType
      vehicleUniqueCode
      vehicleUniqueId
      co2Emissions
      colour
      cylinderCapacity
      v5c
      refrigeratorHours
      isRefrigerator
      emmissionType
      ulezComplaint
      ukVehicle
      description
      fuelType
      startDate
      imported
      infoSource
      insuranceGroup
      licencePlate
      vehicleType
      vin
      make
      model
      manufactureDate
      year
      registrationDate
      vehicleTest
      vehicleTestDetails
      vehicleTestDueDate
      numberOfDoors
      numberOfPreviousOwners
      numberOfSeats
      premium
      isCarShare
      smartCarVehicleID
      price
      msrp
      grossVehicleWeight
      satNav
      sixMonthRate
      status
      steering
      taxDetails
      taxed
      taxStatus
      telemetryDeviceId
      teltonikaDeviceId
      telemetryDevicesData {
        status
        medium
        disabledAt
        enabledAt
        enabledBy
        disabledBy
        active
        telemetryDeviceId
        deviceId
        latitude
        longitude
      }
      immobiliser
      transmission
      twelveMonthRate
      typeApproval
      tyreFitments
      wheelPlan
      acrissCode
      branch
      canTransfer
      residualValue,
      holdingCostPerDay
      branchDetails{
        id
        name
      }
      imageUrl
      deleted
      id
      interiorColour
      pcoNumber
      pcoExpiry
      nextPMIDate
      nextBRTDate
      frogDiagram
      notes{
        id
        description
        createdBy
        createdDate
      }
      batteries {
        voltage
        capacity
        coldTestCurrent
        length
        width
        height
        postPositions
        terminalType
        holdDownType
      }
      frontTyres {
        width
        ratio
        rim
        speedRating
        psi
        loadIndex
      }
      rearTyres {
        width
        ratio
        rim
        speedRating
        psi
        loadIndex
      }
      vehicleTestReports {
        testDate
        expiryDate
        testResult
        odometerReading
        odometerUnit
        motTestNumber
        advisoryItems
        minorItems
        failureItems
      }
      vehicleDocuments {
        title
        documentName
        documentType
        expiryDate
        url
        createdAt
      }
      bookingSchedules {
        booking {
          id
        }
        endDate
        id
        startDate
        status
      }
      features
      vehicleState {
        exterior {
          front {
            description
            images
          }
          rear {
            description
            images
          }
          top {
            description
            images
          }
          right {
            description
            images
          }
          left {
            description
            images
          }
        }
        interior {
          frontSeat {
            clean
            controls
            seatBelt
          }
          rearSeat {
            clean
            controls
            seatBelt
          }
          lockAndSwitch {
            frontDoor
            rearDoor
            frontWindow
            rearWindow
            childLock
          }
          images
        }
        generalChecks{
          name
          status
          images
        }
        defaultAccesories{
          name
          status
          images
        }
        bookedAccesories {
          name
          type
          quantity
          description
        }
        tyre {
          frontLeft {
            condition
            pressure
            description
          }
          frontRight {
            condition
            pressure
            description
          }
          rearLeft {
            condition
            pressure
            description
          }
          rearRight {
            condition
            pressure
            description
          }
        }
        odometerReading
        fuelStatus
        batteryStatus
        updatedAt
        createdAt
        updatedBy {
          firstName
          lastName
        }
        createdBy {
          firstName
          lastName
        }
      }
      services{
        id
        status
        serviceType
        serviceProviderName
        serviceProviderAddress{
          fullAddress
        }
        appointmentDate
        startDate
        completionDate
        odometerReading
        description
        totalExpenses
        documents{
          expiryDate
          documentName
          documentType
          title
        }
      }
      activeVehicleDamages{
        id
        frogDiagram
        approvalStatus
        referenceNumber
        damages {
          title
          descriptions
          damageType
          damageKind
          damageArea
          images
          location {
            x
            y
          }
        }
      }
      damages{
        id
        vehicleId
        circumstance
        referenceNumber
        bookingRef
        reportDate
        incidentDate
        reportedBy
        frogDiagram
        approvalStatus
        estimatedCost
        status
        damageSeverity
        estimatedRepairDate
        damages {
          title
          descriptions
          damageType
          damageKind
          damageArea
          images
          location {
            x
            y
          }
        }
      }
      deFleetParameters{
        byDistanceDriven
        byDate
        alertBeforeDistance
        alertBeforeDays
      }
      regularServiceSchedule{
        byDistance
        byMonth
        alertBefore
      }
      dtcAlertSupported
      vehicleTaxAmount
    }
  }
`;