import React, { useEffect, useState } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { CircularProgress, Grid, Paper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { IAppState } from "../../../../store";
import { ITable, TableNames } from "../../../../reducers/tableState/types";
import { DATE_TYPE, formatGraphQLErrorMessage, returnSortedOrder, setTableSortOrder, toCurrency } from "../../../common/utils";
import { SEARCH_INVOICE } from "../../../../graphql/invoices/searchInvoicesQuery";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import {  GET_INDIVIDUAL_CUSTOMER_INVOICE_COUNT } from "../../../../graphql/invoices/getInvoiceCountQuery";
import _ from "lodash";
import { IInvoice } from "../../../../reducers/invoices/types";
import { InvoiceType, reshapeInvoicesIntoRows } from "../../Invoices/utils";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { InvoiceStatus } from "../../Invoices/UpdateInvoice/InvoiceComponents/types";
import { MUIDataTableOptions } from "mui-datatables";
import { SelectableRows } from "../../../common/NuvvenTable/types";
import { addTableState } from "../../../../actions/tableState/actions";
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { GET_INDIVIDUAL_CUSTOMER_INVOICES } from "../../../../graphql/invoices/getIndividualCustomerInvoices";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        newPaper: {
            [theme.breakpoints.down('md')]: {
                width: `calc(100vw - 45px)`,
            },
            [theme.breakpoints.up('sm')]: {
                width: `calc(100vw - 245px)`,
            }
        },
        expansionPanel: {
            marginTop: 8,
            [theme.breakpoints.down('md')]: {
                minWidth: `calc(100vw - 105px)`,
            },
            [theme.breakpoints.up('sm')]: {
                minWidth: `calc(100vw - 305px)`,
            },
        },
        summaryLeftMargin: {
            marginLeft: 5
        },
        linkText: {
            color: theme.palette.primary.main,
            marginLeft: "1rem",
            cursor: "pointer"
        }
    })
);
interface IProps {
    customerId: string;
}
export const CustomerInvoiceHistory: React.FC<IProps> = (props) => {
    const classes = useStyles();
    const userState = useSelector((state: IAppState) => state.userReducer);
    const snackbar = useSnackBar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const invoicesTableState = useSelector(
        (state: IAppState) => state.tableStateReducer.invoices
    );
    const { locale, currency } = userState.currentOrganisation;
    const { country } = userState.currentOrganisation.address;
    const [totalInvoiceCount, setTotalInvoiceCount] = useState<number>(0);
    const [rows, setRows] = useState<any[]>([]);
    const [invoicesTable, setInvoicesTable] = useState<ITable>();
    const [filtering, setIsFiltering] = useState<boolean>(false);
    const [filterKey, setFilterKey] = useState<string>("");
    const [filterValue, setFilterValue] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string | undefined>();
    const [limit, setLimit] = useState(10);

    const [loadCustomerInvoices, { loading: invoicesLoading, data }] = useLazyQuery(GET_INDIVIDUAL_CUSTOMER_INVOICES, { fetchPolicy: "network-only" })

    const [getInvoiceCount] = useLazyQuery(
        GET_INDIVIDUAL_CUSTOMER_INVOICE_COUNT, {
        fetchPolicy: "network-only",
        onCompleted(data) {
            setTotalInvoiceCount(data.individualCustomerInvoiceCount)
        },
        onError: (error: ApolloError) => {
            snackbar({
                message: formatGraphQLErrorMessage(error.message),
                variant: SnackBarVariant.ERROR
            });
        }
    });

    const [searchInvoice, { loading: searchInvoicesLoading, data: searchInvoiceData }] = useLazyQuery(
        SEARCH_INVOICE,
        {
            fetchPolicy: "network-only",
            onError: (error: ApolloError) => {
                snackbar({
                    message: formatGraphQLErrorMessage(error.message),
                    variant: SnackBarVariant.ERROR
                });
            }
        }
    );

    useEffect(() => {
        if (data && data.getIndividualCustomerInvoices) {
            const oldInvoices = [...rows];
            const shapedInvoices = shapeInvoices(data.getIndividualCustomerInvoices);
            const newInvoices = _.union(oldInvoices, shapedInvoices);
            setRows(newInvoices);
        }
    }, [data])

    useEffect(() => {
        if (searchInvoiceData && searchInvoiceData.searchBusinessCustomerInvoice) {
            let invoices = shapeInvoices(searchInvoiceData.searchBusinessCustomerInvoice)
            setTotalInvoiceCount(invoices.length)
            setRows(invoices);
        }
    }, [searchInvoiceData])

    useEffect(() => {
        if (invoicesTableState) {
            setInvoicesTable(invoicesTableState);
            let sortedOrder = returnSortedOrder(invoicesTableState);
            if (sortedOrder) {
                setSortOrder(sortedOrder);
                setTableSortOrder(columns, invoicesTableState, sortedOrder)
            }
        }
    }, [invoicesTableState]);

    useEffect(() => {
        if (props.customerId) {
            getInvoiceCount({
                variables: {
                    individualCustomerId: props.customerId
                }
            })
            loadCustomerInvoices({
                variables: {
                    individualCustomerId: props.customerId,
                    limit: invoicesTableState?.rowsPerPage ? invoicesTableState.rowsPerPage : limit,
                    offset: 0
                }
            })
        }
    }, [])

    const handleSearch = _.debounce((searchText: string) => {
        if (searchText) {
            searchInvoice({
                variables: {
                    q: searchText,
                    limit,
                    offset: 0,
                    filters: []
                }
            })
        }
    }, 1000);

    const shapeInvoices = (data: any) => {
        let invoicesArr = data
            .map((invoice: IInvoice) => {
                const isBusinessCustomerBooking =
                    invoice.customerType === "business" &&
                        invoice.businessCustomer &&
                        invoice.businessCustomer.id !== ""
                        ? true
                        : false;
                const businessCustomer = invoice.businessCustomer;
                const individualCustomer = invoice.customer;
                const customer = isBusinessCustomerBooking
                    ? businessCustomer?.businessName || ""
                    : individualCustomer
                        ? individualCustomer.firstName + " " + individualCustomer.lastName
                        : "";
                let invoiceRow = reshapeInvoicesIntoRows(
                    invoice,
                    locale,
                    currency,
                    invoice.booking?.referenceNumber || "",
                    customer,
                    country
                )
                return invoiceRow;
            })
        return invoicesArr;
    }


    const columns = [
        {
            label: `${getLocalizedBookingSyntex(country)} Ref.`,
            name: "referenceNumber",
            options: {
                filter: false
            }
        },
        {
            label: "Invoice Id",
            name: "invoiceRef",
            options: {
                filter: false
            }
        },
        {
            label: "Invoice Date",
            name: "dateCreated",
            options: {
                sort: true,
                filter: false,
                customBodyRender: (value: string) => {
                    if (value) {
                        return getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED)
                    } else {
                        return "-"
                    }
                }
            }
        },
        {
            label: "Invoice Type",
            name: "invoiceType",
            options: {
                customBodyRender: (value: string) => {
                    if (value === InvoiceType.SUNDRY) {
                        return "SUNDRY"
                    } else {
                        return "REGULAR"
                    }
                },
                filterOptions: {
                    names: ["SUNDRY", "REGULAR"]
                }
            }
        },
        {
            label: "Customer",
            name: "customer",
            options: {
                filter: false
            }
        },
        {
            label: "Total",
            name: "total",
            options: {
                filter: false,
                customBodyRender: (value: number) => {
                    if (value) {
                        return toCurrency(value, currency, locale)
                    }
                    return "-"
                }
            }
        },
        {
            label: "Balance Due",
            name: "dueAmount",
            options: {
                filter: false,
                customBodyRender: (value: number) => {
                    if (value) {
                        return toCurrency(value, currency, locale)
                    }
                    return "-"
                }
            }
        },
        {
            label: "Due Date",
            name: "dueDate",
            options: {
                sort: true,
                filter: false,
                customBodyRender: (value: string) => {
                    if (value) {
                        return getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED)
                    } else {
                        return "-"
                    }
                }
            }
        },
        {
            label: "Status",
            name: "status",
            options: {
                customBodyRender: (value: any) => {
                    if ([InvoiceStatus.PARTIALLY_PAID, InvoiceStatus.UNPAID].includes(value)) {
                        return "UNPAID"
                    } else {
                        return value
                    }
                },
                filterOptions: {
                    names: [InvoiceStatus.DRAFT, InvoiceStatus.PAID, InvoiceStatus.UNPAID, InvoiceStatus.VOID]
                }
            }
        },
        {
            label: "Email",
            name: "lastSent",
            options: {
                customBodyRender: (value: any) => {
                    if (value) {
                        return "SENT"
                    } else {
                        return "-";
                    }
                },
                filterOptions: {
                    names: ["-", "SENT"]
                }
            }
        }
    ]

    let previousFilterList: any = [];
    const options: MUIDataTableOptions = {
        selectableRows: SelectableRows.NONE,
        count: totalInvoiceCount,
        rowsPerPageOptions: [10, 20, 100],
        setRowProps: (row, rowIndex) => {
            const invoiceRow = rows.find(
                (invoice: IInvoice) => invoice.invoiceRef === row[1]
            );
            return {
                style: { backgroundColor: invoiceRow.sageAccountingInvoiceId || invoiceRow.sentToXero ? "#def7e8" : "#FFEFED" }
            }
        },
        onTableChange: (action: string, tableState: any) => {
            tableState.filterData[3] = ["SUNDRY", "REGULAR"]
            tableState.filterData[8] = ["PAID", "UNPAID", "VOID", "DRAFT"]
            tableState.filterData[9] = ["SENT", "-"]

            switch (action) {
                case "changePage":
                    if (tableState.searchText) {
                        return; // Skip executing changePage if there is a search text
                    }
                    const { page, rowsPerPage } = tableState
                    if (page * rowsPerPage >= rows.length || filtering) {
                        if (filtering) {
                            loadCustomerInvoices({
                                variables: {
                                    individualCustomerId: props?.customerId,
                                    offset: page * rowsPerPage,
                                    limit: rowsPerPage,
                                    filterKey: filterKey,
                                    filterValue: filterValue
                                }
                            });
                        }
                        else {
                            loadCustomerInvoices({
                                variables: {
                                    individualCustomerId: props?.customerId,
                                    limit: rowsPerPage,
                                    offset: page * rowsPerPage
                                }
                            });
                        }
                    }
                    break;
                case "changeRowsPerPage":
                    setRows([])
                    loadCustomerInvoices({
                        variables: {
                            individualCustomerId: props?.customerId,
                            offset: 0,
                            limit: tableState.rowsPerPage || limit
                        }
                    });
                    break;
                default:
                    break;
            }
        },
        textLabels: {
            body: {
                toolTip: "Sort",
                noMatch: (invoicesLoading || searchInvoicesLoading) ?
                    'Loading...' :
                    'Sorry, there is no matching data to display',
            },
            pagination: {
                next: "Next Page",
                previous: "Previous Page",
                rowsPerPage: "Rows per page:",
                displayRows: "of",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
            },
            filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
            },
            viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
            },
            selectedRows: {
                text: "row(s) selected",
                delete: "Delete",
                deleteAria: "Delete Selected Rows",
            },
        },
        onChangeRowsPerPage: (numberOfRows: number) => {
            setLimit(numberOfRows)
            dispatch(
                addTableState({
                    tableName: TableNames.INVOICES,
                    rowsPerPage: numberOfRows
                })
            );
        },
        onRowClick: (rowData: string[]) => {
            if (rowData && rowData.length) {
                // FIXME: Idially the rows should change based on sorting and then find data based on rowindex
                const invoiceClicked = rows.find(
                    (row: any) => row.invoiceRef === rowData[1]
                );
                if (invoiceClicked) {
                    navigate(`/update-billing?invoice=${invoiceClicked.id}`)
                }
            }
        },
        rowsPerPage:
            invoicesTable && invoicesTable.rowsPerPage
                ? invoicesTable.rowsPerPage
                : limit,
        onColumnSortChange: (changedColumn: string, direction: string) => {
            dispatch(
                addTableState({
                    tableName: TableNames.INVOICES,
                    columnName: changedColumn,
                    direction
                })
            );
        },
        onFilterChange(changedColumn, filterList, type, changedColumnIndex, displayData) {
            const flatFilterList = filterList.reduce((acc, curr) => {
                if (curr.length > 0) {
                    acc.push(curr[0]);
                }
                return acc;
            }, []);
            const newData = flatFilterList.filter((item: any) => !previousFilterList.includes(item));
            previousFilterList = flatFilterList;
            if (changedColumn && newData[0]) {
                setIsFiltering(true)
                setFilterKey(changedColumn as string)
                setFilterValue(newData[0])
                loadCustomerInvoices({
                    variables: {
                        individualCustomerId: props?.customerId,
                        limit: invoicesTableState?.rowsPerPage || limit,
                        offset: 0,
                        filterKey: changedColumn,
                        filterValue: newData[0]
                    }
                })
            }
            else {
                setIsFiltering(false)
            }
        },
        onTableInit: () => {
            if (invoicesTable && sortOrder) {
                setTableSortOrder(columns, invoicesTable, sortOrder);
            }
        },
        onSearchChange: (searchText: string | null) => {
            if (searchText) {
                handleSearch(searchText)
            } else {
                getInvoiceCount({
                    variables: {
                        individualCustomerId: props.customerId
                    }
                })
                loadCustomerInvoices({
                    variables: {
                        individualCustomerId: props?.customerId,
                        limit: invoicesTableState?.rowsPerPage ? invoicesTableState.rowsPerPage : limit,
                        offset: 0
                    }
                })
            }
        },
    };

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12}>
                <Typography variant={"h3"}>INVOICE HISTORY</Typography>
            </Grid>
            <Grid container item xs={12}>
                {!(invoicesLoading && !rows.length) ? (
                    <Paper variant='outlined' className={classes.newPaper}>
                        <NuvvenTable
                            title={""}
                            rows={rows}
                            columns={columns}
                            options={options}
                        />
                    </Paper>) : (
                    <CircularProgress />
                )}
            </Grid>
        </Grid>

    )
};