import "./index.scss";

import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Fab,
  FormControl,
  Grid,
  TextField as InputField,
  MenuItem,
  Typography,
  FormControlLabel,
  Switch,
  CircularProgress,
  Checkbox,
  Tooltip,
  IconButton,
  Autocomplete,
  InputLabel,
  Select,
  Button,
  Chip,
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { ApolloError } from "@apollo/client";

import { Field, FieldProps, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import { setOrganisation } from "../../../../../actions/organisation/actions";
import {
  updateTenancyOrganisation,
  updateUser
} from "../../../../../actions/user/actions";
import { CREATE_ORGANISATION } from "../../../../../graphql/organisation/createOrganisationMutation";
import { GET_ORGANISATIONS } from "../../../../../graphql/organisation/getOrganisationQuery";
import { GET_USERS } from "../../../../../graphql/organisation/getUsersQuery";
import { UPDATE_ORGANISATION } from "../../../../../graphql/organisation/updateOrganisationMutation";
import {
  IBookingCategory,
  ICostCenter,
  ICreditLimits,
  IOrganisation,
  ITax
} from "../../../../../reducers/organisation/types";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  ViewTermsAndConditions
} from "../../../../common/TermsAndConditions/TermsAndConditions";
import {
  checkDeleteOrDownloadFileType,
  checkUploadFileFormat,
  formatGraphQLErrorMessage,
  getImage,
  isImageFileType,
  returnfileContentTypeAndExtention,
  uploadFileExtensionAndContentType
} from "../../../../common/utils";
import { UserRoles } from "../../../../hoc/Authorization";
import { CURRENCY_LIST, LANGUAGE_LIST } from "./languageCurrencyConstant";
import { ViewLogo } from "./UploadLogo";
import { IAppState } from '../../../../../store';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { IAddress } from "../../../../../reducers/user/types";
import AddressPicker from "../../../CustomerManagement/NewCustomer/AddressPicker/AddressPicker";
import { getSignedUrl } from '../../../../../utils/getSignedUrl';
import { getDefaultCountryCode, getLocalizedBookingSyntex, getLocalizedOrganisationSyntex, getLocalizedVatNumberSyntex } from "../../../../../utils/localized.syntex";
import NuvvenAutoCompleteTags from "../../../../common/NuvvenAutoCompleteTags";
import { REMOVE_BUSINESS_CUSTOMER_TAG } from "../../../../../graphql/businessCustomers/businessCustomer";
import { ConfirmationDialog } from "../../../../common/ConfirmationDialog/ConfirmationDialog";
import { captureErrorException } from "../../../../../utils/sentry";
import DragDropImages from "../../../../common/DragAndDropFiles/DragAndDropImage/DragDropImages";
import { FilePicker } from "../../../../common/FilePicker/FilePicker";
import { BOOKING_CATEGORY, bookingCategoryLabels, preDefinedAllocationRules, RuleItems } from "../../../../../reducers/bookings/types";

const uniqueId = uuidv4();
interface IProps {
  organisation: IOrganisation;
  step: number;
  initiateSageOauth: () => void;
}

const Organisation: React.FC<IProps> = (props) => {

  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const navigate = useNavigate();

  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const { country } = userReducer.currentOrganisation.address;
  // if (!props.organisation.taxes) {
  //   props.organisation.taxes = [{ name: "vat", rate: 0, type: "variable" }];
  // } else if (props.organisation.taxes.length === 0) {
  //   props.organisation.taxes.push({ name: "vat", rate: 0, type: "variable" });
  // }

  const [values, setValues] = useState<IOrganisation>(props.organisation);

  const [logo, setLogo] = useState<any>("");
  const [logoUrl, setLogoUrl] = useState<string>("");
  const [countryCode, setCountryCode] = useState<any>(getDefaultCountryCode(country));
  const [phoneError, setPhoneError] = useState<string>("");
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [loading, setLoading] = useState<boolean>(false);
  // FIXME: used termAndContitionUrl and logoUrl from props.organisation
  const [termsAndConditionsUrl, setTermsAndConditionsUrl] = useState<string>(
    values.termsAndConditionsUrl
  );
  const [logoUploadloading, setlogoUploadloading] = useState(false);
  const [termsAndConditionsLoading, setTermsAndConditionsLoading] = useState(false);
  const [costCenters, setCostCenters] = useState<ICostCenter[]>([]);
  const { data: usersList } = useQuery(GET_USERS);
  const [openModal, setOpenModal] = useState(false);
  const [tagToRemove, setTagToRemove] = useState('');
  const [documentDialogVisible, setDocumentDialogVisible] = useState<boolean>(
    false
  );
  const [orgLogoDialogVisible, setOrgLogoDialogVisible] = useState<boolean>(
    false
  );
  const [uploadedTermAndCondDocs, setUploadedTermAndCondDocs] = useState<object[]>([]);
  const [uploadedOrgImage, setUploadedorgImage] = useState<object[]>([]);
  useEffect(() => {
    if (props.organisation) {
      if (props.organisation.logoUrl) {
        getLogoUpload(props.organisation.logoUrl);
        setLogoUrl(props.organisation.logoUrl);
      }
      setTermsAndConditionsUrl(props.organisation.termsAndConditionsUrl || "");
      setValues(props.organisation);
      if (props.organisation.costCenters) {
        setCostCenters(props.organisation.costCenters)
      }
    }
  }, [props.organisation]);

  const [addOrganisation] = useMutation(CREATE_ORGANISATION, {
    onCompleted: (addOrganisation) => {
      snackbar({
        message: "Organisation created",
        variant: SnackBarVariant.SUCCESS
      });
      navigate("/org-structure");
      dispatch(setOrganisation(addOrganisation.createOrganisation));
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      }),
    update: (proxy, data) => {
      const result: any = proxy.readQuery({ query: GET_ORGANISATIONS });
      proxy.writeQuery({
        data: {
          organisations: result.organisations.concat([
            data.data.createOrganisation
          ])
        },
        query: GET_ORGANISATIONS
      });
    }
  });

  const [onUpdateOrganisation] = useMutation(UPDATE_ORGANISATION, {
    onCompleted: (data: any) => {
      setLoading(false)
      if (props.organisation.id === userReducer.currentOrganisation.id) {
        dispatch(updateTenancyOrganisation(data.updateOrganisation));
      }
      dispatch(setOrganisation(data.updateOrganisation));
      if (userReducer.currentOrganisation.id === data.updateOrganisation.id) {
        dispatch(
          updateUser({
            ...userReducer,
            currentOrganisation: data.updateOrganisation
          })
        );
      }
      snackbar({
        message: "Organisation updated",
        variant: SnackBarVariant.SUCCESS
      });
      navigate(
        `/update-organisation?organisation=${props.organisation.id}&step=${props.step}`
      );
    },
    onError: (error: ApolloError) => {
      setLoading(false)
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  // function handleTaxChange(event: any, name: string) {
  //   const taxes = values.taxes;
  //   if (taxes && taxes.length) {
  //     const newTaxes: any[] = taxes.map((tax: ITax) => {
  //       if (name === tax.name) {
  //         tax.rate = parseInt(event.target.value);
  //       }
  //       return tax;
  //     });
  //     setValues({ ...values, taxes: newTaxes });
  //   }
  // }

  function isUpdateScreen() {
    return false;
  }

  const nullCreditCheck = (arr: any) => {
    let count = 0;
    arr.map((x: any) => {
      count += (x.lowerLimit + x.upperLimit)
    })
    if (count === 0) return true
    else return false
  }

  const validateEmptyCheck = (arr: any) => {
    for (let i = 0; i < arr.length; i++) {
      const current = arr[i];
      if (current.upperLimit === "" || isNaN(current.upperLimit)) {
        return false;
      }
      if ((current.type === "APPROVAL_REQUIRED" || current.type === "AUTO_APPROVE") &&
        (current.lowerLimit === "" || isNaN(current.lowerLimit))) {
        return false;
      }
    }
    return true;
  }

  const validateCreditLimits = (arr: [ICreditLimits]) => {
    let valid = true;
    arr.map((obj: ICreditLimits, idx) => {
      if (obj.lowerLimit >= obj.upperLimit) valid = false
      if (idx > 0 && (obj.lowerLimit <= arr[idx - 1].upperLimit)) {
        valid = false
      }
    })
    return valid;
  }

  const onSubmitHandler = (data: any) => {
    const obj = { ...data };
    delete obj.id;
    delete obj.branches;
    delete obj.rate;
    delete obj.convergeEnabled;
    obj.termsAndConditionsUrl = termsAndConditionsUrl;
    obj.logoUrl = logoUrl;
    obj.costCenters = costCenters;
    const enabledBookingCategories = obj.bookingCategories.filter((category: IBookingCategory) => category.isActive).map((category: IBookingCategory) => category.type);
    if (
      enabledBookingCategories.length === 1 &&
      enabledBookingCategories[0] === BOOKING_CATEGORY.COURTESY_CAR
    ) {
      return snackbar({
        message: "Booking category (not including Courtesy Car) required.",
        variant: SnackBarVariant.ERROR
      })
    }
    if (obj.bookingCategories.length &&
      !obj.bookingCategories.some((category: IBookingCategory) => category.isActive && category.default)
    ) {
      return snackbar({
        message: "Add at-least one booking category and default.",
        variant: SnackBarVariant.ERROR
      })
    }
    if (obj.enableCostCenter) {
      if (!costCenters.length) {
        setLoading(false);
        snackbar({
          message: "Minimum 1 cost center has to be added",
          variant: SnackBarVariant.ERROR
        })
        return
      } else if (costCenters.some((item) => !item.name)) {
        setLoading(false);
        snackbar({
          message: "Cost center name cannot be empty!",
          variant: SnackBarVariant.ERROR
        })
        return
      }
    }
    if (obj.maxImagesPerDamage && obj.maxImagesPerDamage > 10) {
      setLoading(false);
      snackbar({
        message: "Maximum Images that can be added per damage is 10",
        variant: SnackBarVariant.ERROR
      })
      return
    }
    if (obj.enableAutoCreditCheck) {
      const isValid = validateEmptyCheck(obj.creditLimits);
      if (!isValid) {
        return snackbar({
          message: "Upper limit can't be empty.",
          variant: SnackBarVariant.ERROR
        });
      }
    }
    if (obj.enableAutoCreditCheck && nullCreditCheck(obj.creditLimits)) {
      setLoading(false);
      return snackbar({
        message: "Please enter Credit Check Limits",
        variant: SnackBarVariant.ERROR
      });
    } else if (obj.enableAutoCreditCheck && obj.creditLimits && !validateCreditLimits(obj.creditLimits)) {
      setLoading(false);
      return snackbar({
        message: "Credit Check Limits are not correct",
        variant: SnackBarVariant.ERROR
      });
    } else {
      setLoading(true)
      if (props.organisation.id) {
        onUpdateOrganisation({
          variables: {
            organisation: obj,
            organisationId: props.organisation.id
          }
        });
      } else {
        addOrganisation({
          variables: {
            organisation: obj,
            tenancyId: userReducer.tenancy.id
          }
        });
      }
    }
  };

  async function setTermsAndConditions(file: any) {
    setDocumentDialogVisible(false)
    const fileToUpload = file[0]
    try {
      if (fileToUpload.type === "application/pdf" && fileToUpload.size > 52428800) {
        throw new Error("File size exceeded limit of 50MB");
      }
      if (isImageFileType(fileToUpload.type) && fileToUpload.size > 5242880) {
        throw new Error("File size exceeded limit of 5MB");
      }
      if (checkUploadFileFormat(fileToUpload.type)) {
        const { fileExtension, contentType } = uploadFileExtensionAndContentType(
          fileToUpload.type
        );
        const key = `${userReducer.tenancy.id}/${uniqueId}-tnc.${fileExtension}`;
        if (_e_) {
          setTermsAndConditionsLoading(true);
          await _e_
            .add({
              name: key,
              file: fileToUpload,
              complete: async () => {
                setTermsAndConditionsUrl(key);
                setValues({
                  ...values,
                  termsAndConditionsUrl: key
                })
                snackbar({
                  message: "Terms And Condition uploaded",
                  variant: SnackBarVariant.SUCCESS
                });
              }
            });
          setTermsAndConditionsLoading(false);
        }
      } else {
        setTermsAndConditionsLoading(false);
        return snackbar({
          message: "Please only upload PDF, PNG, JPG or JPEG file types",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err) {
      captureErrorException(err);
      if (err instanceof Error) {
        snackbar({ message: err.message, variant: SnackBarVariant.ERROR });
      }
    }
  }
  // FIXME:Remove this function if it not used
  function removeTermsAndConditions() {
    const key = `${values.id}.pdf`;
    // TODO: implement file deleteion from s3
    setValues({ ...values, termsAndConditionsUrl: "" });
  }

  async function downloadTermsAndConditions(conditionsUrl: string) {
    const { fileExtension, contentType } = checkDeleteOrDownloadFileType(
      conditionsUrl
    );
    const config = { contentType, level: "public" };
    const file = await getSignedUrl(conditionsUrl)
    const url: any = file;
    fetch(url, {
      method: "GET"
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `TermsAndConditions.${fileExtension}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  const setLogoUpload = async (file: any) => {
    const logoFile = file[0]
    setlogoUploadloading(true);
    setOrgLogoDialogVisible(false)
    const { fileExtension, contentType } = returnfileContentTypeAndExtention(logoFile);
    if (fileExtension === "png" || fileExtension === "jpg" ||
      fileExtension === "jpeg" || fileExtension === "bmp") {
      const key = `${uniqueId}.${fileExtension}`;
      if (_e_) {
        await _e_
          .add({
            name: key,
            file: logoFile,
            complete: async () => {
              const image = await getImage(key)
              setLogoUrl(key);
              setLogo(image)
              setlogoUploadloading(false);
              snackbar({
                message: "Profile Image uploaded successfully",
                variant: SnackBarVariant.SUCCESS
              });
            }
          });
      }
    } else {
      setTimeout(() => {
        return snackbar({
          message: "Invalid Image type. Supported types: .png/.jpeg/.jpg/.bmp",
          variant: SnackBarVariant.ERROR
        });
      }, 2000);
    }
  };

  const getLogoUpload = async (logoUrl: string) => {
    const config = { contentType: "image/*", level: "public" };
    const file = await getSignedUrl(logoUrl)
    setLogo(file);
  };

  const organisationSchema = Yup.object().shape({
    emailId: Yup.string()
      .required("Email is required.")
      .email("Please enter a valid email address."),
    name: Yup.string().required("Organisation name is required."),
    owner: Yup.string().required("Please select a Manager"),
    orgRegNumber: Yup.string().required("Registration number is required."),
    phoneNumber: Yup.object().shape({
      phone: Yup.string()
        .test("test-is-b-valid-phone-number", "", function (value) {
          if (value && !isValidPhoneNumber(value)) {
            return this.createError({ message: "Invalid phone number" });
          }
          return true;
        })
        .required("Phone number is required.")
    }),
    address: Yup.object().shape({
      fullAddress: Yup.string().required("Address is required"),
      country: Yup.string().required("Country is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      street: Yup.string().required("Street is required"),
      zipcode: Yup.string().required("Zip/Postal Code is required")
    }).required("Address is required"),
  });

  const addRows = () => {
    const rowsArr = [...costCenters];
    rowsArr.push({
      name: "",
      projectIds: []
    });
    setCostCenters(rowsArr);
  };

  const initiateSageOauth = props.initiateSageOauth;

  const [removeBusinessCustomerTag] = useMutation(REMOVE_BUSINESS_CUSTOMER_TAG, {
    onCompleted(data) {
      if (data && data.removeBusinessCustomerTag) {
        snackbar({
          message: data.removeBusinessCustomerTag.message,
          variant: SnackBarVariant.SUCCESS
        });
      }
    },
  })

  const removeTag = () => {
    const updatedTags = values.businessCustomerTags && values.businessCustomerTags.filter(tag => tag !== tagToRemove);
    setValues({
      ...values,
      businessCustomerTags: updatedTags
    });
    removeBusinessCustomerTag({
      variables: {
        tag: tagToRemove
      }
    })
  };

  const handleTags = (tags: any) => {
    const addedTag = tags.find((tag: any) => values.businessCustomerTags && !values.businessCustomerTags.includes(tag));
    const removedTag = values.businessCustomerTags && values.businessCustomerTags.find(tag => !tags.includes(tag));

    if (addedTag) {
      setValues({
        ...values,
        businessCustomerTags: tags
      });
    } else if (removedTag) {
      setTagToRemove(removedTag);
      setOpenModal(true);
    }
  };

  const handleConfirmation = () => {
    removeTag();
    setOpenModal(false)
  };

  const [selectedRule, setSelectedRule] = useState<string | null>(null);
  const [selectedBookingType, setSelectedBookingType] = useState<string | null>(null);

  const handleRuleChange = (newValue: string) => {
    setSelectedRule(newValue);
    if (newValue === RuleItems.TYPE_OF_BOOKING) {
      setSelectedBookingType(null);
    } else if (newValue && !isRuleSelected(newValue)) {
      addRule(newValue);
    }
  };

  const addRule = (rule: string) => {
    if (!isRuleSelected(rule)) {
      setValues((prevValues) => ({
        ...prevValues,
        driverAllocationRules: [...(prevValues.driverAllocationRules || []), rule],
      }));
    }
    setSelectedRule(null);
    setSelectedBookingType(null);
  };

  const addRuleWithBookingType = (bookingType: string) => {
    if (selectedRule && !isRuleSelected(`${selectedRule} - ${bookingType}`)){
      addRule(`${selectedRule} - ${bookingType}`);
    };
  };

  const removeRule = (ruleToRemove: string) => {
    setValues((prevValues) => ({
      ...prevValues,
      driverAllocationRules: prevValues.driverAllocationRules?.filter((rule) => rule !== ruleToRemove) || [],
    }))
  };

  const isRuleSelected = (ruleLabel: string) => {
    const normalizedRuleLabel = ruleLabel.trim().toLowerCase();
    return values.driverAllocationRules?.some((rule) => rule.trim().toLowerCase() === normalizedRuleLabel) ?? false;
  };

  console.log("values", )

  return (
    <Grid container style={{ padding: 30 }}>
      <Formik
        enableReinitialize
        validationSchema={organisationSchema}
        initialValues={values}
        onSubmit={(values, { setSubmitting }) => {
          const updateValues = {
            ...values,
            phoneNumber: {
              ...values.phoneNumber,
              country: countryCode
            }
          };
          onSubmitHandler(updateValues);
          setSubmitting(false);
        }}
      >
        {(props) => (
          <Form noValidate>
            <Grid container item xs={12}>
              <Grid item container xs={12} spacing={2}>
                <Grid
                  item
                  className={"new-form-update " + isUpdateScreen()}
                  container
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                >
                  <Field
                    component={TextField}
                    name="name"
                    placeholder={`${getLocalizedOrganisationSyntex(country)} Name`}
                    label={`${getLocalizedOrganisationSyntex(country)} Name`}
                    value={values.name}
                    inputProps={{ maxLength: 75 }}
                    onChange={handleChange}
                    fullWidth
                    required
                    disabled={userReducer.role === UserRoles.BRANCH_MANAGER}
                  />
                </Grid>
                <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      component={TextField}
                      name={"owner"}
                      fullWidth
                      select
                      label="Manager"
                      value={props.values.owner}
                      InputLabelProps={{
                        shrink: true
                      }}
                      required
                      disabled={userReducer.role === UserRoles.BRANCH_MANAGER}
                      InputProps={{
                        onChange: (event: any) => {
                          setValues({
                            ...props.values,
                            owner: event.target.value
                          });
                        }
                      }}
                    >
                      {usersList &&
                        usersList.users.map((user: any) => (
                          <MenuItem key={user.id} value={user.id}>
                            {user.email}
                          </MenuItem>
                        ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  className={"new-form-update " + isUpdateScreen()}
                  container
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                >
                  <Field
                    component={TextField}
                    placeholder="Email"
                    label="Email"
                    name={"emailId"}
                    type="email"
                    value={values.emailId}
                    onChange={handleChange}
                    fullWidth
                    required
                    disabled={userReducer.role === UserRoles.BRANCH_MANAGER}
                  ></Field>
                </Grid>
                <Grid
                  item
                  className={"new-form-update " + isUpdateScreen()}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                >
                  <Field
                    component={TextField}
                    name={"phoneNumber.phone"}
                    required
                  >
                    {({ field, form: { setFieldValue, setTouched, touched, errors }, meta }: FieldProps) => {
                      return (
                        <div className={`phone-input-container`}>
                          <label className={`${phoneError ? "input-error-label" : ""} phone-input-label`}>
                            Phone Number <sup className={"phone-input-required"}>*</sup>
                          </label>
                          <PhoneInput
                            international={false}
                            defaultCountry={countryCode}
                            placeholder="Enter phone number"
                            className={phoneError ? "phone-input-error" : ""}
                            value={values.phoneNumber.phone}
                            name={"phoneNumber.phone"}
                            required
                            onChange={(val: any) => {
                              if (val && isValidPhoneNumber(val)) {
                                setPhoneError("");
                              }
                              setValues({
                                ...props.values,
                                phoneNumber: {
                                  ...props.values.phoneNumber,
                                  phone: val
                                }
                              });
                            }}
                            onBlur={(val) => {
                              if (!props.values.phoneNumber.phone) {
                                setPhoneError("Phone number is required.");
                              } else if (!isValidPhoneNumber(props.values.phoneNumber.phone)) {
                                setPhoneError("Enter a valid phone number.");
                              } else {
                                setPhoneError("");
                              }
                            }}
                            onCountryChange={(val) => {
                              setCountryCode(val);
                            }}
                          />
                          {
                            phoneError !== "" ?
                              <span className={"phone-error-message"}>{phoneError}</span>
                              : (meta.touched && meta.error) &&
                              <span className={"phone-error-message"}>
                                {phoneError || "Phone number is required."}
                              </span>
                          }
                        </div>
                      );
                    }}
                  </Field>
                </Grid>
                <Grid
                  item
                  className={"new-form-update " + isUpdateScreen()}
                  container
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                >
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      component={InputField}
                      name={"currency"}
                      fullWidth
                      select
                      label="Currency"
                      value={values.currency}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        onChange: (event: any) => {
                          setValues({
                            ...props.values,
                            currency: event.target.value
                          });
                        }
                      }}
                      disabled={userReducer.role === UserRoles.BRANCH_MANAGER}
                    >
                      {CURRENCY_LIST.map((currency) => (
                        <MenuItem key={currency.name} value={currency.value}>
                          {currency.label} ({currency.value})
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  className={"new-form-update " + isUpdateScreen()}
                  container
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                >
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      component={InputField}
                      name={"locale"}
                      fullWidth
                      select
                      label="Language"
                      value={props.values.locale}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        onChange: (event: any) => {
                          setValues({
                            ...props.values,
                            locale: event.target.value
                          });
                        }
                      }}
                      disabled={userReducer.role === UserRoles.BRANCH_MANAGER}
                    >
                      {LANGUAGE_LIST.map((language) => (
                        <MenuItem key={language.name} value={language.value}>
                          {language.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  className={"new-form-update " + isUpdateScreen()}
                  container
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                >
                  <Field
                    component={TextField}
                    placeholder={getLocalizedVatNumberSyntex(country)}
                    label={getLocalizedVatNumberSyntex(country)}
                    name={"orgVatNumber"}
                    value={values.orgVatNumber}
                    onChange={handleChange}
                    fullWidth
                    disabled={userReducer.role === UserRoles.BRANCH_MANAGER}
                  ></Field>
                </Grid>
                <Grid
                  item
                  className={"new-form-update " + isUpdateScreen()}
                  container
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                >
                  <Field
                    component={TextField}
                    placeholder="Registration Number"
                    label="Registration Number"
                    name={"orgRegNumber"}
                    value={values.orgRegNumber}
                    onChange={handleChange}
                    fullWidth
                    required
                    disabled={userReducer.role === UserRoles.BRANCH_MANAGER}
                  ></Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <AddressPicker
                    fieldName="address"
                    required
                    onChange={(address: IAddress) => {
                      if (address) {
                        setValues({
                          ...props.values,
                          address
                        })
                      }
                    }}
                    addGeometry={true}
                    disabled={userReducer.role === UserRoles.BRANCH_MANAGER}
                  />
                </Grid>
                {/* {props.values.taxes.map((tax: ITax, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Grid
                        item
                        className={"new-form-update " + isUpdateScreen()}
                        container
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                      >
                        <Field
                          component={TextField}
                          placeholder="VAT"
                          label="VAT/TAX %"
                          name={tax.name}
                          InputProps={{
                            onChange: (event: any) =>
                              handleTaxChange(event, tax.name),
                            value: tax.rate,
                            inputComponent: FloatInput as any
                          }}
                          inputProps={{
                            hasCurrencyPrefix: false,
                            allowNegative: false
                          }}
                          fullWidth
                          required
                          disabled={
                            userReducer.role === UserRoles.BRANCH_MANAGER
                          }
                        ></Field>
                      </Grid>
                    </React.Fragment>
                  );
                })} */}
                <Grid item container xs={12}>
                  <Grid xs={12} sm={6} item spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography variant="h4">
                        UPLOAD TERMS AND CONDITIONS DOCUMENT:
                      </Typography>
                    </Grid>
                    <Grid item container>
                      {" "}
                      <Box mt={2}></Box>
                    </Grid>
                    {termsAndConditionsUrl && (
                      <Grid container item xs={12}>
                        <ViewTermsAndConditions
                          termsAndConditions={termsAndConditionsUrl}
                          removeTermsAndConditions={removeTermsAndConditions}
                          downloadTermsAndConditions={
                            downloadTermsAndConditions
                          }
                          disabled={
                            userReducer.role === UserRoles.BRANCH_MANAGER
                          }
                        />
                      </Grid>
                    )}
                    <Box mt={1}></Box>
                    <Grid item xs={12}>
                      {userReducer &&
                        (userReducer.role === UserRoles.SUPER_ADMIN ||
                          userReducer.role === UserRoles.ADMIN ||
                          userReducer.role === UserRoles.ORGANISATION_OWNER) && (
                          <>
                            <Grid>
                              <FilePicker
                                onFilePick={(e: any) => {
                                  setTermsAndConditions(e.target.files)
                                }}
                                accept={"image/jpeg, image/png, image/jpg, application/pdf"}
                                title="UPLOAD A FILE"
                              />
                            </Grid>
                            <Box mt={1}></Box>
                            <Grid item>
                              {termsAndConditionsLoading && <Typography><CircularProgress size={20} style={{ color: "white", marginRight: "10px" }} /><strong>The File is being loaded please wait</strong>  </Typography>}
                              <Typography variant="h4">
                                Supported file format: .pdf, .png, .jpg, .jpeg
                              </Typography>
                              <Typography variant="h4">
                                Maximum supported file size of 50 MB for PDF.
                              </Typography>
                              <Typography variant="h4">
                                Maximum supported file size of 5 MB for image files.
                              </Typography>
                            </Grid>
                          </>
                        )}
                    </Grid>
                  </Grid>
                  <Grid xs={12} sm={12} md={6} lg={6} xl={6} spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography variant="h4">UPLOAD LOGO:</Typography>
                    </Grid>
                    <Grid item container>
                      {" "}
                      <Box mt={2}></Box>
                    </Grid>
                    {logo && <ViewLogo logoUrl={logo} />}
                    <Grid item xs={12}>
                      <Box mt={1}></Box>
                    </Grid>
                    {userReducer &&
                      (userReducer.role === UserRoles.SUPER_ADMIN ||
                        userReducer.role === UserRoles.ADMIN ||
                        userReducer.role === UserRoles.ORGANISATION_OWNER) && (
                        <>
                          <Grid>
                            <Fab
                              className="blackBackButton"
                              variant="extended"
                              size="medium"
                              aria-label="Update"
                              onClick={() => {
                                setOrgLogoDialogVisible(true);
                              }}
                            >
                              UPLOAD LOGO
                            </Fab>
                          </Grid>
                          <Box mt={1}></Box>
                          <Grid item>
                            {logoUploadloading && <Typography><CircularProgress size={20} style={{ color: "white", marginRight: "10px" }} /><strong>The File is being loaded please wait</strong>  </Typography>}
                            <Typography variant="h4">
                              Maximum file size of 50 MB. Supported file formats: .jpg/.jpeg/.png/.bmp
                            </Typography>
                            <Typography variant="body1">
                              For better experience, logo size should be lesser than 100x150(height x width) pixels.
                            </Typography>
                          </Grid>
                        </>
                      )}
                  </Grid>
                  <DragDropImages
                    title={"Upload organisation Logo"}
                    open={orgLogoDialogVisible}
                    handleCloseDragDrop={() => setOrgLogoDialogVisible(false)}
                    handleSave={setLogoUpload}
                    filesLimit={1}
                    initialFiles={uploadedOrgImage}
                  />
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} style={{ marginTop: 15 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={props.values.requiresCustomerVerification ? props.values.requiresCustomerVerification : false}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              // setZeroCostBooking(event.target.checked);
                              setValues({
                                ...values,
                                requiresCustomerVerification: event.target.checked
                              })
                            }}
                            name="requiresCustomerVerification"
                            color="primary"
                          />
                        }
                        label={<>
                          <b>Mandate Customer Verification &amp; Approval</b>
                          <p style={{ margin: 0 }}>{`Customer will have to provide profile photo, driving license, and insurance details and be manually verified before making a ${getLocalizedBookingSyntex(country)} via the website and the mobile customer applications.`}</p>
                        </>}
                      // disabled={props.bookingData.id ? true : false}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} style={{ marginTop: 15 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={props.values.allowCarSharing ? props.values.allowCarSharing : false}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setValues({
                                ...values,
                                allowCarSharing: event.target.checked
                              })
                            }}
                            name="allowCarSharing"
                            color="primary"
                          />
                        }
                        label={<>
                          <b>Enable Car Sharing</b>
                          <p style={{ margin: 0 }}>{`This will allow the ability to skip operators checks between rentals for selected vehicles`}</p>
                        </>}
                      />
                    </Grid>
                  </Grid>
                  {props.values.allowCarSharing &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid item xs={12} sm={12} md={8} lg={8} xl={8} style={{ marginTop: 15 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={props.values.disableMapForCarShareVehicles ? props.values.disableMapForCarShareVehicles : false}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setValues({
                                  ...values,
                                  disableMapForCarShareVehicles: event.target.checked
                                })
                              }}
                              name="disableMapForCarShareVehicles"
                              color="primary"
                            />
                          }
                          label={<>
                            <b>Disable Map for Car Share Vehicles</b>
                            <p style={{ margin: 0 }}>{`This option will hide the car share vehicles selection from the map in your customer portal app.`}</p>
                          </>}
                        />
                      </Grid>
                    </Grid>

                  }

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} style={{ marginTop: 15 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={props.values.inspectionCheckEnabled ? props.values.inspectionCheckEnabled : false}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setValues({
                                ...values,
                                inspectionCheckEnabled: event.target.checked
                              })
                            }}
                            name="inspectionCheckEnabled"
                            color="primary"
                          />
                        }
                        label={<>
                          <b>Enable Vehicle Inspection Checks</b>
                          <p style={{ margin: 0 }}>{`This will allow the ability to capture additional images of a vehicles state during check-in and check-out of the vehicle`}</p>
                        </>}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 15 }}>
                    <Typography variant="h4"><span style={{ textTransform: "capitalize" }}>{getLocalizedBookingSyntex(country)}</span> Categories Settings</Typography>
                    <Grid item xs={6} container spacing={2}>
                      {
                        props.values.bookingCategories.map((option, index) => {
                          const isCarShareEnabled = option.type === BOOKING_CATEGORY.SUBSCRIPTION && !userReducer.currentTenancy.carSubscriptionEnabled
                          return (<React.Fragment key={option.type}>
                            <Grid item xs={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={option.isActive}
                                    disabled={isCarShareEnabled}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      if (e.target.checked) {
                                        setValues({
                                          ...values,
                                          bookingCategories: [
                                            ...values.bookingCategories.slice(0, index),
                                            {
                                              ...values.bookingCategories[index],
                                              isActive: e.target.checked
                                            },
                                            ...values.bookingCategories.slice(index + 1)
                                          ]
                                        })
                                      } else {
                                        setValues({
                                          ...values,
                                          bookingCategories: [
                                            ...values.bookingCategories.slice(0, index),
                                            {
                                              ...values.bookingCategories[index],
                                              isActive: e.target.checked,
                                              default: e.target.checked
                                            },
                                            ...values.bookingCategories.slice(index + 1)
                                          ]
                                        })
                                      }

                                    }}
                                  />
                                }
                                label={bookingCategoryLabels[option.type]}
                              />
                            </Grid>
                            <Grid item xs={6} container alignItems={"center"}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={option.default}
                                    disabled={!option.isActive}
                                    onChange={() => {
                                      setValues({
                                        ...values,
                                        bookingCategories: values.bookingCategories.map((type, i) => ({
                                          ...type,
                                          default: i === index,
                                        })),
                                      });
                                    }}
                                  />
                                }
                                label="Set Default"
                              />
                              {
                                isCarShareEnabled && (
                                  <IconButton>
                                    <Tooltip title={<Typography variant="h4" sx={{ color: "#fff" }}>{"The subscription settings will be available once you have activated a car subscription feature."}</Typography>}>
                                      <InfoIcon />
                                    </Tooltip>
                                  </IconButton>
                                )
                              }

                            </Grid>
                          </React.Fragment>
                          )
                        })
                      }
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 15 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.requiresBookingApproval ? true : false}
                            onClick={(e: any) => {
                              setValues({
                                ...values,
                                requiresBookingApproval: e.target.checked
                              })
                            }}
                            value="requiresBookingApproval"
                          />
                        }
                        label={<b>{getLocalizedBookingSyntex(country)}s from Website/Customer Portals requires Approval</b>}
                      />
                    </Grid>
                  </Grid>
                  {userReducer.currentTenancy.creditCheckEnabled &&
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 15 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.enableAutoCreditCheck ? true : false}
                              onClick={(e: any) => {
                                setValues({
                                  ...values,
                                  enableAutoCreditCheck: e.target.checked
                                })
                              }}
                              value="creditCheckConfiguration"
                            />
                          }
                          label={<b>Enable auto credit check</b>}
                        />
                      </Grid>
                    </Grid>
                  }
                  {userReducer.currentTenancy.idvEnabled &&
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 15 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.enableIdentityVerification ? true : false}
                              onClick={(e: any) => {
                                setValues({
                                  ...values,
                                  enableIdentityVerification: e.target.checked
                                })
                              }}
                              value="enableIdentityVerification"
                            />
                          }
                          label={<b>Enable Auto Identity Verification</b>}
                        />
                      </Grid>
                    </Grid>
                  }
                  {values.enableAutoCreditCheck &&
                    <Grid item xs={6} container justifyContent="flex-end">
                      <Grid item container xs={12} sm={12} style={{ marginTop: "20px" }}>
                        <Grid item container xs={3} sm={3}>
                          <Typography variant="h4">Auto Rejection</Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={6} sm={3}
                          style={{ paddingRight: "10px" }}
                        >
                          <Field
                            component={InputField}
                            fullWidth
                            placeholder="Lower Limit"
                            label="Lower Limit"
                            name={"lowerLimit"}
                            type="number"
                            step={0.1}
                            InputProps={{
                              value: (values.creditLimits && values.creditLimits.length > 0 ? ([...values.creditLimits].filter(x => x.type === "AUTO_REJECT"))[0].lowerLimit : 0) || 0,
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const val = parseFloat(e.target.value);
                                let limits: ICreditLimits[] = [...values.creditLimits || []]

                                let idx = limits.findIndex(x => x.type === "AUTO_REJECT")
                                if (val < 0) {
                                  snackbar({
                                    message: "Limit cannot be negative",
                                    variant: SnackBarVariant.ERROR,
                                    duration: 1000
                                  })
                                } else {
                                  const newUpdatedLimits: ICreditLimits[] = [
                                    ...limits.slice(0, idx),
                                    {
                                      ...limits[idx],
                                      lowerLimit: val
                                    },
                                    ...limits.slice(idx + 1)
                                  ]
                                  setValues({
                                    ...values,
                                    creditLimits: newUpdatedLimits
                                  })
                                }
                              },
                            }}
                            validate={() => {
                            }}
                            inputProps={{
                              allowNegative: false,
                              maxlength: 9
                            }}
                          ></Field>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={6} sm={3}
                          style={{ paddingRight: "10px" }}
                        >
                          <Field
                            component={InputField}
                            fullWidth
                            placeholder="Upper Limit"
                            label="Upper Limit"
                            name={"upperLimit"}
                            type="number"
                            step={0.1}
                            InputProps={{
                              value: values.creditLimits ? ([...values.creditLimits].filter(x => x.type === "AUTO_REJECT"))[0].upperLimit : 0,
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const val = parseFloat(e.target.value);
                                let limits: ICreditLimits[] = [...values.creditLimits || []]
                                let idx = limits.findIndex(x => x.type === "AUTO_REJECT")

                                if (val <= limits[idx].lowerLimit) {
                                  snackbar({
                                    message: "Upper Limit should be greater than lower limit",
                                    variant: SnackBarVariant.ERROR,
                                    duration: 1000
                                  })
                                } else {
                                  const newUpdatedLimits: ICreditLimits[] = [
                                    ...limits.slice(0, idx),
                                    {
                                      ...limits[idx],
                                      upperLimit: val
                                    },
                                    ...limits.slice(idx + 1)
                                  ]
                                  setValues({
                                    ...values,
                                    creditLimits: newUpdatedLimits

                                  })
                                }

                              },
                            }}
                            validate={() => {

                            }}
                            inputProps={{
                              allowNegative: false,
                              maxlength: 9
                            }}
                          ></Field>
                        </Grid>

                      </Grid>
                      <Grid item container xs={12} sm={12} style={{ marginTop: "20px" }}>
                        <Grid item container xs={3} sm={3}>
                          <Typography variant="h4">Approval Required</Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={6} sm={3}
                          style={{ paddingRight: "10px" }}
                        >
                          <Field
                            component={InputField}
                            fullWidth
                            placeholder="Lower Limit"
                            label="Lower Limit"
                            name={"lowerLimit"}
                            type="number"
                            step={0.1}
                            InputProps={{
                              value: values.creditLimits ? ([...values.creditLimits].filter(x => x.type === "APPROVAL_REQUIRED"))[0].lowerLimit : 0,
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const val = parseFloat(e.target.value);
                                let limits: ICreditLimits[] = [...values.creditLimits || []]
                                let idx = limits.findIndex(x => x.type === "APPROVAL_REQUIRED")
                                if (val <= limits[idx - 1].upperLimit) {
                                  snackbar({
                                    message: "Approval required limit should be greater than Auto Rejection upper limit",
                                    variant: SnackBarVariant.ERROR,
                                    duration: 1000
                                  })
                                }
                                const newUpdatedLimits: ICreditLimits[] = [
                                  ...limits.slice(0, idx),
                                  {
                                    ...limits[idx],
                                    lowerLimit: val
                                  },
                                  ...limits.slice(idx + 1)
                                ]
                                setValues({
                                  ...values,
                                  creditLimits: newUpdatedLimits

                                })
                              }
                            }}
                            validate={() => {

                            }}
                            inputProps={{
                              allowNegative: false,
                              maxlength: 9
                            }}
                          ></Field>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={6} sm={3}
                          style={{ paddingRight: "10px" }}
                        >
                          <Field
                            component={InputField}
                            fullWidth
                            placeholder="Upper Limit"
                            label="Upper Limit"
                            name={"upperLimit"}
                            type="number"
                            step={0.1}
                            InputProps={{
                              value: values.creditLimits ? (values.creditLimits.filter(x => x.type === "APPROVAL_REQUIRED"))[0].upperLimit : 0,
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const val = parseFloat(e.target.value);
                                let limits: ICreditLimits[] = [...values.creditLimits || []]
                                let idx = limits.findIndex(x => x.type === "APPROVAL_REQUIRED")
                                if (val <= limits[idx].lowerLimit) {
                                  snackbar({
                                    message: "Upper Limit should be greater than lower limit",
                                    variant: SnackBarVariant.ERROR,
                                    duration: 1000
                                  })
                                } else {
                                  const newUpdatedLimits: ICreditLimits[] = [
                                    ...limits.slice(0, idx),
                                    {
                                      ...limits[idx],
                                      upperLimit: val
                                    },
                                    ...limits.slice(idx + 1)
                                  ]
                                  setValues({
                                    ...values,
                                    creditLimits: newUpdatedLimits

                                  })
                                }

                              }
                            }}
                            validate={() => {
                              // if (< 0) {
                              //   return `Time cannot be a negative value`
                              // }
                            }}
                            inputProps={{
                              allowNegative: false,
                              maxlength: 9
                            }}
                          ></Field>
                        </Grid>

                      </Grid>
                      <Grid item container xs={12} sm={12} style={{ marginTop: "20px" }} >
                        <Grid item container xs={3} sm={3}>
                          <Typography variant="h4">Auto Approval</Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={6} sm={3}
                          style={{ paddingRight: "10px" }}
                        >
                          <Field
                            component={InputField}
                            fullWidth
                            placeholder="Lower Limit"
                            label="Lower Limit"
                            name={"lowerLimit"}
                            type="number"
                            step={0.1}
                            InputProps={{
                              value: values.creditLimits ? (values.creditLimits.filter(x => x.type === "AUTO_APPROVE"))[0].lowerLimit : 0,
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const val = parseFloat(e.target.value);
                                let limits: ICreditLimits[] = [...values.creditLimits || []]
                                let idx = limits.findIndex(x => x.type === "AUTO_APPROVE")
                                if (val <= limits[idx - 1].upperLimit) {
                                  snackbar({
                                    message: "Auto Approval limit should be greater than approval required limit",
                                    variant: SnackBarVariant.ERROR,
                                    duration: 1000
                                  })
                                }
                                if (limits[idx - 1].upperLimit === 0 && val <= limits[idx - 2].upperLimit) {
                                  snackbar({
                                    message: "Auto Approval limit should be greater than auto rejection limit",
                                    variant: SnackBarVariant.ERROR,
                                    duration: 1000
                                  })
                                } else {
                                  const newUpdatedLimits: ICreditLimits[] = [
                                    ...limits.slice(0, idx),
                                    {
                                      ...limits[idx],
                                      lowerLimit: val
                                    },
                                    ...limits.slice(idx + 1)
                                  ]
                                  setValues({
                                    ...values,
                                    creditLimits: newUpdatedLimits

                                  })
                                }

                              }
                            }}
                            validate={() => {
                              // if ( < 0) {
                              //   return `Time cannot be a negative value`
                              // }
                            }}
                            inputProps={{
                              allowNegative: false,
                              maxlength: 9
                            }}
                          ></Field>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={6} sm={3}
                          style={{ paddingRight: "10px" }}
                        >
                          <Field
                            component={InputField}
                            fullWidth
                            placeholder="Upper Limit"
                            label="Upper Limit"
                            name={"upperLimit"}
                            type="number"
                            step={0.1}
                            InputProps={{
                              value: values.creditLimits ? (values.creditLimits.filter(x => x.type === "AUTO_APPROVE"))[0].upperLimit : 0,
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const val = parseFloat(e.target.value);
                                let limits: ICreditLimits[] = [...values.creditLimits || []]
                                let idx = limits.findIndex(x => x.type === "AUTO_APPROVE")
                                if (val <= limits[idx].lowerLimit) {
                                  snackbar({
                                    message: "Upper Limit should be greater than lower limit",
                                    variant: SnackBarVariant.ERROR,
                                    duration: 1000
                                  })
                                } else {
                                  const newUpdatedLimits: ICreditLimits[] = [
                                    ...limits.slice(0, idx),
                                    {
                                      ...limits[idx],
                                      upperLimit: val
                                    },
                                    ...limits.slice(idx + 1)
                                  ]
                                  setValues({
                                    ...values,
                                    creditLimits: newUpdatedLimits

                                  })
                                }

                              }
                            }}
                            validate={() => {
                              // if (< 0) {
                              //   return `Time cannot be a negative value`
                              // }
                            }}
                            inputProps={{
                              allowNegative: false,
                              maxlength: 9
                            }}
                          ></Field>
                        </Grid>

                      </Grid>

                    </Grid>
                  }
                </Grid>
                <Grid container xs={12} style={{ marginLeft: "1rem" }}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 15 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.enableCostCenter ? true : false}
                            onClick={(e: any) => {
                              setValues({
                                ...values,
                                enableCostCenter: e.target.checked
                              })
                              if (!costCenters.length) {
                                addRows()
                              }
                            }}
                            value="enableCostCenter"
                          />
                        }
                        label={<b>Enable Cost Center Details</b>}
                      />
                    </Grid>
                  </Grid>
                  {
                    values.bookingCategories.some(category => category.type === BOOKING_CATEGORY.SUBSCRIPTION && category.isActive) && (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 15 }}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.allowPreSigningAgreement ? true : false}
                                onClick={(e: any) => {
                                  setValues({
                                    ...values,
                                    allowPreSigningAgreement: e.target.checked
                                  })
                                }}
                                value="allowPreSigningAgreement"
                              />
                            }
                            label={<b>Allow Pre-signing agreement from mobile/customer portals</b>}
                          />
                        </Grid>
                      </Grid>
                    )
                  }
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                  {values.enableCostCenter &&
                    <>
                      <Grid container xs={12} style={{ marginBottom: "1rem" }}>
                        <Grid item xs={11}>
                          <Typography
                            variant={"subtitle1"}
                          >
                            Cost Center Details
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            variant={"subtitle1"}
                            onClick={addRows}
                            style={{ cursor: "pointer" }}
                          >
                            + Add New
                          </Typography>
                        </Grid>
                      </Grid>
                      {costCenters.map((item, idx) => {
                        return (<>
                          <Grid container xs={11} spacing={2} style={{ marginBottom: "0.8rem" }}>
                            <Grid item xs={12} md={4}>
                              <Field
                                component={TextField}
                                placeholder={"Cost Center Name"}
                                label={"Cost Center Name"}
                                name={"costCenterName"}
                                value={item.name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  const newItem = { ...item, name: e.target.value };
                                  const newCostCenters = [...costCenters];
                                  newCostCenters[idx] = newItem;
                                  setCostCenters(newCostCenters);
                                }}
                                fullWidth
                                disabled={userReducer.role === UserRoles.BRANCH_MANAGER}
                              ></Field>
                            </Grid>
                            <Grid container item xs={12} md={8}>
                              <NuvvenAutoCompleteTags
                                label="Project Ids"
                                placeHolder="Project Ids"
                                singleLine={true}
                                handleTags={(tags: string[]) => {
                                  const rowsArr = [...costCenters];
                                  const updatedItem = { ...costCenters[idx], projectIds: tags };
                                  rowsArr.splice(idx, 1, updatedItem);
                                  setCostCenters(rowsArr);
                                }}
                                customTags={true}
                                values={item.projectIds}
                                features={
                                  item.projectIds || []
                                }
                                columnLarge={12}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              variant={"subtitle1"}
                              style={{ cursor: "pointer", marginLeft: 20 }}
                              onClick={() => {
                                const rowsArr = [...costCenters];
                                rowsArr.splice(idx, 1);
                                setCostCenters(rowsArr);
                              }}
                            >
                              X
                            </Typography>
                          </Grid>
                        </>)
                      })}
                    </>
                  }
                </Grid>
                <Grid container xs={12} style={{ marginLeft: "1rem", marginTop: 20 }}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={props.values.crossLocationBookingEnabled ? true : false}
                          onClick={(e: any) => {
                            setValues({
                              ...values,
                              crossLocationBookingEnabled: e.target.checked
                            })
                          }}
                          value="crossLocationBookingEnabled"
                        />
                      }
                      label={<b>Enable One Way Rental Bookings</b>}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.closeGroupSharingEnabled ? true : false}
                            onClick={(e: any) => {
                              setValues({
                                ...values,
                                closeGroupSharingEnabled: e.target.checked
                              })
                            }}
                            value="closeGroupSharingEnabled"
                          />
                        }
                        label={<b>Enable Close Group Car Sharing</b>}
                      />
                    </Grid>
                  </Grid>

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid item xs={12} sm={6} style={{ marginTop: 15, display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                    <Typography variant="h4">
                      Maximum number of images per damage:
                    </Typography>
                    <Field
                      component={TextField}
                      placeholder="No. of Images"
                      label="No. of Images"
                      name={"imagesAllowed"}
                      InputProps={{
                        value: values.maxImagesPerDamage,
                        onChange: (e: any) => {
                          const val = parseInt(e.target.value) || 0;
                          if (val < 1) {
                            snackbar({
                              message: "Minimum no. of images per damage must be 1",
                              variant: SnackBarVariant.ERROR
                            })
                          }
                          if (val > 10) {
                            snackbar({
                              message: "Maximum no. of images per damage allowed must be less than 10",
                              variant: SnackBarVariant.ERROR
                            })
                          }
                          setValues({
                            ...values,
                            maxImagesPerDamage: val
                          })
                        },
                      }}
                      inputProps={{
                        allowNegative: false,
                      }}
                    ></Field>
                  </Grid>
                </Grid>
                {values.supplierPortalEnabled && (
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.enableDriverAllocationRules ? true : false}
                            onClick={(e: any) => {
                              setValues({
                                ...values,
                                enableDriverAllocationRules: e.target.checked
                              })
                            }}
                            value="enableDriverAllocationRules"
                          />
                        }
                        label={<b>Enable Driver Allocation Rules</b>}
                      />
                      {values.enableDriverAllocationRules && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{
                              marginTop: 15,
                              display: "flex",
                              alignItems: "baseline",
                              justifyContent: "space-between",
                              gap: "16px",
                            }}
                          >
                            <Grid item xs={12} md={6} style={{ display: "flex", alignItems: "center" }}>
                              <Autocomplete
                                id="rule-item-selector"
                                freeSolo={false}
                                fullWidth
                                options={preDefinedAllocationRules}
                                getOptionLabel={(option) => option}
                                value={selectedRule}
                                onChange={(event, newValue) => handleRuleChange(newValue!)}
                                onInputChange={(event, value) => setSelectedRule(value)}
                                onKeyDown={(event) => {
                                  if (event.key === "Enter" && selectedRule) {
                                    event.preventDefault();
                                    handleRuleChange(selectedRule);
                                  }
                                }}
                                renderInput={(params) => (
                                  <InputField
                                    {...params}
                                    label="Rule Items"
                                    placeholder="Select or Type a Rule Item"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>

                            {selectedRule === RuleItems.TYPE_OF_BOOKING && (
                              <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                  <InputLabel id="booking-type-label">Booking Type</InputLabel>
                                  <Select
                                    labelId="booking-type-label"
                                    value={selectedBookingType || ""}
                                    onChange={(event) => {
                                      setSelectedBookingType(event.target.value);
                                      addRuleWithBookingType(event.target.value);
                                    }}
                                    label="Booking Type"
                                  >
                                    {Object.entries(bookingCategoryLabels).map(([value, label]) => (
                                      <MenuItem key={value} value={label}>
                                        {label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            )}
                          </Grid>

                          {values.driverAllocationRules && values.driverAllocationRules.length > 0 && (
                            <Grid
                              item
                              xs={12}
                              md={6}
                              mt={2}
                            >
                              <Typography variant="h5">Selected Rules:</Typography>
                              {values.driverAllocationRules.map((rule, index) => (
                                <Chip
                                  key={index}
                                  label={rule}
                                  onDelete={() => removeRule(rule)}
                                  sx={{ margin: "5px" }}
                                />
                              ))}
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                  </>
                )}
                <Grid item container xs={12}>
                  <Typography variant="h4">
                    Business Customer Segmentation
                  </Typography>
                </Grid>
                <Grid container item >
                  <NuvvenAutoCompleteTags
                    handleTags={handleTags}
                    label="tags"
                    columnLarge={6}
                    values={values.businessCustomerTags ?? []}
                    customTags={true}
                    features={
                      values.businessCustomerTags && values.businessCustomerTags.length
                        ? values.businessCustomerTags
                        : []
                    }
                    placeHolder="Tags"
                  />
                </Grid>
              </Grid>

              <Grid item container>
                {" "}
                <Box mt={2}></Box>
              </Grid>
              <Grid item container xs={12}>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="add"
                  type="submit"
                  disabled={
                    loading ||
                    userReducer.role === UserRoles.BRANCH_MANAGER
                  }
                >
                  {loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                  Save
                </Fab>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <ConfirmationDialog
        isOpen={openModal}
        title="Remove Tag"
        description="Are you sure you want to remove this tag? If removed, all the tag linked to customers will be removed."
        onCancel={() => setOpenModal(false)}
        cancelText="Cancel"
        confirmText="Remove"
        onConfirm={handleConfirmation}
      />
    </Grid>
  );
};

export default Organisation;
