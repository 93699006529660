export const eventClasses = {
  availableForHire: "available-for-hire",
  outOnHire: "out-on-hire",
  inTransit: "in-transit",
  onHold: "on-hold",
  inService: "in-service",
  confirmed: "vehicle-confirmed",
  deactivated: "deactivated",
  inProgress: "in-progress",
  cancelled: "cancelled",
  immobilized: "immobilized",
  completed: "completed",
  isSharedFleetEvent: "shared-vehicle-event",
  TBA: "tba"
};
export enum VehicleStatus {
  OUT_ON_HIRE = "Out on Hire",
  ACTIVE = "Active",
  AVAILABLE_FOR_HIRE = "Available_for_hire",
  IN_SERVICE = "IN_SERVICE",
  DEACTIVATED = "Deactivated",
  ON_HOLD = "ON_HOLD",
  CONFIRMED = "CONFIRMED",
  IN_TRANSIT = "In Transit",
  IN_PROGRESS = "IN_PROGRESS",
  CANCELLED = "CANCELLED",
  IMMOBILIZED = "IMMOBILIZED",
  COMPLETED = "COMPLETED",
  TBA = "Flex"
}
export const getVehicleStatusClass = (
  status: string,
  isSharedFleet?: boolean
) => {
  switch (status) {
    case VehicleStatus.OUT_ON_HIRE:
      return [eventClasses.outOnHire];
    case VehicleStatus.IN_SERVICE:
      return [
        `${eventClasses.inService} ${isSharedFleet ? eventClasses.isSharedFleetEvent : ""
        }`
      ];
    case VehicleStatus.DEACTIVATED:
      return [eventClasses.deactivated];
    case VehicleStatus.ON_HOLD:
      return [
        `${eventClasses.onHold} ${isSharedFleet ? eventClasses.isSharedFleetEvent : ""
        }`
      ];
    case VehicleStatus.CONFIRMED:
      return [
        `${eventClasses.confirmed} ${isSharedFleet ? eventClasses.isSharedFleetEvent : ""
        }`
      ];
    case VehicleStatus.IN_TRANSIT:
      return [
        `${eventClasses.inTransit} ${isSharedFleet ? eventClasses.isSharedFleetEvent : ""
        }`
      ];
    case VehicleStatus.IN_PROGRESS:
      return [
        `${eventClasses.inProgress} ${isSharedFleet ? eventClasses.isSharedFleetEvent : ""
        }`
      ];
    case VehicleStatus.CANCELLED:
      return [eventClasses.cancelled];
    case VehicleStatus.IMMOBILIZED:
      return [eventClasses.immobilized];
    case VehicleStatus.COMPLETED:
      return [
        `${eventClasses.completed} ${isSharedFleet ? eventClasses.isSharedFleetEvent : ""
        }`
      ];
    case VehicleStatus.TBA:
      return[eventClasses.TBA]
    default:
      return [];
  }
};

export enum vehicleStatuses {
  service = "service",
  hold = "hold",
  bookingSchedule = "bookingSchedule",
  deactivate = "deactivate",
  confirmed = "confirmed"
}

export enum DialogTypes {
  BOOKING_SUMMARY = "BOOKING_SUMMARY",
  RESERVATION_SUMMARY = "RESERVATION_SUMMARY",
  HOLD_SUMMARY = "ON_HOLD_SUMMARY",
  NEW_EVENT = "NEW_EVENT",
  NEW_BOOKING = "NEW_BOOKING",
  SERVICE_SUMMARY = "SERVICE_SUMMARY",
  UPDATE_BOOKING = "UPDATE_BOOKING",
  EXTEND_BOOKING = "EXTEND_BOOKING",
  CANCEL_BOOKING = "CANCEL_BOOKING",
  COMPLETE_BOOKING = "COMPLETE_BOOKING",
  DROP_ERROR = "DROP_ERROR",
  DEACTIVATED_VEHICLE = "DEACTIVATED_VEHICLE",
  DROP_VEHICLE_GROUP = "DROP_VEHICLE_GROUP",
  SAME_DAY_CREATE_EVENT = "SAME_DAY_CREATE_EVENT",
  CONFIRM_BOOKING_UPDATE = "CONFIRM_BOOKING_UPDATE",
  DRAG_DROP_EVENT_ERROR = "DRAG_DROP_EVEN_ERROR",
  EXTEND_EVENT_ERROR = "EXTEND_EVENT_ERROR",
  EXTEND_MULTIPLE_EVENT_ERROR = "EXTEND_MULTIPLE_EVENT_ERROR",
  DRAG_IN_PROGRESS_ERROR = "DRAG_IN_PROGRESS_ERROR",
  EVENT_OVERLAP_ERROR = "EVENT_OVERLAP_ERROR",
  EVENT_EXISTS_ALREADY_ERROR = "EVENT_EXISTS_ALREADY_ERROR",
  VEHICLE_PREPARATION_TIME = "VEHICLE_PREPARATION_TIME",
  DAY_VIEW_DRAG_DROP_ERROR = "DAY_VIEW_DRAG_DROP_ERROR",
  VEHICLE_IN_TRANSFER_PROCESS = "VEHICLE_IN_TRANSFER_PROCESS"
}

export const DIALOG_TITLES: { [key in DialogTypes]: string } = {
  BOOKING_SUMMARY: "Booking Summary",
  RESERVATION_SUMMARY: "Reservation Summary",
  ON_HOLD_SUMMARY: "Hold Summary",
  NEW_EVENT: "Please Select Event Type",
  NEW_BOOKING: "Create New Booking",
  SERVICE_SUMMARY: "Service Summary",
  UPDATE_BOOKING: "Confirm Booking Update",
  EXTEND_BOOKING: "Extend Booking",
  CANCEL_BOOKING: "Cancel Booking",
  COMPLETE_BOOKING: "Complete Booking",
  DROP_ERROR: "Failed",
  DEACTIVATED_VEHICLE: "This Vehicle is deactivated",
  DROP_VEHICLE_GROUP: "Select Vehicle Group",
  SAME_DAY_CREATE_EVENT: "Event creation failed",
  CONFIRM_BOOKING_UPDATE: "Confirm booking update",
  DRAG_DROP_EVEN_ERROR: "Failed",
  EXTEND_EVENT_ERROR: "Failed",
  EXTEND_MULTIPLE_EVENT_ERROR: "Failed",
  DRAG_IN_PROGRESS_ERROR: "Failed",
  EVENT_OVERLAP_ERROR: "Failed",
  EVENT_EXISTS_ALREADY_ERROR: "Failed",
  VEHICLE_PREPARATION_TIME: "Vehicle Preparation",
  DAY_VIEW_DRAG_DROP_ERROR: "Failed",
  VEHICLE_IN_TRANSFER_PROCESS: "Event Creation failed"
};

export enum VehicleEventType {
  BOOKING_SCHEDULE = "BOOKING_SCHEDULE",
  IN_SERVICE = "IN_SERVICE",
  ON_HOLD = "ON_HOLD",
  IMMOBILISATION = "IMMOBILISATION",
  VEHICLE_PREPARATION_TIME = "VEHICLE_PREPARATION_TIME"
}

export const vehicleReplacementOptions: string[] = [
  "Vehicle Off Road (VOR)  - in case of an accident",
  "Tyres",
  "Glass",
  "Service/MOT",
  "Warranty",
  "Accident Damage",
  "Minor Bodywork",
  "Keys - Lost or require replacement",
  "Mechanical"
]

export enum ReplacementType {
  PERMANENT = "PERMANENT_RPLACEMENT",
  TEMPORARY_REPLACEMENT = "TEMPORARY_REPLACEMENT"
}

export const replacementTypeOptions = [
  {
    label: "Permanent",
    value: ReplacementType.PERMANENT
  },
  {
    label: "Temporary",
    value: ReplacementType.TEMPORARY_REPLACEMENT
  }
]

export interface IVehicleReplacementArgs {
  bookingId: string;
  bookingScheduleId: string;
  reason: string;
  description: string;
  putVehicleOnHold: boolean;
  previousVehicleId: string;
  previousVehicleGroupId: string;
  replacementDateTime: string;
  vehicleGroup: IVehicleGroup;
  startDate?: string;
  endDate?: string;
}

export interface IVehicleSubscriptionReplacementArgs {
  bookingId: string;
  bookingScheduleId: string;
  reason: string;
  description: string;
  putVehicleOnHold: boolean;
  previousVehicleId: string;
  replacementDateTime: string;
  startDate?: string;
  endDate?: string;
  newVehicleId: string;
}

export interface IVehicleGroup {
  vehicleGroup: string;
  vehicleIds: string[];
  baseRate: number;
  priceRuleOperator: string;
  priceRuleValueType: string;
  priceRuleTriggerType: string;
  priceRuleAmount: number;
  priceRuleName: string;
  priceRule: string;
  deposit: number;
  mileageLimit: number;
  pricePerExtraMile: number;
  unlimitedMileage: boolean;
  count: number;
  name: string;
}
