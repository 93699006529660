import React, { useEffect, useState } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { GET_PARTNER_DRIVERS_IN_PARTNERSHIP } from "../../../graphql/jobSchedular/getJobsQuery";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { formatGraphQLErrorMessage } from "../../common/utils";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { CircularProgress, Paper } from "@mui/material";
import { MUIDataTableOptions } from "mui-datatables";
import { SelectableRows } from "../../common/NuvvenTable/types";
import { NuvvenTable } from "../../common/NuvvenTable/NuvvenTable";

export const PartnerDrivers = () => {
    const snackbar = useSnackBar();
    const [partnerState, setPartnerState] = useState<any[]>([]);
    const [rows, setRows] = useState<any[]>([]);

    const [
        getPartnerDrivers,
        { loading: partnerDriversLoading, data: partnerDriversData }
      ] = useLazyQuery(GET_PARTNER_DRIVERS_IN_PARTNERSHIP, {
        fetchPolicy: "network-only",
        onCompleted: (partnerDriversData) => {
          const partnerdriverdata = reshapeDriverData(partnerDriversData.getPartnerDriversInPartnership)          
          setPartnerState(partnerDriversData.getPartnerDriversInPartnership)
          if(partnerdriverdata) {
          setRows(partnerdriverdata);
          }
        },
        onError: (error: ApolloError) => {
          snackbar({
            message: formatGraphQLErrorMessage(error.message),
            variant: SnackBarVariant.ERROR
          });
        }
      });

      useEffect(() => {
        if (location && location.search) {
          const params = new URLSearchParams(location.search);
          const partnerId = params.get("partner");
          if (partnerId) {
            getPartnerDrivers({
              context: {
                headers: {
                  partnerId: partnerId
                }
             },
            });
          }
        }
      }, [location]);

      const reshapeDriverData = (data: any[]) => {
        return data.map((driver: any) => {
          return {
            firstName: driver?.firstName,
            lastName: driver?.lastName,
            phoneNumber: driver?.phoneNumber.phone,
            skills: driver?.skills ? driver?.skills.map((skill: any) => skill).join(", ") : [],
            operatingAreas: driver?.operatingAreas ? driver.operatingAreas?.map((area: any) => area.name).join(", ") : [],
            status: driver?.status || ""      
          };
        });
      };

  const columns = [
    {
      label: "First Name",
      name: "firstName"
    },
    {
      label: "Last Name",
      name: "lastName"
    },
    {
      label: "Phone Number",
      name: "phoneNumber"
    },
    {
      label: "Skills",
      name: "skills",
      options: {
        customBodyRender: (value: string | string[]) => {
          const skillsArray = Array.isArray(value) ? value : value?.split(", ") || [];
          if (skillsArray.length > 1) {
            return `${skillsArray[0]} +${skillsArray.length - 1} more`;
          }
          return skillsArray[0] || ""; 
        }
      }
    },
    {
      label: "Operation Areas",
      name: "operatingAreas",
      options: {
        customBodyRender: (value: string | string[]) => {
          const areasArray = Array.isArray(value) ? value : value?.split(", ") || [];
          if (areasArray.length > 1) {
            return `${areasArray[0]} +${areasArray.length - 1} more`;
          }
          return areasArray[0] || ""; 
        }
      }
    },
    {
      label: "Status",
      name: "status"
    },
  ]

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    rowsPerPageOptions: [10, 20, 100],
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: partnerDriversLoading ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <div>
      {!(partnerDriversLoading && !rows.length) ? (
        <Paper variant="outlined">
          <NuvvenTable
            title={""}
            rows={rows}
            columns={columns}
            setSelection={() => { }}
            options={options}
          />
        </Paper>
      ) : (
        <CircularProgress />
      )}
    </div>
  )

};