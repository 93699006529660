import { VehicleDamageStatus } from "../../../reducers/damageVehicle/types";
import { IAddress, IServiceDocument, IVehicle, IVehicleDocument, VehicleServiceStatus } from "../../../reducers/fleet/types";
import { VehicleDamageCircumstance } from "../Fleet/Issues&Damages/constants";

export const AssetInitialValue: IAsset = {
  id: "",
  registrationNumber: "",
  identificationNumber: "",
  serialNumber: "",
  vehicle: "",
  assetTag: "",
  make: "",
  model: "",
  color: "",
  frogDiagram: "",
  images: [],
  isActive: true,
  documents: [],
  size: "",
  deFleetParameter: {
    byDate: "",
    condition: ""
  },
  dimension: {
    height: 0,
    length: 0,
    width: 0
  },
  assetType: "",
  capacity: 0,
  capacityUnit: "",
  yearOfManufacture: "",
  yearOfRegistration: "",
  issueDate: "",
  grossWeight: {
    designWeight: 0,
    maximumCountryPermittedWeight: 0,
    maximumPermittedWeight: 0
  },
  trainWeight: {
    designWeight: 0,
    maximumCountryPermittedWeight: 0,
    maximumPermittedWeight: 0
  },
  axleWeights: [],
  description: "",
  source: "",
  licencePlate: "",
  services: [],
  damages: [],
  purchaseCost: 0
}

export const AssetServiceInitialValue: IAssetService = {
  id: "",
  asset: "",
  referenceNumber: "",
  appointmentDate: "",
  serviceProviderName: "",
  assetTag: "",
  completionDate: "",
  serviceType: "",
  status: "",
  damages: [],
  description: "",
  documents: [],
  totalExpense: 0,
  serviceProviderAddress: {
    street: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    fullAddress: ""
  }
}

export const AssetDamageInitialValue: IAssetDamage = {
  id: "",
  asset: "",
  assetTag: "",
  referenceNumber: "",
  status: VehicleDamageStatus.DUE,
  damages: [],
  totalExpense: 0,
  bookingRef: "",
  circumstance: "",
  incidentDate: "",
  estimatedRepairDate: "",
  reportDate: "",
  frogDiagram: ""
}

export const AssetTypeInitialValue: IAssetType = {
  id: "",
  name: "",
  isActive: true,
  customFields: [],
  dateCreated: ""
}

export interface IAsset extends IAssetInput {
  id: string;
  licencePlate?: string;
  services: IAssetService[];
  damages: IAssetDamage[];
}

export interface IAssetInput {
  registrationNumber: string;
  serialNumber: string;
  identificationNumber: string;
  vehicle: string | Partial<IVehicle>;
  assetTag: string;
  make: string;
  model: string;
  color: string;
  frogDiagram: string;
  images: string[];
  isActive: boolean;
  documents: IVehicleDocument[];
  size: string;
  deFleetParameter: IAssetDeFleetParameter;
  dimension: IAssetDimension;
  assetType: string;
  capacity: number;
  capacityUnit: string;
  yearOfManufacture: string;
  yearOfRegistration: string;
  issueDate: string;
  grossWeight: IAssetWeightInfo;
  trainWeight: IAssetWeightInfo;
  axleWeights: IAssetWeightInfo[];
  description: string;
  source: string;
  purchaseCost: number;
}

export enum AssetTypes {
  VEHICLE = "VEHICLE",
  ADDON = "ADDON",
  DRIVER = "DRIVER",
  THIRD_PARTY = "THIRD_PARTY",
  TRAILER = "TRAILER",
  TYRE = "TYRE",
  BATTERY = "BATTERY",
  BABY_SEAT = "BABY_SEAT",
  REFRIGERATOR = "REFRIGERATOR",
  ROOF_RAIL = "ROOF_RAIL",
  BEACON = "BEACON",
  CYCLE_RACK = "CYCLE_RACK",
  BIKE_RACK = "BIKE_RACK",
  DASHBOARD_CAM = "DASHBOARD_CAM",
  BIKE_HELMET = "BIKE_HELMET"
}

export const AssetTypesValue: {
  [key in AssetTypes]: string;
} = {
  VEHICLE: "Vehicle",
  ADDON: "Addon",
  DRIVER: "Driver",
  THIRD_PARTY: "Third Party",
  TRAILER: "Trailer",
  TYRE: "Tyre",
  BATTERY: "Battery",
  BABY_SEAT: "Baby Seat",
  REFRIGERATOR: "Refrigerator",
  ROOF_RAIL: "Roof Rail",
  BEACON: "Beacon",
  CYCLE_RACK: "Cycle Rack",
  BIKE_RACK: "Bike Rack",
  DASHBOARD_CAM: "Dashboard Cam",
  BIKE_HELMET: 'Bike Helmet'
};

export type TAssetType = {
  label: string;
  value: string;
}

export const AssetTypesMenu: TAssetType[] = [
  {
    label: "Tyre",
    value: AssetTypes.TYRE
  },
  {
    label: "Trailer",
    value: AssetTypes.TRAILER
  },
  {
    label: "Baby Seat",
    value: AssetTypes.BABY_SEAT
  },
  {
    label: "Refrigerator",
    value: AssetTypes.REFRIGERATOR
  },
  {
    label: "Roof Rail",
    value: AssetTypes.ROOF_RAIL
  },
  {
    label: "Beacon",
    value: AssetTypes.BEACON
  },
  {
    label: "Cycle Rack",
    value: AssetTypes.CYCLE_RACK
  },
  {
    label: "Bike Rack",
    value: AssetTypes.BIKE_RACK
  },
  {
    label: "Dashboard Cam",
    value: AssetTypes.DASHBOARD_CAM
  },
  {
    label: "Bike Helmet",
    value: AssetTypes.BIKE_HELMET
  }
]


export interface IAssetDeFleetParameter {
  byDate: string;
  condition: string;
}

export interface IAssetDimension {
  length: number;
  width: number;
  height: number;
}

export interface IAssetWeightInfo {
  maximumPermittedWeight: number;
  maximumCountryPermittedWeight: number;
  designWeight: number;
}

export enum ASSET_SOURCE {
  "MANUAL" = "MANUAL",
  "BULK_CSV" = "BULK_CSV"
}

export interface IAssetService extends IAssetServiceBaseInput {
  id?: string;
  asset: string | IAsset;
  damages: (IAssetDamage | string)[];
  assetTag: string;
  referenceNumber: string;
}

export interface IAssetServiceBaseInput {
  serviceType: string;
  appointmentDate: string;
  completionDate: string;
  serviceProviderName: string;
  serviceProviderAddress: IAddress
  description?: string;
  totalExpense?: number;
  status: string;
  documents?: IServiceDocument[];
  initialAppointmentDate?: string;
  initialCompletionDate?: string;
}

export interface IUpdateAssetServiceStatusInput {
  status: VehicleServiceStatus;
  completionDate?: string;
}

export interface IAssetDamage extends IAssetDamageBaseInput {
  id: string;
  assetTag: string;
  asset: string | IAsset;
  referenceNumber: string;
}

export interface IAssetDamageBaseInput {
  circumstance: string;
  bookingRef: string;
  incidentDate: string;
  frogDiagram?: string;
  totalExpense?: number;
  estimatedRepairDate?: string
  reportDate?: string;
  damages: IAssetDamageItem[];
  status: VehicleDamageStatus;
}

export interface IAssetDamageItem {
  id?: number;
  title: string;
  descriptions: string;
  damageType: string;
  damageKind: string;
  damageArea?: string;
  images?: string[];
  location?: DamageLocationCoordinates
}

export interface DamageLocationCoordinates {
  x: number;
  y: number;
}

export interface IAssetType {
  id?: string;
  name: string;
  isActive: boolean;
  customFields: IAssetTypeCustomField[];
  dateCreated?: string;
}

export interface IAssetTypeCustomField {
  label: string;
  value: string;
}

export const assetDamageCircumstances = [
  {
    value: "TRANSIT",
    label: "During Transit"
  },
  {
    value: "OTHER",
    label: "Other"
  }
];

export enum DamageTypes {
  EXTERIOR = 'exterior damage',
  INTERIOR = 'interior damage',
  OTHER = 'other damage',
}

export const assetDamageTypes = [
  {
    label: "Exterior Damage",
    value: "EXTERIOR"
  },
  {
    label: "Interior Damage",
    value: "INTERIOR"
  },
  {
    label: "Other",
    value: "OTHER"
  },
]

export const assetDamageKinds = [
  { label: 'Broken', value: 'BROKEN' },
  { label: 'Dent', value: 'DENT' },
  { label: 'Chip', value: 'CHIP' },
  { label: 'Scuff', value: 'SCUFF' },
  { label: 'Tear', value: 'TEAR' },
  { label: 'Burnt', value: 'BURNT' },
  { label: 'Cracked', value: 'CRACKED' },
  { label: 'Gum', value: 'GUM' },
  { label: 'Spill', value: 'SPILL' },
  { label: 'Other', value: 'OTHER' },
]