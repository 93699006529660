import "../index.scss";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Autocomplete from "@mui/material/Autocomplete";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-mui";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Dispatch } from "redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import FlatPickerBar from "../../../../common/FlatPicker"
import _ from 'lodash';

import {
  resetVehicleService,
  updateVehicleService
} from "../../../../../actions/fleet/vehicle/actions";
import { GET_VEHICLES_LICENCE_PLATE } from "../../../../../graphql/fleet/getVehiclesLicencePlate";
import { ADD_VEHICLE_SERVICE_NOTE } from "../../../../../graphql/fleet/vehicleServices/addVehicleServiceNoteMutation";
import { CREATE_VEHICLE_SERVICE } from "../../../../../graphql/fleet/vehicleServices/createVehicleServiceMutation";
import { GET_VEHICLE_ACTIVE_DAMAGES } from "../../../../../graphql/fleet/vehicleServices/getActiveDamagesQuery";
import { GET_VEHICLE_SERVICE } from "../../../../../graphql/fleet/vehicleServices/getVehicleService";
import { UPDATE_VEHICLE_SERVICE } from "../../../../../graphql/fleet/vehicleServices/updateVehicleServiceMutation";
import { IVehicleDamage } from "../../../../../reducers/damageVehicle/types";
import { getVehicleService } from "../../../../../reducers/fleet/selectors";
import {
  IServiceDocument,
  IServiceScheduleType,
  IVehicle,
  IVehicleService,
  IVehicleServiceNote,
  JobSheetStatus,
  VehicleServiceStatus,
  VehicleServiceType,
} from "../../../../../reducers/fleet/types";
import { IAction, IAppState } from "../../../../../store";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  checkDeleteOrDownloadFileType,
  checkUploadFileFormat,
  formatGraphQLErrorMessage,
  uploadFileExtensionAndContentType, DocumentType, returnSortedDocuments, DATE_TYPE, isImageFileType
} from "../../../../common/utils";
import { VehicleStatus } from "../../utils";
import {
  IServiceOptionsMenu,
  serviceTypes
} from "../constants";
import { ConfirmationModel } from "./ConfirmationModel";
import { DamagesTable } from "./DamagesTable";
import { SimpleDateTimePicker } from "../../../../common/SimpleDateTimePicker";
import { DocumentDialog } from "../../../CustomerManagement/CustomerDetails/DocumentDialog";
import { NuvvenTable } from "../../../../common/NuvvenTable/NuvvenTable";
import { UserRoles } from "../../../../hoc/Authorization";
import { IAddress } from "../../../../../reducers/user/types";
import AddressPicker from "../../../CustomerManagement/NewCustomer/AddressPicker/AddressPicker";
import { ConfirmationDialog } from "../../../../common/ConfirmationDialog/ConfirmationDialog";
import { getSignedUrl } from '../../../../../utils/getSignedUrl';
import { CREATE_SHARED_VEHICLE_SERVICE } from "../../../../../graphql/fleet/vehicleServices/createSharedVehicleService";
import { UPDATE_SHARED_VEHICLE_SERVICE } from "../../../../../graphql/fleet/vehicleServices/updateSharedVehicleService";
import ServiceJobSheet from "./ServiceJobSheet";
import { DateTime as d } from "luxon"
import { GET_PARTNERS } from "../../../../../graphql/partners/getPartnersQuery";
import { IPartner } from "../../../../../reducers/Procurement/types";
import { PartnerType } from "../../../Partners/utils";
import { FilePicker } from "../../../../common/FilePicker/FilePicker";
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import CloseIcon from '@mui/icons-material/Close';
import { CANCEL_VEHICLE_SERVICE } from "../../../../../graphql/fleet/vehicleServices/cancelVehicleService";
import { MARK_VEHICLE_IN_SERVICE } from "../../../../../graphql/fleet/vehicleServices/updateJobSheetStatusMutation";
import { SEARCH_ALL_VEHICLES, SEARCH_VEHICLES } from "../../Graphql/getVehiclesQuery";
import { captureErrorException } from "../../../../../utils/sentry";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(3)
    },
    createButton: {
      background: "#000000",
      color: "#fff",
      fontSize: "12px"
    },
    uploadButton: {
      marginTop: "20px",
      backgroundColor: "#faaf40",
      color: "white"
    },
    viewDocumentList: {
      backgroundColor: "#f4f4fa"
    }
  })
);

const remainder = 15 - (d.now().minute % 15);
const minServiceAppointmentDate = d.now()
  .plus({ minutes: remainder })
  .toJSDate();
minServiceAppointmentDate.setMilliseconds(0);
minServiceAppointmentDate.setSeconds(0);

interface IVehicleServicesProps {
  vehicleService: IVehicleService;
  updateVehicleService(payload: IVehicleService): void;
  resetVehicleService(): void;
}

interface IViewDocumentProps {
  isOpen: boolean;
  deleteInProgress: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  vehicleService: IVehicleService;
  downloadDocument(documentUrl: string, documentName: string): void;
  deleteDocument(url: string, index: number | undefined): void;
}

export interface IDateTime {
  date: string;
  time: number
}

enum DocumentUploadType {
  INSURANCE = "INSURANCE",
  PO = "PO"
}

export const NewServicesAndRepair = React.memo(
  (props: IVehicleServicesProps) => {
    const styles = useStyles();
    const snackbar = useSnackBar();
    const location = useLocation();
    const userState = useSelector((state: IAppState) => state.userReducer);
    const { country } = userState.currentOrganisation.address;
    const { _e_ } = useSelector((state: IAppState) => state.authReducer);
    const [values, setValues] = useState(props.vehicleService);
    const [documentDialogVisible, setDocumentDialogVisible] = React.useState<boolean>(false);
    const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
    const [poUploading, setPoUploading] = useState(false);
    const [licencePlate, setLicencePlate] = useState([]);
    const [activeDamages, setActiveDamages] = useState<IVehicleDamage[]>([]);
    const [minDate, setMinDate] = useState<string>(d.now().toUTC().toISO());
    const [selectedDamageIds, setSelectedDamageIds] = useState<string[]>([]);
    const [serviceScheduleType, setServiceScheduleType] = useState<IServiceScheduleType>({
      byMonth: false,
      byMileage: false
    });
    const [note, setNote] = useState<IVehicleServiceNote>({
      description: "",
      createdBy: "",
      createdDate: ""
    });
    const [openConfirmatonDialog, setOpenConfirmationDialog] = useState<boolean>(false);
    const [documentInputValues, setDocumentInputValues] = useState({ documentTitle: "", description: "" });
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
    const [selectedVehicleBranch, setSelectedVehicleBranch] = useState<{ id: string, name: string }>({ id: "", name: "" });
    const [garagePartners, setGaragePartners] = useState<IPartner[]>([]);
    const [selectedPartner, setSelectedPartner] = useState<string>("");
    const [insuranceDocLoading, setInsuranceDocLoading] = useState(false);
    const [purchaseOrderLoading, setPurchaseOrderLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [serviceId, setServiceId] = useState<string>("");
    const prevBranchRef = useRef(userState.currentBranch);
    const [openMarkInservice, setOpenMarkInService] = useState(false);
    const navigate = useNavigate();
    const [query, setQuery] = useState<string>("");

    const [
      loadPartners,
      { data: partnersData }
    ] = useLazyQuery(GET_PARTNERS, { fetchPolicy: "network-only" });

    const [loadVehiclesLicencePlate, { data }] = useLazyQuery(GET_VEHICLES_LICENCE_PLATE, {
      fetchPolicy: "network-only"
    });

    const [searchOwnedAndExternalVehicles, { loading: searchOwnedAndExternalVehiclesLoading, data: searchOwnedAndExternalVehiclesData }] = useLazyQuery(SEARCH_ALL_VEHICLES, {
      fetchPolicy: "network-only"
    })

    const [getActiveVehicleDamage, { data: activeVehicleDamages }] = useLazyQuery(GET_VEHICLE_ACTIVE_DAMAGES, {
      variables: {
        vehicleId: values.vehicleId
      },
      fetchPolicy: "network-only"
    });
    const [loadVehicleService, { loading: loadingService, data: vehicleServiceData }] = useLazyQuery(GET_VEHICLE_SERVICE, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (!data.vehicleService) {
          navigate("/services-and-repair");
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    });

    useEffect(() => {
      if (prevBranchRef.current !== userState.currentBranch) {
        navigate("/services-and-repair");
        prevBranchRef.current = userState.currentBranch;
      }
    }, [userState.currentBranch]);

    useEffect(() => {
      if (!props.vehicleService.id) {
        setValues({
          ...values,
          documents: []
        })
      }
    }, [props.vehicleService]);

    useEffect(() => {
      loadVehiclesLicencePlate();
      loadPartners();
    }, []);

    useEffect(() => {
      if (partnersData && partnersData.partners) {
        const partners = partnersData.partners.filter((partner: IPartner) => partner.partnerType === PartnerType.GARAGE && partner.isActive)
        setGaragePartners(partners)
      }
    }, [partnersData]);

    useEffect(() => {
      if (
        activeVehicleDamages &&
        activeVehicleDamages.getVehicleActiveDamages
      ) {
        setActiveDamages(activeVehicleDamages.getVehicleActiveDamages);
      }
    }, [activeVehicleDamages]);

    useEffect(() => {
      if (location && location.search) {
        const params = new URLSearchParams(location.search);
        const serviceId = params.get("id");
        if (serviceId) {
          setServiceId(serviceId)
          loadVehicleService({
            variables: {
              id: serviceId
            }
          });
        }
      }
    }, [location]);

    useEffect(() => {
      if (vehicleServiceData && vehicleServiceData.vehicleService) {
        getActiveVehicleDamage({
          variables: {
            vehicleId: vehicleServiceData.vehicleService.vehicleId
          }
        });
        setValues({ ...vehicleServiceData.vehicleService, licencePlate });
        setMinDate(d.fromISO(vehicleServiceData.vehicleService.appointmentDate).toUTC().toISO());
      }
      if (vehicleServiceData &&
        vehicleServiceData.vehicleService &&
        vehicleServiceData.vehicleService.serviceProviderName) {
        setSelectedPartner(vehicleServiceData.vehicleService.serviceProviderName)
      }
      if (
        vehicleServiceData &&
        vehicleServiceData.vehicleService &&
        vehicleServiceData.vehicleService.scheduleType
      ) {
        setServiceScheduleType({
          ...serviceScheduleType,
          byMonth: vehicleServiceData.vehicleService.scheduleType.byMonth,
          byMileage: vehicleServiceData.vehicleService.scheduleType.byMileage
        });
      }
    }, [vehicleServiceData]);

    useEffect(() => {
      if (query) {
        handleSearch(query)
      } else {
        setLicencePlate([])
      }
    }, [query])

    useEffect(() => {
      if (searchOwnedAndExternalVehiclesData && searchOwnedAndExternalVehiclesData.searchOwnedAndExternalVehicles) {
        const vehicles = searchOwnedAndExternalVehiclesData.searchOwnedAndExternalVehicles.filter(
          (vehicle: any) => vehicle.status !== VehicleStatus.DEACTIVATED
        );
        setLicencePlate(vehicles);
      }
    }, [searchOwnedAndExternalVehiclesData]);

    useEffect(() => {
      if (props.vehicleService) {
        if (props.vehicleService.vehicleId) {
          getActiveVehicleDamage({
            variables: {
              vehicleId: values.vehicleId
            }
          });
        }
        if (props.vehicleService.startDate) {
          setValues({
            ...values,
            appointmentDate: props.vehicleService.startDate
          })
        }
      }
    }, [props.vehicleService])

    useEffect(() => {
      if (
        data &&
        data.getOwnedAndExternalVehicles &&
        props.vehicleService
      ) {
        let vehicle = data.getOwnedAndExternalVehicles.find((vehicle: IVehicle) => vehicle.id === props.vehicleService.vehicleId);

        if (vehicle) {
          setValues({
            ...props.vehicleService,
            licencePlate: vehicle.licencePlate,
            appointmentDate: props.vehicleService.startDate,
            completionDate: props.vehicleService.endDate,
          });
          setSelectedVehicleBranch({ id: vehicle.branchDetails.id, name: vehicle.branchDetails.name });
        }
      }
    }, [props.vehicleService, data]);

    useEffect(() => {
      if (
        data &&
        data.getOwnedAndExternalVehicles &&
        vehicleServiceData &&
        vehicleServiceData.vehicleService
      ) {
        let vehicle = data.getOwnedAndExternalVehicles.find((vehicle: IVehicle) => vehicle.id === vehicleServiceData.vehicleService.vehicleId);

        if (vehicle) {
          setValues({
            ...vehicleServiceData.vehicleService,
            licencePlate: vehicle.licencePlate
          });
          setSelectedVehicleBranch({ id: vehicle.branchDetails.id, name: vehicle.branchDetails.name });
        }
      }
    }, [vehicleServiceData, data]);

    useEffect(() => {
      const damagesId: string[] = [];
      if (vehicleServiceData && vehicleServiceData.vehicleService && vehicleServiceData.vehicleService.damages) {
        vehicleServiceData.vehicleService.damages.forEach((damage: IVehicleDamage) => {
          if (damage && damage.id) {
            damagesId.push(damage.id);
          }
        });
        setSelectedDamageIds([...selectedDamageIds, ...damagesId]);
      }
    }, [vehicleServiceData]);

    const [createServicesAndRepair, { loading: serviceSaveLoading }] = useMutation(CREATE_VEHICLE_SERVICE, {
      onCompleted: () => {
        props.resetVehicleService();
        navigate("/services-and-repair");
        snackbar({
          message: "Service saved successfully",
          variant: SnackBarVariant.SUCCESS
        });
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        }),
    });

    const [updateVehicleService, { loading: updateServiceLoading }] = useMutation(UPDATE_VEHICLE_SERVICE, {
      onCompleted: (data) => {
        if (data && data.updateVehicleService) {
          setValues({
            ...values,
            jobSheetStatus: data.updateVehicleService.jobSheetStatus,
            status: data.updateVehicleService.status,
            completionDate: data.updateVehicleService.completionDate,
            documents: data.updateVehicleService.documents
          });
          setInsuranceDocLoading(false);
          setPurchaseOrderLoading(false);
        }
        snackbar({
          message: "Service updated successfully",
          variant: SnackBarVariant.SUCCESS
        });
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        setInsuranceDocLoading(false);
        setPurchaseOrderLoading(false);
      }
    });
    const [addVehicleServiceNote] = useMutation(ADD_VEHICLE_SERVICE_NOTE, {
      onCompleted: (data) => {
        setValues({
          ...values,
          notes: values.notes ? [...values.notes, { ...data.addVehicleServiceNote }] : [{ ...data.addVehicleServiceNote }]
        });
        setNote({
          description: "",
          createdBy: "",
          createdDate: ""
        });
      }
    });

    const [createSharedVehicleService] = useMutation(CREATE_SHARED_VEHICLE_SERVICE, {
      onCompleted: () => {
        navigate("/services-and-repair");
        snackbar({
          message: "Service saved successfully",
          variant: SnackBarVariant.SUCCESS
        });
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    });

    const [updateSharedVehicleService] = useMutation(UPDATE_SHARED_VEHICLE_SERVICE, {
      onCompleted: () => {
        setInsuranceDocLoading(false)
        setPurchaseOrderLoading(false);
        snackbar({
          message: "Service saved successfully",
          variant: SnackBarVariant.SUCCESS
        });
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    });

    const [cancelVehicleService, { loading: loadingCancelVehicleService }] = useMutation(CANCEL_VEHICLE_SERVICE, {
      onCompleted: () => {
        snackbar({
          message: "Service cancelled successfully",
          variant: SnackBarVariant.SUCCESS
        });
        setOpenDialog(false);
        navigate('/services-and-repair');
      },

      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    })

    const [markVehicleInService] = useMutation(MARK_VEHICLE_IN_SERVICE, {
      onCompleted: ({ message }) => {
        snackbar({
          message: message,
          variant: SnackBarVariant.SUCCESS
        });
        setOpenMarkInService(false);
        setValues({
          ...values,
          status: VehicleServiceStatus.IN_SERVICE
        })
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    })
    const handlerNoteInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      setNote({
        ...note,
        description: event.target.value,
        createdBy: userState.id,
        createdDate: new Date().toISOString()
      });
    };

    async function handleDocumentSave(file: File, title: string, documentType: DocumentType, reminderBefore: number, expiryDate: string) {
      try {
        // 5MB limit exceeded
        if (file.size > 5242880) {
          throw new Error("File size exceeded limit of 5MB");
        }
        const fileName = file.name.split(".")[0];
        setUploadInProgress(true);
        if (checkUploadFileFormat(file.type)) {
          const uniqueId = uuidv4();
          const {
            fileExtension
          } = uploadFileExtensionAndContentType(file.type);
          const key = `${userState.tenancy.id}/${uniqueId}-service-and-repair-document.${fileExtension}`;
          if (_e_) {
            await _e_
              .add({
                name: key,
                file: file,
                complete: async () => {
                  const docs = [...values.documents]
                  docs.push({
                    documentUrl: key,
                    title,
                    documentName: fileName,
                    expiryDate,
                    documentType,
                    reminderBefore,
                    createdAt: ""
                  })
                  setValues({
                    ...values,
                    documents: docs
                  });
                  setDocumentInputValues({
                    documentTitle: "",
                    description: ""
                  });
                  if (values.id) {
                    handleFormSubmit({
                      ...values,
                      documents: docs
                    });
                  }
                  setDocumentDialogVisible(false);
                  setUploadInProgress(false);
                }
              });
          }

        } else {
          setDocumentDialogVisible(false);
          setUploadInProgress(false);
          return snackbar({
            message: "Please only upload .pdf!",
            variant: SnackBarVariant.ERROR
          });
        }
      } catch (err) {
        if (err instanceof Error) {
          captureErrorException(err);
          snackbar({ message: err.message, variant: SnackBarVariant.ERROR });
        }
      }
    }

    async function uploadDocument(file: File, documentType: DocumentUploadType) {
      if (values.status === VehicleServiceStatus.COMPLETED) {
        return snackbar({
          message: "Service has been completed.",
          variant: SnackBarVariant.ERROR
        });
      } else if (values.status === VehicleServiceStatus.CANCELLED) {
        return snackbar({
          message: "Service has been cancelled.",
          variant: SnackBarVariant.ERROR
        });
      } else if (values.branchId && values.branchId !== userState.currentBranch.id) {
        return snackbar({
          message: "Serivce blongs to external branch",
          variant: SnackBarVariant.ERROR
        });
      }
      else {
        try {
          // 50MB limit exceeded
          if (file.type === "application/pdf" && file.size > 52428800) {
            throw new Error("File size exceeded limit of 50MB");
          }
          if (isImageFileType(file.type) && file.size > 5242880) {
            throw new Error("File size exceeded limit of 5MB");
          }
          if (documentType === DocumentUploadType.PO) {
            setPoUploading(true)
          } else {
            setUploadInProgress(true);
          }
          if (checkUploadFileFormat(file.type)) {
            const uniqueId = uuidv4();
            const {
              fileExtension
            } = uploadFileExtensionAndContentType(file.type);
            const key = `${userState.tenancy.id}/${uniqueId}-service-and-repair-document-${documentType}.${fileExtension}`;
            if (_e_) {
              await _e_
                .add({
                  name: key,
                  file: file,
                  complete: async () => {
                    if (documentType === DocumentUploadType.INSURANCE) {
                      setValues({
                        ...values,
                        insuranceInformation: {
                          ...values.insuranceInformation,
                          documentUrl: key
                        }
                      });
                    }
                    if (documentType === DocumentUploadType.PO) {
                      setValues({
                        ...values,
                        purchaseOrderInformation: {
                          ...values.purchaseOrderInformation,
                          documentUrl: key
                        }
                      });
                    }
                    if (values.id && documentType === DocumentUploadType.INSURANCE) {
                      handleFormSubmit({
                        ...values,
                        insuranceInformation: {
                          ...values.insuranceInformation,
                          documentUrl: key
                        }
                      });
                    }
                    if (values.id && documentType === DocumentUploadType.PO) {
                      handleFormSubmit({
                        ...values,
                        purchaseOrderInformation: {
                          ...values.purchaseOrderInformation,
                          documentUrl: key
                        }
                      });
                    }
                    setDocumentDialogVisible(false);
                    if (documentType === DocumentUploadType.PO) {
                      setPoUploading(false);
                    } else {
                      setUploadInProgress(false);
                    }
                  }
                });
            }

          } else {
            setDocumentDialogVisible(false);
            setUploadInProgress(false);
            return snackbar({
              message: "Please only upload PDF, PNG, JPG or JPEG file types",
              variant: SnackBarVariant.ERROR
            });
          }
        } catch (err) {
          if (err instanceof Error) {
            captureErrorException(err);
            snackbar({ message: err.message, variant: SnackBarVariant.ERROR });
          }
        }
      }
    }
    const downloadDocument = async (
      documentUrl: string,
      documentName: string
    ) => {
      const { fileExtension } = checkDeleteOrDownloadFileType(
        documentUrl
      );
      const file = await getSignedUrl(documentUrl)
      const url: any = file;
      fetch(url, {
        method: "GET"
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${documentName}.${fileExtension}`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    };

    const deleteDocument = (
      url: string,
      index: number,
    ) => {
      setDeleteInProgress(true);
      // TODO: implement file deleteion from s3
      const { damages, licencePlate, referenceNumber, notes, branchId, epyxReferenceNumber, sentToXero, ...rest } = values;
      const documents = [...rest.documents]
      const updatedDocuments = [
        ...documents.slice(0, index),
        ...documents.slice(index + 1)
      ]
      const updatedValues = {
        ...rest,
        insuranceInformation: {
          ...rest.insuranceInformation,
          claimAmount: rest.insuranceInformation && rest.insuranceInformation.claimAmount ?
            typeof rest.insuranceInformation.claimAmount === 'string' ?
              parseInt(rest.insuranceInformation.claimAmount) : rest.insuranceInformation.claimAmount : 0
        },
        documents: updatedDocuments
      }
      updateVehicleService({
        variables: {
          vehicleService: { ...updatedValues }
        }
      }).then(() => {
        snackbar({
          message: "Document Removed",
          variant: SnackBarVariant.SUCCESS
        });
        setDeleteInProgress(false);
        handleDialogClose();
      });
    };

    const handleDialogOpen = () => {
      setOpenDeleteDialog(true)
    }

    const handleDialogClose = () => {
      setOpenDeleteDialog(false);
    }

    const handleOptionChange = (event: any) => {
      setSelectedOption(event.target.value);
    };

    const handleProceed = () => {
      if (selectedOption === "current") {
        cancelVehicleService({
          variables: {
            cancelService: {
              vehicleId: "",
              serviceId: serviceId,
            }
          }
        });
      }
      if (selectedOption === "scheduled") {
        cancelVehicleService({
          variables: {
            cancelService: {
              vehicleId: values?.vehicleId,
              serviceId: ""
            }
          }
        });
      }
    };

    const handleServiceDialogClose = () => {
      setOpenDialog(false);
    };

    const createTask = () => {
      if (values) {
        navigate(`/user-job?vehicleservice=${values?.id}`)
      }
    }
    const detailsFormSchema = Yup.object().shape({
      endDate: Yup.string().required("Completion date is required."),
      serviceProviderAddress: Yup.object().shape({
        fullAddress: Yup.string().required("Address is required"),
        country: Yup.string().required("Country is required"),
        state: Yup.string().required("State is required"),
        city: Yup.string().required("City is required"),
        street: Yup.string().required("Street is required"),
      }).required("Location is required"),
      serviceProviderName: Yup.string().required(
        "Service provider name is required."
      ),
      serviceType: Yup.string().required("Please select Service type."),
      appointmentDate: Yup.string().required("Appointment date is required."),
    });

    const damagesSelectionHandler = (selectedDamageIndices: number[]) => {
      if (selectedDamageIndices && selectedDamageIndices.length) {
        let _selectedDamageIds = [...selectedDamageIds];
        for (let index = 0; index < selectedDamageIndices.length; index++) {
          const damageIndex = selectedDamageIndices[index];
          const matchedDamage: IVehicleDamage = activeDamages[damageIndex];
          if (matchedDamage && matchedDamage.id) {
            if (_selectedDamageIds.includes(matchedDamage.id)) {
              _selectedDamageIds = _selectedDamageIds.filter((id) => id !== matchedDamage.id);
            } else {
              _selectedDamageIds.push(matchedDamage.id);
            }
          }
        }
        setSelectedDamageIds(_selectedDamageIds);
      } else {
        setSelectedDamageIds([]);
      }
    };

    const handleFormSubmit = (values: IVehicleService, status?: VehicleServiceStatus, isServiceCompleted?: boolean) => {
      const vehicleService = {
        ...values,
        insuranceInformation: {
          ...values.insuranceInformation,
          claimAmount: values.insuranceInformation && values.insuranceInformation.claimAmount ?
            typeof values.insuranceInformation.claimAmount === 'string' ?
              parseInt(values.insuranceInformation.claimAmount) : values.insuranceInformation.claimAmount : 0
        }
      };
      if (!vehicleService.vehicleId) {
        return snackbar({
          message: "Please select Registration number.",
          variant: SnackBarVariant.ERROR
        });
      }
      if (!vehicleService.epyxReferenceNumber && !vehicleService.serviceProviderId) {
        return snackbar({
          message: "Please choose valid service provide name",
          variant: SnackBarVariant.ERROR
        });
      }
      if (
        (vehicleService && !vehicleService.serviceProviderAddress.street) ||
        !vehicleService.serviceProviderAddress.city ||
        !vehicleService.serviceProviderAddress.state ||
        !vehicleService.serviceProviderAddress.country ||
        !vehicleService.serviceProviderAddress.zipcode ||
        !vehicleService.serviceProviderAddress.fullAddress
      ) {
        return snackbar({
          message: "Service provider address is mandatory",
          variant: SnackBarVariant.ERROR
        });
      }
      vehicleService.status = status ? status : vehicleService.serviceType === VehicleServiceType.REGULAR ?
        VehicleServiceStatus.SCHEDULED : VehicleServiceStatus.IN_SERVICE;
      vehicleService.damages = [...selectedDamageIds];
      vehicleService.scheduleType = serviceScheduleType;
      vehicleService.startDate = vehicleService.appointmentDate;
      if (isServiceCompleted) {
        vehicleService.completionDate = values.completionDate;
      } else {
        vehicleService.completionDate = vehicleService.endDate;
      }
      const { id, notes, referenceNumber, licencePlate, epyxReferenceNumber, branchId, sentToXero, ...rest } = vehicleService;
      if (userState.currentBranch.id !== selectedVehicleBranch.id) {
        if (values.id) {
          delete vehicleService.notes;
          updateSharedVehicleService({
            variables: {
              vehicleService: {
                ...rest,
                id: values.id
              }
            }
          });
        } else {
          delete vehicleService.id;
          createSharedVehicleService({
            variables: { vehicleService: { ...rest } }
          });
        }
      } else {
        if (values.id) {
          updateVehicleService({
            variables: {
              vehicleService: {
                ...rest,
                id: values.id
              }
            }
          });
        } else {
          createServicesAndRepair({
            variables: { vehicleService: { ...rest } }
          });
        }
      }
    };

    const handleChange = _.debounce((searchText: string) => {
      setQuery(searchText);
    }, 500)

    const handleSearch = (searchText: string) => {
      if (searchText.trim().length > 2) {
        searchOwnedAndExternalVehicles({
          variables: {
            q: searchText.trim()
          }
        })
      } else {
        setLicencePlate([])
      }
    }

    const handleServiceCompletionConfirm = (values: IVehicleService) => {
      handleFormSubmit(values, VehicleServiceStatus.COMPLETED, true);
      setOpenConfirmationDialog(false);
    };

    const handleAutoCompleteInputChange = _.debounce((value: string, reason: any) => {
      if (reason === "input" || reason === "reset") {
        const resourcesToFilter = [...garagePartners];
        if (value) {
          const matchedGaragePartner = resourcesToFilter.find((partner) => partner.partnerName === value);
          if (matchedGaragePartner) {
            const { address } = matchedGaragePartner;
            setValues({
              ...values,
              serviceProviderId: matchedGaragePartner.id,
              serviceProviderName: matchedGaragePartner.partnerName,
              serviceProviderAddress: {
                street: address.street,
                city: address.city,
                state: address.state,
                country: address.country,
                zipcode: address.zipcode,
                fullAddress: address.fullAddress
              }
            });
          }
        }
      }
      if (reason === 'clear') {
        setValues({
          ...values,
          serviceProviderName: ""
        });
        setSelectedPartner("")
      }
    }, 200);

    if (loadingService) {
      return <CircularProgress />;
    }
    return (
      <Grid container spacing={2}>
        <Grid container item xs={12} alignItems="center">
          <Typography variant="h1" color="primary">
            Services And Repair
          </Typography>
          <Box color="white" sx={{ pr: 1 }}></Box>
          <DoubleArrowIcon />
          <Box color="white" sx={{ pl: 1 }}></Box>
          {values.id ? (
            <Typography variant="h1" color="primary">
              Update
            </Typography>
          ) : (
            <Typography variant="h1" color="primary">
              New
            </Typography>
          )}
          <Typography variant="h1" color="primary">
            &nbsp;Services And Repair
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Paper className={styles.root}>
            <Formik
              enableReinitialize
              validationSchema={detailsFormSchema}
              initialValues={values}
              onSubmit={(values, { setSubmitting }) => {
                handleFormSubmit(values);
                setSubmitting(false);
              }}
            >
              {(props) => {
                return (
                  <React.Fragment>
                    <Form>
                      <Grid item xs={4}>
                       {vehicleServiceData?.vehicleService?.referenceNumber && (
                        <Typography variant="h2" style={{ marginBottom: '10px' }}>
                          Service ref:{" "}
                          {vehicleServiceData?.vehicleService?.referenceNumber}
                        </Typography>
                       )}
                      </Grid>
                      <Grid item container xs={12} spacing={2}>
                        <Grid container item xs={values.id ? 2 : 12} style={{ maxWidth: '70%' }}>
                          <Typography variant="h2">SERVICE DETAILS</Typography>
                        </Grid>
                        {<Dialog open={openDialog} onClose={handleServiceDialogClose} fullWidth maxWidth="sm">
                          <DialogTitle>
                            <div className={`flex cross-center space-between bold`}>
                              <span style={{ fontSize: "20px" }}>
                                Cancel Service
                              </span>
                              <IconButton onClick={handleServiceDialogClose} aria-label="close" size="small" color="inherit">
                                <CloseIcon style={{ fontSize: 16 }} />
                              </IconButton>
                            </div>
                          </DialogTitle>
                          <Divider />
                          <DialogContent>
                            <RadioGroup
                              name="serviceOption"
                              value={selectedOption}
                              onChange={handleOptionChange}
                            >
                              <FormControlLabel
                                value="current"
                                control={<Radio color="primary" />}
                                label="Current Service"
                              />
                              <FormControlLabel
                                value="scheduled"
                                control={<Radio color="primary" />}
                                label="All Scheduled Services"
                              />
                            </RadioGroup>
                          </DialogContent>
                          <DialogActions>
                            <Fab variant="extended" size="medium" color="primary" onClick={handleProceed} disabled={!selectedOption}>
                              {loadingCancelVehicleService && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                              Proceed
                            </Fab>
                          </DialogActions>
                        </Dialog>}
                        {
                          values.id &&
                          <Grid container item xs={10} justifyContent="flex-end">
                            {values.status !== VehicleServiceStatus.CANCELLED && values.status !== VehicleServiceStatus.COMPLETED &&
                              values.status !== VehicleServiceStatus.IN_SERVICE && (
                                <Grid item>
                                  <Fab
                                    variant="extended"
                                    size="medium"
                                    color="primary"
                                    disabled={values.branchId && values.branchId !== userState.currentBranch.id ? true : false}
                                    style={{ marginRight: '10px' }}
                                    onClick={() => createTask()}
                                  >
                                    Create Task
                                  </Fab>
                                </Grid>
                              )
                            }
                            {values.status !== VehicleServiceStatus.CANCELLED && values.status !== VehicleServiceStatus.COMPLETED &&
                              values.status !== VehicleServiceStatus.IN_SERVICE && (
                                <Grid item>
                                  <Fab
                                    variant="extended"
                                    size="medium"
                                    color="primary"
                                    style={{ marginRight: '10px' }}
                                    disabled={values.branchId && userState.currentBranch.id !== values.branchId ? true : false}
                                    onClick={() => {
                                      setOpenMarkInService(true);
                                    }}
                                  >
                                    Mark In-Service
                                  </Fab>
                                </Grid>
                              )}
                            <Grid item>
                              <Fab
                                variant="extended"
                                size="medium"
                                color="primary"
                                style={{ marginRight: '10px' }}
                                onClick={() => {
                                  setOpenDialog(true);
                                }}
                                disabled={(values.branchId && userState.currentBranch.id !== values.branchId) || values.status === VehicleServiceStatus.CANCELLED}
                              >
                                Cancel Service
                              </Fab>
                            </Grid>
                            <Grid item style={{ marginLeft: '10px' }}>
                              {values.status !== VehicleServiceStatus.CANCELLED && <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      values.status === VehicleServiceStatus.IN_SERVICE ? true : false
                                    }
                                    disabled={
                                      (values.branchId && userState.currentBranch.id !== values.branchId) ||
                                      values.status === VehicleServiceStatus.COMPLETED ||
                                      values.status === VehicleServiceStatus.SCHEDULED}
                                    onChange={() => {
                                      if (props.values.id && !props.values.completionDate) {
                                        return snackbar({
                                          message: 'Completion date is mandatory to complete the service',
                                          variant: SnackBarVariant.ERROR,
                                        });
                                      } else if (props.values.id) {
                                        setOpenConfirmationDialog(true);
                                      }
                                    }}
                                    name="inService"
                                    color="primary"
                                  />
                                }
                                label={<Typography variant="body1">{values.status === VehicleServiceStatus.COMPLETED ? 'Completed' : 'In Service'}</Typography>}
                              />}
                            </Grid>
                          </Grid>
                        }
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormControl variant="outlined" fullWidth>
                            <Field
                              component={InputField}
                              name={"serviceType"}
                              fullWidth
                              type="text"
                              select
                              required
                              label="Service Type"
                              inputProps={{
                                onChange: (event: any) => {
                                  setValues({
                                    ...props.values,
                                    serviceType: event.target.value
                                  });
                                },
                                value: props.values.serviceType
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                            >
                              {serviceTypes.map(
                                (item: IServiceOptionsMenu, index: number) => {
                                  return (
                                    <MenuItem key={index} value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Field>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            options={garagePartners}
                            getOptionLabel={(option: any) =>
                              option.partnerName
                            }
                            onInputChange={(event: any, value: string, reason: string) => {
                              handleAutoCompleteInputChange(value, reason);
                              setSelectedPartner(value);
                            }}
                            inputValue={selectedPartner}
                            renderInput={(params) => (
                              <TextField {...params}
                                label="Service Provider Name"
                                placeholder="Service Provider name"
                                required
                                margin="normal"
                                variant="outlined"
                                style={{ backgroundColor: "white" }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                        >
                          <AddressPicker
                            fieldName="serviceProviderAddress"
                            disabled={true}
                            required
                            onChange={(address: IAddress) => {
                              if (address) {
                                setValues({
                                  ...props.values,
                                  serviceProviderAddress: address
                                });
                              }
                            }}
                          />
                        </Grid>
                        <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} >
                          <SimpleDateTimePicker
                            date={props.values.appointmentDate}
                            key={minDate}
                            handleChange={(date: IDateTime) => {
                              props.setValues({
                                ...props.values,
                                appointmentDate: d.fromISO(date.date).toUTC().toISO(),
                              });
                            }}
                            required={true}
                            disabled={false}
                            name={"appointmentDate"}
                            dateTitle={"Appointment Date"}
                            timeTitle={"Appointment Time"}
                            minDate={values.appointmentDate && values.appointmentDate < d.now().toUTC().toISO() ? values.appointmentDate : d.now().toUTC().toISO()}
                          />
                        </Grid>
                        <Grid container item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <SimpleDateTimePicker
                            date={props.values.endDate}
                            key={props.values.endDate}
                            handleChange={(date: IDateTime) => {
                              setValues({
                                ...props.values,
                                endDate: d.fromISO(date.date).toUTC().toISO()
                              })
                            }}
                            required={true}
                            disabled={!props.values.appointmentDate}
                            name={"endDate"}
                            dateTitle={"Estd. Completion Date"}
                            timeTitle={"Estd. Completion Time"}
                            minDate={d.fromISO(props.values.appointmentDate).toUTC().toISO()}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Autocomplete
                            id="combo-box-vehicle-id"
                            onChange={(
                              _: React.ChangeEvent<{}>,
                              newVal: any
                            ) => {
                              if (newVal && newVal.id) {
                                setValues({
                                  ...props.values,
                                  vehicleId: newVal.id,
                                  licencePlate: newVal.licencePlate
                                });
                                setSelectedVehicleBranch({ id: newVal.branchDetails.id, name: newVal.branchDetails.name });
                                getActiveVehicleDamage();
                              } else {
                                setValues({
                                  ...props.values,
                                  vehicleId: "",
                                  licencePlate: ""
                                });
                                setSelectedVehicleBranch({ id: selectedVehicleBranch.id, name: (!newVal) ? "" : newVal.branchDetails.name });
                              }
                            }}
                            disabled={values.id ? true : false}
                            loading={searchOwnedAndExternalVehiclesLoading}
                            loadingText={"Loading vehicles..."}
                            noOptionsText={query.length > 2 && !licencePlate.length ?
                              "No vehicle found with search criteria" :
                              "Type to search vehicles"
                            }
                            options={licencePlate}
                            getOptionLabel={(option: any) => {
                              if (selectedVehicleBranch.name) {
                                return (
                                  `${option.licencePlate}${option.licencePlate
                                    ? (userState.currentBranch.id === selectedVehicleBranch.id)
                                      ? " (Own)"
                                      : ` (${selectedVehicleBranch.name})` : ""}`
                                )
                              }
                              return (
                                `${option?.licencePlate}${option?.licencePlate
                                  ? (userState.currentBranch?.id === option?.branchDetails?.id)
                                    ? " (Own)"
                                    : ` (${option?.branchDetails?.name})` : ""}`
                              )
                            }
                            }
                            value={values}
                            style={{ width: "100%" }}
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                label={"Search for vehicle"}
                                variant="outlined"
                                fullWidth
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
                                value={values.licencePlate}
                                required
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Field
                            component={TextField}
                            placeholder="Odometer Reading"
                            label="Odometer Reading"
                            name="odometerReading"
                            value={props.values.odometerReading}
                            type="number"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              const value = parseInt(event.target.value);
                              if (value >= 0) {
                                setValues({
                                  ...props.values,
                                  odometerReading: parseInt(event.target.value)
                                })
                              }
                              else {
                                snackbar({
                                  message: "Negative value cannot be given",
                                  variant: SnackBarVariant.ERROR
                                });
                              }
                            }

                            }
                            required
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Field
                            component={TextField}
                            placeholder="Driver name"
                            label="Driver Name"
                            name={"driverName"}
                            value={props.values.driverName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setValues({
                                ...props.values,
                                driverName: event.target.value
                              })
                            }}
                            fullWidth
                          ></Field>
                        </Grid>
                        <Grid item xs={4}>
                          <Field
                            component={TextField}
                            placeholder="Driver Email"
                            label="Driver Email"
                            name={"driverEamil"}
                            type="email"
                            value={props.values.driverEmail}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setValues({
                                ...props.values,
                                driverEmail: event.target.value
                              })
                            }}
                            fullWidth
                          ></Field>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Field
                            component={InputField}
                            label={"Service Description"}
                            name={"description"}
                            onChange={props.handleChange}
                            value={props.values.description}
                            inputProps={{ maxLength: 500 }}
                            fullWidth
                            multiline
                            rows={"3"}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Field
                            component={InputField}
                            label="Total Expenses"
                            name={"totalExpenses"}
                            placeholder={"e.g 100"}
                            disabled={values.epyxReferenceNumber ? false : true}
                            InputProps={{
                              inputComponent: FloatInput as any
                            }}
                            inputProps={{
                              hasCurrencyPrefix: true,
                              allowNegative: false
                            }}
                            value={props.values.totalExpenses}
                            onChange={props.handleChange}
                            fullWidth
                          ></Field>
                        </Grid>
                        {activeDamages && activeDamages.length > 0 && (
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <Grid item container xs={12}>
                              <Typography variant="h3" gutterBottom>
                                DAMAGES
                              </Typography>
                            </Grid>
                            <DamagesTable
                              vehicleDamages={activeDamages}
                              damagesSelectionHandler={damagesSelectionHandler}
                              selectedDamages={props.values.damages}
                            />
                          </Grid>
                        )}
                        <Grid
                          item
                          container
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                        >
                          <ServiceJobSheet
                            vehicleServiceId={values.id || ""}
                            serviceBranchId={values.branchId}
                            serviceStatus={values.status}
                            jobSheetStatus={values.jobSheetStatus}
                            jobSheet={props.values.jobSheet}
                            taxPercent={props.values.taxPercent}
                            onSubmit={({ jobSheet, taxPercent, totalCost }) => {
                              setValues({
                                ...values,
                                jobSheet,
                                taxPercent,
                                totalExpenses: totalCost
                              });
                              if (values.id) {
                                handleFormSubmit({
                                  ...props.values,
                                  jobSheet,
                                  taxPercent,
                                  jobSheetStatus: JobSheetStatus.PENDING,
                                  totalExpenses: totalCost
                                })
                              } else {
                                snackbar({
                                  message: "Job Sheet updated",
                                  variant: SnackBarVariant.SUCCESS
                                });
                              }
                            }}
                          />
                        </Grid>
                        <Grid item container xs={12} md={12}>
                          <Paper variant="outlined" className={styles.root} style={{ width: "100%" }}>
                            <Grid container xs={12} spacing={2}>
                              <Grid item xs={12} md={12}>
                                <Typography variant="h3" gutterBottom>
                                  Insurance Document
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Field
                                  component={TextField}
                                  placeholder="Provider Name"
                                  label="Provider Name"
                                  name={"insuranceInformation.providerName"}
                                  disabled={values.status === VehicleServiceStatus.COMPLETED || values.status === VehicleServiceStatus.CANCELLED}
                                  value={props.values.insuranceInformation && props.values.insuranceInformation.providerName}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setValues({
                                      ...props.values,
                                      insuranceInformation: {
                                        ...props.values.insuranceInformation,
                                        providerName: event.target.value
                                      }
                                    })
                                  }}
                                  fullWidth
                                ></Field>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Field
                                  component={TextField}
                                  placeholder="Policy Number"
                                  label="Policy Number"
                                  name={"insuranceInformation.policyNumber"}
                                  disabled={values.status === VehicleServiceStatus.COMPLETED || values.status === VehicleServiceStatus.CANCELLED}
                                  value={props.values.insuranceInformation && props.values.insuranceInformation.policyNumber}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setValues({
                                      ...props.values,
                                      insuranceInformation: {
                                        ...props.values.insuranceInformation,
                                        policyNumber: event.target.value
                                      }
                                    })
                                  }}
                                  fullWidth
                                ></Field>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FlatPickerBar
                                  enableTime={false}
                                  handleDateChange={(value: Date) => {
                                    setValues({
                                      ...props.values,
                                      insuranceInformation: {
                                        ...props.values.insuranceInformation,
                                        expiryDate: d.fromJSDate(value).toUTC().toISO()
                                      }
                                    })
                                  }}
                                  label={"Expiry Date"}
                                  identifier={`expiryDate`}
                                  placeholderValue={"select date..."}
                                  disabled={values.status === VehicleServiceStatus.COMPLETED || values.status === VehicleServiceStatus.CANCELLED}
                                  value={props.values.purchaseOrderInformation && props.values.insuranceInformation.expiryDate}
                                  minDate={"today"}
                                  country={country}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Field
                                  component={InputField}
                                  label="Claim Amount"
                                  name={"insuranceInformation.claimAmount"}
                                  placeholder={"e.g 100"}
                                  disabled={values.status === VehicleServiceStatus.COMPLETED || values.status === VehicleServiceStatus.CANCELLED}
                                  InputProps={{
                                    inputComponent: FloatInput as any,
                                    value: values.insuranceInformation &&
                                      values.insuranceInformation.claimAmount ?
                                      values.insuranceInformation.claimAmount : 0,
                                    onChange: (
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setValues({
                                        ...values,
                                        insuranceInformation: {
                                          ...values.insuranceInformation,
                                          claimAmount: parseInt(e.target.value)
                                        }
                                      })

                                    }
                                  }}
                                  inputProps={{
                                    hasCurrencyPrefix: true,
                                    allowNegative: false
                                  }}
                                  fullWidth
                                ></Field>
                              </Grid>
                              {values.status !== VehicleServiceStatus.CANCELLED && (
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                  <FilePicker
                                    onFilePick={(e: any) => {
                                      const file = e.target.files[0];
                                      uploadDocument(file, DocumentUploadType.INSURANCE);
                                    }}
                                    title={"Upload Document"}
                                    accept={"image/jpg, image/jpeg, image/png, application/pdf"}
                                  />
                                  {uploadInProgress && (
                                    <span> <CircularProgress size={18} /> </span>
                                  )}
                                  <p style={{ fontSize: 12 }}>Allowed document type is PDF, PNG, JPG or JPEG. Max size for PDF is 50MB, Image files is 5MB</p>
                                </Grid>)}
                              {values.insuranceInformation && values.insuranceInformation.documentUrl && (
                                <Grid xs={1} sm={1} md={1}>
                                  <span
                                    title="Download file"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      downloadDocument(values.insuranceInformation.documentUrl, "Vehicle-service-insurance")
                                    }}
                                  >
                                    <CloudDownloadIcon fontSize="large" style={{ marginTop: 10 }} />
                                  </span>
                                </Grid>
                              )}
                              {values.id && (
                                <Grid xs={12} sm={12} md={12}>
                                  <Fab
                                    variant="extended"
                                    size="medium"
                                    aria-label="Save"
                                    className="createButton"
                                    disabled={
                                      (values.branchId && userState.currentBranch.id !== values.branchId) ||
                                      values.status === VehicleServiceStatus.COMPLETED ||
                                      values.status === VehicleServiceStatus.CANCELLED ||
                                      !values.insuranceInformation ||
                                      (
                                        values.insuranceInformation &&
                                        !values.insuranceInformation.providerName &&
                                        !values.insuranceInformation.policyNumber &&
                                        !values.insuranceInformation.expiryDate &&
                                        !values.insuranceInformation.documentUrl
                                      ) || insuranceDocLoading
                                    }
                                    onClick={() => {
                                      handleFormSubmit(props.values);
                                      setInsuranceDocLoading(true);
                                    }}
                                  >
                                    {insuranceDocLoading && (
                                      <CircularProgress
                                        size={14}
                                        style={{ color: "white", marginRight: "10px" }}
                                      />
                                    )}
                                    Save
                                  </Fab>
                                </Grid>
                              )}
                            </Grid>
                          </Paper>
                        </Grid>

                        <Grid item container xs={12} md={12} spacing={2} style={{ marginTop: 16 }}>
                          <Paper variant='outlined' className={styles.root} style={{ width: "100%" }}>
                            <Grid container xs={12} spacing={2}>
                              <Grid item xs={12} md={12}>
                                <Typography variant="h3" gutterBottom>
                                  Purchase Order
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Field
                                  component={TextField}
                                  placeholder="PO Number"
                                  label="PO Number"
                                  name={"purchaseOrderInformation.poNumber"}
                                  disabled={values.status === VehicleServiceStatus.COMPLETED || values.status === VehicleServiceStatus.CANCELLED}
                                  value={props.values.purchaseOrderInformation && props.values.purchaseOrderInformation.poNumber}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setValues({
                                      ...props.values,
                                      purchaseOrderInformation: {
                                        ...props.values.purchaseOrderInformation,
                                        poNumber: event.target.value
                                      }
                                    })
                                  }}
                                  fullWidth
                                ></Field>
                              </Grid>
                              {values.status !== VehicleServiceStatus.CANCELLED && (<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <FilePicker
                                  onFilePick={(e: any) => {
                                    const file = e.target.files[0];
                                    uploadDocument(file, DocumentUploadType.PO);
                                  }}
                                  title={"Upload Document"}
                                  accept={"image/jpg, image/jpeg, image/png, application/pdf"}
                                />
                                {poUploading && (
                                  <span> <CircularProgress size={18} /> </span>
                                )}
                                <p style={{ fontSize: 12 }}>Allowed document type is PDF, PNG, JPG or JPEG. Max size for PDF is 50MB, Image files is 5MB</p>
                              </Grid>)}
                              {values.purchaseOrderInformation && values.purchaseOrderInformation.documentUrl && (
                                <Grid xs={1} sm={1} md={1}>
                                  <span
                                    title="Download file"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      downloadDocument(values.purchaseOrderInformation.documentUrl, "Vehicle-Service-PO-Number")
                                    }}
                                  >
                                    <CloudDownloadIcon fontSize="large" style={{ marginTop: 10 }} />
                                  </span>
                                </Grid>
                              )}
                              {values.id && (
                                <Grid xs={12} sm={12} md={12}>
                                  <Fab
                                    variant="extended"
                                    size="medium"
                                    aria-label="Save"
                                    className="createButton"
                                    disabled={
                                      (values.branchId && userState.currentBranch.id !== values.branchId) ||
                                      values.status === VehicleServiceStatus.COMPLETED ||
                                      values.status === VehicleServiceStatus.CANCELLED ||
                                      !values.purchaseOrderInformation ||
                                      (
                                        values.purchaseOrderInformation &&
                                        !values.purchaseOrderInformation.poNumber &&
                                        !values.purchaseOrderInformation.documentUrl
                                      ) || purchaseOrderLoading
                                    }
                                    onClick={() => {
                                      handleFormSubmit(props.values);
                                      setPurchaseOrderLoading(true);
                                    }}
                                  >
                                    {purchaseOrderLoading && (
                                      <CircularProgress
                                        size={14}
                                        style={{ color: "white", marginRight: "10px" }}
                                      />
                                    )}
                                    Save
                                  </Fab>
                                </Grid>
                              )}
                            </Grid>
                          </Paper>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          style={{ marginTop: "20px" }}
                        >
                          <Grid item container xs={12}>
                            <Typography variant="h3" gutterBottom>
                              NOTES
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              style={{ width: "100%" }}
                              label="Note"
                              name={"noteDescription"}
                              multiline
                              rows={3}
                              inputProps={{ maxLength: 500 }}
                              onChange={handlerNoteInputChange}
                              variant="outlined"
                              value={note.description}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Fab
                            variant="extended"
                            size="medium"
                            onClick={() => {
                              if (props.values.id) {
                                addVehicleServiceNote({
                                  variables: {
                                    serviceId: values.id,
                                    note
                                  }
                                });
                              } else {
                                setValues({
                                  ...props.values,
                                  notes: props.values.notes ? [...props.values.notes, { ...note }] : [{ ...note }]
                                });
                              }
                            }}
                            disabled={
                              (values.branchId && userState.currentBranch.id !== values.branchId) ||
                              !note.description ||
                              values.status === VehicleServiceStatus.COMPLETED ||
                              values.status === VehicleServiceStatus.CANCELLED
                            }
                          >
                            Add
                          </Fab>
                        </Grid>
                        {props.values &&
                          props.values.notes &&
                          props.values.notes.map((note: any, index: number) => {
                            const createdBy =
                              note &&
                                note.createdBy &&
                                note.createdBy.firstName &&
                                note.createdBy.lastName
                                ? note.createdBy.firstName +
                                " " +
                                note.createdBy.lastName
                                : userState.firstName +
                                " " +
                                userState.lastName;
                            return (
                              <Grid
                                item
                                container
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                key={index}
                              >
                                <Grid xs={10}>
                                  <p>{note.description}</p>
                                </Grid>

                                <Grid xs={9}>
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      padding: 0,
                                      margin: 0
                                    }}
                                  >
                                    {getLocalizedDateFormat(country, note.createdDate, DATE_TYPE.EXPANDED)}
                                    <span>&nbsp;</span> | <span>&nbsp;</span>{" "}
                                    {createdBy}
                                  </p>
                                </Grid>
                              </Grid>
                            );
                          })}
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                        >
                          <Grid container item xs={12} spacing={2}>
                            <Grid item xs={10}>
                              <Typography variant="h3" gutterBottom>
                                SERVICE AND REPAIR ADDITIONAL DOCUMENTS
                              </Typography>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                              <Fab
                                className="blackBackButton"
                                variant="extended"
                                size="medium"
                                aria-label="Update"
                                disabled={(values.branchId && userState.currentBranch.id !== values.branchId) || values.status === VehicleServiceStatus.COMPLETED || values.status === VehicleServiceStatus.CANCELLED}
                                onClick={() => {
                                  setDocumentDialogVisible(true);
                                }}
                              >
                                ADD DOCUMENT
                              </Fab>
                            </Grid>
                            <Grid item xs={10}>
                              <DocumentDialog
                                open={documentDialogVisible}
                                handleClose={() => setDocumentDialogVisible(false)}
                                uploadInProgress={uploadInProgress}
                                onSubmit={handleDocumentSave}
                                accept="application/pdf"
                              />
                              <Paper variant='outlined' style={{ width: "73vw" }}>
                                <ViewDocuments
                                  isOpen={openDeleteDialog}
                                  deleteInProgress={deleteInProgress}
                                  handleOpen={handleDialogOpen}
                                  handleClose={handleDialogClose}
                                  vehicleService={props.values}
                                  downloadDocument={downloadDocument}
                                  deleteDocument={deleteDocument}
                                />
                              </Paper>
                            </Grid>
                          </Grid>

                        </Grid>
                        <Grid item xs={12}>
                          <Fab
                            variant="extended"
                            size="medium"
                            type="submit"
                            disabled={
                              !d.fromISO(values.appointmentDate).isValid ||
                              !d.fromISO(values.endDate).isValid ||
                              values.serviceType === "" ||
                              (values.branchId && userState.currentBranch.id !== values.branchId) ||
                              values.status === VehicleServiceStatus.COMPLETED || values.status === VehicleServiceStatus.CANCELLED || updateServiceLoading || serviceSaveLoading
                            }
                          >
                            {(serviceSaveLoading || updateServiceLoading) && (
                              <CircularProgress
                                size={14}
                                style={{ color: "white", marginRight: "10px" }}
                              />
                            )}
                            Save
                          </Fab>
                        </Grid>
                      </Grid>
                    </Form>
                  </React.Fragment>
                );
              }}
            </Formik>
            <ConfirmationModel
              open={openConfirmatonDialog}
              handleconfirm={(completionDate) =>
                handleServiceCompletionConfirm({ ...values, completionDate })
              }
              handleClose={() => setOpenConfirmationDialog(false)}
              completionDate={values.completionDate}
              appointmentDate={values.appointmentDate}
            />
            {openMarkInservice && (
              <ConfirmationDialog
                isOpen={openMarkInservice}
                title={"Mark vehicle In-Service"}
                description={"Are you sure, you want to mark this vehicle In-Service"}
                onCancel={() => setOpenMarkInService(false)}
                onConfirm={() => {
                  markVehicleInService({
                    variables: {
                      vehicleServiceId: values.id,
                      status: VehicleServiceStatus.IN_SERVICE
                    }
                  })
                }}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.vehicleService === nextProps.vehicleService;
  }
);

const ViewDocuments = (props: IViewDocumentProps) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [deleteDocumentUrl, setDeleteDocumentUrl] = useState<string>("");
  const [documentIndex, setDocumentIndex] = useState<number>();

  const columns = [
    {
      label: "Document Type",
      name: "documentType",
    },
    {
      label: "Document Name/Title",
      name: "title"
    },
    {
      label: "Expiry Date",
      name: "expiryDate",
      options: {
        customBodyRender: (value: string) => value ? getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED) : "N/A"
      }
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        filter: false,
        customBodyRender: (value: any) => {
          return (
            <React.Fragment>
              {(userState.role === UserRoles.ADMIN || userState.role === UserRoles.SUPER_ADMIN) && (
                <div>
                  <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={() =>
                      props.downloadDocument(value.url, value.name)
                    }
                    size="large">
                    <CloudDownloadIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    disabled={props.vehicleService.status === VehicleServiceStatus.COMPLETED}
                    onClick={() => {
                      setDeleteDocumentUrl(value.url);
                      setDocumentIndex(value.index);
                      props.handleOpen();
                    }}
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}
            </React.Fragment>
          );
        }
      }
    }
  ];

  const getRows = (serviceDocuments: IServiceDocument[]) => {
    if (!serviceDocuments || serviceDocuments === null) {
      return [];
    }
    const sortedDocuments = returnSortedDocuments(serviceDocuments);
    if (sortedDocuments && sortedDocuments.length > 0) {
      return sortedDocuments.map((serviceDocument: IServiceDocument, index: number) => {
        return {
          ...serviceDocument,
          actions: { url: serviceDocument.documentUrl, name: serviceDocument.documentName, index },
          title: serviceDocument.title || serviceDocument.documentName,
          expiryDate: serviceDocument.expiryDate,
          documentType: serviceDocument.documentType
        };
      });
    } else {
      return []
    }
  };

  return (
    <Grid item xs={12}>
      <NuvvenTable
        key={new Date().getMilliseconds()}
        title={""}
        rows={getRows(props.vehicleService.documents)}
        columns={columns}
        setSelection={() => { }}
        options={{
          selectableRows: "none",
          download: false,
          filter: false,
          print: false,
          search: false,
          viewColumns: false,
          elevation: 0
        }}
      />
      {props.isOpen && (
        <ConfirmationDialog
          isOpen={props.isOpen}
          onCancel={props.handleClose}
          onConfirm={() => {
            props.deleteDocument(deleteDocumentUrl, documentIndex);
          }}
          isInProgress={props.deleteInProgress}
          title=""
          description={"Are you sure, you want to delete this document?"}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    vehicleService: getVehicleService(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<IAction>) => {
  return {
    resetVehicleService: () => {
      dispatch(resetVehicleService());
    },
    updateVehicleService: (payload: IVehicleService) => {
      dispatch(updateVehicleService(payload));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewServicesAndRepair);
