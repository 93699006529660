import { gql } from "@apollo/client";

export const SEND_PCN_MAIL = gql`
  mutation sendPcnPdf(
    $customerId: String!
    $vehicle: String!
    $noticeUrl: String!
  ) {
    sendPcnPdf(
      customerId: $customerId
      vehicle: $vehicle
      noticeUrl: $noticeUrl
    ) {
      success
      message
    }
  }
`;
