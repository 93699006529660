import "./index.scss";
import { SelectableRows } from "./../../../common/NuvvenTable/types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  Typography
} from "@mui/material";
import { Display, MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import papa from "papaparse";
import {
  resetVehicleService,
} from "../../../../actions/fleet/vehicle/actions";
import { addTableState } from "../../../../actions/tableState/actions";
import { GET_VEHICLE_SERVICES } from "../../../../graphql/fleet/vehicleServices/getVehicleServicesQuery";
import { IVehicleService, VehicleServiceStatus, VehicleServiceStatusValue, VehicleServiceType, VehicleServiceTypeValue } from "../../../../reducers/fleet/types";
import { ITable, TableNames } from "../../../../reducers/tableState/types";
import { IAppState } from "../../../../store";
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { CsvUploadStatusType, DATE_TYPE, formatGraphQLErrorMessage, ICsvUploadError, possibleCsvMimeTypes, returnSortedOrder, setTableSortOrder, toCurrency, vehicleServiceUploadCsvHeaders } from "../../../common/utils";
import { BulkCsvUploadDialog } from "../../../common/BulkCsvUploadDialog/BulkCsvUploadDialog";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { UPLOAD_VEHICLE_SERVICE } from "../../../../graphql/fleet/vehicleServices/uploadVehicleServiceMutation";
import { ApolloError } from "@apollo/client";
import { getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { captureErrorException } from "../../../../utils/sentry";
import { isValidDate } from "../VehicleInventory/VehicleInventory";
interface IFilter {
  key: string;
  value: string;
}

export const ServicesAndRepair = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [rows, setRows] = useState<IVehicleService[]>([]);
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [uploadErrors, setUploadErrors] = useState<ICsvUploadError[]>([]);
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [fileValue, setFileValue] = useState<File | undefined>();
  const [filterList, setFilterList] = useState<IFilter[]>([]);
  const [filtering, setIsFiltering] = useState<boolean>(false);


  const [uploadStatus, setUploadStatus] = useState<number>(
    CsvUploadStatusType.DEFAULT
  )
  const [servieAndRepairTable, setServieAndRepairTable] = useState<ITable>();
  const { locale, currency } = userState.currentOrganisation;

  const [
    loadVehicleServices,
    { loading, data }
  ] = useLazyQuery(GET_VEHICLE_SERVICES, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });
  const servicesAndRepairTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.serviceAndRepair
  );

  const [uploadVehicleService] = useMutation(UPLOAD_VEHICLE_SERVICE, {
    onError: (error: ApolloError) => {
      setFileName("");
      setFileValue(undefined);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: (data) => {
      if (
        data.uploadVehicleService &&
        data.uploadVehicleService.errors &&
        data.uploadVehicleService.errors.length
      ) {
        setFileName("");
        setFileValue(undefined);
        setUploadErrors(data.uploadVehicleService.errors);
        return setUploadStatus(CsvUploadStatusType.ERROR);
      }
      snackbar({
        message: "Vehicle service uploaded successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setUploadModalOpen(false);
      loadVehicleServices();
      setUploadStatus(CsvUploadStatusType.DEFAULT);
      setFileName("");
      setFileValue(undefined);
    }
  });

  useEffect(() => {
    if (servicesAndRepairTableState) {
      setServieAndRepairTable(servicesAndRepairTableState);
      let sortedOrder = returnSortedOrder(servicesAndRepairTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(servicesTableColumn, servicesAndRepairTableState, sortedOrder)
      }
    }
  }, [servicesAndRepairTableState])

  useEffect(() => {
    if (data && data.vehicleServices) {
      setRows(data.vehicleServices);
    }
  }, [data]);

  useEffect(() => {
    if (!uploadModalOpen) {
      setUploadErrors([]);
    }
  }, [uploadModalOpen]);


  function onSelectDocument(event: any) {
    const file: File = event.target.files[0];
    setUploadErrors([]);
    setUploadStatus(CsvUploadStatusType.DEFAULT);
    if (file) {
      setFileName(file.name);
      if (possibleCsvMimeTypes.includes(file.type)) {
        setFileValue(file);
      } else {
        return snackbar({
          message: "Invalid File Type. Allowed type: csv",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  function uploadCsv() {
    try {
      if (fileValue) {
        setUploadStatus(CsvUploadStatusType.IN_PROGRESS);
        papa.parse(fileValue, {
          skipEmptyLines: true,
          complete(results: any) {
            const vehicleServices = [];
            if (results.data.length) {
              for (let index = 0; index < results.data[0].length; index++) {
                const header = results.data[0][index];
                if (header !== vehicleServiceUploadCsvHeaders[index]) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: `Invalid File Header: "${header}" is incorrect.`,
                    variant: SnackBarVariant.ERROR
                  });
                }
              }
              if (results.data.length === 2 && !results.data[1][0]) {
                setUploadStatus(CsvUploadStatusType.ERROR);
                setFileName("");
                setFileValue(undefined);
                return snackbar({
                  message: "License plate is missing.",
                  variant: SnackBarVariant.ERROR
                });
              }
              for (let index = 1; index < results.data.length; index++) {
                const element: any = results.data[index];
                if (element.length < 3) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: "Invalid file",
                    variant: SnackBarVariant.ERROR
                  });
                }
                if (!element[0]) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: `Licence plate is missing at row ${index}`,
                    variant: SnackBarVariant.ERROR
                  })
                }
                if (!isValidDate(element[1], userState.currentOrganisation.address.country)) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: `Invalid date format for Vehicle De-Fleet Date at row ${index}`,
                    variant: SnackBarVariant.ERROR
                  });
                }
                if (!element[2]) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: `Service Schedule By Month not found for ${element[0]}`,
                    variant: SnackBarVariant.ERROR
                  });
                }
                const data = {
                  licencePlate: element[0],
                  deFleetByDate: element[1],
                  scheduledByMonth: parseInt(element[2]),
                };
                vehicleServices.push(data);
              }
              if (vehicleServices.length > 0) {
                uploadVehicleService({
                  variables: {
                    vehicleServices
                  }
                });
              } else {
                setUploadStatus(CsvUploadStatusType.ERROR);
                snackbar({
                  message: "No data found.",
                  variant: SnackBarVariant.ERROR
                });
              }
            }
          }
        });
      }
    } catch (error: any) {
      setUploadStatus(CsvUploadStatusType.ERROR);
      captureErrorException(error)
    }
  }

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    rowHover: true,
    setRowProps: (row, rowIndex) => {
      const serviceRow = rows.find(
        (serviceRow: IVehicleService) => serviceRow.referenceNumber === row[0]
      );
      if (!serviceRow) {
        return { backgroundColor: "#000000" };
      }
      return {
        style: { backgroundColor: serviceRow.sentToXero ? "#def7e8" : "#FFEFED" }
      }
    },
    onRowClick: (
      rowData: string[],
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row: IVehicleService = rows[rowMeta.dataIndex];
      if (row && row.id) {
        navigate(`/new-services-and-repair?id=${row?.id}`);
      }
    },

    onChangeRowsPerPage: (numberOfRows: number) => {
      dispatch(
        addTableState({
          tableName: TableNames.SERVICE_AND_REPAIR,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      servieAndRepairTable && servieAndRepairTable.rowsPerPage
        ? servieAndRepairTable.rowsPerPage
        : 10,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.SERVICE_AND_REPAIR,
          columnName: changedColumn,
          direction
        })
      );
    },
    onFilterChange(changedColumn, filterList, type, changedColumnIndex, displayData) {
      dispatch(
        addTableState({
          tableName: TableNames.SERVICE_AND_REPAIR,
          filters: filterList
        })
      );
      let filterColumns: IFilter[] = [];
      filterList.forEach((value, index) => {
        if (value.length) {
          filterColumns.push({
            key: servicesTableColumn[index].name,
            value: value[0]
          });
        }
      });
      if (filterList) {
        setFilterList(filterColumns);
        setIsFiltering(true);
        loadVehicleServices({
          variables: {
            filter: filterColumns
          }
        });
      } else {
        setIsFiltering(false);
      }
    },
    onColumnViewChange: (changedColumn, action) => {
      const updatedViewColumns = { ...servicesAndRepairTableState?.viewColumns, [changedColumn]: String(action === 'add') };
      dispatch(
        addTableState({
          tableName: TableNames.SERVICE_AND_REPAIR,
          viewColumns: updatedViewColumns
        })
      );
    },
    onTableInit: (_: string, tableState: MUIDataTableState) => {
      if (servicesAndRepairTableState && servicesAndRepairTableState.filters) {
        let filterColumns: IFilter[] = [];
        servicesAndRepairTableState.filters.forEach((value, index) => {
          if (value.length) {
            filterColumns.push({
              key: servicesTableColumn[index].name,
              value: value[0]
            });
          }
        });
        tableState.filterList = servicesAndRepairTableState.filters;
      }
      if (servicesAndRepairTableState && servicesAndRepairTableState.viewColumns) {
        for (const [key, value] of Object.entries(servicesAndRepairTableState.viewColumns)) {
          let columnIndex = tableState.columns.findIndex(column => column.name === key);
          if (columnIndex > -1) {
            tableState.columns[columnIndex].display = value as Display;
          }
        }
      }
      if (servieAndRepairTable && sortOrder) {
        setTableSortOrder(servicesTableColumn, servieAndRepairTable, sortOrder);
      }
    }
  };
  useEffect(() => {
    if (userState.tenancy) {
      loadVehicleServices();
    }
  }, [userState]);

  const servicesTableColumn: any = [
    {
      label: "Reference Number",
      name: "referenceNumber"
    },
    {
      label: "Registration Number",
      name: "licencePlate"
    },
    {
      label: "Service Type",
      name: "serviceType",
      options: {
        customBodyRender: (value: VehicleServiceType) => VehicleServiceTypeValue[value]
      }
    },
    {
      label: "Appointment Date",
      name: "appointmentDate",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
      }
    },
    {
      label: "Completion Date",
      name: "completionDate",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
      }
    },
    {
      label: "Provider Name",
      name: "serviceProviderName"
    },
    {
      label: "Total Expenses",
      name: "totalExpenses",
      options: {
        customBodyRender: (value: string) => toCurrency(parseInt(value), currency, locale)
      }
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value: VehicleServiceStatus) => VehicleServiceStatusValue[value]
      }
    }
  ];

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6}>
        <Typography variant="h1" color="primary">
          Services And Repair
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <Fab
          variant="extended"
          size="medium"
          aria-label="Create"
          className="createButton"
          onClick={() => {
            dispatch(resetVehicleService());
            navigate("/new-services-and-repair");
          }}
          disabled={userState.role === "FINANCE_MANAGER"}
        >
          Create
        </Fab>
        <Fab
          variant="extended"
          size="medium"
          aria-label="Create Multiple"
          style={{ marginLeft: 10 }}
          onClick={() => setUploadModalOpen(true)}
          disabled={userState.role === "FINANCE_MANAGER"}
        >
          Create Multiple
        </Fab>
      </Grid>
      <Grid container item xs={12}>
        {!loading ? (
          <NuvvenTable
            title=""
            rows={rows}
            columns={servicesTableColumn}
            options={options}
            setSelection={() => { }}
          />
        ) : (
          <CircularProgress />
        )}
      </Grid>
      {uploadModalOpen && (
        <BulkCsvUploadDialog
          title={"Create multiple vehicle service"}
          subTitle={"Vehicle Service"}
          csvTemplateLink={"https://nuvvenassets.s3.eu-west-2.amazonaws.com/downloads/Coastr-Vehicle-Service-Upload.csv"}
          uploadErrors={uploadErrors}
          uploadStatus={uploadStatus}
          onDocumentSelect={onSelectDocument}
          fileName={fileName}
          uploadCsv={uploadCsv}
          handleClose={() => setUploadModalOpen(false)}
        />
      )}
    </Grid>
  );
};

export default ServicesAndRepair;
