import "./index.scss";

import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import {
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  Typography
} from "@mui/material";
import * as _ from "lodash";
import { Display, MUIDataTableOptions, MUIDataTableState } from 'mui-datatables';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { addTableState } from "../../../actions/tableState/actions";
import { GET_INVOICE_COUNT } from "../../../graphql/invoices/getInvoiceCountQuery";
import { GET_INVOICES } from "../../../graphql/invoices/getInvoices";
import { GET_UNPAID_INVOICE_COUNT } from "../../../graphql/invoices/getUnpaidInvoieCountquery";
import { MARK_INVOICES_PAID } from "../../../graphql/invoices/markInvoicesPaidMutation";
import { REQUEST_PAYMENT_FOR_INVOICES } from "../../../graphql/invoices/requestPaymentMutation";
import { SEARCH_INVOICE } from "../../../graphql/invoices/searchInvoicesQuery";
import { IInvoice } from "../../../reducers/invoices/types";
import { ITable, TableNames } from "../../../reducers/tableState/types";
import { IAppState } from "../../../store";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../utils/localized.syntex";
import { ConfirmationDialog } from "../../common/ConfirmationDialog/ConfirmationDialog";
import { NuvvenTable } from "../../common/NuvvenTable/NuvvenTable";
import { SelectableRows } from "../../common/NuvvenTable/types";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  formatGraphQLErrorMessage,
  returnSortedOrder,
  setTableSortOrder,
  toCurrency,
} from "../../common/utils";
import { InvoiceStatus } from "./UpdateInvoice/InvoiceComponents/types";
import { InvoiceType, reshapeInvoicesIntoRows } from "./utils";
import { GET_UNPAID_INVOICES } from "../../../graphql/invoices/getUnpaidInvoices";

interface IFilter {
  key: string;
  value: string;
}

export function Invoices(props: any) {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const invoicesTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.invoices
  );
  const [selectedInvoices, setSelectedInvoices] = useState<IInvoice[]>([]);
  const [rows, setRows] = useState<any>([]);
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [invoicesTable, setInvoicesTable] = useState<ITable>();
  const [totalInvoiceCount, setTotalInvoiceCount] = useState<number>(0);
  const [fetchUnpaid, setFetchUnpaid] = useState<boolean>(false);
  const [readyToFetch, setReadyToFetch] = useState<boolean>(false);
  const [currentFilters, setCurrentFilters] = useState<IFilter[]>([]);
  const [limit, setLimit] = useState(10);
  const [filtering, setIsFiltering] = useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
  const [enableMarkAsPaid, setEnabledMarkAsPaid] = useState<boolean>(false);
  const [selectedUnpaidInvoices, setSelectedUnpaidInvoices] = useState<any[]>([]);
  const [confirmationDialogProps, setConfirmationDialogProps] = useState<any>({});
  const [paramsInvoiceType, setParamsInvoiceType] = useState<string>("");


  const fetchInvoices = () => {
    if (fetchUnpaid) {
      getUnpaidInvoiceCount();
      loadUnpaidInvoices({
        variables: {
          limit: invoicesTableState?.rowsPerPage ? invoicesTableState.rowsPerPage : limit,
          offset: 0,
          filters: currentFilters
        }
      });
    } else {
      getInvoiceCount();
      loadInvoices({
        variables: {
          limit: invoicesTableState?.rowsPerPage ? invoicesTableState.rowsPerPage : limit,
          offset: 0,
          filters: currentFilters 
        }
      });
    }
  }

  const [loadInvoices, { loading: invoicesLoading }] = useLazyQuery(
    GET_INVOICES,
    {
      fetchPolicy: "network-only",
      onCompleted: (invoicesData) => {
        if (invoicesData && invoicesData.invoices) {
          const newInvoices = shapeInvoices(invoicesData.invoices)
          const oldInvoicess = [...rows];
          newInvoices.forEach((invoices: IInvoice) => {
            if (!oldInvoicess.some((item) => item.id === invoices.id)) {
              oldInvoicess.push(invoices);
            }
          });
          setRows(oldInvoicess);
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [loadUnpaidInvoices, { loading: unpaidInvoicesLoading }] = useLazyQuery(
    GET_UNPAID_INVOICES,
    {
      fetchPolicy: "network-only",
      onCompleted: (invoicesData) => {
        if (invoicesData && invoicesData.unpaidInvoices) {
          const newInvoices = shapeInvoices(invoicesData.unpaidInvoices)
          const oldInvoicess = [...rows];
          newInvoices.forEach((invoices: IInvoice) => {
            if (!oldInvoicess.some((item) => item.id === invoices.id)) {
              oldInvoicess.push(invoices);
            }
          });
          setRows(oldInvoicess);
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [searchInvoice, { loading: searchInvoicesLoading, data: searchInvoiceData }] = useLazyQuery(
    SEARCH_INVOICE,
    {
      fetchPolicy: "network-only",
      onCompleted: (searchInvoiceData) => {
        if (searchInvoiceData && searchInvoiceData.searchInvoice) {
          let invoices = shapeInvoices(searchInvoiceData.searchInvoice)
          setTotalInvoiceCount(invoices.length)
          setRows(invoices);
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [getInvoiceCount] = useLazyQuery(
    GET_INVOICE_COUNT,
    {
      fetchPolicy: "network-only",
      onCompleted: (invoiceCountData) => {setTotalInvoiceCount(invoiceCountData.invoiceCount)},
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [markInvoicesAsPaid, {loading: markInvoicesLoading}] = useMutation(MARK_INVOICES_PAID, {
    onCompleted: (data: any) => {
      if (data.markInvoicesAsPaid) {
        snackbar({
          message: "Selected Invoices have been marked as PAID",
          variant: SnackBarVariant.SUCCESS
        });
        setShowConfirmationDialog(false);
        setEnabledMarkAsPaid(false);
        setRows([]);
        fetchInvoices();
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [requestPaymentForInvoices, {loading: requestPaymentLoading}] = useMutation(REQUEST_PAYMENT_FOR_INVOICES, {
    onCompleted: () => {
      snackbar({
        message: "Payment requested successfully for selected invoices.",
        variant: SnackBarVariant.SUCCESS
      })
      setShowConfirmationDialog(false)
      setRows([]);
      fetchInvoices();
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  })

  const [getUnpaidInvoiceCount, { data: unpaidInvoiceCountData }] = useLazyQuery(
    GET_UNPAID_INVOICE_COUNT,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  useEffect(() => {
    if (location) {
      if (location.search) {
        const params = new URLSearchParams(location.search);
        const type = params.get("type");
        if(type){
          setParamsInvoiceType(type)
        }
        if (type && type === "UNPAID") {
          setFetchUnpaid(true)
        }
      }
      else {
        setFetchUnpaid(false)
      }
    }
    setReadyToFetch(true)
  }, [location]);

  useEffect(() => {
    if (unpaidInvoiceCountData && unpaidInvoiceCountData.unpaidInvoiceCount) {
      setTotalInvoiceCount(unpaidInvoiceCountData.unpaidInvoiceCount)
    }
  }, [unpaidInvoiceCountData])

  useEffect(() => {
    if (invoicesTableState) {
      setInvoicesTable(invoicesTableState);
      let sortedOrder = returnSortedOrder(invoicesTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, invoicesTableState, sortedOrder)
      }
    }
  }, [invoicesTableState]);

  useEffect(() => {
    if (userState.tenancy && readyToFetch) {
      fetchInvoices();
    }
  }, [userState, fetchUnpaid, readyToFetch]);

  useEffect(() => {
    const unpaidInvoices = selectedInvoices
      .filter((invoice) => {
        if (
          [InvoiceStatus.UNPAID, InvoiceStatus.PARTIALLY_PAID].includes(
            invoice.status as InvoiceStatus
          )
        ) {
          return invoice.id;
        }
      })
      .map((invoice) => invoice.id);
    if (unpaidInvoices.length) {
      setSelectedUnpaidInvoices(unpaidInvoices);
      setEnabledMarkAsPaid(true);
    } else {
      setEnabledMarkAsPaid(false);
    }
  }, [selectedInvoices]);

  const shapeInvoices = (data: any) => {
    let invoicesArr = data
      .map((invoice: IInvoice) => {
        const isBusinessCustomerBooking =
          invoice.customerType === "business" &&
            invoice.businessCustomer &&
            invoice.businessCustomer.id !== ""
            ? true
            : false;
        const businessCustomer = invoice.businessCustomer;
        const individualCustomer = invoice.customer;
        const customer = isBusinessCustomerBooking
          ? businessCustomer?.businessName || ""
          : individualCustomer
            ? individualCustomer.firstName + " " + individualCustomer.lastName
            : "";
        let invoiceRow = reshapeInvoicesIntoRows(
          invoice,
          locale,
          currency,
          invoice.booking?.referenceNumber || "",
          customer,
          country
        )
        return invoiceRow;
      })
    return invoicesArr;
  }

  const columns = [
    {
      label: `${getLocalizedBookingSyntex(country)} Ref.`,
      name: "referenceNumber",
      options: {
        filter: false
      }
    },
    {
      label: "Invoice Id",
      name: "invoiceRef",
      options: {
        filter: false
      }
    },
    {
      label: "Invoice Date",
      name: "dateCreated",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string) => {
          if (value) {
            return getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED)
          } else {
            return "-"
          }
        }
      }
    },
    {
      label: "Invoice Type",
      name: "invoiceType",
      options: {
        customBodyRender: (value: string) => {
          if (value === InvoiceType.SUNDRY) {
            return "SUNDRY";
          } else {
            return "REGULAR";
          }
        },
        filterOptions: {
          names: ["SUNDRY", "REGULAR"]
        }
      }
    },
    {
      label: "Customer",
      name: "customer",
      options: {
        filter: false
      }
    },
    {
      label: "Total",
      name: "total",
      options: {
        filter: false,
        customBodyRender: (value: number) => {
          if (value) {
            return toCurrency(value, currency, locale)
          }
          return "-"
        }
      }
    },
    {
      label: "Balance Due",
      name: "dueAmount",
      options: {
        filter: false,
        customBodyRender: (value: number) => {
          if (value) {
            return toCurrency(value, currency, locale)
          }
          return "-"
        }
      }
    },
    {
      label: "Due Date",
      name: "dueDate",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string) => {
          if (value) {
            return getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED)
          } else {
            return "-"
          }
        }
      }
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value: any) => {
          if ([InvoiceStatus.PARTIALLY_PAID, InvoiceStatus.UNPAID].includes(value)) {
            return "UNPAID"
          } else {
            return value
          }
        },
        filterOptions: {
          names: [InvoiceStatus.DRAFT, InvoiceStatus.PAID, InvoiceStatus.UNPAID, InvoiceStatus.VOID]
        }
      }
    },
    {
      label: "Email",
      name: "lastSent",
      options: {
        customBodyRender: (value: any) => {
          if (value) {
            return "SENT"
          } else {
            return "-";
          }
        },
        filterOptions: {
          names: ["-", "SENT"]
        }
      }
    }
  ]

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.MULTIPLE,
    responsive: "vertical",
    count: totalInvoiceCount,
    rowsPerPageOptions: [10, 20, 100],
    setRowProps: (row, rowIndex) => {
      const invoiceRow = rows.find(
        (invoice: IInvoice) => invoice.invoiceRef === row[1]
      );
      return {
        style: { backgroundColor: invoiceRow.sageAccountingInvoiceId || invoiceRow.sentToXero ? "#def7e8" : "#FFEFED" }
      }
    },
    onTableChange: (action: string, tableState: any) => {
      tableState.filterData[3] = ["SUNDRY", "REGULAR"]
      tableState.filterData[8] = ["PAID", "UNPAID", "VOID", "DRAFT"]
      tableState.filterData[9] = ["SENT", "-"]
      let filterColumns: IFilter[] = currentFilters; // Use the stored filters

      switch (action) {
        case "changePage":
          if (tableState.searchText) {
            return; // Skip executing changePage if there is a search text
          }
          const { page, rowsPerPage } = tableState
          if (page * rowsPerPage >= rows.length || filtering) {
            setCurrentFilters([]);
            if (fetchUnpaid) {
              loadUnpaidInvoices({
                variables: {
                  limit: rowsPerPage,
                  offset: page * rowsPerPage,
                  filters: filterColumns
                }
              });
            } else {
              if (filtering) {
                loadInvoices({
                  variables: {
                    offset: page * rowsPerPage,
                    limit: rowsPerPage,
                    filters: filterColumns
                  }
                });
              }
              else {
                loadInvoices({
                  variables: {
                    limit: rowsPerPage,
                    offset: page * rowsPerPage
                  }
                });
              }
            }
          }
          break;
        case "changeRowsPerPage":
          setRows([])
          fetchInvoices();
          break;
        default:
          break;
      }
    },
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: (invoicesLoading || unpaidInvoicesLoading || searchInvoicesLoading) ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    onRowClick: (rowData: string[]) => {
      if (rowData && rowData.length) {
        // FIXME: Idially the rows should change based on sorting and then find data based on rowindex
        const invoiceClicked = rows.find(
          (row: any) => row.invoiceRef === rowData[1]
        );
        if (invoiceClicked) {
          navigate(`/update-billing?invoice=${invoiceClicked.id}`)
        }
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)
      if (fetchUnpaid) {
        setRows([])
        loadUnpaidInvoices({
          variables: {
            limit: numberOfRows || limit,
            offset: 0,
            filters: currentFilters
          }
        });
      } else {
        loadInvoices({
          variables: {
            offset: 0,
            limit: numberOfRows || limit,
            filters: currentFilters // Pass the current filters
          }
        });}
      dispatch(
        addTableState({
          tableName: TableNames.INVOICES,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      invoicesTable && invoicesTable.rowsPerPage
        ? invoicesTable.rowsPerPage
        : limit,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.INVOICES,
          columnName: changedColumn,
          direction
        })
      );
    },
    onFilterChange(changedColumn, filterList, type, changedColumnIndex, displayData)  {
      dispatch(
        addTableState({
          tableName: TableNames.INVOICES,
          filters: filterList
        })
      );
      let filterColumns: IFilter[] = [];
      filterList.forEach((value, index) => {
        if (value.length) {
          filterColumns.push({
            key: columns[index].name,
            value: value[0]
          });
        }
      });
      setCurrentFilters(filterColumns); // Save filters to state

      if (filterList) {
      setIsFiltering(true);
      if (fetchUnpaid) {
        loadUnpaidInvoices({
        variables: {
          limit: invoicesTableState?.rowsPerPage || limit,
          offset: 0,
          filters: filterColumns
        }
      })
    }
    else {
      loadInvoices({
        variables: {
          limit: invoicesTableState?.rowsPerPage || limit,
          offset: 0,
          filters: filterColumns
        }
      })
    }
    }
    else {
      setIsFiltering(false)
    }
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      if (rowsSelected && rowsSelected.length === 0) {
        setEnabledMarkAsPaid(false);
        setSelectedInvoices([]);
      } else if (rowsSelected) {
        const invoicesToMark: IInvoice[] = [];
        for (const index of rowsSelected) {
          invoicesToMark.push(rows[index]);
        }
        setSelectedInvoices(invoicesToMark);
      }
    },
    onTableInit: (_: string, tableState: MUIDataTableState) => {
      if (invoicesTableState && invoicesTableState.filters && !location.search) {
        let filterColumns: IFilter[] = [];
        invoicesTableState.filters.forEach((value, index) => {
          if (value.length) {
            filterColumns.push({
              key: columns[index].name,
              value: value[0]
            });
          }
        });
        setCurrentFilters(filterColumns); // Initialize the current filters

        loadInvoices({
          variables: {
            limit: invoicesTableState?.rowsPerPage || limit,
            offset: 0,
            filters: filterColumns 
          }
        });
        tableState.filterList = invoicesTableState.filters;
      }
      if (invoicesTableState && invoicesTableState.viewColumns) {
        for (const [key, value] of Object.entries(invoicesTableState.viewColumns)) {
          let columnIndex = tableState.columns.findIndex(column => column.name === key);
          if (columnIndex > -1) {
            tableState.columns[columnIndex].display = value as Display;
          }
        }
      }
      if (invoicesTable && sortOrder) {
        setTableSortOrder(columns, invoicesTable, sortOrder);
      }
      if (invoicesTable && invoicesTable.rowsPerPage) {
        setLimit(invoicesTable.rowsPerPage);
      }
    },
    onColumnViewChange: (changedColumn, action) => {
      const updatedViewColumns = { ...invoicesTableState?.viewColumns, [changedColumn]: String(action === 'add') };
      dispatch(
        addTableState({
          tableName: TableNames.INVOICES,
          viewColumns: updatedViewColumns
        })
      );
    },
    onSearchChange: (searchText: string | null) => {
      if (searchText) {
        handleSearch(searchText)
      } else {
        if (!fetchUnpaid) {
        setRows([])
        setCurrentFilters([]);
        getInvoiceCount()
        fetchInvoices(); 
        }
      }
    },
  };

  const handleSearch = _.debounce((searchText: string) => {
    if (searchText) {
      searchInvoice({
        variables: {
          q: searchText,
          limit,
          offset:0,
          filters: currentFilters
        }
      })
    }
  }, 1000);

  const handleMarkInvoicesAsPaidClick = () => {
    setShowConfirmationDialog(true);
    setConfirmationDialogProps({
      isLoading: markInvoicesLoading,
      isInProgress: markInvoicesLoading,
      onCancel: () => {
        setShowConfirmationDialog(false);
      },
      showInfo: selectedInvoices.length !== selectedUnpaidInvoices.length,
      infoMessage:
        "Some invoices that have been selected are already marked as PAID/VOID/DRAFT. These invoices will be excluded from the selection."
      ,
      onConfirm: () => {
        markInvoicesAsPaid({
          variables: {
            invoiceIds: selectedUnpaidInvoices,
          }
        });
      },
      title: "Confirm Bulk Action",
      description:
        "The selected invoices will be marked PAID by CASH. Proceed?"
    });
  }

  const handleRequestPaymentClick = () => {
    setShowConfirmationDialog(true);
    setConfirmationDialogProps({
      isLoading: requestPaymentLoading,
      isInProgress: requestPaymentLoading,
      onCancel: () => {
        setShowConfirmationDialog(false);
      },
      showInfo: selectedInvoices.length !== selectedUnpaidInvoices.length,
      infoMessage:
        "Some invoices that have been selected are already marked as PAID/VOID/DRAFT. These invoices will be excluded from the selection."
      ,
      onConfirm: () => {
        requestPaymentForInvoices({
          variables: {
            invoiceIds: selectedUnpaidInvoices,
          }
        });
      },
      title: "Confirm Bulk Action",
      description:
        "Are you sure, you want to request payment for selected invoices?"
    });
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid
        item
        container
        xs={12}
        justifyContent={"space-between"}
        direction={"row"}
      >
        <Grid container item xs={6}>
          <Typography variant="h1" color="primary">
            Invoices
          </Typography>
        </Grid>
        <Grid item container xs={6} justifyContent={"flex-end"} spacing={1}>
          <Grid item>
            <Fab
              variant="extended"
              size="medium"
              aria-label="request payment for selected invoices"
              onClick={handleRequestPaymentClick}
              disabled={!enableMarkAsPaid || userState.role === "FLEET_MANAGER"}
            >
              Request Payment
            </Fab>
          </Grid>
          <Grid item>
            <Fab
              variant="extended"
              size="medium"
              aria-label="mark selected invoices as paid offline"
              onClick={handleMarkInvoicesAsPaidClick}
              disabled={!enableMarkAsPaid || userState.role === "FLEET_MANAGER"}
            >
              Mark As Paid
            </Fab>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        {!((invoicesLoading || unpaidInvoicesLoading) && !rows.length) ? (
          <NuvvenTable
            title={""}
            rows={rows}
            columns={columns}
            options={options}
          />
        ) : (
          <CircularProgress />
        )}
      </Grid>
      <ConfirmationDialog isOpen={showConfirmationDialog} {...confirmationDialogProps} />
    </Grid>
  );
}

export default Invoices;