import {
  Fab,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { TextField } from "formik-mui";
import { DateTimePicker } from "../../../../common/DateTimePicker";
import { IUserState } from "../../../../../reducers/user/types";
import { NuvvenTable } from "../../../../common/NuvvenTable/NuvvenTable";
import { SelectableRows } from "../../../../common/NuvvenTable/types";
import { DateTime as d } from "luxon";
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { DATE_TYPE } from "../../../../common/utils";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
interface IHold {
  id?: string;
  vehicleId?: string;
  startDate?: string;
  endDate?: string;
  isSharedFleetEvent?: boolean;
  notes?: IHoldNote[];
  eventsColor?: IEventColor;
  isVehicleOffRoad?: boolean;
}

export interface IEventColor {
  reason: string;
  color: string;
}

interface IProps {
  type: string;
  handleClose(): void;
  onCreateHold(
    vehicleId: string,
    holdId?: string,
    holdInfo?: IHoldInput
  ): void;
  holdSummary?: IHold;
}

export interface IHoldNote {
  id?: string;
  description: string;
  createdBy: IUserState;
  dateCreated: string;
}

export interface IHoldInput {
  startDate: string;
  endDate: string;
  note: string;
  eventsColor: IEventColor;
  isVehicleOffRoad: boolean;
}

const initialState: IHoldInput = {
  startDate: "",
  endDate: "",
  note: "",
  eventsColor: {reason:"", color:""},
  isVehicleOffRoad: false
};
export const OnHoldSummary: React.FC<IProps> = (props) => {
  const {
    holdSummary,
    onCreateHold
  } = props;
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [values, setValues] = useState<IHoldInput>(initialState);
  const [holdNotes, setHoldNotes] = useState<IHoldNote[]>([]);
  const [onHold, setOnHold] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const branch = userState.currentBranch;
  const eventsColorOptions = branch?.eventsColor || [];
  const [selectedReason, setSelectedReason] = useState<string>("");

  useEffect(() => {
    if (holdSummary?.startDate && holdSummary?.endDate) {
      setValues({
        ...values,
        startDate: holdSummary?.startDate,
        endDate: holdSummary?.endDate,
        isVehicleOffRoad: holdSummary?.isVehicleOffRoad ? true : false,
      });
    }
  }, [holdSummary?.startDate, holdSummary?.endDate]);

  useEffect(() => {
    if (holdSummary?.notes?.length) {
      setHoldNotes(holdSummary.notes)
    }
  }, [holdSummary?.notes]);

  useEffect(() => {
    if (holdSummary?.eventsColor) {
      setSelectedReason(holdSummary?.eventsColor?.reason)
    }
  }, [holdSummary?.eventsColor]);

  const columns = [
    {
      label: "Note",
      name: "description",
      options: {
        setCellProps: () => ({ style: { maxWidth: "500px" } }),
      },
    },
    {
      label: "Date",
      name: "dateCreated",
      options: {
        customBodyRender: (data: string) => getLocalizedDateFormat(country, data, DATE_TYPE.EXPANDED)
      }
    },
    {
      label: "Created By",
      name: "createdBy",
      options: {
        customBodyRender: (data: IUserState) => `${data.firstName} ${data.lastName}`
      }
    }
  ];
  return (
    <>
      <Grid container item xs={12}>
        {holdSummary && !holdSummary.isSharedFleetEvent ? (
          <Formik
            enableReinitialize
            initialValues={values}
            onSubmit={(values, { setSubmitting }) => {
              const pickup = d.fromISO(values.startDate);
              setLoading(true);
              values.endDate = d.fromISO(values.endDate).toUTC().toISO();
              if (holdSummary?.vehicleId) {
                if (holdSummary.id && onHold) {
                  return onCreateHold(
                    holdSummary.vehicleId,
                    holdSummary.id,
                    values
                  );
                }
                if (holdSummary.id && !onHold) {
                  return onCreateHold(holdSummary.vehicleId, holdSummary.id);
                }
                return onCreateHold(holdSummary.vehicleId, undefined, values);
              }
            }}
          >
            {(formProps) => (
              <>
                <Form>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={RadioGroup}
                        name={"onHold"}
                        value={onHold ? "onHold" : "available"}
                        row
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const check =
                            event.target.value === "onHold" ? true : false;
                          setOnHold(check);
                        }}
                      >
                        <FormControlLabel
                          value="onHold"
                          name={"onHold"}
                          control={<Radio />}
                          label={
                            <Typography variant="body1">{"On Hold"}</Typography>
                          }
                        />
                        {holdSummary?.id && (
                          <FormControlLabel
                            value="available"
                            name={"available"}
                            control={<Radio />}
                            label={
                              <Typography variant="body1">
                                {"Available for hire"}
                              </Typography>
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    {onHold && values.startDate && values.endDate && ( <>
                      <Grid container item xs={12} spacing={2}>
                        <DateTimePicker
                          fromDateTime={values.startDate}
                          toDateTime={values.endDate}
                          handleChange={(
                            fromDateTime: string,
                            toDateTime: string
                          ) => {
                            setValues({
                              ...values,
                              startDate: fromDateTime,
                              endDate: toDateTime
                            });
                          }}
                          required={true}
                          labels={[
                            "From Date",
                            "From Time",
                            "To Date",
                            "To Time"
                          ]}
                          gridSpan={6}
                          minDateTime={
                            props.holdSummary?.id ? d.fromISO(values.startDate).minus({ minute: 15 }).toUTC().toISO() : d.now().toUTC().toISO()
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <InputLabel>Event Reason</InputLabel>
                          <Select
                            label="Event Reason"
                            value={selectedReason}
                            onChange={(e) => {
                              setSelectedReason(e.target.value);
                              const selected = eventsColorOptions.find((event:any) => event.reason === e.target.value);
                              if (selected) {
                                setValues({
                                  ...values,
                                  eventsColor: selected
                                })
                              }
                            }}
                          >
                            {eventsColorOptions.map((event: any) => (
                              <MenuItem key={event.reason} value={event.reason}>
                                {event.reason}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.isVehicleOffRoad}
                              name={"Vehicle Off Road"}
                              value={"isVehicleOffRoad"}
                              onChange={(event: any) => {
                                setValues({
                                  ...values,
                                  isVehicleOffRoad: event.target.checked
                                })
                              }}
                            />
                          }
                          label="Vehicle Off Road"
                        />
                      </Grid>
                    </>
                    )}
                    {onHold && (
                      <>
                        <Grid item container xs={12}>
                          <Typography variant="h3" gutterBottom>
                            NOTES
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            placeholder="Note"
                            required
                            label="Note"
                            name={"note"}
                            fullWidth
                            multiline
                            row={3}
                            InputProps={{
                              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                setValues({
                                  ...values,
                                  note: event.target.value
                                })
                              },
                              value: values.note
                            }}
                            inputProps={{ maxLength: 300 }}
                            disabled={false}
                          ></Field>
                        </Grid>
                      </>
                    )}
                    <Grid container item xs={12}>
                      <Fab variant="extended" size="medium" type="submit" disabled={loading || (!values.note && onHold)}>
                        {loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                        SAVE
                      </Fab>
                    </Grid>
                  </Grid>
                </Form>
                {holdNotes.length > 0 ? <Grid container xs={12}>
                  <NuvvenTable
                    title={""}
                    rows={holdNotes}
                    columns={columns}
                    options={{
                      download: false,
                      filter: false,
                      print: false,
                      search: false,
                      selectableRows: SelectableRows.NONE,
                      viewColumns: false,
                      elevation: 0,
                    }}
                  />
                </Grid> : <Grid container xs={12} style={{ height: '100px' }}></Grid>}
              </>
            )}
          </Formik>
        ) : (
          <Typography variant={"h3"}>
            This vehicle has been hold by source branch. Please
            communicate with the source branch for further details or to release the hold.
          </Typography>
        )}
      </Grid>
    </>
  );
};